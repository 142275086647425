import { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from './useAuth'

const useWebSocket = (url) => {
  const ws = useRef(null)
  const updateActiveSts_timeout = useRef(null)
  const [wsMessage, setWsMessage] = useState({})
  const { userInfo } = useContext(UserContext)

  useEffect(() => {
    ws.current = new WebSocket(url)

    ws.current.onopen = () => {
      console.log('ws opened')
      ws.current.send(JSON.stringify({ action: 'GetOnlineUsers', orgId: userInfo.orgID }))
      setUpdateActiveStsTimer()
    }

    ws.current.onclose = () => {
      clearUpdateActiveStsTimer()
      console.log('ws closed')
    }

    ws.current.onmessage = (event) => {
      console.info('ws message', event)
      setWsMessage(JSON.parse(event.data))
    }

    return () => {
      ws.current.close()
    }
  }, [])

  const setUpdateActiveStsTimer = () => {
    clearUpdateActiveStsTimer()
    updateActiveStatus()
    updateActiveSts_timeout.current = setTimeout(() => {
      setUpdateActiveStsTimer()
    }, 1000 * 30)
  }

  const clearUpdateActiveStsTimer = () => {
    if (updateActiveSts_timeout.current) {
      clearTimeout(updateActiveSts_timeout.current)
    }
  }

  const force_logout = (forced_userId) => {
    ws.current.send(
      JSON.stringify({
        action: 'ForceLogout',
        orgId: userInfo.orgID,
        forcedUserId: forced_userId,
        platform: 'manager'
      })
    )
  }

  const changeUserObservationMode = (userIdArr, observationMode) => {
    ws.current.send(
      JSON.stringify({
        action: 'UpdateObservationMode',
        orgId: userInfo.orgID,
        userIdList: userIdArr,
        type: 'user',
        observationMode: observationMode,
        platform: 'manager'
      })
    )
  }

  const changeOrgObservationMode = (observationMode) => {
    ws.current.send(
      JSON.stringify({
        action: 'UpdateObservationMode',
        orgId: userInfo.orgID,
        userIdList: [],
        type: 'org',
        observationMode: observationMode,
        platform: 'manager'
      })
    )
  }

  const updateActiveStatus = () => {
    ws.current.send(
      JSON.stringify({
        action: 'UpdateExpiredTime'
      })
    )
  }

  return { force_logout, changeUserObservationMode, changeOrgObservationMode, wsMessage }
}

export default useWebSocket
