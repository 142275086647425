import styles from './TeacherList.module.css'
import SearchInput from '../../../parts/SearchInput'
import { useContext, useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import useTeacherList from '../../../CustomHooks/useTeacherList'
import CustomPagination from '../../../parts/CustomPagination'
import PrivacyModal from '../../../parts/Modals/PrivacyModal/PrivacyModal'
import { UserContext } from '../../../CustomHooks/useAuth'

const UtilityRow = ({ searchVal, handleSearchVal }) => {
  return (
    <div className={styles.searchInputWrap}>
      <SearchInput searchVal={searchVal} handleSearchVal={handleSearchVal} placeholder='Search People By Name or Email' />
    </div>
  )
}

const TableHeader = () => {
  return (
    <div className={`${styles.tableWrap} ${styles.tableHeader} ${styles.center}`}>
      <div className={`${styles.tableItem} ${styles.name}`}>Name</div>
      <div className={`${styles.tableItem} ${styles.email}`}>Email</div>
      <div className={`${styles.tableItem} ${styles.course}`}>Course</div>
      <div className={`${styles.tableItem} ${styles.displayName}`}>Display Name</div>
      <div className={`${styles.tableItem} ${styles.status}`}>status</div>
    </div>
  )
}

const TableContent = ({ item }) => {
  // const { course, email, firstName, lastName, nickname, status } = item
  let name = item.firstName ? `${item.firstName.S} ${item.lastName.S}` : item.nickname.S

  return (
    <div className={`${styles.tableWrap} ${styles.tableContent} ${styles.center}`}>
      <div className={`${styles.tableItem} ${styles.name}`}>{name}</div>
      <div className={`${styles.tableItem} ${styles.email}`}>{item.email.S}</div>
      <div className={`${styles.tableItem} ${styles.course}`}>
        {item.course.L.length !== 0 &&
          item.course.L.filter((item, idx) => idx < 3).map((item, index) => {
            let courseString = item.courseName

            return (
              <div key={index} className={styles.courseBlockContainer} title={courseString}>
                <div className={styles.ellipsis}>{courseString}</div>
              </div>
            )
          })}
        {item.course.L.length > 3 && (
          <div
            className={styles.leftCourse}
            title={item.course.L.map((item) => item.courseName)
              .filter((item, idx) => idx > 2)
              .join(' / ')}
          >{`+${item.course.L.length - 3}`}</div>
        )}
      </div>
      <div className={`${styles.tableItem} ${styles.displayName}`}>{`${item.nickname.S}`}</div>
      <div className={`${styles.tableItem} ${styles.status}`}>
        {item.status.S === 'UNCONFIRMED' && <div className={styles.pending}>pending</div>}
        {item.status.S !== 'UNCONFIRMED' && (
          <div>
            <CheckIcon sx={{ color: '#009135' }} />
          </div>
        )}
      </div>
    </div>
  )
}

const TeacherList = () => {
  const { allTeacherListWithCourses, loadingGetAllTeacherList, page, totalPage, handlePage, handleTotalPage } = useTeacherList()
  const [teacherFilterList, setTeacherFilterList] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const { chosenOrg } = useContext(UserContext)

  useEffect(() => {
    filterTeacherList()
  }, [searchVal, allTeacherListWithCourses])

  const handleSearchVal = (e) => {
    setSearchVal(e.target.value)
  }

  const filterCondition = (item) => {
    let name = item.firstName ? `${item.firstName.S} ${item.lastName.S}` : item.nickname.S
    let nameBool = name.toLowerCase().includes(searchVal.toLowerCase()) || searchVal === ''
    let emailBool = item.email.S.toLowerCase().includes(searchVal.toLowerCase())
    return nameBool || emailBool
  }

  const filterTeacherList = () => {
    console.info('filter teacher list', allTeacherListWithCourses, totalPage)
    let arr = allTeacherListWithCourses.filter((item) => filterCondition(item)).map((item) => item)
    setTeacherFilterList(arr)
    handleTotalPage(Math.max(Math.ceil(arr.length / 14), 1))
    handlePage(1)
  }

  return (
    <div className={styles.TeacherListWrap}>
      <UtilityRow searchVal={searchVal} handleSearchVal={handleSearchVal} />
      <div className={styles.tableContainer}>
        <TableHeader />
        {loadingGetAllTeacherList && (
          <div style={{ height: 'calc(100vh - 330px)', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        )}
        {allTeacherListWithCourses.length !== 0 && (
          <div style={{ position: 'relative', minHeight: '400px' }}>
            {teacherFilterList
              .filter((item, idx) => idx >= (page - 1) * 14 && idx <= (page - 1) * 14 + 13)
              .map((item, idx) => {
                item.email.S = chosenOrg === 'Free Trial' ? 'freetrial@email.com' : item.email.S

                return chosenOrg === 'Free Trial' ? (
                  <div key={idx} style={{ width: '100%', height: '100%', filter: 'blur(4px)', userSelect: 'none' }}>
                    <TableContent item={item} />
                  </div>
                ) : (
                  <TableContent key={idx} item={item} />
                )
              })}
            {chosenOrg === 'Free Trial' ? <PrivacyModal typ='others' /> : null}
          </div>
        )}
        {!loadingGetAllTeacherList && allTeacherListWithCourses.length === 0 && <div className={styles.messageBox}>Add your member!</div>}
      </div>
      {totalPage > 1 && (
        <div className={styles.paginationWrap}>
          <CustomPagination page={page} totalPage={totalPage} handlePage={handlePage} />
        </div>
      )}
    </div>
  )
}

export default TeacherList
