import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(() => ({
  borderRadius: '8px',
  border: 'none',
  '& .MuiAccordion-root Mui-expanded': {
    marginTop: '2rem'
  }
}))

const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1rem' }} />} {...props} />)(({ theme }) => ({
  padding: '0rem',
  backgroundColor: '#fff',
  borderRadius: '8px',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    margin: '1rem 0rem 1rem 1rem',
    color: '#1452CC'
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '0rem'
}))

const CustomAccordion = ({ expanded, handleAccordionChange, title: Title, details: Details, accordionStyles = {}, summaryStyles = {}, detailsStyles = {} }) => {
  return (
    <>
      <Accordion sx={accordionStyles} expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary sx={summaryStyles} aria-controls='panel1d-content' id='panel1d-header'>
          {Title}
        </AccordionSummary>
        <AccordionDetails sx={detailsStyles}>{Details}</AccordionDetails>
      </Accordion>
    </>
  )
}

export default CustomAccordion
