import { stripHtml } from 'string-strip-html'

const checkKeyExist = (item, key) => {
  return item[key] ? item[key] : ''
}

export const reformQTIItem_v3p0 = (assessmentItem) => {
  return {
    attr: {
      identifier: checkKeyExist(assessmentItem['$'], 'identifier'),
      title: checkKeyExist(assessmentItem['$'], 'title'),
      label: checkKeyExist(assessmentItem['$'], 'label'),
      language: checkKeyExist(assessmentItem['$'], 'language'),
      'tool-name': checkKeyExist(assessmentItem['$'], 'tool-name'),
      'tool-version': checkKeyExist(assessmentItem['$'], 'tool-version'),
      adaptive: checkKeyExist(assessmentItem['$'], 'adaptive'),
      'time-dependent': checkKeyExist(assessmentItem['$'], 'time-dependent'),
      dataExtension: checkKeyExist(assessmentItem['$'], 'dataExtension')
    },
    'qti-item-body': {
      attr: {
        id: assessmentItem['qti-item-body'][0]['$'] ? checkKeyExist(assessmentItem['qti-item-body'][0]['$'], 'id') : '',
        class: assessmentItem['qti-item-body'][0]['$'] ? checkKeyExist(assessmentItem['qti-item-body'][0]['$'], 'class') : '',
        language: assessmentItem['qti-item-body'][0]['$'] ? checkKeyExist(assessmentItem['qti-item-body'][0]['$'], 'language') : '',
        label: assessmentItem['qti-item-body'][0]['$'] ? checkKeyExist(assessmentItem['qti-item-body'][0]['$'], 'label') : '',
        dir: assessmentItem['qti-item-body'][0]['$'] ? checkKeyExist(assessmentItem['qti-item-body'][0]['$'], 'dir') : '',
        'data-catalog-idref': assessmentItem['qti-item-body'][0]['$'] ? checkKeyExist(assessmentItem['qti-item-body'][0]['$'], 'data-catalog-idref') : ''
      },
      'qti-choice-interaction': {
        attr: {
          'response-identifier': checkKeyExist(assessmentItem['qti-item-body'][0]['qti-choice-interaction'][0]['$'], 'response-identifier'),
          shuffle: checkKeyExist(assessmentItem['qti-item-body'][0]['qti-choice-interaction'][0]['$'], 'shuffle'),
          'max-choices': checkKeyExist(assessmentItem['qti-item-body'][0]['qti-choice-interaction'][0]['$'], 'max-choices'),
          'min-choices': checkKeyExist(assessmentItem['qti-item-body'][0]['qti-choice-interaction'][0]['$'], 'min-choices'),
          orientation: checkKeyExist(assessmentItem['qti-item-body'][0]['qti-choice-interaction'][0]['$'], 'orientation'),
          'data-min-selections-message': checkKeyExist(assessmentItem['qti-item-body'][0]['qti-choice-interaction'][0]['$'], 'data-min-selections-message'),
          'data-max-selections-message': checkKeyExist(assessmentItem['qti-item-body'][0]['qti-choice-interaction'][0]['$'], 'data-max-selections-message')
        },
        'qti-prompt': checkKeyExist(assessmentItem['qti-item-body'][0]['qti-choice-interaction'][0], 'qti-prompt'),
        'qti-simple-choice': checkKeyExist(assessmentItem['qti-item-body'][0]['qti-choice-interaction'][0], 'qti-simple-choice').map((item) => ({
          identifier: checkKeyExist(item['$'], 'identifier'),
          fixed: checkKeyExist(item['$'], 'fixed'),
          text: item['_']
        }))
      }
    },
    'qti-outcome-declaration': {
      attr: {
        identifier: checkKeyExist(assessmentItem['qti-outcome-declaration'][0]['$'], 'identifier'),
        cardinality: checkKeyExist(assessmentItem['qti-outcome-declaration'][0]['$'], 'cardinality'),
        'base-type': checkKeyExist(assessmentItem['qti-outcome-declaration'][0]['$'], 'base-type')
      }
    },
    'qti-response-declaration': {
      attr: {
        identifier: checkKeyExist(assessmentItem['qti-response-declaration'][0]['$'], 'identifier'),
        cardinality: checkKeyExist(assessmentItem['qti-response-declaration'][0]['$'], 'cardinality'),
        'base-type': checkKeyExist(assessmentItem['qti-response-declaration'][0]['$'], 'base-type')
      },
      'qti-correct-response': {
        'qti-value': checkKeyExist(assessmentItem['qti-response-declaration'][0]['qti-correct-response'][0], 'qti-value'),
        'qti-mapping': {
          attr: {
            'lower-bound': assessmentItem['qti-response-declaration'][0]['qti-mapping']
              ? checkKeyExist(assessmentItem['qti-response-declaration'][0]['qti-mapping'][0]['$'], 'lower-bound')
              : '',
            'upper-bound': assessmentItem['qti-response-declaration'][0]['qti-mapping']
              ? checkKeyExist(assessmentItem['qti-response-declaration'][0]['qti-mapping'][0]['$'], 'upper-bound')
              : '',
            'default-value': assessmentItem['qti-response-declaration'][0]['qti-mapping']
              ? checkKeyExist(assessmentItem['qti-response-declaration'][0]['qti-mapping'][0]['$'], 'default-value')
              : ''
          },
          'qti-map-entry': assessmentItem['qti-response-declaration'][0]['qti-mapping']
            ? checkKeyExist(assessmentItem['qti-response-declaration'][0]['qti-mapping'][0]['qti-map-entry'], 'default-value')
            : ''
        }
      }
    },
    'qti-response-processing': checkKeyExist(assessmentItem['qti-response-processing'][0]['$'], 'template')
  }
}

// canvas classic quiz <--> UNIVERSE
export const reformQTIItem_v1p2 = (assessmentItem) => {
  const typeConfig = {
    multiple_answers_question: 'multiple',
    multiple_choice_question: 'single',
    true_false_question: 'tf'
  }

  const getImagePath = (item) => {

    let image = ''
    let parse_result = stripHtml(item['presentation'][0]['material'][0]['mattext'][0]['_'], {
      ignoreTags: ['img', 'image']
    }).result

    const regex = /%[\w|%]*\/[\w|\W]*g/
    let m

    if ((m = regex.exec(parse_result)) !== null) {
        console.info('m', m)
        image = decodeURI(m[0].split('\" alt')[0])
    }

    return image
  }

  const processItem = (item) => {

    let titleType = item['presentation'][0]['material'][0]['mattext'][0]['$'].texttype

    let obj = {
      type: typeConfig[item['itemmetadata'][0]['qtimetadata'][0]['qtimetadatafield'][0]['fieldentry'][0]],
      title: titleType === 'text/html' ? stripHtml(item['presentation'][0]['material'][0]['mattext'][0]['_']).result : item['presentation'][0]['material'][0]['mattext'][0]['_'],
      option: item['presentation'][0]['response_lid'][0]['render_choice'][0]['response_label'].map((labelItem) => ({
        text: labelItem['material'][0]['mattext'][0]['_'],
        correct: item['resprocessing'][0]['respcondition'][0]['conditionvar'][0]['and']
          ? item['resprocessing'][0]['respcondition'][0]['conditionvar'][0]['and'][0]['varequal'].map((varItem) => varItem['_']).includes(labelItem['$']['ident'])
          : item['resprocessing'][0]['respcondition'][0]['conditionvar'][0]['varequal'].map((varItem) => varItem['_']).includes(labelItem['$']['ident'])
      })),
      imagePath: getImagePath(item)
    }

    if(obj.type == 'tf') {
      obj.option[0].text = 'True'
      obj.option[1].text = 'False'
    }

    return obj
  }

  let list = []
  // 如果key是item
  if (assessmentItem.item) {
    assessmentItem.item.forEach((item) => {
      list.push(processItem(item))
    })
  }

  // 如果key是section
  if (assessmentItem.section) {
    assessmentItem.section.forEach((sectionObj) => {
      if (sectionObj.item) {
        sectionObj.item.forEach((item) => {
          list.push(processItem(item))
        })
      }
    })
  }

  return list
}
