import styles from './ChangeDateCard.module.css'
import ArrowLeftIcon from '../../../assets/icons/arrowLeft.svg'
import ArrowRightIcon from '../../../assets/icons/arrowRight.svg'

const ChangeDateCard = ({ hasPrev, date, hasNext, onClickArrow }) => {
  return (
    <div className={styles.changeDateCard}>
      <div className={`${styles.arrow} ${hasPrev ? undefined : styles.disabled}`} onClick={() => onClickArrow('prev', hasPrev)}>
        <img alt='previous' src={ArrowLeftIcon} />
      </div>
      <div className={styles.date}>
        <p>{date}</p>
      </div>
      <div className={`${styles.arrow} ${hasNext ? undefined : styles.disabled}`} onClick={() => onClickArrow('next', hasNext)}>
        <img alt='next' src={ArrowRightIcon} />
      </div>
    </div>
  )
}

export default ChangeDateCard
