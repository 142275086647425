import styles from './DeleteQuizRecordModal.module.css'

const DeleteQuizRecordModal = ({ onDeleteRecordQuiz, setDeleteQuizRecordModal }) => {
  return (
    <div className={styles.modalWrap}>
      <div className={styles.title}>Delete Quiz Record</div>
      <div className={styles.message}>Are you sure you want to delete this Quiz record?</div>
      <div className={styles.btnWrap}>
        <div
          className={`${styles.delete} ${styles.btn}`}
          onClick={() => {
            setDeleteQuizRecordModal(false)
            onDeleteRecordQuiz()
          }}
        >
          Delete
        </div>
        <div className={`${styles.cancel} ${styles.btn}`} onClick={() => setDeleteQuizRecordModal(false)}>
          Cancel
        </div>
      </div>
    </div>
  )
}

export default DeleteQuizRecordModal
