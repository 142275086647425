import Pagination from '@mui/material/Pagination'
import { styled } from '@mui/material/styles'

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-previousNext': {
    width: 48,
    height: 48,
    borderRadius: 8,
    backgroundColor: '#fff',
    color: '#000000',
  },
  '& .MuiPaginationItem-previousNext .MuiPaginationItem-icon': {
    transform: 'scale(2)'
  },
  '& .MuiPaginationItem-page': {
    width: 48,
    height: 48,
    borderRadius: 8,
    color: '#888888',
    font: 'font: normal normal 600 13px/16px InterRegular;',
  },
  '& .Mui-selected.MuiPaginationItem-page': {
    color: '#1452CC',
    backgroundColor: 'transparent',
  },
}));

const CustomPagination = ({ page, totalPage, handlePage }) => {
  return (
    <StyledPagination count={totalPage} page={page} onChange={(e, p) => handlePage(p)} />
  )
}

export default CustomPagination