import styles from './EditSchedule.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import useScheduleForm from '../../../CustomHooks/useScheduleForm'
import Modal from '../../../parts/Modal'
import dayjs from 'dayjs'
import { useState } from 'react'
import moment from 'moment-timezone'
import BorderInput from '../../../parts/BorderForm/BorderInput'
import BorderWindowSelect from '../../../parts/BorderForm/BorderWindowSelect'
import BorderGroupSelect from '../../../parts/BorderForm/BorderGroupSelect'
import { useContext } from 'react'
import { UserContext } from '../../../CustomHooks/useAuth'
import { HourMinPicker, LinkDatePicker, CustomDatePicker } from '../../../parts/TimePicker/TimePicker'
import SquareCheckbox from '../../../parts/SquareCheckbox'
const timeZoneOptions = moment.tz.names()

const Form = ({
  courseName,
  handleCourseNameChange,
  teachers,
  teacherName,
  handleTeacherNameChange,
  sDate,
  handleSdateChange,
  stim,
  handleStimChange,
  etim,
  handleEtimChange,
  handleAccept,
  frequency,
  handleFrequency,
  chosenWeekDayArr,
  handleChosenWeekDayArr,
  disableWeekDayOptions,
  eDate,
  handleEdateChange,
  editInfoMode,
  classroom,
  handleClassroomChange,
  timeZone,
  handletimeZoneChange,
  autoJoin,
  handleAutoJoin
}) => {
  const disabled = editInfoMode === 'Single'
  const { userInfo } = useContext(UserContext)

  return (
    <div className={styles.formWrap}>
      <div className={styles.firstRow}>
        <div className={styles.courseName}>
          <BorderInput inputhVal={courseName} handleInputVal={handleCourseNameChange} placeholder='Course' isValid={true} helperText='' disabled={disabled} />
        </div>
        {teachers.length !== 0 && (
          <div className={styles.teacherName}>
            <BorderWindowSelect
              label='Teacher'
              options={[...new Set(teachers)].map((item) => item.name)}
              val={teacherName}
              handleValChange={handleTeacherNameChange}
              disabled={userInfo.identity === 'teacher'}
            />
          </div>
        )}
        <div className={styles.classroom}>
          <BorderGroupSelect val={classroom} handleValChange={handleClassroomChange} disabled={disabled} height={60} />
        </div>
      </div>
      <div className={styles.secondRow}>
        <div className={styles.left}>
          <div className={styles.timePickerWrap}>
            <CustomDatePicker value={sDate} handleChange={handleSdateChange} />
          </div>
          <div className={styles.timePickerWrap}>
            <HourMinPicker label='Start Time' value={stim} otherValue={etim} handleChange={handleStimChange} />
          </div>
        </div>
        <div className={styles.timePickerWrap}>
          <HourMinPicker label='End Time' value={etim} otherValue={stim} handleChange={handleEtimChange} />
        </div>
        <div className={styles.timePickerWrap}>
          <BorderWindowSelect label='timeZone' options={timeZoneOptions} val={timeZone} handleValChange={handletimeZoneChange} disabled={disabled} />
        </div>
      </div>
      <div className={styles.thirdRow}>
        <div className={`${styles.frequency}`}>
          <BorderWindowSelect
            label='Frequency'
            options={['Repeat: Never', 'Every week', 'Every month']}
            val={frequency}
            handleValChange={handleFrequency}
            typ='inset'
            disabled={disabled}
          />
        </div>
        <div className={`${styles.weekdayWrap} ${disableWeekDayOptions || disabled ? styles.disabled : ''}`}>
          {chosenWeekDayArr.map((item, idx) => {
            return (
              <div key={idx} className={`${styles.weekDayBtn} ${styles.block} ${item.selected ? styles.active : ''}`} onClick={() => handleChosenWeekDayArr(item)}>
                {item.text}
              </div>
            )
          })}
        </div>
      </div>
      <div className={`${styles.forthRow} ${(frequency === 'Repeat: Never' || disabled) && styles.disabled}`}>
        <div className={`${styles.timStrWrap} ${disableWeekDayOptions ? styles.disabled : ''}`}>{`From: ${dayjs(sDate).format('YYYY/MM/DD')}`}</div>
        <div className={`${styles.timStrWrap} ${disableWeekDayOptions ? styles.disabled : ''}`}>
          <div>To:</div>
          <div className={styles.linkPicker}>
            <LinkDatePicker typ='linkInput' value={eDate} handleChange={handleEdateChange} />
          </div>
        </div>
      </div>
      {editInfoMode == 'Multiple' && (
        <div>
          <SquareCheckbox val={autoJoin} handleValChange={handleAutoJoin} option='Enable students to be joined into the classroom at the scheduled time.' />
        </div>
      )}
    </div>
  )
}

const ConfirmModal = ({ confirmModalTyp, closeConfirmModal, handleAction }) => {
  let config = {
    Discard: { title: 'Discard', content: 'Are you sure you want to discard all the changes?', action: 'Discard' },
    EditNever: { title: 'Edit Class', content: 'Are you sure you want to change this class?', action: 'Save' },
    EditSingle: { title: 'Edit Class', content: 'Are you sure you want to change this class?', action: 'Save' },
    EditMultiple: { title: 'Edit Classes', content: 'Are you sure you want to change all classes in the series?', action: 'Save' },
    DeleteNever: { title: 'Delete', content: 'Are you sure you want to delete this class?', action: 'Delete' },
    DeleteSingle: { title: 'Delete', content: 'Are you sure you want to delete this class?', action: 'Delete' },
    DeleteMultiple: { title: 'Delete', content: 'Are you sure you want to delete all classes in the series?', action: 'Delete' }
  }

  return (
    <div className={styles.confirmModalWrap}>
      <div className={styles.title}>{config[confirmModalTyp].title}</div>
      <div className={styles.content}>{config[confirmModalTyp].content}</div>
      <div className={styles.btnWrap}>
        <button className={`${styles.btn} ${styles.action} ${styles[config[confirmModalTyp].action]}`} onClick={handleAction}>
          {config[confirmModalTyp].action}
        </button>
        <button className={`${styles.btn} ${styles.cancel}`} onClick={() => closeConfirmModal()}>
          Cancel
        </button>
      </div>
    </div>
  )
}

const EditSchedule = ({ closeEditModal, call_editApi, editInfoMode, call_deleteApi, editInfoItem, handleScheduleInfoModal }) => {
  const {
    courseName,
    handleCourseNameChange,
    teachers,
    teacherName,
    handleTeacherNameChange,
    sDate,
    handleSdateChange,
    stim,
    handleStimChange,
    etim,
    handleEtimChange,
    handleAccept,
    frequency,
    handleFrequency,
    chosenWeekDayArr,
    handleChosenWeekDayArr,
    disableWeekDayOptions,
    eDate,
    handleEdateChange,
    reformEditItem,
    classroom,
    handleClassroomChange,
    timeZone,
    handletimeZoneChange,
    disableAddBtn,
    autoJoin,
    handleAutoJoin
  } = useScheduleForm(editInfoItem, editInfoMode)
  const [confirmModalTyp, setConfirmModalTyp] = useState('')

  const openConfirmModal = (typ) => {
    // closeEditModal
    if (typ === 'Edit') {
      if (disableAddBtn) return
    }
    setConfirmModalTyp(typ)
  }

  const closeConfirmModal = () => {
    setConfirmModalTyp('')
  }

  const handleAction = () => {
    switch (confirmModalTyp) {
      case 'Discard':
        break
      case 'DeleteNever':
      case 'DeleteSingle':
      case 'DeleteMultiple':
        call_deleteApi(editInfoItem)
        break
      case 'EditSingle':
      case 'EditNever':
      case 'EditMultiple':
        call_editApi(reformEditItem())
        break
      default:
    }
    closeConfirmModal()
    closeEditModal()
  }

  return (
    <div className={styles.editScheduleWrap}>
      <div className={styles.titleWrap}>
        <div className={styles.title}>{editInfoMode === 'Single' || editInfoMode === 'Never' ? 'Edit Class' : 'Edit Classes'}</div>
        <div className={styles.cancel} onClick={() => openConfirmModal('Discard')}>
          <CloseRoundedIcon sx={{ color: '#808080', '&:hover': { color: '#1452CC' } }} />
        </div>
      </div>
      <Form
        courseName={courseName}
        handleCourseNameChange={handleCourseNameChange}
        teachers={teachers}
        teacherName={teacherName}
        handleTeacherNameChange={handleTeacherNameChange}
        sDate={sDate}
        handleSdateChange={handleSdateChange}
        stim={stim}
        handleStimChange={handleStimChange}
        etim={etim}
        handleEtimChange={handleEtimChange}
        handleAccept={handleAccept}
        frequency={frequency}
        handleFrequency={handleFrequency}
        chosenWeekDayArr={chosenWeekDayArr}
        handleChosenWeekDayArr={handleChosenWeekDayArr}
        disableWeekDayOptions={disableWeekDayOptions}
        eDate={eDate}
        handleEdateChange={handleEdateChange}
        editInfoMode={editInfoMode}
        classroom={classroom}
        handleClassroomChange={handleClassroomChange}
        timeZone={timeZone}
        handletimeZoneChange={handletimeZoneChange}
        autoJoin={autoJoin}
        handleAutoJoin={handleAutoJoin}
      />
      <div className={styles.btnWrap}>
        <div className={`${styles.btn} ${styles.delete}`} onClick={() => openConfirmModal(`Delete${editInfoMode}`)}>
          Delete
        </div>
        <div className={`${styles.btn} ${styles.save} ${disableAddBtn && styles.disabled}`} onClick={() => openConfirmModal(`Edit${editInfoMode}`)}>
          Save
        </div>
      </div>
      {!!confirmModalTyp && (
        <Modal>
          <ConfirmModal confirmModalTyp={confirmModalTyp} closeConfirmModal={closeConfirmModal} handleAction={handleAction} />
        </Modal>
      )}
    </div>
  )
}

export default EditSchedule
