import styles from './SingleResult.module.css'
import ArrowIcon from '../../../assets/icons/breadcrumbArrow.svg'
import { useEffect, useState } from 'react'
import TimerIcon from '../../../assets/icons/timer.svg'
import CircularProgress from '@mui/material/CircularProgress'
import { convertToDate, convertToAMPMtime } from '../../../utils/time'
import Questions from './Questions/Questions'
import Participants from './Participants/Participants'
import Modal from '../../Modal'
import ShowImageModal from '../../Modals/ShowImageModal/ShowImageModal'

const SingleResult = ({ typ, setStep, selectedParent, resultLoading, call_getResultsQuestions, call_getResultsParticipants, 
  questionRes, participantRes, setResultParticipants }) => {

  const [tab, setTab] = useState('Questions')
  const [openShowImageModal, setOpenShowImageModal] = useState(false)
  const [imgURL, setImgURL] = useState(null)

  const handleShowImageModal = (url=null, sts) => {
    setImgURL(url)
    setOpenShowImageModal(sts)
  }

  const navigateToParentList = () => {
    setStep(1)
  }

  const navigateToResults = () => {
    setStep(6)
  }

  useEffect(() => {
    if(typ == 'Assignment') {
      if(tab == 'Questions') {
        call_getResultsQuestions({
          assignmentId: selectedParent.assignmentId
        })
      } else {
        call_getResultsParticipants({
          assignmentId: selectedParent.assignmentId
        })
      }
    }

    if(typ == 'Quiz') {
      if(tab == 'Questions') {
        call_getResultsQuestions({
          popQuizId: selectedParent.popQuizId
        })
      } else {
        call_getResultsParticipants({
          popQuizId: selectedParent.popQuizId
        })
      }
    }
  }, [tab])

  return (
    <>
      <div className={styles.breadCrumbs}>
        <div className={`${styles.tab} ${styles.pointer}`} onClick={() => navigateToParentList()}>
          { typ }
        </div>
        <div>
          <img src={ArrowIcon} alt='' />
        </div>
        <div className={`${styles.tab} ${styles.pointer}`} onClick={() => navigateToResults()}>{ typ } Results</div>
        <div>
          <img src={ArrowIcon} alt='' />
        </div>
        <div className={styles.tab}>{selectedParent.name}</div>
      </div>
      <div className={styles.container}>
        {resultLoading && (
          <div
            style={{
              height: 'calc(100vh - 330px)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!resultLoading && (
          <div>
            <div className={styles.titleArea}>
              <div>
                <h1>{selectedParent.name}</h1>
                { (typ === 'Quiz') && <div className={styles.classroom}>{ selectedParent.course }</div> }
                <div className={styles.timeArea}>
                  <img alt='latestUpdated' src={TimerIcon} />
                  {
                    (typ === 'Assignment') ? (
                      <p>
                        Latest Updated:{' '}
                        {`${convertToDate(selectedParent.updatedDate)} ${convertToAMPMtime(selectedParent?.updatedDate)} (UTC${new Date()
                          .toString()
                          .split('GMT')[1]
                          .substring(0, 5)})`}{' '}
                      </p>
                    ) : (
                      <p>
                        Course Date:{' '}
                        {`${convertToDate(selectedParent.courseDate)} ${convertToAMPMtime(selectedParent?.courseDate)} (UTC${new Date()
                          .toString()
                          .split('GMT')[1]
                          .substring(0, 5)})`}{' '}
                      </p>
                    )
                  }
                </div>
              </div>
              <div className={styles.tabArea}>
                <div className={styles.buttonArea}>
                  <button onClick={() => setTab('Questions')} className={`${tab == 'Questions' && styles.active}`}>Questions</button>
                  <button onClick={() => setTab('Participants')} className={`${tab == 'Participants' && styles.active}`}>Participants</button>
                </div>
                <div className={styles.descriptionArea}>
                  <div className={styles.correct}>
                    <div></div>
                    <p>Correct</p>
                  </div>
                  <div className={styles.incorrect}>
                    <div></div>
                    <p>Incorrect</p>
                  </div>
                </div>
              </div>
            </div>
            {
              (tab === 'Questions') ? (
                <Questions typ={typ} result={questionRes} />
              ) : (
                <Participants 
                  typ={typ}
                  result={participantRes} 
                  setResultParticipants={setResultParticipants}
                  handleShowImageModal={handleShowImageModal}
                />
              )
            }
          </div>
        )}
      </div>
      {
        openShowImageModal && (
          <Modal>
            <ShowImageModal handleModal={handleShowImageModal} imgURL={imgURL} />
          </Modal>
        )
      }
    </>
  )
}

export default SingleResult
