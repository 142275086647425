import styles from './CreateNewQuestion.module.css'
import ArrowIcon from '../../../assets/icons/breadcrumbArrow.svg'
// import AssignmentIcon from '../../../assets/icons/breadcrumbQuiz.svg'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import FormGroup from '@mui/material/FormGroup'
import RadioUnselected from '../../../assets/icons/quiz/radioUnselected.svg'
import RadioSelected from '../../../assets/icons/quiz/radioSelected.svg'
import CheckboxChecked from '../../../assets/icons/quiz/checkboxChecked.svg'
import CheckboxUnchecked from '../../../assets/icons/quiz/checkboxUnchecked.svg'
import { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import useAutosizeTextArea from '../../../CustomHooks/useAutosizeTextArea'
import DeleteIcon from '../../../assets/icons/delete.svg'
import Modal from '../../../parts/Modal'
import DiscardChangesModal from '../../../parts/Modals/DiscardChangesModal/DiscardChangesModal'
import { useRef } from 'react'
import SelectFilterIcon from '../../../parts/SelectFilterIcon'
import TrueFalseIcon from '../../../assets/icons/quiz/trueFalse.svg'
import SingleSelectionIcon from '../../../assets/icons/quiz/singleSelection.svg'
import MultipleSelectionIcon from '../../../assets/icons/quiz/multipleSelection.svg'
import { v4 as uuidv4 } from 'uuid'
import ImageIcon from '../../../assets/icons/image.svg'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import { uploadImage } from '../../../api'

const Question = ({
  preview,
  setPreview,
  selectedFile,
  setSelectedFile,
  fileInputValue,
  setFileInputValue,
  selectedQuestionType,
  questionContent,
  setQuestionContent,
  setIsInit,
  generateS3ImageId
}) => {
  const [height, setHeight] = useState(null)
  const textareaRef = useRef(null)
  useAutosizeTextArea(textareaRef.current, questionContent)

  const onDeleteImage = () => {
    setPreview(null)
    // setFileInputValue('')
    setSelectedFile(null)
    setIsInit(false)
  }

  const onContentChange = (e) => {
    setQuestionContent(e.target.value)
    setIsInit(false)
  }

  const getFile = (e) => {
    console.log('e', e)
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    if (e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpeg') return

    setSelectedFile(e.target.files[0])
    setIsInit(false)
  }

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    generateS3ImageId(selectedFile)

    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  return (
    <>
      <div className={styles.titleWrap}>
        <div className={styles.questionTypeIcon}>{selectedQuestionType}</div>
        <p>Question</p>
        <span>*</span>
        <input className={styles.inputFile} id='file' type='file' accept='image/png, image/jpeg' onChange={getFile} value={fileInputValue} />
        <label htmlFor='file'>
          <div className={styles.imageIcon}></div>
        </label>
      </div>
      <div className={styles.questionContent} style={{ height: height }}>
        <textarea className={styles.textarea} placeholder='Type your question here...' ref={textareaRef} value={questionContent} onChange={(e) => onContentChange(e)}></textarea>
        {preview && (
          <div className={styles.imageArea}>
            <div className={styles.photoCenter}>
              <img className={styles.image} alt='question' src={preview} />
              <img className={styles.delete} alt='delete' src={DeleteIcon} onClick={onDeleteImage} />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const TrueFalse = ({ selectedTFValue, setSelectedTFValue, setIsInit }) => {
  const handleTFChange = (state) => {
    setIsInit(false)
    setSelectedTFValue(state)
  }

  return (
    <>
      <div className={styles.trueFalseOptions}>
        <img alt='selected' src={selectedTFValue ? RadioSelected : RadioUnselected} onClick={() => handleTFChange(true)} />
        <label onClick={() => handleTFChange(true)}>True</label>
      </div>
      <div className={styles.trueFalseOptions}>
        <img alt='selected' src={selectedTFValue ? RadioUnselected : RadioSelected} onClick={() => handleTFChange(false)} />
        <label onClick={() => handleTFChange(false)}>False</label>
      </div>
    </>
  )
}

const SingleSelection = ({ selectedSingleValue, setSelectedSingleValue, singleOptions, setSingleOptions, inputStyle, setIsInit }) => {
  const addNewSingleOptions = () => {
    setIsInit(false)
    setSingleOptions((prev) => [...prev, ''])
  }

  const deleteSingleOption = (index) => {
    setIsInit(false)
    setSingleOptions((prev) => {
      let temp = [...prev]
      temp.splice(index, 1)
      return temp
    })
  }

  const handleSingleAnsChange = (index) => {
    setIsInit(false)
    setSelectedSingleValue(index)
  }

  const handleSingleInputChange = (e, index) => {
    setIsInit(false)
    setSingleOptions((prev) => {
      let temp = [...prev]
      temp[index] = e.target.value
      return temp
    })
  }
  return (
    <>
      {singleOptions.map((option, index) => {
        return (
          <div key={index} className={styles.singleSelection}>
            <img alt='selected' src={index === selectedSingleValue ? RadioSelected : RadioUnselected} onClick={() => handleSingleAnsChange(index)} />
            <TextField required sx={inputStyle} value={option} placeholder='Type an answer option here' onChange={(e) => handleSingleInputChange(e, index)} id='outlined-basic' />
            {singleOptions.length > 2 && (
              <CloseRoundedIcon
                onClick={() => deleteSingleOption(index)}
                sx={{
                  cursor: 'pointer',
                  marginLeft: '1rem',
                  fontSize: '2rem',
                  color: '#808080',
                  '&:hover': { color: '#1452CC' }
                }}
              />
            )}
          </div>
        )
      })}
      <button className={styles.addOptionBtn} onClick={addNewSingleOptions}>
        + Add Option
      </button>
    </>
  )
}

const MultipleSelection = ({ multipleOptions, setMultipleOptions, inputStyle, setIsInit }) => {
  const deleteMultipleOption = (index) => {
    setIsInit(false)
    setMultipleOptions((prev) => {
      let temp = [...prev]
      temp.splice(index, 1)
      return temp
    })
  }

  const addNewMultipleOptions = () => {
    setIsInit(false)
    setMultipleOptions((prev) => [...prev, { checked: false, input: '' }])
  }

  const handleMultipleInputChange = (e, index) => {
    setIsInit(false)
    setMultipleOptions((prev) => {
      let temp = [...prev]
      temp[index].input = e.target.value
      return temp
    })
  }

  const handleCheckboxChange = (index) => {
    setIsInit(false)
    setMultipleOptions((prev) => {
      let temp = JSON.parse(JSON.stringify(prev))
      temp[index].checked = !temp[index].checked
      return temp
    })
  }
  return (
    <FormGroup>
      {multipleOptions.map((option, index) => {
        return (
          <div key={index} className={styles.multipleSelection}>
            <img alt='checked' src={option.checked ? CheckboxChecked : CheckboxUnchecked} onClick={() => handleCheckboxChange(index)} />
            <TextField
              required
              sx={inputStyle}
              value={option.input}
              placeholder='Type an answer option here'
              onChange={(e) => handleMultipleInputChange(e, index)}
              id='outlined-basic'
            />
            {multipleOptions.length > 2 && (
              <CloseRoundedIcon
                onClick={() => deleteMultipleOption(index)}
                sx={{
                  cursor: 'pointer',
                  marginLeft: '1rem',
                  fontSize: '2rem',
                  color: '#808080',
                  '&:hover': { color: '#1452CC' }
                }}
              />
            )}
          </div>
        )
      })}
      <button className={styles.addOptionBtn} onClick={addNewMultipleOptions}>
        + Add Option
      </button>
    </FormGroup>
  )
}

const CreateNewQuestion = ({
  typ,
  setStep,
  selectedQuestionType,
  setSelectedQuestionType,
  questionTypeList,
  actionType,
  questionActionType,
  selectedParent,
  selectedQuestion,
  call_updateQuestionApi,
  call_createQuestionApi,
  setSeverity,
  handleOpenToaster,
  isQuestionSaving,
  setIsQuestionSaving,
  saveImageLoading,
  setSaveImageLoading
}) => {
  const [selectedTFValue, setSelectedTFValue] = useState(true)
  const [singleOptions, setSingleOptions] = useState(['', '', ''])
  const [multipleOptions, setMultipleOptions] = useState([
    { checked: false, input: '' },
    { checked: false, input: '' },
    { checked: false, input: '' }
  ])
  const [selectedSingleValue, setSelectedSingleValue] = useState(0)
  const [questionContent, setQuestionContent] = useState('')
  const [disableSaveBtn, setDisabledSaveBtn] = useState(true)
  const [discardChangesModal, setDiscardChangesModal] = useState(false)
  const [isInit, setIsInit] = useState(true)
  const [goBack, setGoBack] = useState('')
  const [selectedFile, setSelectedFile] = useState(false)
  const [fileInputValue, setFileInputValue] = useState('')
  const [preview, setPreview] = useState(null)
  const [quesId, setQuesId] = useState(null)
  const [imgId, setImgId] = useState(null)

  useEffect(() => {
    if (questionActionType != 'create') {
      setImgId(selectedQuestion.image)
      setQuesId(selectedQuestion.questionId)
      return
    }
    const id = uuidv4()
    setQuesId(id)
  }, [])

  const generateS3ImageId = (selectedFile) => {
    const id = `${quesId}-${+new Date()}`
    setImgId(id)
    setSaveImageLoading(true)
    let imageData = {
      folderName: typ.toLowerCase(),
      imageId: id
    }
    let headers = {
      'Content-Type': 'multipart/form-data'
    }
    const formData = new FormData()
    formData.append('file', selectedFile)
    uploadImage(imageData)
      .then(async (res) => {
        const s3Url = res.data.s3_signed_url
        await axios.put(s3Url, selectedFile, headers)
        setSaveImageLoading(false)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const questionTypeIconList = [TrueFalseIcon, SingleSelectionIcon, MultipleSelectionIcon]

  const navigateToParentList = () => {
    setGoBack(typ.toLowerCase() + 'List')
  }

  const navigateToAddNewParent = () => {
    setGoBack('addNew' + typ)
  }

  const onQuestionTypeChange = (e) => {
    console.log('e.target.value', e.target.value)
    setIsInit(false)
    setSelectedQuestionType((prev) => {
      switch (prev) {
        case 'True/False':
          if (e.target.value === 'Single Selection') {
            setSingleOptions(['True', 'False'])
            setSelectedSingleValue(selectedTFValue ? 0 : 1)
          } else {
            setMultipleOptions([
              {
                input: 'True',
                checked: selectedTFValue
              },
              {
                input: 'False',
                checked: !selectedTFValue
              }
            ])
          }
          break
        case 'Single Selection':
          if (e.target.value === 'Multiple Selection') {
            let options = []
            singleOptions.forEach((option, index) => {
              options.push({
                input: option,
                checked: selectedSingleValue === index
              })
            })
            setMultipleOptions(options)
          }
          break
        case 'Multiple Selection':
          if (e.target.value === 'Single Selection') {
            let options = []
            options = [...multipleOptions].map((option) => option.input)
            setSingleOptions(options)
            let checked = false
            for (let i = 0; i < multipleOptions.length; i++) {
              if (multipleOptions[i].checked) {
                setSelectedSingleValue(i)
                checked = true
                break
              }
            }
            if (!checked) setSelectedSingleValue(0)
          }
          break
        default:
      }
      return e.target.value
    })
  }

  const onSaveQuestion = () => {
    if (!questionContent.trim() && !preview) {
      setSeverity('error')
      handleOpenToaster('Question must contain content or image.')
      return
    }

    // console.log("questionContent", questionContent);
    setDisabledSaveBtn(true)
    setIsQuestionSaving(true)
    let isOptionEmpty = false
    let type = ''
    let options = []
    switch (selectedQuestionType) {
      case 'True/False':
        type = 'tf'
        options = [
          {
            text: 'True',
            correct: selectedTFValue
          },
          {
            text: 'False',
            correct: !selectedTFValue
          }
        ]
        break
      case 'Single Selection':
        type = 'single'
        options = JSON.parse(JSON.stringify(singleOptions))
        options = options.map((option, index) => {
          if (!option.trim()) isOptionEmpty = true
          return {
            text: option.trim(),
            correct: index === selectedSingleValue
          }
        })
        break
      default:
        type = 'multiple'
        options = JSON.parse(JSON.stringify(multipleOptions))
        options = options.map((option, index) => {
          if (!option.input.trim()) isOptionEmpty = true
          return {
            text: option.input.trim(),
            correct: option.checked
          }
        })
        break
    }

    if (isOptionEmpty) {
      setSeverity('error')
      handleOpenToaster('Question cannot be empty.')
      return
    }

    let data = {
      questionId: quesId,
      type: type,
      image: preview ? imgId : null,
      content: questionContent.trim(),
      options: options
    }

    if (questionActionType === 'create') {
      if (typ == 'Assignment') {
        data.assignmentId = selectedParent.assignmentId
      } else {
        data.quizId = selectedParent.quizId
      }
      console.info('call create question api data', data)
      call_createQuestionApi(data)
    } else {
      data.imageUrl = selectedQuestion.image
      console.info('call update question api data', data, selectedQuestion)
      call_updateQuestionApi(data)
    }
  }

  const inputStyle = {
    width: '80%',
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#EBEDF0',
      borderRadius: '8px'
    },
    '& 	.MuiOutlinedInput-notchedOutline': {
      border: 'none',
      backgroundColor: 'none'
    }
  }

  const checkDisableSaveBtn = () => {
    if (selectedQuestionType === 'True/False') {
      if (!questionContent && !preview) {
        setDisabledSaveBtn(true)
      } else {
        setDisabledSaveBtn(false)
      }
    } else if (selectedQuestionType === 'Single Selection') {
      let isEmpty = false
      singleOptions.forEach((option) => {
        if (!option) {
          setDisabledSaveBtn(true)
          isEmpty = true
          return
        }
      })
      if (isEmpty || (!questionContent && !preview)) {
        setDisabledSaveBtn(true)
      } else {
        setDisabledSaveBtn(false)
      }
    } else if (selectedQuestionType === 'Multiple Selection') {
      let isEmpty = false
      let checked = false
      multipleOptions.forEach((option) => {
        if (!option.input) {
          setDisabledSaveBtn(true)
          isEmpty = true
        }
        if (option.checked) {
          checked = true
        }
      })
      if (isEmpty || !checked || (!questionContent && !preview)) {
        setDisabledSaveBtn(true)
      } else {
        setDisabledSaveBtn(false)
      }
    }
  }

  const onDiscard = () => {
    setDiscardChangesModal(false)
    goBack === `${typ.toLowerCase()}List` ? setStep(1) : setStep(2)
  }

  const onCancel = () => {
    setGoBack('')
    setDiscardChangesModal(false)
  }

  useEffect(() => {
    setIsInit(true)
    checkDisableSaveBtn()
    if (questionActionType !== 'create') {
      console.log('selectedQuestion', selectedQuestion)
      let type = ''
      let options = selectedQuestion.options
      switch (selectedQuestion.type) {
        case 'tf':
          type = 'True/False'
          options.forEach((option) => {
            if (option.text === 'True') {
              setSelectedTFValue(option.correct)
            }
          })
          break
        case 'single':
          type = 'Single Selection'
          options = options.map((option, index) => {
            if (option.correct) {
              setSelectedSingleValue(index)
            }
            return option.text
          })
          setSingleOptions(options)
          break
        default:
          type = 'Multiple Selection'
          options = options.map((option) => {
            return {
              input: option.text,
              checked: option.correct
            }
          })
          setMultipleOptions(options)
      }
      setSelectedQuestionType(type)
      setQuestionContent(selectedQuestion.content)
      setPreview(selectedQuestion.image)
    }
  }, [])

  useEffect(() => {
    if (goBack) {
      if (!isInit) {
        setDiscardChangesModal(true)
      } else {
        goBack === `${typ.toLowerCase()}List` ? setStep(1) : setStep(2)
      }
    }
  }, [goBack, isInit])

  useEffect(() => {
    checkDisableSaveBtn()
  }, [questionContent, singleOptions, multipleOptions, selectedQuestionType, preview])

  return (
    <>
      <div className={styles.breadCrumbs}>
        <div className={`${styles.tab} ${styles.pointer}`} onClick={() => navigateToParentList()}>
          {typ}
        </div>
        <div>
          <img src={ArrowIcon} alt='' />
        </div>
        <div className={`${styles.tab} ${styles.clickable}`} onClick={() => navigateToAddNewParent()}>
          {actionType === 'create' ? `Add New ${typ}` : `Edit ${typ}`}
        </div>
        <div>
          <img src={ArrowIcon} alt='' />
        </div>
        <div className={styles.tab}>{questionActionType === 'create' ? 'Create New Question' : 'Edit Question'}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.contentWrap}>
          <div className={styles.title}>{questionActionType === 'create' ? 'Create New Question' : 'Edit Question'}</div>
          <div className={styles.dropdownArea}>
            <p>Change your Question Type:</p>
            <SelectFilterIcon options={questionTypeList} icons={questionTypeIconList} val={selectedQuestionType} handleValChange={onQuestionTypeChange} typ='border' />
          </div>
          <Question
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            fileInputValue={fileInputValue}
            setFileInputValue={setFileInputValue}
            selectedQuestionType={selectedQuestionType}
            questionContent={questionContent}
            setQuestionContent={setQuestionContent}
            setIsInit={setIsInit}
            preview={preview}
            setPreview={setPreview}
            generateS3ImageId={generateS3ImageId}
          />
          <div className={styles.optionsArea}>
            <div className={styles.titleWrap}>
              <p>Options</p>
              <span>*</span>
            </div>
            <div>
              {selectedQuestionType === 'True/False' && <TrueFalse selectedTFValue={selectedTFValue} setSelectedTFValue={setSelectedTFValue} setIsInit={setIsInit} />}
              {selectedQuestionType === 'Single Selection' && (
                <SingleSelection
                  setIsInit={setIsInit}
                  selectedSingleValue={selectedSingleValue}
                  setSelectedSingleValue={setSelectedSingleValue}
                  singleOptions={singleOptions}
                  setSingleOptions={setSingleOptions}
                  inputStyle={inputStyle}
                />
              )}
              {selectedQuestionType === 'Multiple Selection' && (
                <MultipleSelection multipleOptions={multipleOptions} setMultipleOptions={setMultipleOptions} inputStyle={inputStyle} setIsInit={setIsInit} />
              )}
            </div>
          </div>
        </div>
      </div>
      {!isQuestionSaving && (
        <button className={`${styles.saveBtn} ${disableSaveBtn || isInit || saveImageLoading ? styles.disabled : undefined}`} onClick={onSaveQuestion} disabled={disableSaveBtn}>
          {saveImageLoading ? 'Uploading image...' : 'Save question'}
        </button>
      )}
      {isQuestionSaving && (
        <button className={`${styles.saveBtn} ${styles.disabled}`} disabled={true}>
          <CircularProgress size={20} color='inherit' />
          <span className={styles.text}>Saving...</span>
        </button>
      )}

      {discardChangesModal && (
        <Modal>
          <DiscardChangesModal onDiscard={onDiscard} onCancel={onCancel} />
        </Modal>
      )}
    </>
  )
}

export default CreateNewQuestion
