export function getName(key, item) {
  if(key === 'firstName') {
    return (item.hasOwnProperty('firstName')) ? item.firstName.S : ''
  }
  if(key === 'lastName') {
    return (item.hasOwnProperty('lastName')) ? item.lastName.S : ''
  }
  if(key === 'name') {
    return (item.hasOwnProperty('nickname')) ? item.nickname.S : item.name.S
  }
  if(key === 'nickname') {
    return (item.hasOwnProperty('name')) ? item.name.S : item.nickname.S
  }
}

export function filterName(item) {
  return (item.firstName) ? `${item.firstName} ${item.lastName}` : item.name
}