
import styles from './DisplayNameHintModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

const DisplayNameHintModal = ({ handleModal, toggleToDisallow }) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.modalTitle}>
        <div className={styles.title}>Disallow Change Display Name</div>
        <div className={styles.close} onClick={() => handleModal(false)}><CloseRoundedIcon /></div>
      </div>
      <div className={styles.content}>Disallow your organization member to change their Display Name?</div>
      <div className={styles.btnWrap}>
        <div className={`${styles.disallow} ${styles.btn}`} onClick={toggleToDisallow}>Disallow</div>
        <div className={`${styles.cancel} ${styles.btn}`} onClick={() => handleModal(false)}>Cancel</div>
      </div>
    </div>
  )
}

export default DisplayNameHintModal