import styles from './ShowImageModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

const ShowImageModal = ({ imgURL, handleModal }) => {
  return (
    <div className={styles.modalWrap}>
      <div className={styles.close} onClick={() => handleModal(null, false)}><CloseRoundedIcon sx={{ color: '#808080', "&:hover": { color: "#1452CC" } }} /></div>
      <img className={styles.img} src={imgURL} alt='question image' />
      {/* <div>{ imgURL }</div> */}
    </div>
  )
}

export default ShowImageModal