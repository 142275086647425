import styles from './Forbidden.module.css'
import { Navigate } from 'react-router-dom'
import { UserContext } from '../../CustomHooks/useAuth'
import { useContext } from 'react'

const UpgradeText = () => {
  return (
    <div>To access this page, you need to update your payment.</div>
  )
}

const IdentityWrong = ({ identity }) => {
  return (
    <div>
      <div>{`Your identity is ${identity}`}</div>
      <div>To access this page, you have to be an admin or a teacher.</div>
    </div>
  )
}

const Forbidden = () => {

  const { userInfo } = useContext(UserContext)

  const toProductPage = () => {
    window.location.replace('https://www.viewsonic.io/')
  }

  return (
    <div className={styles.forbiddenWrap}>
      <div className={styles.textWrap}>
        <div>Hi, { userInfo.nickname }</div>
        {
          (!userInfo.payment) ?
          <UpgradeText /> :
          <IdentityWrong identity={userInfo.identity} />
        }
        <div className={styles.link} onClick={toProductPage}>Click here to go to UNIVERSE product page</div>
      </div>
    </div>
  )
}

export default Forbidden