import styles from './GroupUpWarningModal.module.css'

const GroupUpWarningModal = ({ handleGroupUpWarningModal }) => {

  return (
    <div className={styles.modalWrap}>
      <div className={styles.title}>Oops!</div>
      <div className={styles.message}>Please add students first!</div>
      <div className={styles.btnWrap}>
        <div className={`${styles.confirm}`} onClick={() => handleGroupUpWarningModal(false)}>Confirm</div>
      </div>
    </div>
  )
}

export default GroupUpWarningModal