import styles from './WeekDay.module.css'

const weekDay = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

const WeekDay = () => {
  return (
    <div className={styles.weekDayTextWrap}>
      { weekDay.map((dayText, idx) => <div key={idx} className={styles.weekDayText}>{dayText}</div>) }
    </div>
  )
}

export default WeekDay