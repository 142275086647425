import styles from './DiscardChangesModal.module.css'

const DiscardChangesModal = ({ onDiscard, onCancel }) => {
  return (
    <div className={styles.modalWrap}>
      <div className={styles.title}>Discard</div>
      <div className={styles.message}>Are you sure you want to discard your changes?</div>
      <div className={styles.btnWrap}>
        <button className={`${styles.delete} ${styles.btn}`} onClick={() => onDiscard()}>
          Discard
        </button>
        <button className={`${styles.cancel} ${styles.btn}`} onClick={() => onCancel()}>
          Cancel
        </button>
      </div>
    </div>
  )
}

export default DiscardChangesModal
