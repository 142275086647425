import { useContext, useEffect, useState } from "react"
import { getTeacherInfoWithCourses } from "../api"
import { UserContext } from "./useAuth"
import usePagination from "./usePagination"

const useTeacherList = () => {
  const [allTeacherListWithCourses, setAllTeacherListWithCourses] = useState([])
  const [loadingGetAllTeacherList, setLoadingGetAllTeacherList] = useState(true)
  const { page, totalPage, handlePage, handleTotalPage } = usePagination()
  const { userInfo } = useContext(UserContext)

  useEffect(() => {
    call_getApi()
  }, [])

  const call_getApi = () => {
    setLoadingGetAllTeacherList(true)
    setAllTeacherListWithCourses([])
    getTeacherInfoWithCourses({ email: userInfo.email, orgID: userInfo.orgID })
    .then((res) => {
      let data = JSON.parse(res.data.body)
      data.forEach(item => {
        item.email.S = item.email.S.toLowerCase()
      })
      console.info('useTeacherList get api', data)
      setAllTeacherListWithCourses(data)
      handleTotalPage(Math.max(Math.ceil(data.length / 14), 1))
    })
    .catch((err) => {
      console.log('getTeacherInfoWithCourses err', err)
    })
    .finally(() => {
      setLoadingGetAllTeacherList(false)
    })
  }

  return { allTeacherListWithCourses, loadingGetAllTeacherList, page, totalPage, handlePage, handleTotalPage, call_getApi }
}

export default useTeacherList

