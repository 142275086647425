import styles from './StudentList.module.css'
import SearchInput from '../../../parts/SearchInput'
import { useContext, useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import useStudentList from '../../../CustomHooks/useStudentList'
import CustomPagination from '../../../parts/CustomPagination'
import PrivacyModal from '../../../parts/Modals/PrivacyModal/PrivacyModal'
import { UserContext } from '../../../CustomHooks/useAuth'

const UtilityRow = ({ searchVal, handleSearchVal }) => {
  return (
    <div className={styles.searchInputWrap}>
      <SearchInput searchVal={searchVal} handleSearchVal={handleSearchVal} placeholder='Search People By Name or Email' />
    </div>
  )
}

const TableHeader = () => {
  return (
    <div className={`${styles.tableWrap} ${styles.tableHeader} ${styles.center}`}>
      <div className={`${styles.tableItem} ${styles.name}`}>Name</div>
      <div className={`${styles.tableItem} ${styles.email}`}>Email</div>
      <div className={`${styles.tableItem} ${styles.course}`}>Course</div>
      <div className={`${styles.tableItem} ${styles.displayName}`}>Display Name</div>
      <div className={`${styles.tableItem} ${styles.status}`}>status</div>
    </div>
  )
}

const TableContent = ({ item }) => {
  const { course, email, firstName, lastName, nickname, status } = item

  return (
    <div className={`${styles.tableWrap} ${styles.tableContent} ${styles.center}`}>
      <div className={`${styles.tableItem} ${styles.name}`}>{`${firstName.S} ${lastName.S}`}</div>
      <div className={`${styles.tableItem} ${styles.email}`}>{email.S}</div>
      <div className={`${styles.tableItem} ${styles.course}`}>
        {course.L.length !== 0 &&
          course.L.filter((item, idx) => idx < 3).map((item, index) => {
            let courseString = item.courseName

            return (
              <div key={index} className={styles.courseBlockContainer} title={courseString}>
                <div className={styles.ellipsis}>{courseString}</div>
              </div>
            )
          })}
        {course.L.length > 3 && (
          <div
            className={styles.leftCourse}
            title={course.L.map((item) => item.courseName)
              .filter((item, idx) => idx > 2)
              .join(' / ')}
          >{`+${course.L.length - 3}`}</div>
        )}
      </div>
      <div className={`${styles.tableItem} ${styles.displayName}`}>{`${firstName.S} ${lastName.S}`}</div>
      <div className={`${styles.tableItem} ${styles.status}`}>
        {status.S === 'UNCONFIRMED' && <div className={styles.pending}>pending</div>}
        {status.S !== 'UNCONFIRMED' && (
          <div>
            <CheckIcon sx={{ color: '#009135' }} />
          </div>
        )}
      </div>
    </div>
  )
}

const StudentList = () => {
  const { allStudentListWithCourses, loadingGetAllStudentList, page, totalPage, handlePage, handleTotalPage } = useStudentList()
  const [studentFilterList, setStudentFilterList] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const { chosenOrg } = useContext(UserContext)

  useEffect(() => {
    filterStudentList()
  }, [searchVal, allStudentListWithCourses])

  const handleSearchVal = (e) => {
    setSearchVal(e.target.value)
  }

  const filterCondition = (item) => {
    let name = item.firstName ? `${item.firstName.S} ${item.lastName.S}` : item.nickname.S
    let nameBool = name.toLowerCase().includes(searchVal.toLowerCase())
    let emailBool = item.email.S.toLowerCase().includes(searchVal.toLowerCase())
    return nameBool || emailBool
  }

  const filterStudentList = () => {
    let arr = allStudentListWithCourses.filter((item) => filterCondition(item)).map((item) => item)
    setStudentFilterList(arr)
    handleTotalPage(Math.max(Math.ceil(arr.length / 14), 1))
    handlePage(1)
  }

  return (
    <div className={styles.StudentListWrap}>
      <UtilityRow searchVal={searchVal} handleSearchVal={handleSearchVal} />
      <div className={styles.tableContainer}>
        <TableHeader />
        {loadingGetAllStudentList && (
          <div style={{ height: 'calc(100vh - 330px)', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        )}
        {allStudentListWithCourses.length !== 0 && (
          <div style={{ position: 'relative', minHeight: '400px' }}>
            {studentFilterList
              .filter((item, idx) => idx >= (page - 1) * 14 && idx <= (page - 1) * 14 + 13)
              .map((item, idx) => {
                item.email.S = chosenOrg === 'Free Trial' ? 'freetrial@email.com' : item.email.S

                return chosenOrg === 'Free Trial' ? (
                  <div key={idx} style={{ width: '100%', height: '100%', filter: 'blur(4px)', userSelect: 'none' }}>
                    <TableContent item={item} />
                  </div>
                ) : (
                  <TableContent key={idx} item={item} />
                )
              })}
            {chosenOrg === 'Free Trial' ? <PrivacyModal typ='others' /> : null}
          </div>
        )}
        {!loadingGetAllStudentList && allStudentListWithCourses.length === 0 && <div className={styles.messageBox}>Add your member!</div>}
      </div>
      {totalPage > 1 && (
        <div className={styles.paginationWrap}>
          <CustomPagination page={page} totalPage={totalPage} handlePage={handlePage} />
        </div>
      )}
    </div>
  )
}

export default StudentList
