import Popover from '@mui/material/Popover'
import styles from './QuestionListMenu.module.css'

const MenuItem = ({ text, iconName, callback, colorStyle }) => {
  return (
    <div className={`${styles.option} ${styles[colorStyle]}`} onClick={() => callback()}>
      <div className={`${styles.icon} ${styles[iconName]}`}></div>
      <div>{ text }</div>
    </div>
  )
}

const QuestionListMenu = ({ typ, open, anchorEl, handleClose, setDeleteQuestionModal, onEditQuestion, question, setSelectedQuestion, selectedParent, call_duplicateQuestionApi }) => {
    // const style = {
    //   '& .MuiPaper-root': {
    //     borderRadius: '8px',
    //     boxShadow: '0px 0px 6px #00000033',
    //     '& .MuiMenu-list': {
    //       padding: '0px'
    //     },
    //     '& .MuiMenuItem-root': {
    //       padding: '15px'
    //     },
    //   }
    // }
  
    // const textStyle = {
    //   font: 'normal normal 600 14px/18px InterMedium',
    //   '&:hover': {
    //     color: '#1452CC'
    //   }
    // }
  
    // const deleteTextStyle = {
    //   font: 'normal normal 600 14px/18px InterMedium',
    //   '&:hover': {
    //     color: '#E60026'
    //   }
    // }
  
    const onDuplicate = () => {
      
      let data = {
        questionId: question.questionId
      }

      if(typ === 'Assignment') {
        data.assignmentId = selectedParent.assignmentId
      } else {
        data.quizId = selectedParent.quizId
      }

      handleClose()
      call_duplicateQuestionApi(data)
    }
  
    const showDeleteQuestionModal = () => {
      setSelectedQuestion(question)
      setDeleteQuestionModal(true)
      handleClose()
    }
  
    return (
      // <Menu sx={style} anchorEl={anchorEl} open={open} onClose={handleClose}>
      //   <MenuItem onClick={onEditQuestion}>
      //     <div className={`${styles.option} ${styles.blue}`}>
      //       <div className={`${styles.icon} ${styles.edit}`}></div>
      //       <div>Edit</div>
      //     </div>
      //   </MenuItem>
      //   <MenuItem onClick={() => onDuplicate()}>
      //     <div className={`${styles.option} ${styles.blue}`}>
      //       <div className={`${styles.icon} ${styles.duplicate}`}></div>
      //       <div>Duplicate</div>
      //     </div>
      //   </MenuItem>
      //   <MenuItem onClick={() => showDeleteQuestionModal()}>
      //     <div className={`${styles.option} ${styles.red}`}>
      //       <div className={`${styles.icon} ${styles.delete}`}></div>
      //       <div>Delete</div>
      //     </div>
      //   </MenuItem>
      // </Menu>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={styles.menuWrap}>
          <MenuItem text='Edit' iconName='edit' callback={onEditQuestion} colorStyle='blue'/>
          <MenuItem text='Duplicate' iconName='duplicate' callback={onDuplicate} colorStyle='blue'/>
          <MenuItem text='Delete' iconName='delete' callback={showDeleteQuestionModal} colorStyle='red'/>
        </div>
      </Popover>
    )
}

export default QuestionListMenu