import styles from './AppHeader.module.css'
import MenuIcon from '@mui/icons-material/Menu'
import useAuth from '../CustomHooks/useAuth'
import usePopover from '../CustomHooks/usePopover'
import Popover from '@mui/material/Popover'
import { useNavigate } from 'react-router-dom'
import ArrowDownIcon from '../parts/icons/ArrowDownIcon'
import SelectFilter from '../parts/SelectFilter'

const CustomPopover = ({ open, anchorEl, handleClose, closeAndSignOut }) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <div className={styles.menuOption} onClick={closeAndSignOut}>
        Log Out
      </div>
    </Popover>
  )
}

const AppHeader = ({ handleMenuOpen, userInfo, setUserInfo, signOut, handleChosenOrg, chosenOrg, orgOptions }) => {
  const { open, anchorEl, openPopover, handleClose } = usePopover()
  const navigate = useNavigate()

  const closeAndSignOut = () => {
    handleClose()
    signOut(setUserInfo)
  }

  const openMenu = () => {
    if (Object.keys(userInfo).length === 0) return
    handleMenuOpen()
  }

  const backToHomePage = () => {
    if (Object.keys(userInfo).length === 0) return
    navigate('/dashboard', { replace: true })
  }

  const toProductPage = () => {
    window.location.replace('https://www.viewsonic.io/')
  }

  const orgDisplayNameOptions = [...orgOptions.map((item) => item.orgDisplayName.S)]

  return (
    <div className={styles.nav}>
      <div className={styles.left}>
        <div className={`${styles.menu} ${Object.keys(userInfo).length === 0 && styles.disabled}`} onClick={openMenu}>
          <MenuIcon />
        </div>
        <div className={styles.logoWrap} onClick={toProductPage}>
          <img src={require('../assets/icons/brandLogo.png')} />
        </div>
        <div className={`${styles.orgSelect} ${(!chosenOrg || !orgDisplayNameOptions.length) && styles.hidden} ${Object.keys(userInfo).length === 0 && styles.disabled}`}>
          <SelectFilter options={orgDisplayNameOptions} val={chosenOrg} handleValChange={handleChosenOrg} typ='border' disabled={false} />
        </div>
        <div className={styles.version}>1.74.1</div>
      </div>
      <div className={styles.right}>
        <div className={`${styles.subtitle} ${Object.keys(userInfo).length === 0 && styles.disabled}`} onClick={backToHomePage}>
          Home
        </div>
        <div className={styles.identity}>
          <div className={`${styles.userProfile} ${Object.keys(userInfo).length === 0 && styles.disabled}`} onClick={openPopover}>
            {/* <div className={styles.userPic}></div> */}
            <div className={styles.subtitle}>{userInfo.email}</div>
            <div className={styles.arrow}>
              <ArrowDownIcon />
            </div>
          </div>
          <div className={styles.identity_txt}>{userInfo.identity}</div>
        </div>
        <CustomPopover open={open} anchorEl={anchorEl} handleClose={handleClose} closeAndSignOut={closeAndSignOut} />
      </div>
    </div>
  )
}

export default AppHeader
