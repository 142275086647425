import {  TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

const CustomInput = styled(TextField)(({ theme }) => ({
  backgroundColor: '#fff',
  '& .Mui-disabled': {
    backgroundColor: '#F5F8FF',
  },
  // '& 	.MuiOutlinedInput-notchedOutline': {
  //   // normal status border color
  //   borderColor: 'transparent',
  //   boxShadow: 'inset 0px 0px 6px #00000029',
  //   borderRadius: '8px'
  // },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // input focus border color
    // borderColor: 'transparent'
    border: '2px solid #1452CC',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    // input hover border color => color still invalid now
    border: '2px solid #1452CC',
    // borderColor: '#1452CC'
  },
}));

const boxShadowStyle = {
  '& 	.MuiOutlinedInput-notchedOutline': {
    // normal status border color
    borderColor: 'transparent',
    boxShadow: 'inset 0px 0px 6px #00000029',
    borderRadius: '8px'
  },
}

const borderStyle = {
  '& 	.MuiOutlinedInput-notchedOutline': {
    // normal status border color
    border: '1px solid #B3B3B3',
    borderRadius: '8px'
  },
}

const styleConfig = {
  border: borderStyle,
  shadow: boxShadowStyle
}

const SmallBorderInput = ({ placeholder, inputhVal, handleInputVal, isValid, helperText, disabled, type, maxLength=50 }) => {

  return (
    <CustomInput
      disabled={disabled}
      sx={styleConfig[type]}
      error={!isValid}
      helperText={helperText}
      style={{ height: '100%' }}
      fullWidth 
      disableunderline="true"
      type="text"
      value={inputhVal}
      onChange={handleInputVal}
      placeholder={placeholder}
      size="small"
      inputProps={{ maxLength }}
    />
  )
}

export default SmallBorderInput