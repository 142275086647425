import styles from './MyClasses.module.css'
import AddClassButton from '../../../parts/AddClassButton/AddClassButton'
import Modal from '../../../parts/Modal'
import AddClassModal from '../../../parts/Modals/AddClassModal/AddClassModal'
import AddStudentModal from '../../../parts/Modals/AddStudentModal/AddStudentModal'
import GroupSelect from '../../../parts/GroupSelect'
import WindowSelect from '../../../parts/VirtualizedList/VirtualizedList'
import HomeIcon from '../../../assets/icons/breadcrumbHome.svg'
import SettingIcon from '../../../assets/icons/settings.svg'
import ArrowIcon from '../../../assets/icons/breadcrumbArrow.svg'
import { Fragment, useState, useEffect, useContext } from 'react'
import { UserContext } from '../../../CustomHooks/useAuth'
import dayjs from 'dayjs'
import PersonIcon from '@mui/icons-material/Person'
import ScheduleBlock from './DetailPage/ScheduleBlock/ScheduleBlock'
import InfoBlock from './DetailPage/InfoBlock/InfoBlock'
import StudentListByClass from './DetailPage/StudentListByClass/StudentListByClass'
import GroupUp from './DetailPage/GroupUp/GroupUp'
import SearchInput from '../../../parts/SearchInput'
import CircularProgress from '@mui/material/CircularProgress'
import RemoveUserModal from '../../../parts/Modals/RemoveUserModal/RemoveUserModal'
import EditStudentNoteModal from '../../../parts/Modals/EditStudentNoteModal/EditStudentNoteModal'
import classroomConfig from '../../../utils/classroomConfig'
import CustomPagination from '../../../parts/CustomPagination'
import BorderGroupSelect from '../../../parts/BorderForm/BorderGroupSelect'
import { classRoomTypeMapping, getClassRoomUniqueKey, unpackClassRoomTypeAndClassSize } from '../../../utils/classroomConfig'

const UtilityRow = ({ userInfo, searchVal, handleSearchVal, handleAddClassModal, teachers, chosenTeacher, handleChosenTeacher }) => {
  return (
    <div className={styles.utilityRow}>
      <div className={styles.left}>
        <div className={styles.searchInputWrap}>
          <SearchInput searchVal={searchVal} handleSearchVal={handleSearchVal} placeholder='Search Course' />
        </div>
        <div className={`${styles.teacherSelect} ${userInfo.identity === 'teacher' && styles.hidden}`}>
          <WindowSelect type='white' label='Teacher' options={teachers} val={chosenTeacher} handleValChange={handleChosenTeacher} disabled={false} renderOptionType='object' />
        </div>
      </div>
      <AddClassButton handleModal={handleAddClassModal} />
    </div>
  )
}

const ClassBlock = ({ item, handleOpenDetailPage, handleChosenClassId, updateRoomByClass, userInfo, handleUpgradePlanModal }) => {
  const [classroom, setClassroom] = useState('')
  const { classroomStyleList } = useContext(UserContext)

  useEffect(() => {
    setClassroom(item.roomStyleConfigId)
  }, [item])

  const toDetailPage = (e) => {
    handleChosenClassId(item.courseid)
    handleOpenDetailPage(true)
  }

  const handleClassroom = (e) => {
    if (userInfo.isExpired) {
      handleUpgradePlanModal(true)
      return
    }
    let roomStyleConfigId = e.target.value
    setClassroom(roomStyleConfigId)
    let updateObj = {
      roomStyleConfigId,
      courseid: item.courseid
    }
    updateRoomByClass(updateObj)
  }

  return (
    <div className={styles.classBlock} onClick={toDetailPage}>
      <div className={styles.top}>
        <div>
          {item.frequency === 'Never'
            ? `${dayjs.unix(item.firstDate).format('YYYY/MM/DD')}`
            : `${dayjs.unix(item.firstDate).format('YYYY/MM/DD')}~${dayjs.unix(item.lastDate).format('YYYY/MM/DD')}`}
        </div>
        <div className={styles.studentList}>
          <PersonIcon sx={{ color: '#888888' }} />
          <div>{item.studentCount}</div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.left}>
          <div className={styles.courseName}>{item.courseName}</div>
          <div className={styles.teacherName}>{item.teacher}</div>
        </div>
        <div className={styles.right}>
          {classroom && <img className={styles.classImage} src={classroomStyleList.find((item) => item.id == classroom).snapshot_url} alt='' />}
          <div className={styles.classroom}>
            <BorderGroupSelect label={item.showName} val={classroom} handleValChange={handleClassroom} disabled={false} studentNum={item.studentCount} />
          </div>
        </div>
      </div>
    </div>
  )
}

const DetailPage = ({
  handleOpenDetailPage,
  chosenClassItem,
  chosenClassId,
  handleAddStudentModal,
  allStudentListWithCourses,
  handleDeleteModal,
  chosenAccount,
  handleChosenAccount,
  handleEditNoteModal,
  loadingGetCourseDetail,
  setSearchVal,
  setChosenTeacher,
  memberList,
  updateGroupUpListByClass,
  deleteGroupUpListByClass,
  call_courseDetailApi,
  selectedDatesArr,
  call_getClassroomList
}) => {
  const navigateToClassroomList = () => {
    handleOpenDetailPage(false)
    setSearchVal('')
    setChosenTeacher({ label: 'All', id: '' })
    call_getClassroomList('', { label: 'All', id: '' }, 1, null)
  }

  useEffect(() => {
    console.log('chosenClassId', chosenClassId)
    call_courseDetailApi(chosenClassId)
  }, [])

  return (
    <div className={styles.detailPageWrap}>
      {loadingGetCourseDetail && (
        <div style={{ height: 'calc(100vh - 330px)', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {!loadingGetCourseDetail && Object.keys(chosenClassItem).length !== 0 && (
        <>
          <div className={styles.utilityRow}>
            <div className={styles.breadCrumbs}>
              <div className={styles.icon} onClick={navigateToClassroomList}>
                <img src={HomeIcon} alt='' />
              </div>
              <div>
                <img src={ArrowIcon} alt='' />
              </div>
              <div className={styles.tab}>{chosenClassItem.courseName}</div>
            </div>
            <div className={styles.icon}>{/* <img src={SettingIcon} alt="" /> */}</div>
          </div>

          <div className={styles.blockWrap}>
            <div className={styles.firstRow}>
              <InfoBlock chosenClassItem={chosenClassItem} />
              <ScheduleBlock selectedDatesArr={selectedDatesArr} />
            </div>
            <div className={styles.secondRow}>
              <StudentListByClass
                memberList={memberList}
                chosenClassItem={chosenClassItem}
                handleAddStudentModal={handleAddStudentModal}
                allStudentListWithCourses={allStudentListWithCourses}
                handleDeleteModal={handleDeleteModal}
                chosenAccount={chosenAccount}
                handleChosenAccount={handleChosenAccount}
                handleEditNoteModal={handleEditNoteModal}
              />
            </div>
            <div className={styles.thirdRow}>
              <GroupUp
                memberList={memberList}
                chosenClassItem={chosenClassItem}
                updateGroupUpListByClass={updateGroupUpListByClass}
                deleteGroupUpListByClass={deleteGroupUpListByClass}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const MyClasses = ({
  allStudentListWithCourses,
  memberList,
  openToaster,
  handleCloseToaster,
  call_saveApi,
  loadingGetCourseDetail,
  loadingGetList,
  loadingSaveList,
  scheduleData,
  teachers,
  chosenTeacher,
  handleChosenTeacherWindowSelect,
  chosenClassItem,
  chosenClassId,
  handleChosenClassId,
  updateStudentListByClass,
  updateRoomByClass,
  updateGroupUpListByClass,
  deleteGroupUpListByClass,
  setSearchVal,
  setChosenTeacher,
  searchVal,
  handleSearchVal,
  call_getClassroomList,
  filterCourseInfoList,
  page,
  totalPage,
  handlePage,
  call_courseDetailApi,
  selectedDatesArr
}) => {
  const [showAddClassModal, setShowAddClassModal] = useState(false)
  const [showAddStudentModal, setShowAddStudentModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditNoteModal, setShowEditNoteModal] = useState(false)
  const [chosenAccount, setChosenAccount] = useState({})
  const [openDetailPage, setOpenDetailPage] = useState(false)

  const { userInfo, handleUpgradePlanModal } = useContext(UserContext)

  const handleDeleteModal = (sts) => {
    setShowDeleteModal(sts)
  }

  const handleEditNoteModal = (sts) => {
    setShowEditNoteModal(sts)
  }

  const handleChosenAccount = (item) => {
    setChosenAccount(JSON.parse(JSON.stringify(item)))
  }

  const handleOpenDetailPage = (sts) => {
    setOpenDetailPage(sts)
  }

  const handleAddClassModal = (sts) => {
    if (userInfo.isExpired) {
      handleUpgradePlanModal(true)
      return
    }
    setShowAddClassModal(sts)
    // if(!sts) {
    //   if(openToaster) handleCloseToaster()
    // }
  }

  const handleAddStudentModal = (sts) => {
    if (userInfo.isExpired) {
      handleUpgradePlanModal(true)
      return
    }
    setShowAddStudentModal(sts)
    // if(!sts) {
    //   if(openToaster) handleCloseToaster()
    // }
  }

  const removeStudent = () => {
    let studentList = chosenClassItem.studentList.map((item) => item)
    let removedIndex = studentList.findIndex((item) => item.email.toLowerCase() === chosenAccount.email.toLowerCase())
    let chatGroupData = {
      orgID: userInfo.orgID,
      channelID: chosenClassItem.courseid,
      studentList: [
        {
          userID: memberList.find((member) => member.email.toLowerCase() === studentList[removedIndex].email.toLowerCase()).userID,
          typ: 'Leave',
          timestamp: String(dayjs().unix())
        }
      ]
    }
    studentList.splice(removedIndex, 1)
    updateStudentListByClass(studentList, 'Student has been removed successfully!', chatGroupData)
    handleDeleteModal(false)
  }

  const editNoteByStudent = (note) => {
    let studentList = chosenClassItem.studentList.map((item) => JSON.parse(JSON.stringify(item)))
    let editObj = studentList.find((item) => item.email.toLowerCase() === chosenAccount.email.toLowerCase())
    editObj.note = note
    updateStudentListByClass(studentList, 'Note has been edited successfully!')
    handleEditNoteModal(false)
  }

  return (
    <div className={`${styles.wrap}`}>
      {!openDetailPage ? (
        <Fragment>
          <UtilityRow
            userInfo={userInfo}
            handleAddClassModal={handleAddClassModal}
            teachers={teachers}
            chosenTeacher={chosenTeacher}
            handleChosenTeacher={handleChosenTeacherWindowSelect}
            searchVal={searchVal}
            handleSearchVal={handleSearchVal}
          />
          {loadingGetList && (
            <div style={{ height: 'calc(100vh - 330px)', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )}
          <div className={styles.classBlocks}>
            {filterCourseInfoList.length !== 0 &&
              !loadingGetList &&
              filterCourseInfoList
                // .filter((item, idx) => idx >= (page - 1) * 10 && idx <= (page - 1) * 10 + 9)
                .map((item, idx) => (
                  <ClassBlock
                    key={idx}
                    item={item}
                    handleOpenDetailPage={handleOpenDetailPage}
                    handleChosenClassId={handleChosenClassId}
                    updateRoomByClass={updateRoomByClass}
                    userInfo={userInfo}
                    handleUpgradePlanModal={handleUpgradePlanModal}
                  />
                ))}
            {!loadingGetList && filterCourseInfoList.length === 0 && <div className={styles.messageBox}>Let's create your classroom!</div>}
          </div>
          {!loadingGetList && totalPage > 1 && (
            <div className={styles.paginationWrap}>
              <CustomPagination page={page} totalPage={totalPage} handlePage={handlePage} />
            </div>
          )}
        </Fragment>
      ) : (
        <DetailPage
          call_courseDetailApi={call_courseDetailApi}
          memberList={memberList}
          handleOpenDetailPage={handleOpenDetailPage}
          chosenClassId={chosenClassId}
          chosenClassItem={chosenClassItem}
          scheduleData={scheduleData}
          handleAddStudentModal={handleAddStudentModal}
          allStudentListWithCourses={allStudentListWithCourses}
          handleDeleteModal={handleDeleteModal}
          chosenAccount={chosenAccount}
          handleChosenAccount={handleChosenAccount}
          handleEditNoteModal={handleEditNoteModal}
          loadingGetCourseDetail={loadingGetCourseDetail}
          loadingGetList={loadingGetList}
          updateGroupUpListByClass={updateGroupUpListByClass}
          deleteGroupUpListByClass={deleteGroupUpListByClass}
          selectedDatesArr={selectedDatesArr}
          call_getClassroomList={call_getClassroomList}
          setSearchVal={setSearchVal}
          setChosenTeacher={setChosenTeacher}
        />
      )}
      {showAddClassModal && (
        <Modal>
          <AddClassModal
            handleModal={handleAddClassModal}
            call_saveApi={call_saveApi}
            loadingSaveList={loadingSaveList}
            openToaster={openToaster}
            handleCloseToaster={handleCloseToaster}
          />
        </Modal>
      )}
      {showAddStudentModal && (
        <Modal>
          <AddStudentModal
            memberList={memberList}
            handleModal={handleAddStudentModal}
            updateStudentListByClass={updateStudentListByClass}
            chosenClassItem={chosenClassItem}
            loadingGetList={loadingGetList}
          />
        </Modal>
      )}
      {showDeleteModal && (
        <Modal>
          <RemoveUserModal setShowModal={handleDeleteModal} removeUser={removeStudent} />
        </Modal>
      )}
      {showEditNoteModal && (
        <Modal>
          <EditStudentNoteModal handleEditNoteModal={handleEditNoteModal} chosenAccount={chosenAccount} editNoteByStudent={editNoteByStudent} />
        </Modal>
      )}
    </div>
  )
}

export default MyClasses
