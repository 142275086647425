import styles from './AddMemberModal.module.css'
import Modal from '@mui/material/Modal'
import { Fragment, useState } from 'react'
import Toaster from '../../Toaster'

const AddMemberModal = ({ chosenSlotObj, showChildModal, handleClose, saveAndClose }) => {
  
  const childModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '596px',
    height: '310px',
    backgroundColor: '#FFFFFF',
    // border: '1px solid #707070',
    borderRadius: '16px',
    padding: '40px',
    boxSizing: 'border-box'
  }

  return (
    <Fragment>
      <Modal
        disableAutoFocus
        sx={{ border: 'none' }}
        open={showChildModal}
        onClose={handleClose}>
          <div style={childModalStyle}>
            <div className={styles.title}>Add User</div>
            <div className={styles.message}>Are you sure you want to add these users and send verification emails to them?</div>
            <div className={styles.spotWrap}>
              {
                Object.keys(chosenSlotObj).map((role, idx) => (
                  <div key={idx} className={styles.spotItem}>
                    <div className={styles.role}>{role}:</div>
                    <div className={styles.spot}>{chosenSlotObj[role].chosen}</div>
                  </div>
                ))
              }
            </div>
            <div className={styles.btnWrap}>
              <div className={`${styles.cancel} ${styles.btn}`} onClick={handleClose}>Cancel</div>
              <div className={`${styles.confirm} ${styles.btn}`} onClick={saveAndClose}>Confirm & send</div>
            </div>
          </div>
      </Modal>
    </Fragment>
  )
}

export default AddMemberModal