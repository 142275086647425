import { useEffect, useState, useRef } from 'react'
import dayjs from 'dayjs'
import { getMonth, getWeek } from '../parts/Calendar/utils'
import { saveClassList, getClassList, getClassInfo, editClassList, deleteClassList, editChatGroup } from '../api'
import moment from 'moment-timezone'
import { useContext } from 'react'
import { UserContext } from '../CustomHooks/useAuth'
import { fixMeetingRoomOldData, getClassRoomUniqueKey } from '../utils/classroomConfig'

let getDataTimeout = null

const useScheduleData = (from = null) => {
  const [viewMode, setViewMode] = useState('Week')
  const [teachers, setTeachers] = useState([]) // !! different from full teacher list in add class modal, this list will map from data. Only teacher assigned to class will be showed here
  const [chosenTeacher, setChosenTeacher] = useState('All')
  const [chosenSchedule, setChosenSchedule] = useState({})
  const [editInfoItem, setEditInfoItem] = useState({})
  const [editInfoMode, setEditInfoMode] = useState('')
  const [arrowDate, setArrowDate] = useState(new Date(dayjs().hour(0).minute(0).second(0)))
  const [chosenDate, setChosenDate] = useState(new Date(dayjs().hour(0).minute(0).second(0)))
  const [needUpdate, setNeedUpdate] = useState(false)
  const [monthRange, setMonthRange] = useState([])
  const [weekRange, setWeekRange] = useState([])
  const [scheduleData, setScheduleData] = useState([])
  const [courseInfoData, setCourseInfoData] = useState([])
  const [loadingGetList, setLoadingGetList] = useState(false)
  const [loadingSaveList, setLoadingSaveList] = useState(false)
  const [openToaster, setOpenToaster] = useState(false)
  const [toasterText, setToasterText] = useState('')
  const abortControllerRef = useRef()
  const [openScheduleInfoModal, setOpenScheduleInfoModal] = useState(false)
  const [openScheduleEditModal, setOpenScheduleEditModal] = useState(false)
  const { userInfo } = useContext(UserContext)

  useEffect(() => {
    changeCalendarRange()
  }, [arrowDate])

  useEffect(() => {
    if (needUpdate) {
      setArrowDate(chosenDate)
    } else {
      getSchedules(weekRange, monthRange)
    }
  }, [viewMode])

  useEffect(() => {
    changeCalendarRange()
  }, [])

  const changeCalendarRange = () => {
    let w_range = getWeek(arrowDate)
    let m_range = getMonth(arrowDate)
    setMonthRange(m_range)
    setWeekRange(w_range)
    getSchedules(w_range, m_range)
    setNeedUpdate(false)
  }

  const closeEditModal = () => {
    handleScheduleEditModal(false)
    handleScheduleInfoModal(false)
  }

  const handleScheduleInfoModal = (sts, item = {}) => {
    setOpenScheduleInfoModal(sts)
    if (sts) {
      handleChosenScheduleByClickClass(item)
    } else {
      setChosenSchedule({})
    }
  }

  const handleScheduleEditModal = (sts) => {
    // if(userInfo.isExpired && sts) {
    //   handleUpgradePlanModal(true)
    //   return
    // }
    setOpenScheduleEditModal(sts)
  }

  const handleOpenToaster = (text) => {
    setToasterText(text)
    setOpenToaster(true)
  }

  const handleCloseToaster = () => setOpenToaster(false)

  const handleViewMode = (mode) => {
    setViewMode(mode)
  }

  const handleChosenTeacher = (e) => {
    setChosenTeacher(e.target.value)
  }

  const handleChosenTeacherWindowSelect = (e, value) => {
    setChosenTeacher(value)
  }

  const handleChosenScheduleByClickClass = (item) => {
    // set chosen time and schedule
    if (Object.keys(item).length !== 0 && !loadingGetList) {
      let newDate = dayjs(item.begin)
      setNeedUpdate(true)
      setChosenDate(dayjs(newDate).format('YYYY/MM/DD HH:mm'))
    }
    setChosenSchedule(item)
  }

  const handleChosenDateByMonthCalendar = (newValue) => {
    // forbid editting by typing
    if (isNaN(newValue.$D)) return
    setArrowDate(dayjs(newValue).format('YYYY/MM/DD HH:mm'))
    closeEditModal()
  }

  const handleChosenDateByArrow = (num) => {
    let config = {
      Week: { unit: 'day', multiply: 7 },
      Month: { unit: 'month', multiply: 1 }
    }
    let unit = config[viewMode].unit
    let multiply = config[viewMode].multiply
    let newDate = dayjs(arrowDate).add(multiply * num, unit)
    setArrowDate(dayjs(newDate).format('YYYY/MM/DD HH:mm'))
    closeEditModal()
  }

  const toTimestamp = (time) => {
    let year = dayjs(time).year()
    let month = dayjs(time).month()
    let date = dayjs(time).date()
    let hour = dayjs(time).hour()
    let minute = dayjs(time).minute()
    let second = dayjs(time).second()
    let tim = new Date(year, month, date, hour, minute, second)
    return Math.floor(new Date(tim).getTime() / 1000)
  }

  const reformEditInfo = (mode) => {
    setEditInfoMode(mode)

    let groupInfo = courseInfoData.find((item) => item.courseid === chosenSchedule.courseid)

    let days = [
      { text: 'Sun', selected: false },
      { text: 'Mon', selected: false },
      { text: 'Tue', selected: false },
      { text: 'Wed', selected: false },
      { text: 'Thu', selected: false },
      { text: 'Fri', selected: false },
      { text: 'Sat', selected: false }
    ]

    days.forEach((weekday) => {
      if (groupInfo.weekday.includes(weekday.text)) {
        weekday.selected = true
      }
    })

    let item = {
      courseid: chosenSchedule.courseid,
      courseName: chosenSchedule.courseName,
      teacherName: chosenSchedule.teacher,
      begin: new Date(moment(chosenSchedule.begin).tz(chosenSchedule.timeZone).format('YYYY-MM-DD HH:mm')),
      end: new Date(moment(chosenSchedule.end).tz(chosenSchedule.timeZone).format('YYYY-MM-DD HH:mm')),
      firstDate: new Date(
        moment(new Date(dayjs.unix(groupInfo.firstDate)))
          .tz(chosenSchedule.timeZone)
          .format('YYYY-MM-DD HH:mm')
      ),
      lastDate: new Date(
        moment(new Date(dayjs.unix(groupInfo.lastDate)))
          .tz(chosenSchedule.timeZone)
          .format('YYYY-MM-DD HH:mm')
      ),
      frequency: chosenSchedule.frequency === 'Never' ? 'Repeat: Never' : chosenSchedule.frequency,
      days,
      timeZone: chosenSchedule.timeZone,
      studentList: chosenSchedule.studentList,
      isAutoJoin: groupInfo.isAutoJoin,
      classSize: groupInfo.classSize,
      classRoomType: groupInfo.classRoomType,
      roomStyleConfigId: groupInfo.roomStyleConfigId
    }
    handleScheduleEditModal(true)
    setEditInfoItem(item)
  }

  const call_deleteApi = (item) => {
    let dataObj = {}
    dataObj.courseid = item.courseid
    dataObj.begin = toTimestamp(item.begin)
    setLoadingGetList(true)
    deleteClassList(dataObj, editInfoMode)
      .then((res) => {
        getSchedules(weekRange, monthRange, 'Classes have been removed successfully!')
      })
      .catch((err) => {
        console.log('delete class list err', err)
      })
  }

  const call_editApi = (dataObj) => {
    console.info('call_editApi', dataObj)
    setLoadingGetList(true)
    editClassList(JSON.stringify(dataObj), editInfoMode)
      .then((res) => {
        getSchedules(weekRange, monthRange, 'Classes have been edited successfully!')
      })
      .catch((err) => console.log('edit class list error', err))
  }

  const call_saveApi = (data) => {
    console.info('call_saveApi', JSON.parse(data))
    setLoadingSaveList(true)
    saveClassList(data)
      .then((res) => {
        getSchedules(weekRange, monthRange, 'Classes have been created successfully!')
      })
      .catch((err) => console.log('save class list error', err))
      .finally(() => {
        setLoadingSaveList(false)
      })
  }

  const getScheduleInfo = (data, text) => {
    setCourseInfoData([])
    getClassInfo(data)
      .then((res) => {
        let data = JSON.parse(JSON.stringify(res.data))
        console.info('getScheduleInfo', data)
        setCourseInfoData(data)
        if (text) handleOpenToaster(text)
      })
      .catch((err) => {
        console.log('getClassInfo err', err)
      })
      .finally(() => setLoadingGetList(false))
  }

  const getSchedules = (w_range, m_range, text = null) => {
    if (w_range.length === 0 || m_range.length === 0) return
    if (!userInfo.orgID) return
    if (getDataTimeout) clearTimeout(getDataTimeout)
    setScheduleData([])
    setLoadingGetList(true)

    getDataTimeout = setTimeout(() => {
      if (abortControllerRef.current) abortControllerRef.current.abort()
      abortControllerRef.current = new AbortController()
      let first = viewMode === 'Week' ? toTimestamp(new Date(w_range[0])) : toTimestamp(new Date(m_range[0][0]))
      let last = viewMode === 'Week' ? toTimestamp(new Date(w_range[6])) : toTimestamp(new Date(m_range[5][6]))
      if (from) {
        first = 0
        last = 0
      }
      let role = userInfo.identity === 'owner' ? 'admin' : userInfo.identity
      let data = { first, last, orgId: userInfo.orgID, email: userInfo.email, role, courseTable: 'VSU_Course' }

      getClassList({ data, signal: abortControllerRef.current.signal })
        .then((res) => {
          getScheduleInfo({ first, last, orgId: userInfo.orgID, email: userInfo.email, role, courseTable: 'VSU_CourseSum' }, text)
          // api改完後 roomStyleConfigId要接item.roomStyleConfigId
          let data = res.data.map((item) => {
            let newFormatObj = {
              begin: new Date(dayjs.unix(item.begin)),
              end: new Date(dayjs.unix(item.end)),
              classSize: item.classSize,
              classRoomType: item.classRoomType,
              roomStyleConfigId: item.roomStyleConfigId,
              courseNumber: 0, // item.courseNumber,
              courseName: item.courseName,
              courseid: item.courseid,
              teacher: item.teacher,
              frequency: item.frequency === 'Repeat: Never' ? 'Never' : item.frequency,
              timeZone: item.timeZone,
              studentList: item.studentList
            }
            return newFormatObj
          })
          console.info('get class list', data)
          // sort data!!!!!!!!!!!!!!!!!!!!!
          setScheduleData(data)
          let teacherList = [...new Set(data.map((item) => item.teacher))]
          let arr = ['All']
          setTeachers(arr.concat(teacherList))
          // if chosen teacher is deleted from the list, then reset chosen teacher to default value => All
          if (teacherList.filter((item) => item === chosenTeacher).length === 0) {
            setChosenTeacher('All')
          }
        })
        .catch((err) => {
          console.log('getSchedules err', err)
        })
    }, 50)
  }

  return {
    viewMode,
    handleViewMode,
    teachers,
    chosenTeacher,
    handleChosenTeacher,
    monthRange,
    weekRange,
    handleChosenDateByArrow,
    call_saveApi,
    scheduleData,
    loadingGetList,
    loadingSaveList,
    openToaster,
    toasterText,
    handleCloseToaster,
    chosenSchedule,
    closeEditModal,
    call_editApi,
    call_deleteApi,
    openScheduleInfoModal,
    openScheduleEditModal,
    handleScheduleInfoModal,
    handleScheduleEditModal,
    reformEditInfo,
    editInfoItem,
    editInfoMode,
    handleChosenDateByMonthCalendar,
    arrowDate,
    courseInfoData
  }
}

export default useScheduleData
