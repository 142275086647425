import styles from './UpgradePlan.module.css'
import { UserContext } from '../../../CustomHooks/useAuth'
import { useContext } from 'react'
import ClearIcon from '@mui/icons-material/Clear';

const UpgradePlan = () => {

  const { handleUpgradePlanModal } = useContext(UserContext)

  return (
    <div className={styles.modalWrap}>
      <div className={styles.titleWrap}>
        <div></div>
        <div className={styles.title}>Upgrade Your Plan</div>
        <div className={styles.icon} onClick={() => handleUpgradePlanModal(false)}><ClearIcon /></div>
      </div>
      <div className={styles.content}>Your account has been limited. All you have to do is upgrading your subscription.</div>
    </div>
  )
}

export default UpgradePlan