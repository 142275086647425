/*
click import from canvas 
=> if token expired: re-start
=> if not expired yet: call canvas api
*/

/*
Goal: Allow Universe-Manager(third-party app) to request access to Canvas API endpoints via OAuth2 flow(user authprized and developer get API accessToken)
Step 1: get Developer Key(if requested account doesn't enable this developer key, then request fail)
** developer key = cilent ID + client secret **
** instructure should enable their developer key, or we couldn't have access to their api. **
cliendID = developer ID => 10000000000008
clientSecret = developer Key => f6hBhcGCq22IIAq1xKUYSlTRBIpBvYcFrNaAzEqpVoJOQ4jXD2UaXP5bLZvWBZxu
*/

import axios from 'axios'

const canvas_install_url = 'http://ec2-52-77-230-91.ap-southeast-1.compute.amazonaws.com'
const open_source = 'https://canvas.instructure.com'
const client_id = 10000000000007
const client_secret = 'RqWRgugNGhbSoNwjbtQR6TSALal5Bcq9ms4xMR8Bfaioua034kMjA25D697zjQxR'
const redirect_uri = 'https://develop.d16r6sj0n83p8t.amplifyapp.com/dashboard'
const accessToken = '1iBRjAbgK8am0vZB2WRjaWJol4qUIaBI1hExu7jgB68ExTK1LG5ZangORFuIbXkI' // 

export const authorizeByUser = (canvasDomain) => {
  window.location.replace(`${canvasDomain}/login/oauth2/auth?client_id=${client_id}&response_type=code&state=Universe&redirect_uri=${redirect_uri}`)
}

export const getToken = (code) => {
  let data = {
    'grant_type': 'authorization_code',
    'client_id': client_id,
    'client_secret': client_secret,
    'redirect_uri': redirect_uri,
    'code': code,
  }
  return axios.post(`${canvas_install_url}/login/oauth2/token`, data)
}

export const fake_canvasMemberData = [
  {
    created_at: "2022-04-14T21:54:10-06:00",
    id: 7,
    integration_id: null,
    login_id: "cindy.chen@test.com",
    name: "Cindy Chen",
    short_name: "Cindy Chen",
    sis_import_id: null,
    sis_user_id: "CINDYCHEN",
    sortable_name: "Chen, Cindy",
  },
  {
    created_at: "2022-05-10T02:37:03-06:00",
    id: 15,
    integration_id: null,
    login_id: "kate.chen@test.com",
    name: "Kate Chen",
    short_name: "Kate Chen",
    sis_import_id: null,
    sis_user_id: "KATECHEN",
    sortable_name: "Chen, Kate",
  },
  {
    created_at: "2022-04-14T21:52:38-06:00",
    id: 4,
    integration_id: null,
    login_id: "kevin.chu@test.com",
    name: "Kevin Chu",
    short_name: "Kevin Chu",
    sis_import_id: null,
    sis_user_id: "KEVINCHU",
    sortable_name: "Chu, Kevin",
  },
  {
    created_at: "2022-04-14T21:52:58-06:00",
    id: 5,
    integration_id: null,
    login_id: "fox.lin@test.com",
    name: "Fox Lin",
    short_name: "Fox Lin",
    sis_import_id: null,
    sis_user_id: "FOXLIN",
    sortable_name: "Lin, Fox",
  },
  {
    created_at: "2022-05-10T02:36:27-06:00",
    id: 14,
    integration_id: null,
    login_id: "sharon.shih@test.com",
    name: "Sharon Shih",
    short_name: "Sharon Shih",
    sis_import_id: null,
    sis_user_id: "SHARONSHIH1",
    sortable_name: "Shih, Sharon",
  },
  {
    created_at: "2022-04-14T21:52:05-06:00",
    id: 3,
    integration_id: null,
    login_id: "stan.ueno@viewsonic.com",
    name: "Stan Ueno",
    short_name: "Stan Ueno",
    sis_import_id: null,
    sis_user_id: "STANUENO",
    sortable_name: "Ueno, Stan",
  },
  {
    created_at: "2022-04-07T04:31:37-06:00",
    id: 1,
    integration_id: null,
    login_id: "user@example.com",
    name: "user@example.com",
    short_name: "user@example.com",
    sis_import_id: null,
    sis_user_id: null,
    sortable_name: "user@example.com",
  },
  {
    created_at: "2022-04-14T21:51:34-06:00",
    id: 2,
    integration_id: null,
    login_id: "shane.wilks@viewsonic.com",
    name: "Shane Wilks",
    short_name: "Shane Wilks",
    sis_import_id: null,
    sis_user_id: "SHANEWILKS",
    sortable_name: "Wilks, Shane",
  },
  {
    created_at: "2022-04-14T21:53:22-06:00",
    id: 6,
    integration_id: null,
    login_id: "adam.yeh@test.com",
    name: "Adam Yeh",
    short_name: "Adam Yeh",
    sis_import_id: null,
    sis_user_id: "ADAMYEH",
    sortable_name: "Yeh, Adam",
  }
]

// https://<canvas-install-url>/login/oauth2/auth?client_id=<client_id of third party app>&response_type=code&redirect_uri=<redirect_uri>e&state=Canva