import styles from './AddInstanceModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import HelpIcon from '@mui/icons-material/Help'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { TextField } from '@mui/material'
import { Select, MenuItem } from '@mui/material'
import isURL from 'validator/es/lib/isURL'
import { useState } from 'react'
import { useEffect } from 'react'
import { toFaqPage } from '../../../utils/sharedMethod'

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    font: 'normal normal normal 14px/17px InterRegular'
  }
}))

const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    height: '22px',
    padding: '7px',
    font: 'normal normal normal 14px/17px InterRegular'
  },
  '& ul .MuiButtonBase-root.MuiMenuItem-root': {
    color: 'red'
  }
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: '7px',
    font: 'normal normal normal 14px/17px InterRegular'
  }
}))

const LtiInput = ({ val, handleValChange, helperText, disabled = false }) => {
  return (
    <StyledInput
      disabled={disabled}
      error={Boolean(helperText)}
      helperText={helperText}
      fullWidth
      disableunderline='true'
      type='text'
      value={val}
      onChange={handleValChange}
      // placeholder={placeholder}
      inputProps={{ maxLength: 100 }}
    />
  )
}

const LtiSelect = ({ options, val, handleValChange }) => {
  return (
    <StyledSelect fullWidth value={val} onChange={handleValChange}>
      {options.map((item, idx) => (
        <MenuItem key={idx} value={item} sx={{ font: 'normal normal normal 14px/17px InterRegular', height: '36px' }}>
          {item}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

const useForm = (addInstanceType, chosenConfig, list, tab) => {
  const [toolTyp, setToolTyp] = useState('schedule')
  const [domain, setDomain] = useState('')
  const [clientID, setClientID] = useState('')
  const [deployID, setDeployID] = useState('')
  const [devID, setDevID] = useState('')
  const [devKey, setDevKey] = useState('')
  const [note, setNote] = useState('')
  const [configId, setConfigId] = useState(0)

  const [domainErr, setDomainErr] = useState('')
  const [cIDErr, setCIDErr] = useState('')
  const [depIDErr, setDepIDErr] = useState('')
  const [devIDErr, setDevIDErr] = useState('')
  const [devKeyErr, setDevKeyErr] = useState('')

  const [toolTypeList, setToolTypeList] = useState([])

  const checkDuplicateID = () => {
    let sameDeployID = list.find((item) => item.deployID == deployID) && deployID != chosenConfig.deployID
    let sameclientID = list.find((item) => item.clientID == clientID) && clientID != chosenConfig.clientID
    return sameDeployID && sameclientID
  }

  useEffect(() => {
    if (addInstanceType === 'Edit') return

    let temp_list = [...new Set(list.filter((item) => item.tab == tab).map((item) => item.toolTyp))]
    console.info('temp list', temp_list)

    if (temp_list.length == 2) {
      setToolTypeList([])
    }

    if (temp_list.length == 1) {
      if (temp_list.includes('schedule')) {
        setToolTypeList(['assignment'])
        setToolTyp('assignment')
      } else {
        setToolTypeList(['schedule'])
        setToolTyp('schedule')
      }
    }

    if (temp_list.length == 0) {
      setToolTypeList(['schedule', 'assignment'])
      setToolTyp('schedule')
    }
  }, [list])

  useEffect(() => {
    if (!Object.keys(chosenConfig).length) return
    setToolTyp(chosenConfig.toolTyp)
    setDomain(chosenConfig.domain)
    setClientID(chosenConfig.clientID)
    setDeployID(chosenConfig.deployID)
    setDevID(chosenConfig.devID)
    setDevKey(chosenConfig.devKey)
    setNote(chosenConfig.note)
    setConfigId(chosenConfig.configId)
  }, [chosenConfig])

  useEffect(() => {
    if (!domain) {
      setDomainErr('')
      return
    }
    if (!isURL(domain)) {
      setDomainErr('Invalid site domain')
    } else {
      setDomainErr('')
    }
  }, [domain])

  useEffect(() => {
    if (!clientID) {
      setCIDErr('')
      return
    }
    if (!String(clientID).match(/^[a-zA-Z0-9-:]+$/)) {
      setCIDErr('Invalid Client ID')
    } else if (checkDuplicateID()) {
      setCIDErr('Duplicate Client ID')
      setDepIDErr('Duplicate Deployment ID')
    } else if (!checkDuplicateID()) {
      setCIDErr('')
      setDepIDErr('')
    } else {
      setCIDErr('')
    }
  }, [clientID])

  useEffect(() => {
    if (!deployID) {
      setDepIDErr('')
      return
    }
    if (!String(deployID).match(/^[a-zA-Z0-9-:]+$/)) {
      setDepIDErr('Invalid Deployment ID')
    } else if (checkDuplicateID()) {
      setCIDErr('Duplicate Client ID')
      setDepIDErr('Duplicate Deployment ID')
    } else if (!checkDuplicateID()) {
      setCIDErr('')
      setDepIDErr('')
    } else {
      setDepIDErr('')
    }
  }, [deployID])

  useEffect(() => {
    if (!devID) {
      setDevIDErr('')
      return
    }
    if (!String(devID).match(/^[a-zA-Z0-9-:]+$/)) {
      setDevIDErr('Invalid Developer ID')
    } else {
      setDevIDErr('')
    }
  }, [devID])

  useEffect(() => {
    if (!devKey) {
      setDevKeyErr('')
      return
    }
    if (!String(devKey).match(/^[a-zA-Z0-9-:]+$/)) {
      setDevKeyErr('Invalid Developer Key')
    } else {
      setDevKeyErr('')
    }
  }, [devKey])

  const handleToolTyp = (e) => {
    setToolTyp(e.target.value)
  }

  const handleDomain = (e) => {
    setDomain(e.target.value)
  }

  const handleClientID = (e) => {
    setClientID(e.target.value)
  }

  const handleDeployID = (e) => {
    setDeployID(e.target.value)
  }

  const handleDevID = (e) => {
    setDevID(e.target.value)
  }

  const handleDevKey = (e) => {
    setDevKey(e.target.value)
  }

  const handleNote = (e) => {
    setNote(e.target.value)
  }

  const reformSaveItem = () => {
    return {
      toolTyp,
      domain,
      clientID,
      deployID,
      devID,
      devKey,
      note,
      configId
    }
  }

  return {
    toolTyp,
    handleToolTyp,
    domain,
    domainErr,
    handleDomain,
    clientID,
    cIDErr,
    handleClientID,
    deployID,
    depIDErr,
    handleDeployID,
    devID,
    devIDErr,
    handleDevID,
    devKey,
    devKeyErr,
    handleDevKey,
    note,
    handleNote,
    reformSaveItem,
    toolTypeList
  }
}

const AddInstanceModal = ({ handleAddInstanceModal, addInstanceType, list, handleList, tab, chosenConfig }) => {
  const {
    toolTyp,
    handleToolTyp,
    domain,
    domainErr,
    handleDomain,
    clientID,
    cIDErr,
    handleClientID,
    deployID,
    depIDErr,
    handleDeployID,
    devID,
    devIDErr,
    handleDevID,
    devKey,
    devKeyErr,
    handleDevKey,
    note,
    handleNote,
    reformSaveItem,
    toolTypeList
  } = useForm(addInstanceType, chosenConfig, list, tab)

  const hidden = () => {
    if (tab == 'LTI BlackBoard') return true
    return toolTyp == 'assignment'
  }

  const disabledSaveBtn = () => {
    if (tab == 'LTI Canvas' && toolTyp == 'schedule') {
      return !!domainErr || !!cIDErr || !!depIDErr || !!devIDErr || !!devKeyErr || !domain || !clientID || !deployID || !devID || !devKey
    }
    if (tab == 'LTI BlackBoard') {
      return !!domainErr || !!depIDErr || !domain || !deployID
    }
    return !!domainErr || !!cIDErr || !!depIDErr || !!devIDErr || !!devKeyErr || !domain || !clientID || !deployID
  }

  const saveList = (dataObj) => {
    handleList(dataObj)
    handleAddInstanceModal(false)
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.titleWrap}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
          <div className={styles.title}>{addInstanceType} Instance</div>
          <StyledTooltip title='View supporting page' placement='right-end'>
            <HelpIcon onClick={() => toFaqPage()} />
          </StyledTooltip>
        </div>
        <CloseRoundedIcon sx={{ color: '#808080', '&:hover': { color: '#1452CC' }, transform: 'scale(1.5)', cursor: 'pointer' }} onClick={() => handleAddInstanceModal(false)} />
      </div>
      <div className={styles.field}>
        <div className={styles.subTitle}>Type</div>
        {addInstanceType === 'Edit' ? (
          <LtiInput val={toolTyp} handleValChange={() => {}} helperText='' disabled={true} />
        ) : (
          <LtiSelect options={toolTypeList} val={toolTyp} handleValChange={handleToolTyp} />
        )}
      </div>
      <div className={styles.field}>
        <div className={styles.subTitle}>Site Domain</div>
        <LtiInput val={domain} handleValChange={handleDomain} helperText={domainErr} />
      </div>
      <div className={`${styles.field} ${tab == 'LTI BlackBoard' && styles.hidden}`}>
        <div className={styles.subTitle}>Client ID</div>
        <LtiInput val={clientID} handleValChange={handleClientID} helperText={cIDErr} />
      </div>
      <div className={styles.field}>
        <div className={styles.subTitle}>Deployment ID</div>
        <LtiInput val={deployID} handleValChange={handleDeployID} helperText={depIDErr} />
      </div>
      <div className={`${hidden() && styles.hidden}`}>
        <div className={styles.field}>
          <div className={styles.subTitle}>Developer ID</div>
          <LtiInput val={devID} handleValChange={handleDevID} helperText={devIDErr} />
        </div>
        <div className={styles.field}>
          <div className={styles.subTitle}>Developer Key</div>
          <LtiInput val={devKey} handleValChange={handleDevKey} helperText={devKeyErr} />
        </div>
      </div>
      <div className={styles.field}>
        <div className={styles.subTitle}>Note</div>
        <LtiInput val={note} handleValChange={handleNote} helperText='' />
      </div>
      <div className={styles.btnWrap}>
        <button className={`${styles.btn} ${styles.cancel}`} onClick={() => handleAddInstanceModal(false)}>
          Cancel
        </button>
        <button className={`${styles.btn} ${styles.save} ${disabledSaveBtn() && styles.disabled}`} onClick={() => saveList(reformSaveItem())}>
          Save
        </button>
      </div>
    </div>
  )
}

export default AddInstanceModal
