import styles from './ScheduleBlock.module.css'
import { ReadOnlyCalendar } from '../../../../../parts/TimePicker/TimePicker'

const ScheduleBlock = ({ selectedDatesArr }) => {

  return (
    <div className={styles.CalendarBlockWrap}>
      <div className={styles.title}>Schedule</div>
      { selectedDatesArr.length !== 0 && <ReadOnlyCalendar selectedDatesArr={selectedDatesArr}/> }
    </div>
  )
}

export default ScheduleBlock

