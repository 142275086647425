import { useState } from 'react'

const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return { open, anchorEl, openPopover, handleClose }
}

export default usePopover