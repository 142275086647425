import styles from './EditGroupNameModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import InsetInput from '../../InsetInput'
import { useState } from 'react'

const EditGroupNameModal = ({ handleEditGroupNameModal, groupName, handleGroupName }) => {

  const [newGroupName, setNewGroupName] = useState(groupName)

  const handleNewGroupName = (e) => {
    setNewGroupName(e.target.value)
  }

  const disableUpdateButton = () => {
    return groupName === newGroupName
  }

  const updateGroupName = () => {
    handleEditGroupNameModal(false)
    handleGroupName(newGroupName, true)
  }

  return (
    <div className={styles.modalWrap}>
      <div className={styles.titleWrap}>
        <div className={styles.title}>Edit Group Up List Name</div>
        <div className={styles.icon} onClick={() => handleEditGroupNameModal(false)}><CloseRoundedIcon sx={{transform: 'scale(1.2)'}}/></div>
      </div>
      <div className={styles.linkInput}>
        <InsetInput type="border" inputhVal={newGroupName} handleInputVal={handleNewGroupName} placeholder="Group Name" isValid={true} helperText="" disabled={false} maxLength={50} />
      </div>
      <div className={`${styles.update} ${disableUpdateButton() && styles.disabled}`} onClick={updateGroupName}>Update</div>
    </div>
  )
}

export default EditGroupNameModal