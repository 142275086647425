import { useState } from 'react'
import usePagination from './usePagination'
import { UserContext } from './useAuth'
import { useContext } from 'react'
import { getPortfolioList, getPortfolioByClass, getPortfolioAttendedStudents, getStudentPortfolio, getPopQuizDetail, deleteQuizRecord } from '../api/index'
import useToaster from './useToaster'

const usePortfolioList = () => {
  const { userInfo } = useContext(UserContext)
  const { page, totalPage, handlePage, handleTotalPage } = usePagination()
  const [portfolioList, setPortfolioList] = useState([])
  const [studentQuizList, setStudentQuizList] = useState([])
  const [loadingMainPage, setLoadingMainPage] = useState(false)
  const [loadingDashboard, setLoadingDashboard] = useState(false)
  const [loadingAttendedStudents, setLoadingAttendedStudents] = useState(false)
  const [loadingStudentPortfolio, setLoadingStudentPortfolio] = useState(false)
  const [dashboardData, setDashboardData] = useState(null)
  const [attendedStudents, setAttendedStudents] = useState([])
  const [studentPortfolio, setStudentPortfolio] = useState(null)
  const { openToaster, toasterText, handleOpenToaster, handleCloseToaster } = useToaster()
  const [severity, setSeverity] = useState('success')
  const [quizDetail, setQuizDetail] = useState(null)
  const [loadingPopQuiz, setLoadingPopQuiz] = useState(false)

  const call_getPortfolioListApi = () => {
    setLoadingMainPage(true)
    let data = {
      orgId: userInfo.orgID,
      userId: userInfo.userID
    }
    if (!userInfo.orgID || !userInfo.userID) return
    getPortfolioList(data)
      .then((res) => {
        let list = res.data
        setPortfolioList(list)
        setLoadingMainPage(false)
      })
      .catch()
  }

  const call_getDashboardApi = (data) => {
    setLoadingDashboard(true)
    getPortfolioByClass(data)
      .then((res) => {
        setLoadingDashboard(false)
        setDashboardData(res.data)
      })
      .catch()
  }

  const call_getAttendedStudentsApi = (data) => {
    setLoadingAttendedStudents(true)
    getPortfolioAttendedStudents(data)
      .then((res) => {
        setLoadingAttendedStudents(false)
        let studentList = res.data
        studentList.forEach((student) => {
          student.orgID = userInfo.orgID
          switch (student.attendance.type) {
            case 'onTime':
              student.order = 1
              break
            case 'late':
              student.order = 2
              break
            case 'lateAndEarlyLeave':
              student.order = 3
              break
            case 'earlyLeave':
              student.order = 4
              break
            default:
              student.order = 5
          }
        })
        setAttendedStudents(studentList)
      })
      .catch()
  }

  const call_getStudentPortfolio = (data) => {
    setLoadingStudentPortfolio(true)
    getStudentPortfolio(data)
      .then((res) => {
        setLoadingStudentPortfolio(false)
        setStudentPortfolio(res.data)
      })
      .catch()
  }

  const call_getPopQuizDetail = (data) => {
    getPopQuizDetail(data)
      .then((res) => {
        setLoadingPopQuiz(false)
        setQuizDetail(res.data)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const call_deleteQuizRecordApi = (data) => {
    deleteQuizRecord(data)
      .then((res) => {
        setLoadingDashboard(false)
        call_getDashboardApi({
          courseId: data.courseId,
          startTime: data.startTime,
          endTime: data.endTime
        })
        setSeverity('success')
        handleOpenToaster('Quiz Record has been deleted successfully!')
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  return {
    page,
    totalPage,
    handlePage,
    handleTotalPage,
    portfolioList,
    studentQuizList,
    setStudentQuizList,
    call_getPortfolioListApi,
    call_getDashboardApi,
    loadingMainPage,
    loadingDashboard,
    setLoadingDashboard,
    dashboardData,
    call_getAttendedStudentsApi,
    attendedStudents,
    setAttendedStudents,
    loadingAttendedStudents,
    call_getStudentPortfolio,
    loadingStudentPortfolio,
    studentPortfolio,
    openToaster,
    toasterText,
    handleOpenToaster,
    handleCloseToaster,
    severity,
    setSeverity,
    call_getPopQuizDetail,
    quizDetail,
    setLoadingPopQuiz,
    loadingPopQuiz,
    call_deleteQuizRecordApi
  }
}

export default usePortfolioList
