import { useContext, useEffect, useState } from 'react'
import styles from './Users.module.css'
import ManageUser from './ManageUser/ManageUser'
import StudentList from './StudentList/StudentList'
import TeacherList from './TeacherList/TeacherList'
import OnlineStatus from './OnlineStatus/OnlineStatus'
import { UserContext } from '../../CustomHooks/useAuth'

const useTabs = () => {
  const { userInfo } = useContext(UserContext)
  const [tab, setTab] = useState('')

  const handleTab = (tab) => {
    setTab(tab)
  }

  useEffect(() => {
    if (userInfo.identity) setTab(userInfo.identity === 'admin' || userInfo.identity.toLowerCase() === 'owner' ? 'Online Status' : 'Student List')
  }, [userInfo])

  return { tab, handleTab }
}

const tabs = ['Online Status', 'Manage Users', 'Student List', 'Teacher List']

const Tabs = ({ tab, handleTab }) => {
  const { userInfo } = useContext(UserContext)

  const isVisible = (item) => {
    if (userInfo.identity === 'admin' || userInfo.identity === 'owner') {
      return true
    }
    if (userInfo.identity === 'teacher') {
      if (item === 'Online Status') return false
      if (item === 'Manage Users') return false
      return true
    }
  }

  return (
    <div className={styles.tabsWrap}>
      {tabs.map((item, idx) => (
        <div key={idx} className={`${styles.tabWrap} ${!isVisible(item) && styles.hide}`}>
          <div className={`${styles.tab} ${tab === item && styles.active}`} onClick={() => handleTab(item)}>
            {item}
          </div>
          <div className={`${styles.bottomLine}`}></div>
        </div>
      ))}
    </div>
  )
}

const Users = () => {
  const { tab, handleTab } = useTabs()

  return (
    <div className={styles.UsersWrap}>
      <Tabs tab={tab} handleTab={handleTab} />
      {tab === 'Online Status' && <OnlineStatus />}
      {tab === 'Manage Users' && <ManageUser />}
      {tab === 'Student List' && <StudentList />}
      {tab === 'Teacher List' && <TeacherList />}
    </div>
  )
}

export default Users
