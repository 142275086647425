import styles from './LtiSettings.module.css'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { useNavigate } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import CustomSwitch from '../../parts/CustomSwitch'
import useLTISettings from '../../CustomHooks/useLTISettings'
import usePopover from '../../CustomHooks/usePopover'
import CustomPagination from '../../parts/CustomPagination'
import Modal from '../../parts/Modal'
import DeleteInstanceModal from '../../parts/Modals/DeleteInstanceModal/DeleteInstanceModal'
import AddInstanceModal from '../../parts/Modals/AddInstanceModal/AddInstanceModal'
import CircularProgress from '@mui/material/CircularProgress'
import Popover from '@mui/material/Popover'
import Toaster from '../../parts/Toaster'
import BreadCrumb from '../../parts/BreadCrumb/BreadCrumb'

const tabs = ['LTI Canvas', 'LTI BlackBoard']

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    font: 'normal normal normal 14px/17px InterRegular'
  }
}))

const LtiSettings = () => {
  const {
    tab,
    setTab,
    allowCalendar,
    handleAllowCalendarChange,
    allowAssignment,
    handleAllowAssignmentChange,
    page,
    totalPage,
    handlePage,
    deleteModal,
    handleDeleteModal,
    addInstanceModal,
    handleAddInstanceModal,
    addInstanceType,
    list,
    handleList,
    chosenConfig,
    handleChosenConfig,
    removeConfig,
    loading,
    ltiSettingsLoading,
    openToaster,
    toasterText,
    toastSeverity,
    handleCloseToaster
  } = useLTISettings()

  const Tabs = () => {
    return (
      <div className={styles.title}>
        <div style={{ font: 'normal normal 600 24px/33px InterRegular' }}>3rd Party Credentials</div>
        <div className={styles.tabs}>
          {tabs.map((item, idx) => (
            <div key={idx} className={`${styles.tab} ${tab === item && styles.active}`} onClick={() => setTab(item)}>
              {item}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const Utility = () => {
    return (
      <div className={styles.utilityWrap}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
          <div style={{ font: 'normal normal 600 18px/27px InterRegular' }}>Instance list</div>
          <StyledTooltip title='Each organization is only allowed to apply for one Calendar instance and one Assignment instance.' placement='right-end'>
            <HelpIcon />
          </StyledTooltip>
        </div>
        <button
          className={`${styles.btn} ${(loading || list.filter((item) => item.tab == tab).length >= 2) && styles.disabled}`}
          onClick={() => handleAddInstanceModal(true, 'Add')}
        >
          + Add Instance
        </button>
      </div>
    )
  }

  const TableHeader = () => {
    return (
      <div className={styles.tableHeader}>
        <div className={`${styles.tableContent} ${styles.domain}`}>{`${tab.split(' ')[1]} Site Domain`}</div>
        <div className={`${styles.tableContent} ${styles.d_id}`}>Developer ID</div>
        <div className={`${styles.tableContent} ${styles.c_id}`}>Client ID</div>
        <div className={`${styles.tableContent} ${styles.typ}`}>Type</div>
        <div className={`${styles.tableContent} ${styles.note}`}>Note</div>
        <div className={`${styles.tableContent} ${styles.action}`}>Action</div>
      </div>
    )
  }

  const TableRow = ({ item }) => {
    const { open, anchorEl, openPopover, handleClose } = usePopover()

    const edit = () => {
      handleChosenConfig(item)
      handleAddInstanceModal(true, 'Edit')
      handleClose()
    }

    const remove = () => {
      handleChosenConfig(item)
      handleDeleteModal(true)
      handleClose()
    }

    return (
      <div className={styles.tableRows}>
        <div className={`${styles.tableContent} ${styles.domain}`}>{item.domain}</div>
        <div className={`${styles.tableContent} ${styles.d_id}`}>{item.deployID}</div>
        <div className={`${styles.tableContent} ${styles.c_id}`}>{item.clientID}</div>
        <div className={`${styles.tableContent} ${styles.typ}`}>{item.toolTyp}</div>
        <div className={`${styles.tableContent} ${styles.note}`}>{item.note}</div>
        <div className={`${styles.tableContent} ${styles.action}`} onClick={openPopover}>
          <MoreHorizIcon sx={{ color: '#707070', transform: 'scale(1.2)', cursor: 'pointer' }} />
        </div>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <div className={styles.menuWrap}>
            <div className={`${styles.option} ${styles.edit}`} onClick={edit}>
              Edit Registration
            </div>
            <div className={`${styles.option} ${styles.delete}`} onClick={remove}>
              Delete Registration
            </div>
          </div>
        </Popover>
      </div>
    )
  }

  let arr = list.filter((item) => item.tab === tab)

  return (
    <div className={styles.settingWrap}>
      <BreadCrumb title='LTI Setting' />
      <div className={styles.config}>
        <Tabs />
        <div className={styles.content}>
          {ltiSettingsLoading && (
            <div style={{ height: '142px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )}
          {!ltiSettingsLoading && (
            <div className={styles.switches}>
              <div className={styles.switch}>
                <CustomSwitch checked={allowCalendar} handleChange={handleAllowCalendarChange} />
                <div className={styles.switchTxt}>
                  <div className={styles.switchTitle}>Calendar</div>
                  <div className={styles.switchComment}>Allow to set UNIVERSE link when create/edit/delete course</div>
                </div>
              </div>
              <div className={styles.switch}>
                <CustomSwitch checked={allowAssignment} handleChange={handleAllowAssignmentChange} />
                <div className={styles.switchTxt}>
                  <div className={styles.switchTitle}>Assignment</div>
                  <div className={styles.switchComment}>Allow to set UNIVERSE link when create/edit/delete assignment</div>
                </div>
              </div>
            </div>
          )}
          <div className={styles.instanceListWrap}>
            <Utility />
            <TableHeader />
            {loading && (
              <div style={{ height: '200px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            )}
            {!loading && arr.length === 0 && <div className={styles.noRecord}>No records found</div>}
            {!loading &&
              list.length !== 0 &&
              arr.filter((item, idx) => idx >= (page - 1) * 14 && idx <= (page - 1) * 14 + 13).map((item, idx) => <TableRow key={idx} item={item} />)}
            {totalPage > 1 && (
              <div className={styles.paginationWrap}>
                <CustomPagination page={page} totalPage={totalPage} handlePage={handlePage} />
              </div>
            )}
          </div>
        </div>
      </div>
      {deleteModal && (
        <Modal>
          <DeleteInstanceModal handleDeleteModal={handleDeleteModal} removeConfig={removeConfig} />
        </Modal>
      )}
      {addInstanceModal && (
        <Modal>
          <AddInstanceModal
            handleAddInstanceModal={handleAddInstanceModal}
            addInstanceType={addInstanceType}
            list={list}
            handleList={handleList}
            tab={tab}
            chosenConfig={chosenConfig}
          />
        </Modal>
      )}
      <Toaster openToaster={openToaster} handleCloseToaster={handleCloseToaster} severity={toastSeverity} message={toasterText} />
    </div>
  )
}

export default LtiSettings
