import styles from './UtilityRow.module.css'
import usePopover from '../../../CustomHooks/usePopover'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Popover } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import SearchInput from '../../SearchInput'
import { Fragment, useEffect, useState } from 'react'
import WindowSelect from '../../VirtualizedList/VirtualizedList'


const CustomPopover = ({ open, anchorEl, handleClose, handleAddManuallyModal, setName, setActionType, setStep }) => {
  const onClickAddManually = () => {
    setActionType('create')
    handleAddManuallyModal(true)
    handleClose()
    setName('')
  }

  const onClickImportFile = () => {
    setStep(4)
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <div className={styles.menuOption} onClick={onClickImportFile}>
        Import From File
      </div>
      <div className={styles.menuOption} onClick={onClickAddManually}>
        Add manually
      </div>
    </Popover>
  )
}

const UtilityRow = ({ typ, searchVal, handleSearchVal, handleModal, handleAddManuallyModal, setName, setActionType, setStep,
  sortBy, order, handleSortBy, handleOrder }) => {
    
  const { open, anchorEl, openPopover, handleClose } = usePopover()
  const [sortOptions, setSortOptions] = useState([])

  useEffect(() => {
    if(typ == 'Quiz') {
      setSortOptions(['Updated date', `${typ} name (A-Z)`, 'Number of questions'])
    } else {
      setSortOptions(['Updated date', `${typ} name (A-Z)`, 'Status', 'Number of questions'])
    }
  }, [])

  return (
    <div className={styles.utilityRowWrap}>
      <div className={styles.left}>
        <div className={styles.leftWrap}>
          <span style={{ font: 'normal normal normal 24px/29px InterMedium' }}>{ typ } </span>
          {
            typ == 'Assignment' && (
              <Fragment>
                <div className={styles.ltiTag}>LTI</div>
                <div className={styles.questionIcon}>
                  <Tooltip
                    title='Share assignments on Canvas or Blackboard using hidden "Share to other platforms" button in Action column.'
                    placement='bottom-start'
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.arrow}`]: {
                            color: (theme) => theme.palette.common.black
                          },
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) => theme.palette.common.black,
                            fontWeight: 400
                          }
                        }
                      }
                    }}
                    arrow
                  >
                    <QuestionMarkIcon sx={{ fontSize: 16, color: 'white' }} />
                  </Tooltip>
                </div>
              </Fragment>
            )
          }
        </div>
        <div className={styles.searchInputWrap}>
          <SearchInput searchVal={searchVal} handleSearchVal={handleSearchVal} placeholder={`Search ${typ} Name`} />
        </div>
      </div>
      <div className={styles.right}>
        <div style={{ font: 'normal normal 600 14px/17px InterRegular', minWidth: '57px' }}>Sort by:</div>
        <div style={{ width: '230px' }}><WindowSelect type='white' label='' options={sortOptions} val={sortBy} handleValChange={handleSortBy} disabled={false} /></div>
        <div style={{ width: '180px' }}><WindowSelect type='white' label='' options={['Descending', 'Ascending']} val={order} handleValChange={handleOrder} disabled={false} /></div>
        <button className={styles.resultBtn} onClick={() => setStep(6)}>
          <div className={styles.resultIcon}></div>
          <div>Results</div>
        </button>
        <button className={`${styles.addBtn} ${typ == 'Quiz' && styles.small}`} onClick={openPopover}>
          <div>Add {typ}</div>
          <ExpandMoreIcon />
        </button>
        <CustomPopover
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleModal={handleModal}
          handleAddManuallyModal={handleAddManuallyModal}
          setName={setName}
          setActionType={setActionType}
          setStep={setStep}
        />
      </div>
    </div>
  )
}

export default UtilityRow