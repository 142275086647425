import styles from './DeleteParentModal.module.css'

const DeleteParentModal = ({ typ, onDelete, setDeleteModal }) => {
  return (
    <div className={styles.modalWrap}>
      <div className={styles.title}>Delete { typ }</div>
      <div className={styles.message}>
        Are you sure you want to delete this { typ.toLowerCase() } and its contents? This action will also delete any associated portfolio records.
      </div>
      <div className={styles.btnWrap}>
        <button
          className={`${styles.delete} ${styles.btn}`}
          onClick={() => {
            setDeleteModal(false)
            onDelete()
          }}
        >
          Delete
        </button>
        <button className={`${styles.cancel} ${styles.btn}`} onClick={() => setDeleteModal(false)}>
          Cancel
        </button>
      </div>
    </div>
  )
}

export default DeleteParentModal
