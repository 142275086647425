import axios from './axios'
import { Base64 } from 'js-base64'

export function test_fastapi() {
  return axios.get(`${process.env.REACT_APP_FASTAPI_DOMAIN}api/v1/object_categories?lang=en-US`)
}

export function getCanvasMemberList() {
  const data = {
    userID: {
      S: '1'
    }
  }
  return axios.post('https://pumsefl440.execute-api.eu-central-1.amazonaws.com/stage', data)
}

export function googleSignIn(code) {
  const secret = Base64.encode('50b4at73t6jchcmtorl4nkp46f:1qe9agallc88pfd2v479qitn9ctsvm6jaorm3b92n8kprq9g003j')
  const client_id = '50b4at73t6jchcmtorl4nkp46f'
  const options = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: 'Basic ' + secret
    }
  }
  return axios.post(
    `https://universe.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=authorization_code&code=${code}&client_id=${client_id}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`,
    '',
    options
  )
}

export function renewToken() {
  const secret = Base64.encode('50b4at73t6jchcmtorl4nkp46f:1qe9agallc88pfd2v479qitn9ctsvm6jaorm3b92n8kprq9g003j')
  const refreshToken = localStorage.getItem('refreshToken')
  const client_id = '50b4at73t6jchcmtorl4nkp46f'
  const options = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: 'Basic ' + secret
    }
  }
  return axios.post(
    `https://universe.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=refresh_token&client_id=${client_id}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&refresh_token=${refreshToken}`,
    '',
    options
  )
}

export function getPublicKey() {
  const region = 'eu-central-1'
  const userPoolId = 'eu-central-1_uZ8MW3lpX'
  return axios.get(`https://cognito-idp.${region}.amazonaws.com/${userPoolId}/.well-known/jwks.json`)
}

export function getAccountList(data) {
  return axios.post('https://4zja8imqy3.execute-api.eu-central-1.amazonaws.com/dev/test/readorguserlist', data)
}

export function saveAccountList(data, idToken) {
  const config = { headers: { Authorization: `${idToken}` } }
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/dev/saveuniversemanageruserdev', data, config)
}

export function editAccountList(data) {
  return axios.post('https://4zja8imqy3.execute-api.eu-central-1.amazonaws.com/dev/editusername', data)
}

export function deleteAccountList(data) {
  return axios.post('https://4zja8imqy3.execute-api.eu-central-1.amazonaws.com/dev/deleteuser', data)
}

export function getClassList(config) {
  let data = config.data
  let signal = config.signal
  // return axios.post('https://j365vu9jy8.execute-api.eu-central-1.amazonaws.com/product/manager/getcourse', { ...data }, { signal })
  return axios.post('https://eexwkvw2e0.execute-api.eu-central-1.amazonaws.com/dev/getclasslist', { ...data }, { signal })
}

export function getClassInfo(data) {
  return axios.post('https://eexwkvw2e0.execute-api.eu-central-1.amazonaws.com/dev/getclassinfo', data)
}

export function saveClassList(data) {
  return axios.post('https://eexwkvw2e0.execute-api.eu-central-1.amazonaws.com/dev/saveclasslist', data)
}

export function getClassroomList(data) {
  return axios.post('https://eexwkvw2e0.execute-api.eu-central-1.amazonaws.com/dev/getclassroomlist', data)
}

export function getCourseDetail(data) {
  return axios.post('https://eexwkvw2e0.execute-api.eu-central-1.amazonaws.com/dev/getcoursedetail', data)
}

export function getClassroomTeachers(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/classroom/teacher', data)
}

export function editClassList(data, mode) {
  if (mode === 'Single') {
    return axios.post('https://czgvkonp97.execute-api.eu-central-1.amazonaws.com/dev/test-updateonecourse', data)
  }
  return axios.post('https://eexwkvw2e0.execute-api.eu-central-1.amazonaws.com/dev/editclasslist/updatecourse', data)
}

export function getStudentInfoWithCourses(data) {
  return axios.post('https://4zja8imqy3.execute-api.eu-central-1.amazonaws.com/dev/test/getstudentinfo', data)
}

export function getTeacherInfoWithCourses(data) {
  return axios.post('https://4zja8imqy3.execute-api.eu-central-1.amazonaws.com/dev/test/getteacherinfo', data)
}

export function editStudentListByClass(data) {
  return axios.post('https://wd850czloc.execute-api.eu-central-1.amazonaws.com/getGroupRDS_TEST', data)
}

export function editClassSettings(data) {
  return axios.post('https://eexwkvw2e0.execute-api.eu-central-1.amazonaws.com/dev/editclasssettings', data)
}

export function editTeacherByClass(data) {
  return axios.post('https://j365vu9jy8.execute-api.eu-central-1.amazonaws.com/product/updateteacher', data)
}

export function editGroupByClass(data) {
  return axios.post('https://j365vu9jy8.execute-api.eu-central-1.amazonaws.com/product/updategrouplist', data)
}

export function deleteGroupByClass(data) {
  return axios.post('https://j365vu9jy8.execute-api.eu-central-1.amazonaws.com/product/deletegroup', data)
}

export function deleteClassList(data, mode) {
  if (mode === 'Single') {
    return axios.post('https://czgvkonp97.execute-api.eu-central-1.amazonaws.com/dev/test-deleteonecourse', data)
  }
  return axios.post('https://czgvkonp97.execute-api.eu-central-1.amazonaws.com/dev/test-deletecours', data)
}

export function getPermissionAndOrg(userInfo, typ) {
  const config = typ === 'google' ? {} : { headers: { Authorization: `${userInfo.idToken}` } }
  const data = typ === 'google' ? { email: userInfo.email } : { access_token: userInfo.accessToken }
  return axios.post('https://j365vu9jy8.execute-api.eu-central-1.amazonaws.com/product/getcognitoattrv3', data, config)
}

export function editChatGroup(data) {
  return axios.post('https://j365vu9jy8.execute-api.eu-central-1.amazonaws.com/product/editchatgroup', data)
}

export function sendCanvasCodeToBackEnd(data) {
  return axios.post('https://jhagrwvagg.execute-api.eu-central-1.amazonaws.com/getCanvasToken', data)
}

export function getCanvasMember(data) {
  return axios.post('https://5fx8ms6hk2.execute-api.eu-central-1.amazonaws.com/getCanvasAccount', data)
}

export function start_oidc_launch() {
  const data = {
    iss: 'iss',
    login_hint: 'student',
    lti_message_hint: 'lti_message_hint',
    target_link_uri: 'target_link_uri'
  }
  return axios.post('https://e9nwehhkqf.execute-api.eu-central-1.amazonaws.com/stage/lti_oidc_launch', data)
}

export function start_oidc_login() {
  let domain = 'dev-zs33ui-j.us.auth0.com'
  let response_type = 'code id_token token'
  let response_mode = 'form_post'
  let client_id = '4tveCIV2BWUZM2t5dDoPnhdhQJFrCBhn'
  let redirect_uri = 'https://e9nwehhkqf.execute-api.eu-central-1.amazonaws.com/stage/lti_oidc_id_token'
  let scope = 'openid'
  let audience = 'https://ltiTest/'
  let state = 'sdfgsdfgsdfgdfg'
  let nonce = 'sdfgsdfgsdfgdfg'
  const url = `https://${domain}/authorize?response_type=${response_type}&response_mode=${response_mode}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&audience=${audience}&state=${state}&nonce=${nonce}`
  window.location.replace(url)
}

// iss
// https%3A%2F%2Fcanvas.instructure.com

// login_hint
// 8480c90c158f5582c6e5dd79d137622ee4149ed1

// client_id
// 10000000000012

// target_link_uri
// https%3A%2F%2Fmaster.d3svbt9ck4qj84.amplifyapp.com%2F

// lti_message_hint
// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZXJpZmllciI6IjhjNWFmZDhjYTcwYjZjZmI1NjU4OTBmYzQzYjExYTZhYThlNzQ5N2RlN2ExNjIwMzVkYzdkY2Q3YTNkMDg2NzljYzIxM2U4ZTU1YmJjNGE5ZjEwN2EzZjE5MmI2YzRiMzYyODM0ZjEwZjNiYWZlYjQwYjE2NTRiM2MyYmIzYWEzIiwiY2FudmFzX2RvbWFpbiI6IjUyLjc3LjIzMC45MSIsImNvbnRleHRfdHlwZSI6IkNvdX\

// assignment
export function getAssignmentList(data, params, signal) {
  return axios({
    method: 'post',
    baseURL: 'https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/list',
    params,
    data,
    signal
  })
}

export function createAssignment(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment', data)
}

export function updateAssignment(data) {
  return axios.put(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/${data.assignmentId}`, data)
}

export function deleteAssignment(data) {
  return axios.delete(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/${data.assignmentId}`)
}

export function getAssignmentDetail(data) {
  return axios.get(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/${data.assignmentId}`)
}

export function duplicateAssignment(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/duplicate', data)
}

export function uploadImage(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/image', data)
}

export function importAssignmentQuestion(data) {
  console.info('importAssignmentQuestion', data)
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/import', data)
}

export function getAssignmentListForImport(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/import/list', data)
}

export function getImportAssignmentHistory(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/import/history', data)
}

export function updateAssignmentStatus(data) {
  return axios.patch(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/${data.assignmentId}`, data)
}

export function getAssignmentResults(data, params) {
  return axios({
    method: 'post',
    baseURL: 'https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/result',
    params,
    data
  })
  // return axios.post(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/result?offset=${offset}&limit=${limit}`, data)
}

export function getAssignmentResultsQuestions(data) {
  return axios.get(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/result/question/${data.assignmentId}`)
}

export function getAssignmentResultsParticipants(data) {
  return axios.get(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/result/participant/${data.assignmentId}`)
}

export function createAssignmentQuestion(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/question', data)
}

export function updateAssignmentQuestion(data) {
  return axios.put(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/question/${data.questionId}`, data)
}

export function deleteAssignmentQuestion(data) {
  return axios.delete(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/question/${data.questionId}`)
}

export function duplicateAssignmentQuestion(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/assignment/question/duplicate', data)
}

// quiz

export function getQuizList(data, params, signal) {
  return axios({
    method: 'post',
    baseURL: 'https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/list',
    params,
    data,
    signal
  })
}

export function createQuiz(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz', data)
}

export function updateQuiz(data) {
  return axios.put(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/${data.quizId}`, data)
}

export function deleteQuiz(data) {
  return axios.delete(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/${data.quizId}`)
}

export function getQuizDetail(data) {
  return axios.get(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/detail/${data.quizId}`)
}

export function getQuizResults(data, params) {
  return axios({
    method: 'post',
    baseURL: 'https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/result',
    params,
    data
  })
}

export function deleteQuizResult(data) {
  return axios.delete(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/portfolio/popquiz/${data.popQuizId}`)
}

export function getQuizResultsQuestions(data) {
  return axios.get(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/result/question/${data.popQuizId}`)
}

export function getQuizResultsParticipants(data) {
  return axios.get(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/result/participant/${data.popQuizId}`)
}

export function duplicateQuiz(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/duplicate', data)
}

export function getQuizListForImport(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/import/list', data)
}

export function import_qti_zip_question() {
  return new Promise((resolve) => resolve('hi'))
}

export function getImportQuizHistory(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/import/history', data)
}

export function getCourseByTeacher(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/course', data)
}

export function importQuizQuestion(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/import', data)
}

export function createQuizQuestion(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/question', data)
}

export function updateQuizQuestion(data) {
  return axios.put(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/question/${data.questionId}`, data)
}

export function deleteQuizQuestion(data) {
  return axios.delete(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/question/${data.questionId}`)
}

export function duplicateQuizQuestion(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/quiz/question/duplicate', data)
}

// portfolio

export function getPortfolioList(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/portfolio', data)
}

export function getPortfolioByClass(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/portfolio/class', data)
}

export function getPortfolioAttendedStudents(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/portfolio/class/students', data)
}

export function getStudentPortfolio(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/portfolio/class/student', data)
}

export function getCourseExcel(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/portfolio/course/excel', data)
}

export function getClassExcel(data) {
  return axios.post('https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/portfolio/class/excel', data)
}

export function getPopQuizDetail(data) {
  return axios.get(`https://j365vu9jy8.execute-api.eu-central-1.amazonaws.com/product/pop_quiz/${data.popQuizId}`, data)
}

export function deleteQuizRecord(data) {
  return axios.delete(`https://0deal6nau0.execute-api.eu-central-1.amazonaws.com/product/v2/portfolio/popquiz/${data.popQuizId}`, data)
}

export function getLTIConfig(data) {
  return axios.post('https://lynyzdngm5.execute-api.eu-central-1.amazonaws.com/dev/get_lti_tool_config', data)
}

export function createLTIConfig(data) {
  return axios.post('https://lynyzdngm5.execute-api.eu-central-1.amazonaws.com/dev/register_tool_config', data)
}

export function updateLTIConfig(data) {
  return axios.post('https://lynyzdngm5.execute-api.eu-central-1.amazonaws.com/dev/edit_lti_tool_config', data)
}

export function deleteLTIConfig(data) {
  return axios.post('https://lynyzdngm5.execute-api.eu-central-1.amazonaws.com/dev/delete_lti_tool_config', data)
}

export function editLTIOrgSettings(data) {
  return axios.post('https://lynyzdngm5.execute-api.eu-central-1.amazonaws.com/dev/allow_lti_org_settings', data)
}

export function getClassroomStyleList() {
  return axios.get('https://czgvkonp97.execute-api.eu-central-1.amazonaws.com/dev/test-getclassroominfo')
}
