import styles from './GroupUpModal.module.css'
import ClearIcon from '@mui/icons-material/Clear'
import InsetInput from '../../InsetInput'
import RadioGroupBox from '../../RadioGroupBox/RadioGroupBox'
import useRadioGroupBox from '../../../CustomHooks/useRadioGroupBox'

const GroupUpModal = ({ handleToSettingPage, handleGroupUpModal, groupName, 
  handleGroupName, childOption, groupUpMode, setGroupUpParams,
  addGroupMode
 }) => {

  const  { groupUpModeInRadioBox, handleGroupUpModeInRadioBox, 
    childOptionInRadioBox, handleChildOptionInRadioBox } = useRadioGroupBox({ groupUpMode, childOption, setGroupUpParams })
    // (addGroupMode === 'duplicate') ? { groupUpMode, childOption } : {}
  
  const disableSaveButton = () => {
    if(!groupName.trim()) return true
    if(!childOption) return true
    let childOptionArr = childOption.split('-')
    if(childOptionArr[0] !== groupUpMode) {
      return true
    }
    return false
  }

  const closeModal = () => {
    if(addGroupMode === 'create') {
      handleGroupUpModal(false)
      setGroupUpParams(groupUpModeInRadioBox, childOptionInRadioBox)
      handleToSettingPage(true, 'create')
    }
    if(addGroupMode === 'duplicate') {
      handleGroupUpModal(false)
      handleToSettingPage(true, 'duplicate')
    }
  }

  return (
    <div className={styles.modalWrap}>
      <div className={styles.titleWrap}>
        <div className={styles.title}>Add Group Up List</div>
        <div className={styles.icon} onClick={() => handleGroupUpModal(false)}><ClearIcon /></div>
      </div>
      <div className={styles.groupName}>
        <InsetInput type="border" inputhVal={groupName} handleInputVal={handleGroupName} placeholder="Group Name" isValid={true} helperText="" disabled={false} />
      </div>
      <div className={styles.custom}>
        <RadioGroupBox childOption={childOptionInRadioBox} groupUpMode={groupUpModeInRadioBox} handleGroupUpMode={handleGroupUpModeInRadioBox} handleChildOption={handleChildOptionInRadioBox} addGroupMode={addGroupMode} />
      </div>
      <div className={`${styles.save} ${disableSaveButton() && styles.disabled}`} onClick={closeModal}>Save</div>
    </div>
  )
}

export default GroupUpModal