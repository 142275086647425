import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import ArrowDownIcon from '../icons/ArrowDownIcon'
import { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import classroomConfig from '../../utils/classroomConfig'
import { standard, groupUp, meeting, collaborative } from '../../utils/classroomConfig'
import { UserContext } from '../../CustomHooks/useAuth'

const CustomSelect = styled(Select)(({ theme }) => ({
  height: 48,
  bgcolor: '#fff',
  borderColor: '#B3B3B3',
  borderRadius: '8px',
  maxHeight: 100,
  font: 'normal normal normal 16px/20px InterRegular',
  '& 	.MuiOutlinedInput-notchedOutline': {
    // normal status border color
    borderColor: '#B3B3B3',
    borderRadius: '8px'
  },
  '& .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation1': {
    maxHeight: '250px'
  }
}))

// MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiMenu-paper MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation8 MuiPopover-paper css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper

const optionStyle = { font: 'normal normal normal 16px/20px InterRegular', height: '60px' }

export default function BorderGroupSelect({ val, handleValChange, disabled, studentNum = 0 }) {
  const { classroomStyleList } = React.useContext(UserContext)

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor='grouped-select'>Classroom</InputLabel>
      <CustomSelect
        disabled={disabled}
        defaultValue='0-Chestnut(1~30)'
        id='grouped-select'
        label='Classroom'
        value={val}
        onClick={(e) => e.stopPropagation()}
        onChange={handleValChange}
        IconComponent={ArrowDownIcon}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 400
            }
          }
        }}
      >
        <ListSubheader sx={{ display: classroomStyleList.filter((item) => item.room_type == 0 && item.capacity >= studentNum).length === 0 ? 'none' : 'block' }}>
          Classroom
        </ListSubheader>
        {classroomStyleList
          .filter((item) => item.room_type == 0 && item.capacity >= studentNum)
          .map((item, idx) => (
            <MenuItem key={item + idx} sx={optionStyle} value={item.id}>
              {`${item.display_name}(1~${item.capacity})`}
            </MenuItem>
          ))}
        <ListSubheader sx={{ display: classroomStyleList.filter((item) => item.room_type == 1 && item.capacity >= studentNum).length === 0 ? 'none' : 'block' }}>
          Classroom-Group Up
        </ListSubheader>
        {classroomStyleList
          .filter((item) => item.room_type == 1 && item.capacity >= studentNum)
          .map((item, idx) => (
            <MenuItem key={item + idx} sx={optionStyle} value={item.id}>
              {`${item.display_name}(1~${item.capacity})-Group Up`}
            </MenuItem>
          ))}
        <ListSubheader sx={{ display: classroomStyleList.filter((item) => item.room_type == 2 && item.capacity >= studentNum).length === 0 ? 'none' : 'block' }}>
          Meeting Room
        </ListSubheader>
        {classroomStyleList
          .filter((item) => item.room_type == 2 && item.capacity >= studentNum)
          .map((item, idx) => (
            <MenuItem key={item + idx} sx={optionStyle} value={item.id}>
              {`${item.display_name}(1~${item.capacity})`}
            </MenuItem>
          ))}
        <ListSubheader sx={{ display: classroomStyleList.filter((item) => item.room_type == 3 && item.capacity >= studentNum).length === 0 ? 'none' : 'block' }}>
          Collaborative Space
        </ListSubheader>
        {classroomStyleList
          .filter((item) => item.room_type == 3 && item.capacity >= studentNum)
          .map((item, idx) => (
            <MenuItem key={item + idx} sx={optionStyle} value={item.id}>
              {`${item.display_name}(1~${item.capacity})`}
            </MenuItem>
          ))}
      </CustomSelect>
    </FormControl>
  )
}
