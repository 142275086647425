import { useEffect, useState, useCallback } from 'react'
import dayjs from 'dayjs'
import {
  saveClassList,
  editStudentListByClass,
  editClassSettings,
  editGroupByClass,
  deleteGroupByClass,
  editChatGroup,
  getClassroomList,
  getCourseDetail,
  getClassroomTeachers
} from '../api'
import { useContext } from 'react'
import { UserContext } from '../CustomHooks/useAuth'
import usePagination from './usePagination'
import { fixMeetingRoomOldData } from '../utils/classroomConfig'
import { debounce } from 'lodash'

const useClassroomList = () => {
  const [teachers, setTeachers] = useState([]) // !! different from full teacher list in add class modal, this list will map from data. Only teacher assigned to class will be showed here
  const [chosenTeacher, setChosenTeacher] = useState({ label: 'All', value: '' })
  const [scheduleData, setScheduleData] = useState([])
  const [courseInfoData, setCourseInfoData] = useState([])
  const [loadingGetList, setLoadingGetList] = useState(false)
  const [loadingSaveList, setLoadingSaveList] = useState(false)
  const [openToaster, setOpenToaster] = useState(false)
  const [toasterText, setToasterText] = useState('')
  const [chosenClassId, setChosenClassId] = useState('')
  const [chosenClassItem, setChosenClassItem] = useState(null)
  const [searchVal, setSearchVal] = useState('')
  const [filterCourseInfoList, setFilterCourseInfoList] = useState([])
  const { page, totalPage, handlePage, handleTotalPage } = usePagination()
  const [loadingGetCourseDetail, setLoadingGetCourseDetail] = useState(true)
  const [selectedDatesArr, setSelectedDatesArr] = useState([])
  const { userInfo } = useContext(UserContext)

  useEffect(() => {
    call_getClassroomList(searchVal, chosenTeacher, page)
  }, [page])

  const call_getClassroomList = (searchVal, chosenTeacher, page, text = null) => {
    setLoadingGetList(true)
    let role = userInfo.identity === 'owner' ? 'admin' : userInfo.identity
    if (Object.keys(userInfo).length === 0) return
    let data = { orgId: userInfo.orgID, email: userInfo.email, role, page: page, limit: 10, teacherId: chosenTeacher.id ?? '', searchVal: searchVal }
    getClassroomList(data).then((res) => {
      console.info('get class list', res.data)
      handleTotalPage(Math.max(1, Math.ceil(res.data.totalCount / 10)))
      handlePage(page)
      setFilterCourseInfoList(res.data.courses)
      let data = { orgId: userInfo.orgID, email: userInfo.email, role }
      getClassroomTeachers(data).then((res) => {
        let teacherList = res.data
        let arr = [
          {
            label: 'All',
            id: ''
          }
        ]
        setTeachers(arr.concat(teacherList))
        if (text) handleOpenToaster(text)
        setLoadingGetList(false)
      })
    })
  }

  const debouncedAPICall = useCallback(
    debounce((searchInput, chosenTeacher) => {
      call_getClassroomList(searchInput, chosenTeacher, page)
    }, 500),
    [] // Empty dependency array ensures the debounced function is memoized once
  )

  const handleSearchVal = (e) => {
    setSearchVal(e.target.value)
    debouncedAPICall(e.target.value, chosenTeacher)
  }

  const handleChosenClassId = (id, text = null) => {
    setChosenClassId(id)
  }

  const call_courseDetailApi = (courseId, text = null) => {
    setLoadingGetCourseDetail(true)
    let role = userInfo.identity === 'owner' ? 'admin' : userInfo.identity
    let data = { orgId: userInfo.orgID, role, courseId: courseId }
    getCourseDetail(data).then((res) => {
      let data = JSON.parse(JSON.stringify(res.data))
      console.info('getScheduleInfo', data)
      setChosenClassItem(data[0])
      data = data.map((item) => {
        let newFormatObj = {
          begin: new Date(dayjs.unix(item.begin)),
          end: new Date(dayjs.unix(item.end)),
          classSize: item.classSize,
          classRoomType: item.classRoomType,
          showName: item.classSize,
          courseNumber: 0, // item.courseNumber,
          courseName: item.courseName,
          courseid: item.courseid,
          teacher: item.teacher,
          teacherEmail: item.teacherEmail,
          frequency: item.frequency === 'Repeat: Never' ? 'Never' : item.frequency,
          timeZone: item.timeZone,
          studentList: item.studentList
        }
        return newFormatObj
      })
      setScheduleData(data)
      let arr = data.map((item) => item.begin)
      setSelectedDatesArr(arr)
      setLoadingGetCourseDetail(false)
      setLoadingGetList(false)
      if (text) handleOpenToaster(text)
    })
  }

  const handleOpenToaster = (text) => {
    setToasterText(text)
    setOpenToaster(true)
  }

  const handleCloseToaster = () => setOpenToaster(false)

  const handleChosenTeacherWindowSelect = (e, value) => {
    setChosenTeacher(value)
    console.log('value', value)
    debouncedAPICall(searchVal, value)
  }

  const call_saveApi = (data) => {
    console.info('call_saveApi', JSON.parse(data))
    setLoadingSaveList(true)
    saveClassList(data)
      .then((res) => {
        call_getClassroomList('', { label: 'All', id: '' }, 1, 'Classes have been created successfully!')
      })
      .catch((err) => console.log('save class list error', err))
      .finally(() => {
        setLoadingSaveList(false)
      })
  }

  const updateChatGroupList = (data, text) => {
    editChatGroup(data)
      .then((res) => {
        call_courseDetailApi(chosenClassId, text)
      })
      .catch((err) => {
        console.log('editChatGroup', err)
        setLoadingGetList(false)
      })
  }

  const updateStudentListByClass = (studentList, text, chatGroupList) => {
    setLoadingGetList(true)
    setLoadingGetCourseDetail(true)
    let data = {
      courseID: chosenClassId,
      studentList
    }
    editStudentListByClass(data)
      .then((res) => {
        updateChatGroupList(chatGroupList, text)
        // getSchedules(weekRange, monthRange, text)
      })
      .catch((err) => {
        console.log('editStudentListByClass err', err)
        setLoadingGetList(false)
      })
  }

  const updateGroupUpListByClass = (dataObj, text) => {
    setLoadingGetList(true)
    editGroupByClass(dataObj)
      .then((res) => {
        console.log('updateGroupUpListByClass res', res)
        call_courseDetailApi(chosenClassId, text)
      })
      .catch((err) => {
        console.log('updateGroupUpListByClass err', err)
        setLoadingGetList(false)
      })
  }

  const deleteGroupUpListByClass = (dataObj, text) => {
    setLoadingGetList(true)
    deleteGroupByClass(dataObj)
      .then((res) => {
        console.log('deleteGroupByClass res', res)
        call_courseDetailApi(chosenClassId, text)
      })
      .catch((err) => {
        console.log('deleteGroupByClass err', err)
        setLoadingGetList(false)
      })
  }

  // editClassSettings
  const updateRoomByClass = (obj) => {
    console.info('updateRoomByClass obj', obj)
    setLoadingGetList(true)
    setCourseInfoData([])
    editClassSettings(obj)
      .then((res) => {
        call_getClassroomList(searchVal, chosenTeacher, page, 'Classroom style has been edited successfully!')
      })
      .catch((err) => {
        console.log('editStudentListByClass err', err)
        setLoadingGetList(false)
      })
  }

  return {
    teachers,
    chosenTeacher,
    handleChosenTeacherWindowSelect,
    call_saveApi,
    scheduleData,
    loadingGetList,
    loadingSaveList,
    openToaster,
    handleCloseToaster,
    courseInfoData,
    chosenClassId,
    handleChosenClassId,
    chosenClassItem,
    updateStudentListByClass,
    updateRoomByClass,
    toasterText,
    updateGroupUpListByClass,
    deleteGroupUpListByClass,
    setSearchVal,
    setChosenTeacher,
    searchVal,
    handleSearchVal,
    call_getClassroomList,
    filterCourseInfoList,
    page,
    totalPage,
    handlePage,
    loadingGetCourseDetail,
    call_courseDetailApi,
    selectedDatesArr
  }
}

export default useClassroomList
