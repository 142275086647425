import styles from './ScheduleInfo.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import dayjs from 'dayjs'
import usePopover from '../../../CustomHooks/usePopover'
import Popover from '@mui/material/Popover'
import EditPopoverMenu from '../EditPopoverMenu/EditPopoverMenu'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useContext, useState } from 'react'
import { UserContext } from '../../../CustomHooks/useAuth'

const CustomPopover = ({ open, anchorEl, handleClose, reformEditInfo }) => {

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <EditPopoverMenu closePopover={handleClose} reformEditInfo={reformEditInfo} />
    </Popover>
  )
}


const ScheduleInfo = ({ courseInfoData, chosenSchedule, handleScheduleInfoModal, handleScheduleEditModal, reformEditInfo }) => {

  const { open, anchorEl, openPopover, handleClose } = usePopover()
  const { userInfo } = useContext(UserContext)

  const openEditInfoModal = (e) => {
    if(chosenSchedule.frequency === 'Never' || chosenSchedule.frequency === 'Repeat: Never') {
      handleScheduleEditModal(true)
      reformEditInfo('Never')
    } else {
      openPopover(e)
    }
  }

  const hideEditBtn = () => {
    let teacherEmail = courseInfoData.find(item => item.courseid === chosenSchedule.courseid).teacherEmail
    let classIsExpired = dayjs(chosenSchedule.begin).isBefore(dayjs()) || dayjs(chosenSchedule.begin).isSame(dayjs())
    let isNewFormat = chosenSchedule.frequency == 'Every day'
    return userInfo.isExpired || userInfo.identity.toLowerCase() === 'student' || (userInfo.identity.toLowerCase() === 'teacher' && userInfo.email !== teacherEmail) || classIsExpired || isNewFormat
  }

  return (
    <div className={styles.scheduleInfoWrap}>
      <div className={styles.row} style={{ justifyContent: 'space-between' }}>
        {/* <div className={styles.durationRow}>
          <div className={styles.ymd}>{ dayjs(chosenSchedule.begin).format('MMM D, YYYY') }</div>
          <div className={styles.hm}>{`${ dayjs(chosenSchedule.begin).format('HH:mm') } ~ ${ dayjs(chosenSchedule.end).format('HH:mm') }`}</div>  
          <div className={styles.courseName}>{ chosenSchedule.courseName }</div>
        </div> */}
        <div className={styles.courseName}>{ chosenSchedule.courseName }</div>
        <div className={styles.close} onClick={() => handleScheduleInfoModal(false)}><CloseRoundedIcon sx={{ color: '#808080', "&:hover": { color: "#1452CC" } }} /></div>
      </div>
      {/* <div className={styles.courseName}>{ chosenSchedule.courseName }</div> */}
      <div className={styles.durationRow}>
        <div className={styles.ymd}>{ dayjs(chosenSchedule.begin).format('MMM D, YYYY') }</div>
        <div className={styles.hm}>{`${ dayjs(chosenSchedule.begin).format('HH:mm') } ~ ${ dayjs(chosenSchedule.end).format('HH:mm') }`}</div>  
      </div>
      <div className={styles.row}>
        <div className={styles.title}>Teacher</div>
        <div className={styles.content}>{ chosenSchedule.teacher }</div>
      </div>
      <div className={styles.row}>
        <div className={styles.title}>Classroom</div>
        <div className={styles.content}>{ chosenSchedule.classSize }</div>
      </div>
      <div className={`${styles.row} ${styles.btnWrap} ${hideEditBtn() && styles.disabled}`}>
        <button className={`${styles.edit} ${styles.btn} ${open && styles.active}`} onClick={openEditInfoModal}>
          <div>Edit</div>
          <div className={`${styles.icon} ${(chosenSchedule.frequency === 'Never' || chosenSchedule.frequency === 'Repeat: Never') && styles.hidden}`}><KeyboardArrowDownIcon /></div>
        </button>
      </div>
      <CustomPopover open={open} anchorEl={anchorEl} openPopover={openPopover} handleClose={handleClose} reformEditInfo={reformEditInfo} />
    </div>
  )
}

export default ScheduleInfo