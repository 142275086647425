import { useSearchParams } from "react-router-dom"
import styles from './Login.module.css'
import { Fragment, useContext, useEffect, useState } from "react"
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import useAuth, { UserContext } from  '../../CustomHooks/useAuth'
import CircularProgress from '@mui/material/CircularProgress'
import GoogleIcon from '../../assets/icons/google.svg'

const CustomInput = ({ val, handleChange, typ }) => {

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    if(typ === 'Email') return
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor={`outlined-adornment-${typ}`}>{typ}</InputLabel>
      <OutlinedInput
        sx={{ backgroundColor: '#fff' }}
        id={`outlined-adornment-${typ}`}
        type={(typ === 'Email') ? 'text' : showPassword ? 'text' : 'password'}
        value={val}
        onChange={handleChange}
        endAdornment={
          typ === 'Password' &&
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        label={typ}
      />
    </FormControl>
  )
}

const Login = ({ googleSSOGrantCodeFlow, setUserInfo, signIn, authLoading, errorText, handleErrorText }) => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [searchParams] = useSearchParams()
  const { userInfo } = useContext(UserContext)

  useEffect(() => {
    let code = searchParams.get('code')
    if(!code) return
    googleSSOGrantCodeFlow(code)
  }, [])

  useEffect(() => {
    handleErrorText('')
  }, [email, password])

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const login = () => {
    if(!email || !password) return
    signIn(email, password, setUserInfo)
  }

  const googleLogin = () => {
    window.location.replace(`https://universe.auth.eu-central-1.amazoncognito.com/login?client_id=50b4at73t6jchcmtorl4nkp46f&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`)
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <Fragment>
      <form onSubmit={onFormSubmit}>
        <div className={styles.wrap}>
          <div className={styles.loginWrap}>
            <div className={styles.title}>Sign In</div>
            <div className={styles.email}>
              <CustomInput val={email} handleChange={handleEmailChange} typ="Email" />
            </div>
            <div className={styles.password}>
              <CustomInput val={password} handleChange={handlePasswordChange} typ="Password" />
            </div>
            <div className={styles.errorHint}>{errorText}</div>
            <div className={styles.hint} onClick={() => window.location.replace('https://www.viewsonic.io/account/forgotpw')}>Forgot Password?</div>
            <button className={styles.submit} onClick={login} type="submit">SIGN IN</button>
            <div className={styles.orWrap}>
              <div className={styles.divideLine}></div>
              <div className={styles.or}>OR</div>
              <div className={styles.divideLine}></div>
            </div>
            <div className={styles.googleSubmit} onClick={googleLogin}>
              <img src={GoogleIcon} />
              <div>Sign in with Google</div>
            </div>
          </div>
          { 
            authLoading && <div className={styles.spinnerWrap}><CircularProgress /></div> 
          }
        </div>
      </form>
    </Fragment>
  )
}

export default Login