import { useState } from "react"


const usePagination = () => {
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const handlePage = (page) => {
    setPage(page)
  }

  const handleTotalPage = (page) => {
    setTotalPage(page)
  }

  return { page, totalPage, handlePage, handleTotalPage }

}

export default usePagination