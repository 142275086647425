import styles from './ReAssignTeacherModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useEffect, useState } from 'react'
import WindowSelect from '../../VirtualizedList/VirtualizedList'
import CircularProgress from '@mui/material/CircularProgress'

const Row = ({ item, teachers, handleTeacherNameChange }) => {

  const [teacherName, setTeacherName] = useState('')

  useEffect(() => {
    setTeacherName(teachers[0].name)
  }, [teachers])

  const handleChange = (e, item) => {
    setTeacherName(e.target.textContent)
    handleTeacherNameChange(e, item)
  }

  return (
    <div className={styles.row}>
      <div className={styles.courseName}>{ item.courseName }</div>
      <div className={styles.teacherSelect}>
        <WindowSelect label="Teacher" options={[... new Set(teachers)].map(item => item.name)} val={teacherName} type="inset" handleValChange={(e) => handleChange(e, item)} disabled={false} />
      </div>
    </div>
  )
}

const ReAssignTeacherModal = ({ reAssignTeacherMode, handleReAssignTeacherModal, allTeacherListWithCourses, chosenAccount, loadEditTeacherByClass, updateTeacher }) => {

  const [classList, setClassList] = useState([])
  const [teachers, setTeachers] = useState([])

  useEffect(() => {
    let item = allTeacherListWithCourses.find(item => item.email.S === chosenAccount.email && item.course.L.length !== 0)
    let temp_teachers = allTeacherListWithCourses.filter(item => item.email.S !== chosenAccount.email).map(item => {
      return {
        name: item.nickname.S,
        email: item.email.S
      }
    })
    setClassList(item.course.L.map(courseItem => {
      return {
        courseId: courseItem.courseId,
        courseName: courseItem.courseName,
        teacherName: temp_teachers[0].name,
        teacherEmail: temp_teachers[0].email
      }
    }))
    setTeachers(temp_teachers)
  }, [])

  const handleTeacherNameChange = (e, item) => {
    let temp_Classlist = classList.map(item => item)
    let chosenItem = temp_Classlist.find(classItem => classItem.courseName === item.courseName)
    chosenItem.teacherName = e.target.textContent
    chosenItem.teacherEmail = allTeacherListWithCourses.find(item => item.nickname.S === e.target.textContent).email.S
    setClassList(temp_Classlist)
  }

  return (
    <div className={styles.wrap}>
      { (loadEditTeacherByClass) && <div className={styles.loadingWrap}><CircularProgress /></div> }
      <div className={styles.modalTitle}>
        <div className={styles.title}>Remove User</div>
        <div className={styles.close} onClick={() => handleReAssignTeacherModal(false)}><CloseRoundedIcon /></div>
      </div>
      <div className={styles.explanation}>
        <span>Before remove "</span>
        <span className={styles.hightlight}>{ chosenAccount.display }</span>
        <span>", you have to assign other user to these classes.</span>
      </div>

      <div className={styles.content}>
        {
          classList.map((item, idx) => (
            <Row item={item} key={idx} teachers={teachers} handleTeacherNameChange={handleTeacherNameChange} />
          ))
        }
      </div>
      <div className={styles.btnWrap}>
        <div className={`${styles.btn} ${styles.remove}`} onClick={() => updateTeacher(classList)}>{ (reAssignTeacherMode === 'delete') ? 'Remove' : 'Update' }</div>
        <div className={`${styles.btn} ${styles.cancel}`} onClick={() => handleReAssignTeacherModal(false)}>Cancel</div>
      </div>
    </div>
  )
}

export default ReAssignTeacherModal