import { Fragment, useEffect, useState } from 'react'
import { Route, Routes, BrowserRouter, Navigate, Outlet } from 'react-router-dom'
import './App.css'
import AppHeader from './AppHeader/AppHeader'
import SideBar from './SideBar/SideBar'
import Users from './pages/AdminManager/Users'
import Assignment from './pages/Assignment/Assignment'
import Quiz from './pages/Quiz/Quiz'
import Portfolio from './pages/Portfolio/Portfolio'
import Classroom from './pages/Classroom/Classroom'
import Schedule from './pages/Schedule/Schedule'
import LtiSettings from './pages/LtiSettings/LtiSettings'
import Permission from './pages/Permission/Permission'
import Login from './pages/Login/Login'
import { UserContext } from './CustomHooks/useAuth'
import useAuth from './CustomHooks/useAuth'
import Forbidden from './pages/Forbidden/Forbidden'
import NotFound from './pages/NotFound/NotFound'
import CircularProgress from '@mui/material/CircularProgress'

import './assets/fonts/Inter-Black.ttf'
import './assets/fonts/Inter-Bold.ttf'
import './assets/fonts/Inter-ExtraBold.ttf'
import './assets/fonts/Inter-ExtraLight.ttf'
import './assets/fonts/Inter-Light.ttf'
import './assets/fonts/Inter-Medium.ttf'
import './assets/fonts/Inter-Regular.ttf'
import './assets/fonts/Inter-SemiBold.ttf'
import './assets/fonts/Inter-Thin.ttf'
import UpgradePlan from './parts/Modals/UpgradePlan/UpgradePlan'
import Modal from './parts/Modal'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import AddNewQuiz from './pages/Quiz/AddNewQuiz/AddNewQuiz'
import { getClassroomStyleList } from './api'

const tabNames = [
  {
    text: 'Users',
    path: 'manageusers',
    children: []
  },
  {
    text: 'Course',
    path: 'classroom',
    children: [
      { text: 'Classroom', path: 'classroom' },
      { text: 'Schedule', path: 'schedule' },
      { text: 'Portfolio', path: 'portfolio' }
    ]
  },
  {
    text: 'Activity',
    path: 'quiz',
    children: [
      { text: 'Quiz', path: 'quiz' },
      { text: 'Assignment', path: 'assignment' }
    ]
  },
  {
    text: 'Setting',
    path: 'ltisettings',
    children: [
      { text: 'LTI', path: 'ltisettings' }
      // { text: 'Permission', path: 'permission' }
    ]
  }
]

const HomeLayout = ({ userInfo, setUserInfo }) => {
  useEffect(() => {
    localStorage.clear()
    setUserInfo({})
  }, [])

  // when at login page, navigate to other page if login success
  if (Object.keys(userInfo).length !== 0) {
    // if(userInfo.payment) {
    return <Navigate to='/dashboard' />
    // }
    // return <Navigate to="/forbidden" />
  }

  return <Outlet />
}

const ProtectedLayout = ({ userInfo, sideBarOpen, handleMenuClose, setUserInfo, getCurrentSession, authLoading }) => {
  useEffect(() => {
    if (localStorage.getItem('username') && !userInfo.hasOwnProperty('accessToken')) getCurrentSession(setUserInfo)
  }, [])

  if (!localStorage.getItem('username')) return <Navigate to='/' />

  // if(userInfo.hasOwnProperty('payment') && !userInfo.payment) {
  //   return <Navigate to="/forbidden" />
  // }

  return (
    <div className='content-wrap'>
      {authLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <SideBar tabNames={tabNames} open={sideBarOpen} handleMenuClose={handleMenuClose} />
          <div style={{ width: '100%', minHeight: 'calc(100% - 148px)' }} className='outlet-wrap'>
            <Outlet />
          </div>
        </Fragment>
      )}
    </div>
  )
}

const ForbiddenLayout = ({ userInfo, setUserInfo, getCurrentSession, authLoading }) => {
  useEffect(() => {
    if (localStorage.getItem('username') && !userInfo.hasOwnProperty('accessToken')) getCurrentSession(setUserInfo)
  }, [])

  if (!localStorage.getItem('username')) return <Navigate to='/' />
  if (localStorage.getItem('username') && userInfo.payment) return <Navigate to='/dashboard' />

  return <Fragment>{authLoading ? <Loading /> : <Outlet />}</Fragment>
}

const Loading = () => {
  return (
    <div style={{ width: '100%', height: 'calc(100vh - 72px)', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F5F8FF' }}>
      <CircularProgress />
    </div>
  )
}

const App = () => {
  const [openUpgradePlan, setOpenUpgradePlan] = useState(false)
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const handleMenuOpen = () => setSideBarOpen(true)
  const handleMenuClose = () => setSideBarOpen(false)
  const [classroomStyleList, setClassroomStyleList] = useState([])
  const {
    getCurrentSession,
    authLoading,
    signIn,
    signOut,
    errorText,
    handleErrorText,
    userInfo,
    setUserInfo,
    handleChosenOrg,
    chosenOrg,
    orgOptions,
    forceRefreshKey,
    googleSSOGrantCodeFlow,
    updateObservationMode
  } = useAuth()

  const handleUpgradePlanModal = (sts) => {
    setOpenUpgradePlan(sts)
  }

  useEffect(() => {
    getClassroomStyleList()
      .then((res) => {
        let data = res.data.sort((a, b) => a.seq - b.seq)
        data = data.map((item) => {
          let obj = JSON.parse(JSON.stringify(item))
          obj.showName = `${obj.display_name}(1~${obj.capacity})`
          obj.id = String(obj.id)
          if (obj.room_type == 1) obj.showName += '-Group Up'
          return obj
        })
        setClassroomStyleList(data)
        console.info('res', data)
      })
      .catch((err) => {
        console.info('err', err)
      })
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <UserContext.Provider value={{ userInfo, openUpgradePlan, handleUpgradePlanModal, chosenOrg, updateObservationMode, classroomStyleList }}>
          <AppHeader
            handleMenuOpen={handleMenuOpen}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            signOut={signOut}
            handleChosenOrg={handleChosenOrg}
            chosenOrg={chosenOrg}
            orgOptions={orgOptions}
          />
          <Routes>
            <Route element={<HomeLayout userInfo={userInfo} setUserInfo={setUserInfo} />}>
              <Route
                path='/'
                element={
                  <Login
                    googleSSOGrantCodeFlow={googleSSOGrantCodeFlow}
                    setUserInfo={setUserInfo}
                    signIn={signIn}
                    authLoading={authLoading}
                    errorText={errorText}
                    handleErrorText={handleErrorText}
                  />
                }
              />
            </Route>
            <Route path='/forbidden' element={<ForbiddenLayout getCurrentSession={getCurrentSession} authLoading={authLoading} userInfo={userInfo} setUserInfo={setUserInfo} />}>
              <Route path='' element={<Forbidden />} />
            </Route>
            <Route
              path='/dashboard'
              element={
                <ProtectedLayout
                  getCurrentSession={getCurrentSession}
                  authLoading={authLoading}
                  sideBarOpen={sideBarOpen}
                  userInfo={userInfo}
                  handleMenuClose={handleMenuClose}
                  setUserInfo={setUserInfo}
                />
              }
            >
              {(userInfo.identity === 'admin' || userInfo.identity === 'owner') && <Route index element={<Users key={forceRefreshKey} />} />}
              {userInfo.identity === 'teacher' && <Route index element={<Users key={forceRefreshKey} />} />}
              {userInfo.identity === 'student' && <Route index element={<Schedule key={forceRefreshKey} />} />}
              {userInfo.identity !== 'student' && <Route path='manageusers' element={<Users key={forceRefreshKey} />} />}
              {userInfo.identity !== 'student' && (
                <Fragment>
                  <Route path='classroom' element={<Classroom key={forceRefreshKey} />} />
                  <Route path='assignment' element={<Assignment key={forceRefreshKey} />} />
                  <Route path='quiz' element={<Quiz key={forceRefreshKey} />} />
                  <Route path='portfolio' element={<Portfolio key={forceRefreshKey} />} />
                </Fragment>
              )}
              {(userInfo.identity === 'admin' || userInfo.identity === 'owner') && <Route path='ltisettings' element={<LtiSettings key={forceRefreshKey} />} />}
              {/* {userInfo.identity === 'owner' && <Route path='permission' element={<Permission key={forceRefreshKey} />} />} */}
              <Route path='schedule' element={<Schedule key={forceRefreshKey} />} />
            </Route>
            <Route path='*' element={<NotFound />} />
          </Routes>
          {openUpgradePlan && (
            <Modal>
              <UpgradePlan />
            </Modal>
          )}
        </UserContext.Provider>
      </BrowserRouter>
    </DndProvider>
  )
}

export default App
