export const AssignmentStatusEnum = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  FINISHED: 'finished'
}

export const ObservationModeEnum = {
  Disabled: 1,
  Enabled: 2,
  'Not allowed': 3
}
