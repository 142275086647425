import { useContext, useEffect, useState } from "react"
import { getStudentInfoWithCourses, getAccountList } from "../api"
import { UserContext } from "./useAuth"
import usePagination from "./usePagination"
import { reformAccountList } from "../utils/handleMemberList"

const useStudentList = () => {
  const [allStudentListWithCourses, setAllStudentListWithCourses] = useState([])
  const [loadingGetAllStudentList, setLoadingGetAllStudentList] = useState(true)
  const [memberList, setMemberList] = useState([])
  const { page, totalPage, handlePage, handleTotalPage } = usePagination()
  const { userInfo } = useContext(UserContext)

  useEffect(() => {
    call_getApi()
    getMember()
  }, [])

  const getMember = () => {
    let data = {
      email: (userInfo.email) ? userInfo.email : localStorage.getItem('email'),
      orgID: userInfo.orgID
    }
    getAccountList(data)
    .then(res => {
      let arr = JSON.parse(res.data.body)
      let { m_list } = reformAccountList(arr)
      setMemberList(m_list)
    })
    .catch(err => { 
      console.log('get member list error', err)
      setMemberList([])
    })
  }

  const call_getApi = () => {
    setLoadingGetAllStudentList(true)
    setAllStudentListWithCourses([])
    getStudentInfoWithCourses({ email: userInfo.email, orgID: userInfo.orgID })
    .then((res) => {
      let data = JSON.parse(res.data.body)
      data.forEach(item => {
        item.email.S = item.email.S.toLowerCase()
      })
      setAllStudentListWithCourses(data)
      handleTotalPage(Math.max(Math.ceil(data.length / 14), 1))
    })
    .catch((err) => {
      console.log('getStudentInfoWithCourses err', err)
    })
    .finally(() => {
      setLoadingGetAllStudentList(false)
    })
  }

  return { allStudentListWithCourses, loadingGetAllStudentList, page, totalPage, handlePage, handleTotalPage, call_getApi, memberList }
}

export default useStudentList

