import styles from './Participants.module.css'
import ArrowDownIcon from '../../../../assets/icons/quiz/blueArrowDown.svg'
import ArrowRightIcon from '../../../../assets/icons/quiz/blueArrowRight.svg'

import { useState } from 'react'

const TableHeader = () => {
  return (
    <div className={`${styles.tableWrap} ${styles.tableHeader}`}>
      <div className={`${styles.tableItem} ${styles.question}`}>Question</div>
      <div className={`${styles.tableItem} ${styles.participant_answer}`}>Participant's Answer</div>
      <div className={`${styles.tableItem} ${styles.correct_answer}`}>Correct Answer</div>
    </div>
  )
}

const TableContent = ({ item, index, handleShowImageModal }) => {
  return (
    <div className={`${styles.tableWrap} ${styles.tableContent}`}>
      <div className={`${styles.tableItem} ${styles.question}`}>
        <div style={{ minWidth: '110px' }}>Question {index + 1}</div>
        <div>
          <div>{item.content}</div>
          <div onClick={() => handleShowImageModal(item.image, true)} className={`${styles.img} ${item.image == null && styles.disabled}`}></div>
        </div>
      </div>
      <div className={`${styles.tableItem} ${styles.participant_answer}`}>
        {item.participantAnswer.map((option, idx) => (
          <div key={idx} className={`${item.correct ? styles.right : styles.wrong}`}>
            {option.content}
          </div>
        ))}
      </div>
      <div className={`${styles.tableItem} ${styles.correct_answer}`}>
        {item.correctAnswer.map((option, idx) => (
          <div key={idx} className={`${item.correct ? styles.right : styles.wrong}`}>
            {option.content}
          </div>
        ))}
      </div>
    </div>
  )
}

const Participants = ({ result, typ, setResultParticipants, handleShowImageModal }) => {
  const handleOpenDetail = (item) => {
    let id = item.studentId
    let newRes = JSON.parse(JSON.stringify(result))
    for (let record of newRes) {
      if (record.studentId == id) {
        record.open = !record.open
        break
      }
    }
    setResultParticipants(newRes)
  }

  return (
    <div className={`${styles.participants} ${result.length === 0 && styles.scroll_disabled}`}>
      {result.length === 0 && (
        <div className={styles.noResults}>
          <p>We are sorry, but we could not find any student records.</p>
          <p>You can duplicate this {typ.toLowerCase()} and re-publish it.</p>
        </div>
      )}
      {result.length !== 0 &&
        result.map((item, idx) => (
          <div key={idx} className={styles.participant}>
            <div className={styles.info}>
              <div className={styles.arrow} onClick={() => handleOpenDetail(item)}>
                {item.open ? <img alt='' src={ArrowDownIcon} /> : <img alt='' src={ArrowRightIcon} />}
              </div>
              <div className={styles.name}>{item.studentDisplayName}</div>
              <div className={styles.accuracyNum}>{parseInt((item.questions.filter((item) => item.correct).length / item.questions.length) * 100)}%</div>
              <div className={styles.accuracyTxt}>Accuracy</div>
              <div className={`${styles.box} ${styles.correct}`}>{item.questions.filter((item) => item.correct).length} correct</div>
              <div className={`${styles.box} ${styles.incorrect}`}>{item.questions.filter((item) => !item.correct).length} incorrect</div>
            </div>
            {item.open && (
              <div className={styles.detail}>
                <TableHeader />
                {item.questions.map((question, index) => (
                  <TableContent item={question} index={index} key={index} handleShowImageModal={handleShowImageModal} />
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  )
}

export default Participants
