import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import styles from './QuestionIcon.module.css'

const QuestionIcon = ({ callback }) => {
  return (
    <div className={styles.wrap} onClick={() => callback()}>
      <QuestionMarkIcon sx={{ color: '#fff', transform: 'scale(0.6)' }} />
    </div>
  )
}

export default QuestionIcon
