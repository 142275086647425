import styles from './Results.module.css'
import ArrowIcon from '../../../assets/icons/breadcrumbArrow.svg'
import QuizCard from '../QuizCard/QuizCard'
import CircularProgress from '@mui/material/CircularProgress'
import { Fragment, useEffect } from 'react'
import CustomPagination from '../../../parts/CustomPagination'
import Modal from '../../../parts/Modal'
import DeleteQuizResultModal from '../../../parts/Modals/DeleteQuizResultModal/DeleteQuizResultModal'


const Results = ({ setStep, toSingleResultPage, resultLoading, call_getQuizResults, quizResult,
  resultsPage, resultsTotalPage, handleResultsPage, call_deleteQuizResultApi,
  selectedQuizResult, setSelectedQuizResult, deleteQuizResultModal, setDeleteQuizResultModal }) => {

  useEffect(() => {
    call_getQuizResults()
  }, [])

  return (
    <Fragment>
      {
        resultLoading ? (
          <div
            style={{
              height: 'calc(100vh - 330px)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </div>
        ) : (
        <div>
          <div className={styles.utilityRowWrap}>
            <div className={styles.left}>
                <div style={{ cursor: 'pointer' }} onClick={() => setStep(1)}>Quiz</div>
                <div><img src={ArrowIcon} alt='' /></div>
                <div>Quiz Results</div>
            </div>
            <div className={styles.right}>
                <button onClick={() => setStep(1)} className={styles.btn}>Back to quiz list</button>
            </div>
          </div>
          <div className={styles.cards}>
          {
              quizResult.length === 0 && (
                <div className={styles.empty}>
                  <div>We're sorry, but we could not find any student records.</div>
                  <div>Try to refresh this page again in a few minutes.</div>
                </div>
              )
            }
            { quizResult.length !== 0 && quizResult.map((item, idx) => (
                <QuizCard 
                  key={idx} 
                  typ='result' 
                  item={item}
                  toSingleResultPage={toSingleResultPage}
                  setSelectedQuizResult={setSelectedQuizResult}
                  setDeleteQuizResultModal={setDeleteQuizResultModal}
                /> 
            )) }
          </div>
          { resultsTotalPage > 1 && (
            <div className={styles.paginationWrap}>
              <CustomPagination page={resultsPage} totalPage={resultsTotalPage} handlePage={handleResultsPage} />
            </div>
          ) }
        </div>
        )
      }
      {
        deleteQuizResultModal && (
          <Modal>
            <DeleteQuizResultModal 
              setDeleteQuizResultModal={setDeleteQuizResultModal} 
              call_deleteQuizResultApi={call_deleteQuizResultApi}
              selectedQuizResult={selectedQuizResult}
            />
          </Modal>
        )
      }
    </Fragment>
  )
}

export default Results