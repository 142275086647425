import styles from './AddModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { TextField } from '@mui/material'

const AddModal = ({ typ, actionType, name, setName, setAddModal, onCreate, disableAddModalBtn }) => {
  const inputStyle = {
    marginBottom: '1.5rem',
    '& .MuiOutlinedInput-input': {
      paddingRight: '60px'
    },
    width: '100%',
    '& 	.MuiOutlinedInput-notchedOutline': {
      border: '1px solid #B3B3B3',
      borderRadius: '8px'
    }
  }

  const handleName = (e) => {
    setName(e.target.value)
  }

  return (
    <div className={styles.modalWrap}>
      <div className={styles.titleWrap}>
        <div className={styles.title}>{typ} Name</div>
        <div className={styles.cancel} onClick={() => setAddModal(false)}>
          <CloseRoundedIcon sx={{ color: '#808080', '&:hover': { color: '#1452CC' } }} />
        </div>
      </div>
      <div className={styles.inputWrap}>
        <TextField
          required
          sx={inputStyle}
          value={name}
          onChange={(e) => handleName(e)}
          id='outlined-basic'
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{ maxLength: 50 }}
        />
        <div className={styles.lengthHint}>{name.length}/50</div>
      </div>
      <div className={styles.btnWrap}>
        <button
          className={`${styles.createBtn} ${name === '' || disableAddModalBtn ? styles.disabled : undefined}`}
          onClick={onCreate}
          disabled={disableAddModalBtn || name === ''}
        >
          {actionType === 'create' && 'Create'}
          {actionType === 'edit' && 'Save'}
        </button>
      </div>
    </div>
  )
}

export default AddModal
