import styles from './AddNewQuiz.module.css'
import ArrowIcon from '../../../assets/icons/breadcrumbArrow.svg'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Chip from '@mui/material/Chip'
import { TextField } from '@mui/material'
import { useEffect, useState, useRef } from 'react'
import Modal from '../../../parts/Modal'
import AddQuestionModal from '../../../parts/Modals/AddQuestionModal/AddQuestionModal'
import OptionIcon from '../../../assets/icons/options.svg'
import DeleteQuestionModal from '../../../parts/Modals/Activity/DeleteQuestionModal/DeleteQuestionModal'
import TrueFalseIcon from '../../../assets/icons/quiz/trueFalse.svg'
import SingleSelectionIcon from '../../../assets/icons/quiz/singleSelection.svg'
import MultipleSelectionIcon from '../../../assets/icons/quiz/multipleSelection.svg'
import CheckboxChecked from '../../../assets/icons/quiz/checkboxChecked.svg'
import CheckboxUnchecked from '../../../assets/icons/quiz/checkboxUnchecked.svg'
import WindowSelect from '../../../parts/VirtualizedList/VirtualizedList'
import CircularProgress from '@mui/material/CircularProgress'
import CustomPagination from '../../../parts/CustomPagination'
import DiscardChangesModal from '../../../parts/Modals/DiscardChangesModal/DiscardChangesModal'
import QuestionListMenu from '../../../parts/Activity/QuestionListMenu/QuestionListMenu'

const QuestionList = ({
  loadingGetList,
  setNewQuestionModal,
  setDeleteQuestionModal,
  questions,
  setStep,
  setSelectedQuestionType,
  setSelectedQuestion,
  setQuestionActionType,
  selectedQuiz,
  questionPage,
  questionTotalPage,
  handleQuestionPage,
  handleQuestionTotalPage,
  call_duplicateQuestionApi,
  setIsInit,
  setQuestions,
  setSeverity,
  handleOpenToaster,
  setDuplicateQuestions
}) => {
  const onClickNewQuestion = () => {
    setNewQuestionModal(true)
    setQuestionActionType('create')
  }

  const filterQuestionList = () => {
    handleQuestionTotalPage(Math.max(Math.ceil(questions.length / 10), 1))
    handleQuestionPage(1)
  }

  useEffect(() => {
    if (questions) {
      filterQuestionList()
    }
  }, [questions])

  return (
    <>
      <div className={styles.titleWrap}>
        <div className={styles.title}>Question List</div>
        {!loadingGetList && <button onClick={onClickNewQuestion}>+ New Question</button>}
      </div>
      <div className={styles.tableContainer}>
        <TableHeader />
        {loadingGetList && (
          <div
            style={{
              height: 'calc(100vh - 550px)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loadingGetList && !questions.length && (
          <div className={styles.noItems}>
            <p>Create your new question!</p>
          </div>
        )}
        {!loadingGetList &&
          questions
            .filter((item, index) => index >= (questionPage - 1) * 10 && index <= (questionPage - 1) * 10 + 9)
            .map((question, index) => {
              return (
                <TableContent
                  key={index}
                  index={index}
                  question={question}
                  setDeleteQuestionModal={setDeleteQuestionModal}
                  questions={questions}
                  setStep={setStep}
                  setSelectedQuestionType={setSelectedQuestionType}
                  setSelectedQuestion={setSelectedQuestion}
                  setQuestionActionType={setQuestionActionType}
                  selectedQuiz={selectedQuiz}
                  call_duplicateQuestionApi={call_duplicateQuestionApi}
                  setIsInit={setIsInit}
                  setQuestions={setQuestions}
                  setSeverity={setSeverity}
                  handleOpenToaster={handleOpenToaster}
                  setDuplicateQuestions={setDuplicateQuestions}
                />
              )
            })}
        {questionTotalPage > 1 && (
          <div className={styles.paginationWrap}>
            <CustomPagination page={questionPage} totalPage={questionTotalPage} handlePage={handleQuestionPage} />
          </div>
        )}
      </div>
    </>
  )
}

const TableHeader = () => {
  return (
    <div className={`${styles.tableWrap} ${styles.tableHeader}`}>
      <div className={`${styles.tableItem} ${styles.name}`}>Question Type</div>
      <div className={`${styles.tableItem} ${styles.email}`}>Question</div>
      <div className={`${styles.tableItem} ${styles.displayName}`}>Options</div>
      <div className={`${styles.tableItem} ${styles.action}`}>Action</div>
    </div>
  )
}

const TableContent = ({
  question,
  setDeleteQuestionModal,
  setStep,
  setQuestionActionType,
  setSelectedQuestion,
  selectedQuiz,
  setIsInit,
  setQuestions,
  setSeverity,
  handleOpenToaster,
  questions,
  setDuplicateQuestions,
  call_duplicateQuestionApi
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigateToDetail = (e, question) => {
    setSelectedQuestion(question)
    setStep(3)
    setSelectedQuestion(question)
    setQuestionActionType('edit')
  }

  const questionTypeIcon = (type) => {
    switch (type) {
      case 'tf':
        return TrueFalseIcon
      case 'single':
        return SingleSelectionIcon
      case 'multiple':
        return MultipleSelectionIcon
      default:
        return TrueFalseIcon
    }
  }

  const questionType = (type) => {
    switch (type) {
      case 'tf':
        return 'True/False'
      case 'single':
        return 'Single Selection'
      default:
        return 'Multiple Selection'
    }
  }

  const onEditQuestion = (question) => {
    setStep(3)
    setSelectedQuestion(question)
    setQuestionActionType('edit')
  }

  const iconStyles = { color: '#707070', transform: 'scale(1.2)', padding: '0.5rem' }

  return (
    <>
      <div className={`${styles.itemWrap} ${styles.tableContent}`}>
        <div className={`${styles.tableItem} ${styles.questionType}`}>
          <img alt='questionType' src={questionTypeIcon(question.type)} />
          <span>{questionType(question.type)}</span>
        </div>
        <div className={`${styles.tableItem} ${styles.question}`} onClick={(e) => navigateToDetail(e, question)}>
          Question {question.id} : {question.content}
        </div>
        <div className={`${styles.tableItem} ${styles.options}`}>
          <img alt='options' src={OptionIcon} />
          <p>{question.options.length}</p>
        </div>
        <div className={`${styles.tableItem} ${styles.action} ${open && styles.active}`}>
          <MoreHorizIcon onClick={(e) => handleClick(e, question)} sx={iconStyles} />
          <QuestionListMenu
            typ='Quiz'
            open={open}
            handleClose={handleClose}
            anchorEl={anchorEl}
            setDeleteQuestionModal={setDeleteQuestionModal}
            setStep={setStep}
            setSelectedQuestion={setSelectedQuestion}
            onEditQuestion={() => onEditQuestion(question)}
            question={question}
            selectedParent={selectedQuiz}
            setIsInit={setIsInit}
            setQuestions={setQuestions}
            setSeverity={setSeverity}
            questions={questions}
            handleOpenToaster={handleOpenToaster}
            setDuplicateQuestions={setDuplicateQuestions}
            call_duplicateQuestionApi={call_duplicateQuestionApi}
          />
        </div>
      </div>
    </>
  )
}



const Information = ({ timerMin, setTimerMin, timerSec, setTimerSec, selectedQuiz, quizName, onQuizNameChange, checkedTimer, onCheckTimer, setIsInit, isInit }) => {
  const inputStyle = {
    '& .MuiOutlinedInput-input': {
      paddingRight: '60px'
    },
    width: '100%',
    '& 	.MuiOutlinedInput-notchedOutline': {
      border: '1px solid #B3B3B3',
      borderRadius: '8px'
    }
  }

  const numInputStyle = {
    width: '5rem',
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#EBEDF0',
      borderRadius: '4px'
    },
    '& 	.MuiOutlinedInput-notchedOutline': {
      border: 'none',
      backgroundColor: 'none'
    }
  }

  const onTimerChange = (e, type) => {
    setIsInit(false)
    if (type === 'min') {
      setTimerMin(e.target.value)
    } else {
      setTimerSec(e.target.value)
    }
  }

  const timerIcon = () => {
    return checkedTimer ? CheckboxChecked : CheckboxUnchecked
  }

  return (
    <div className={styles.information}>
      <div className={styles.title}>Information</div>
      <p>Quiz Name</p>
      <div className={styles.inputWrap}>
        <TextField
          required
          sx={inputStyle}
          value={quizName}
          onChange={(e) => onQuizNameChange(e)}
          id='outlined-basic'
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{ maxLength: 50 }}
        />
        {!quizName && !isInit && <p>*Required</p>}
        <div className={styles.lengthHint}>{quizName.length}/50</div>
      </div>
      <p className={styles.timerTitle}>Timer</p>
      <div className={styles.timerRow}>
        <img alt='checked' className={styles.pointer} src={timerIcon()} onClick={onCheckTimer} />
        <p>Timer</p>
        <TextField
          required
          id='outlined-number'
          type='number'
          value={timerMin}
          onChange={(e) => onTimerChange(e, 'min')}
          sx={numInputStyle}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            inputProps: {
              max: 59,
              min: 0
            }
          }}
        />
        <p className={styles.text}>m</p>
        <TextField
          id='outlined-number'
          type='number'
          value={timerSec}
          onChange={(e) => onTimerChange(e, 'sec')}
          sx={numInputStyle}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            inputProps: {
              max: 59,
              min: 0
            }
          }}
        />
        <p className={styles.text}>s</p>
      </div>
    </div>
  )
}

const AssignedCourse = ({ courseRef, courseList, handleChosenCourse, assignedCourse, handleDelete, selectedQuiz }) => {
  return (
    <div className={styles.assignedCourse} ref={courseRef}>
      <div className={styles.title}>Assigned Courses</div>
      <WindowSelect type='border' label='Course' options={courseList} handleValChange={handleChosenCourse} renderOptionType='object' />
      <div className={styles.stackArea}>
        {assignedCourse.map((course, index) => {
          return <Chip key={index} label={course.label} onDelete={() => handleDelete(course)} sx={{ borderRadius: '8px', margin: '0rem 1rem 0.5rem 0rem' }} />
        })}
      </div>
    </div>
  )
}

const AddNewQuiz = ({
  setCourseList,
  timerMin,
  setTimerMin,
  timerSec,
  setTimerSec,
  assignedCourse,
  setAssignedCourse,
  quizName,
  setStep,
  setQuizName,
  setSelectedQuestionType,
  setSelectedQuestion,
  quizActionType,
  setQuestionActionType,
  selectedQuiz,
  selectedQuestion,
  questions,
  setQuestions,
  call_getQuizDetail,
  call_deleteQuestionApi,
  call_updateQuizApi,
  call_duplicateQuestionApi,
  quizDetail,
  questionLoadingGetList,
  courseList,
  setQuizDetail,
  disableSaveQuiz,
  setDisableSaveQuiz,
  questionPage,
  questionTotalPage,
  handleQuestionPage,
  handleQuestionTotalPage,
  setSeverity,
  handleOpenToaster
}) => {
  const [newQuestionModal, setNewQuestionModal] = useState(false)
  const [deleteQuestionModal, setDeleteQuestionModal] = useState(false)
  const [checkedTimer, setCheckedTimer] = useState(false)
  const [height, setHeight] = useState(null)
  const [isInit, setIsInit] = useState(true)
  const [discardChangesModal, setDiscardChangesModal] = useState(false)
  const [goBack, setGoBack] = useState(false)
  const courseRef = useRef(null)

  const navigateToQuiz = () => {
    setGoBack(true)
  }

  const onCheckTimer = () => {
    setIsInit(false)
    setCheckedTimer((prev) => !prev)
  }

  const handleChosenCourse = (e, value) => {
    setIsInit(false)
    setCourseList((prev) => prev.filter((course) => course.id !== value.id))
    setAssignedCourse((prev) => [...prev, value])
  }

  const handleDelete = (course) => {
    setIsInit(false)
    setAssignedCourse((prev) => prev.filter((item) => item.id !== course.id))
    setCourseList((prev) => [...prev, course])
  }

  const onQuizNameChange = (e) => {
    setIsInit(false)
    setQuizName(e.target.value)
  }

  const onDeleteQuestion = () => {
    call_deleteQuestionApi({
      questionId: selectedQuestion.questionId,
      quizId: selectedQuiz.quizId
    })
  }

  const getFinalTimerNum = () => {
    if (checkedTimer) {
      let num = +timerMin * 60 + +timerSec
      if (num === 0) {
        return 10
      } else {
        return num
      }
    }
    return 0
  }

  const onSaveQuiz = () => {
    if (!quizName.trim()) {
      setSeverity('error')
      handleOpenToaster('Quiz name cannot be empty.')
      return
    }
    setDisableSaveQuiz(true)
    let courseIds = JSON.parse(JSON.stringify(assignedCourse))
    courseIds = courseIds.map((course) => course.id)
    call_updateQuizApi({
      quizId: selectedQuiz.quizId,
      name: quizName.trim(),
      timer: getFinalTimerNum(),
      courseIds: courseIds,
      isUpdateName: false
    })
  }

  const checkTimerValue = () => {
    let timer = +timerMin * 60 + +timerSec
    // console.log('timer', timer)
    if (checkedTimer && timer === 0) {
      setTimerSec(10)
    }
  }

  const onDiscard = () => {
    setDiscardChangesModal(false)
    setStep(1)
    setQuizName('')
  }

  const onCancel = () => {
    setGoBack(false)
    setDiscardChangesModal(false)
  }

  useEffect(() => {
    window.addEventListener('click', checkTimerValue)

    return () => {
      window.removeEventListener('click', checkTimerValue)
    }
  }, [timerMin, timerSec, checkedTimer])

  useEffect(() => {
    // console.log('selectedQuiz', selectedQuiz)
    call_getQuizDetail({
      quizId: selectedQuiz.quizId
    })

    setIsInit(true)

    return () => {
      setAssignedCourse([])
      setQuizDetail(null)
      setTimerMin(0)
      setTimerSec(0)
    }
  }, [])

  useEffect(() => {
    if (quizDetail) {
      console.info('quiz detail', quizDetail)
      setQuizName(quizDetail.name)
      setQuestions(quizDetail.questions)
      setCheckedTimer(quizDetail.timer !== 0)
      setTimerMin(Math.floor(quizDetail.timer / 60))
      setTimerSec(quizDetail.timer % 60)
      setAssignedCourse(quizDetail.assignedCourses)
    }
  }, [quizDetail])

  useEffect(() => {
    // dynamically change question list block heights depends on assigned course
    // height = information + assigned course + margin
    setHeight(352 + courseRef.current.clientHeight + 24)
  }, [assignedCourse])

  useEffect(() => {
    if (goBack) {
      if (!isInit) {
        setDiscardChangesModal(true)
      } else {
        setStep(1)
        setQuizName('')
      }
    }
  }, [goBack, isInit])

  return (
    <>
      <div className={styles.breadCrumbs}>
        <div>
          <div className={styles.tab} style={{ cursor: 'pointer' }} onClick={() => navigateToQuiz()}>Quiz</div>
          <div>
            <img src={ArrowIcon} alt='' />
          </div>
          <div className={styles.tab}>{quizActionType === 'create' ? 'Add New Quiz' : 'Edit Quiz'}</div>
        </div>
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.left} style={{ minHeight: `${height}px` }}>
          <QuestionList
            loadingGetList={questionLoadingGetList}
            setNewQuestionModal={setNewQuestionModal}
            setDeleteQuestionModal={setDeleteQuestionModal}
            questions={questions}
            setStep={setStep}
            setSelectedQuestionType={setSelectedQuestionType}
            setSelectedQuestion={setSelectedQuestion}
            setQuestionActionType={setQuestionActionType}
            selectedQuiz={selectedQuiz}
            call_duplicateQuestionApi={call_duplicateQuestionApi}
            questionPage={questionPage}
            questionTotalPage={questionTotalPage}
            handleQuestionPage={handleQuestionPage}
            handleQuestionTotalPage={handleQuestionTotalPage}
            setIsInit={setIsInit}
            setQuestions={setQuestions}
            setSeverity={setSeverity}
            handleOpenToaster={handleOpenToaster}
          />
        </div>
        <div className={styles.right} style={{ height: `${height}px` }}>
          <Information
            setIsInit={setIsInit}
            isInit={isInit}
            timerMin={timerMin}
            setTimerMin={setTimerMin}
            timerSec={timerSec}
            setTimerSec={setTimerSec}
            selectedQuiz={selectedQuiz}
            quizName={quizName}
            onQuizNameChange={onQuizNameChange}
            checkedTimer={checkedTimer}
            onCheckTimer={onCheckTimer}
          />
          <AssignedCourse
            courseRef={courseRef}
            courseList={courseList}
            handleChosenCourse={handleChosenCourse}
            assignedCourse={assignedCourse}
            handleDelete={handleDelete}
            selectedQuiz={selectedQuiz}
          />
        </div>
      </div>
      <div className={styles.btnWrap}>
        <button
          className={`${styles.saveBtn} ${isInit || !quizName || timerMin === '' || timerSec === '' || disableSaveQuiz ? styles.disabled : undefined}`}
          disabled={isInit || !quizName || timerMin === '' || timerSec === '' || disableSaveQuiz}
          onClick={onSaveQuiz}
        >
          Save Quiz
        </button>
      </div>
      {newQuestionModal && (
        <Modal>
          <AddQuestionModal setNewQuestionModal={setNewQuestionModal} setStep={setStep} setSelectedQuestionType={setSelectedQuestionType} />
        </Modal>
      )}
      {deleteQuestionModal && (
        <Modal>
          <DeleteQuestionModal onDeleteQuestion={onDeleteQuestion} setDeleteQuestionModal={setDeleteQuestionModal} />
        </Modal>
      )}
      {discardChangesModal && (
        <Modal>
          <DiscardChangesModal onDiscard={onDiscard} onCancel={onCancel} />
        </Modal>
      )}
    </>
  )
}

export default AddNewQuiz
