import styles from './StudentPortfolio.module.css'
import ArrowIcon from '../../../assets/icons/breadcrumbArrow.svg'
import StudentCard from '../StudentCard/StudentCard'
import ChangeDateCard from '../ChangeDateCard/ChangeDateCard'
import AttendanceIcon from '../../../assets/icons/attendance.svg'
import RaiseHandIcon from '../../../assets/icons/raiseHand.svg'
import QuizIcon from '../../../assets/icons/quiz.svg'
import PointsIcon from '../../../assets/icons/points.svg'
import CustomAccordion from '../../../parts/Accordion/Accordion'
import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { convertToDate, convertTo24HRMode, convertToAMPMtimeWithSeconds } from '../../../utils/time'
import ImageIcon from '../../../assets/icons/image.svg'

const Attendance = ({ attendance }) => {
  const getColor = (type) => {
    switch (type) {
      case 'onTime':
        return styles.onTime
      case 'absence':
        return styles.absence
      case 'late':
        return styles.late
      case 'earlyLeave':
        return styles.early
      default:
        return styles.lateAndEarlyLeave
    }
  }
  return (
    <div className={`${styles.whiteBackground} ${styles.attendance}`}>
      <div>
        <div className={styles.iconWrap}>
          <img alt='attendance' src={AttendanceIcon} />
        </div>
        <div>
          <h2 className={styles.title}>Attendance</h2>
          <div className={styles.content}>
            <div>
              <p>Join Time:</p>
              <p>
                {attendance.joinTime === '-' ? (
                  attendance.joinTime
                ) : (
                  <>
                    <span className={styles.date}>{convertToDate(attendance.joinTime)}</span>
                    <span>{convertToAMPMtimeWithSeconds(attendance.joinTime)}</span>
                  </>
                )}
              </p>
            </div>
            <div>
              <p>Last Seen:</p>
              <p>
                {attendance.lastSeen === '-' ? (
                  attendance.lastSeen
                ) : (
                  <>
                    <span className={styles.date}>{convertToDate(attendance.lastSeen)}</span>
                    <span>{convertToAMPMtimeWithSeconds(attendance.lastSeen)}</span>
                  </>
                )}
                {attendance.lastSeen === '-' && attendance.type !== 'absence' && <span className={styles.laterHint}>{`(Later than the end time of the course)`}</span>}
              </p>
            </div>
            <div>
              <p>Status: </p>
              <div className={styles.statusCard}>
                <div className={`${styles.dot} ${getColor(attendance.type)}`}></div>
                {attendance.type === 'onTime' && <p>On Time</p>}
                {attendance.type === 'absence' && <p>Absence</p>}
                {attendance.type === 'lateAndEarlyLeave' && <p>Late and early leave</p>}
                {attendance.type === 'earlyLeave' && (
                  <p>
                    <span className={styles.minutes}>{attendance.minutes}</span> {<span>min{attendance.minutes !== 1 && <span>s</span>}</span>} Left Early
                  </p>
                )}
                {attendance.type === 'late' && (
                  <p>
                    <span className={styles.minutes}>{attendance.minutes}</span> {<span>min{attendance.minutes !== 1 && <span>s</span>}</span>} Late
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const RaiseHand = ({ raiseHand }) => {
  return (
    <div className={`${styles.raiseHand} ${styles.whiteBackground}`}>
      <div>
        <div className={styles.iconWrap}>
          <img alt='attendance' src={RaiseHandIcon} />
        </div>
        <div>
          <h2 className={styles.title}>Raise Hand</h2>
          <div className={styles.content}>
            <div>
              <p>Times:</p>
              <p>{raiseHand.total}</p>
            </div>
            <div className={styles.timestampArea}>
              <p>Timestamp:</p>
              <div className={styles.timestamp}>
                {raiseHand.timeStamp.map((item, index) => {
                  return (
                    <div key={index}>
                      <span>{convertTo24HRMode(item.time, true)}</span>
                      {item.count !== 1 && <div>{item.count}</div>}
                    </div>
                  )
                })}
                {!raiseHand.timeStamp.length && <span className={styles.noRecordHint}>{`(No records found)`}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Points = ({ points }) => {
  return (
    <div className={`${styles.raiseHand} ${styles.whiteBackground}`}>
      <div>
        <div className={styles.iconWrap}>
          <img alt='points' src={PointsIcon} />
        </div>
        <div>
          <h2 className={styles.title}>Points</h2>
          <div className={styles.content}>
            <div>
              <p>Points:</p>
              <p>{points.total}</p>
            </div>
            <div className={styles.timestampArea}>
              <p>Timestamp:</p>
              <div className={styles.timestamp}>
                {points.timeStamp.map((item, index) => {
                  return (
                    <div key={index}>
                      <span>{convertTo24HRMode(item.time, true)}</span>
                      {item.count !== 1 && <div>{item.count}</div>}
                    </div>
                  )
                })}
                {!points.timeStamp.length && <span className={styles.noRecordHint}>{`(No records found)`}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const AccordionTitle = ({ quiz }) => {
  return (
    <div className={styles.summary}>
      <div className={`${styles.largeCol} ${styles.quizName}`}>
        <p>{quiz.name}</p>
      </div>
      <div className={styles.smallCol}>
        <p>{quiz.accuracyRate}%</p>
      </div>
    </div>
  )
}

const AccordionDetails = ({ questions }) => {
  const getColor = (answer) => {
    switch (answer) {
      case true:
        return styles.correct
      case false:
        return styles.incorrect
      default:
        return styles.null
    }
  }
  return (
    <>
      <div className={styles.details}>
        {questions.map((question, index) => {
          return (
            <div className={styles.question} index={index}>
              <div className={`${styles.largeCol} ${styles.questionContent}`}>
                <p>
                  {question.content ? (
                    <div>
                      Question {index + 1}: {question.content}
                    </div>
                  ) : (
                    <div className={styles.imageContent}>
                      Question {index + 1}: <img alt='quizImage' src={ImageIcon} />
                    </div>
                  )}
                </p>
              </div>
              <div className={styles.smallCol}>
                <div className={`${styles.answer} ${getColor(question.answer)}`}>{question.answer === true ? 'Correct' : question.answer === false ? 'Incorrect' : 'Null'}</div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

const QuizHeader = () => {
  return (
    <div className={styles.tableTitleWrap}>
      <div className={styles.largeCol}>Quiz</div>
      <div className={styles.smallCol}>Accuracy Rate</div>
    </div>
  )
}

// const QuizContent = ({ quizList }) => {
//   const getColor = (answer) => {
//     switch (answer) {
//       case true:
//         return styles.correct
//       case false:
//         return styles.incorrect
//       default:
//         return styles.null
//     }
//   }
//   return (
//     <>
//       {quizList.map((quiz, index) => {
//         return (
//           <div className={styles.quizRow} key={index}>
//             <div className={`${styles.largeCol} ${styles.questionContent}`}>
//               <p>{quiz.name}</p>
//             </div>
//             <div className={styles.smallCol}>
//               <div className={`${styles.answer} ${getColor(quiz.answer)}`}>{quiz.answer === true ? 'Correct' : quiz.answer === false ? 'Incorrect' : 'Null'}</div>
//             </div>
//           </div>
//         )
//       })}
//     </>
//   )
// }

const QuizContent = ({ quizList, setQuizList }) => {
  const handleAccordionChange = (e, index) => {
    setQuizList((prev) => {
      const originList = JSON.parse(JSON.stringify(prev))
      const newList = originList.map((item, idx) => {
        if (index === idx) {
          return Object.assign(item, { expanded: !item.expanded })
        } else {
          return Object.assign(item, { expanded: false })
        }
      })
      return newList
    })
  }

  return (
    <>
      {quizList.map((quiz, index) => {
        return (
          <div className={styles.accordion} key={index}>
            <CustomAccordion
              expanded={quiz.expanded}
              handleAccordionChange={(e) => handleAccordionChange(e, index)}
              title={<AccordionTitle quiz={quiz} />}
              details={<AccordionDetails questions={quiz.questions} />}
            />
          </div>
        )
      })}
    </>
  )
}

const Quiz = ({ quizList, setQuizList }) => {
  return (
    <div className={`${styles.quiz} ${styles.whiteBackground}`}>
      <div>
        <div className={styles.iconWrap}>
          <img alt='quiz' src={QuizIcon} />
        </div>
        <div className={styles.content}>
          <div className={styles.titleWrap}>
            <h2 className={styles.title}>Quiz</h2>
          </div>
          <div className={styles.content}>
            <QuizHeader />
            {quizList.length > 0 && <QuizContent quizList={quizList} setQuizList={setQuizList} />}
            {quizList.length === 0 && (
              <div className={styles.noRecordsFound}>
                <div>
                  <p>No records found.</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const StudentPortfolio = ({
  setStep,
  selectedStudent,
  selectedClassList,
  selectedClassIndex,
  call_getStudentPortfolio,
  loadingStudentPortfolio,
  studentPortfolio,
  setSelectedClassIndex
}) => {
  const [studentCard, setStudentCard] = useState(null)
  const [quizList, setQuizList] = useState([])
  const navigateToMainPage = () => {
    setStep(1)
  }

  const navigateToDashboard = () => {
    setStep(2)
  }

  const navigateToAttendedStudent = () => {
    setStep(3)
  }

  const onClickArrow = (type, valid) => {
    if (valid) {
      if (type === 'prev') {
        setSelectedClassIndex((prev) => {
          let newIndex = prev + 1
          let classInfo = selectedClassList[newIndex]
          let data = {
            studentId: selectedStudent.studentId,
            courseId: classInfo.courseId,
            startTime: classInfo.begin,
            endTime: classInfo.end
          }
          call_getStudentPortfolio(data)
          return newIndex
        })
      } else {
        setSelectedClassIndex((prev) => {
          let newIndex = prev - 1
          let classInfo = selectedClassList[newIndex]
          let data = {
            studentId: selectedStudent.studentId,
            courseId: classInfo.courseId,
            startTime: classInfo.begin,
            endTime: classInfo.end
          }
          call_getStudentPortfolio(data)
          return newIndex
        })
      }
    }
  }

  useEffect(() => {
    console.log('selectedStudent', selectedStudent)
    console.log('selectedClassList', selectedClassList)
    console.log('selectedClassIndex', selectedClassIndex)
    let classInfo = selectedClassList[selectedClassIndex]
    let data = {
      studentId: selectedStudent.studentId,
      courseId: classInfo.courseId,
      startTime: classInfo.begin,
      endTime: classInfo.end
    }
    call_getStudentPortfolio(data)
  }, [])

  useEffect(() => {
    if (studentPortfolio) {
      setStudentCard({
        studentId: selectedStudent.studentId,
        name: selectedStudent.name,
        attendance: {
          type: studentPortfolio.attendance.type,
          minutes: studentPortfolio.attendance.minutes
        },
        raiseHand: studentPortfolio.raiseHand.total,
        points: studentPortfolio.points.total,
        quizAccuracyRate: selectedStudent.quizAccuracyRate
      })
      studentPortfolio.quizList.map((quiz, index) => {
        if (index === 0) {
          return Object.assign(quiz, { expanded: true })
        } else {
          return Object.assign(quiz, { expanded: false })
        }
      })
      setQuizList(studentPortfolio.quizList)
    }
  }, [studentPortfolio])

  return (
    <>
      <div className={styles.breadCrumbs}>
        <div className={`${styles.tab} ${styles.pointer}`} onClick={() => navigateToMainPage()}>
          Portfolio
        </div>
        <div>
          <img src={ArrowIcon} alt='arrow' />
        </div>
        <div className={`${styles.tab} ${styles.pointer}`} onClick={() => navigateToDashboard()}>
          Dashboard
        </div>
        <div>
          <img src={ArrowIcon} alt='arrow' />
        </div>
        <div className={`${styles.tab} ${styles.pointer}`} onClick={() => navigateToAttendedStudent()}>
          Attended Student
        </div>
        <div>
          <img src={ArrowIcon} alt='arrow' />
        </div>
        <div className={styles.tab}>Student Portfolio</div>
      </div>
      <div className={styles.titleWrap}>
        <h1>{selectedClassList[selectedClassIndex].courseName}</h1>
        <ChangeDateCard
          hasPrev={selectedClassIndex !== selectedClassList.length - 1}
          date={convertToDate(selectedClassList[selectedClassIndex].begin)}
          hasNext={selectedClassIndex !== 0}
          onClickArrow={onClickArrow}
        />
      </div>
      {(loadingStudentPortfolio || !studentPortfolio) && (
        <div
          style={{
            height: 'calc(100vh - 330px)',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loadingStudentPortfolio && studentPortfolio && (
        <div className={styles.contentWrap}>
          <div className={styles.left}>{studentCard && <StudentCard student={studentCard} />}</div>
          <div className={styles.right}>
            <Attendance attendance={studentPortfolio?.attendance} />
            <RaiseHand raiseHand={studentPortfolio?.raiseHand} />
            <Points points={studentPortfolio?.points} />
            <Quiz quizList={quizList} setQuizList={setQuizList} />
          </div>
        </div>
      )}
    </>
  )
}

export default StudentPortfolio
