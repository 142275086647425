import { useEffect, useState } from 'react'
import { getAccountList, saveAccountList, editAccountList, deleteAccountList } from '../api/index'
import { UserContext } from './useAuth'
import { useContext } from 'react'
import { reformAccountList } from '../utils/handleMemberList'
import useWebSocket from './useWebSocket'

const PER_PAGE = 14

const omConfig = {
  1: 'Disabled',
  2: 'Enabled',
  3: 'Not allowed'
}

const itemUniqueValue = (item) => {
  return item.role + item.name + item.email + item.origin
}

const useMemberList = () => {
  const { userInfo } = useContext(UserContext)
  const [memberList, setMemberList] = useState([])
  const [loadingGetList, setLoadingGetList] = useState(false)
  const [slotObj, setSlotObj] = useState(JSON.parse(JSON.stringify(userInfo.slot)))
  const [loadingSaveList, setLoadingSaveList] = useState(false)
  const [openToaster, setOpenToaster] = useState(false)
  const [toasterText, setToasterText] = useState('')
  const [getDataError, setGetDataError] = useState(false)
  const [originArr, setOriginArr] = useState(['All Origins'])
  const { changeUserObservationMode, wsMessage } = useWebSocket(`${process.env.REACT_APP_WS_DOMAIN}?userId=${userInfo.userID}&orgId=${userInfo.orgID}&platform=manager`)

  useEffect(() => {
    call_getApi()
  }, [])

  const handleOriginArr = (arr) => {
    setOriginArr(arr)
  }

  const handleOpenToaster = (text) => {
    setToasterText(text)
    setOpenToaster(true)
  }

  const handleCloseToaster = () => setOpenToaster(false)

  const handleSlotObj = (arr) => {
    let obj = JSON.parse(JSON.stringify(userInfo.slot))
    let config = {
      owner: 'Admin',
      Owner: 'Admin',
      admin: 'Admin',
      student: 'Student',
      teacher: 'Teacher',
      Admin: 'Admin',
      Student: 'Student',
      Teacher: 'Teacher'
    }
    arr.forEach((item) => {
      obj[config[item.role]].occupied++
    })
    setSlotObj(obj)
  }

  const handleMemberList = (arr) => {
    setMemberList(arr)
  }

  const call_getApi = (text = '') => {
    handleMemberList([])
    setLoadingGetList(true)
    setGetDataError(false)
    let data = {
      email: userInfo.email ? userInfo.email : localStorage.getItem('email'),
      orgID: userInfo.orgID
    }
    getAccountList(data)
      .then((res) => {
        let arr = JSON.parse(res.data.body)
        let { m_list, t_list } = reformAccountList(arr)
        handleMemberList(m_list)
        handleOriginArr(['All Origins', ...new Set(m_list.map((item) => item.origin))])
        localStorage.setItem('teacherCache', JSON.stringify(t_list))
        handleSlotObj(m_list)
        if (text) handleOpenToaster(text)
      })
      .catch((err) => {
        console.log('get member list error', err)
        setGetDataError(true)
        handleMemberList([])
      })
      .finally(() => setLoadingGetList(false))
  }

  const reformImportArr = (importArr) => {
    let arr = importArr
      .filter((item) => !item.disabled)
      .map((item) => {
        let obj = {
          role: item.role.toLowerCase(),
          firstName: item.firstName ? String(item.firstName) : '',
          lastName: item.lastName ? String(item.lastName) : '',
          email: item.email,
          name: `${item.firstName} ${item.lastName}`
          // status: 'pending',
        }

        obj.firstName = obj.firstName.trim()
        obj.lastName = obj.lastName.trim()
        obj.name = `${obj.firstName} ${obj.lastName}`

        obj.createFrom = 'Universe'
        delete obj.disabled
        return obj
      })

    console.info('save acc', arr)

    let data = {
      email: userInfo.email,
      orgID: userInfo.orgID,
      body: JSON.stringify(arr)
    }
    return data
  }

  const reformCanvasImportArr = (importArr) => {
    let arr = importArr
      .filter((item) => !item.disabled && item.checked)
      .map((item) => {
        let obj = {
          role: item.role.toLowerCase(),
          firstName: item.name,
          lastName: '',
          email: item.email,
          name: item.name
          // status: 'pending',
        }
        obj.createFrom = 'Canvas'
        delete obj.disabled
        return obj
      })

    let data = {
      email: userInfo.email,
      orgID: userInfo.orgID,
      body: JSON.stringify(arr)
    }
    console.info('reformCanvasImportArr', arr)
    return data
  }

  const call_saveApi = (data, type = 'save') => {
    setLoadingSaveList(true)
    saveAccountList(data, userInfo.idToken)
      .then((res) => {
        console.log('saveAccountList', JSON.parse(res.data.body))
        let text = type === 'edit' ? 'User information has been updated!' : 'Verification emails have been sent successfully!'
        call_getApi(text)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingSaveList(false))
  }

  const call_editApi = async (obj) => {
    console.info('edit account list', obj)
    handleMemberList([])
    setLoadingGetList(true)
    let data = {
      data: obj,
      orgId: userInfo.orgID
    }
    editAccountList(data)
      .then((res) => {
        const text = "User's information has been edited successfully!"
        const obervationMode = obj.role == 'admin' ? 1 : 3
        changeUserObservationMode([obj.userID], obervationMode)
        updateObservationModeManually([obj.userID], obervationMode)
        call_getApi(text)
      })
      .catch((err) => {
        console.log('editAccountList err', err)
        setLoadingGetList(false)
      })
  }

  const call_deleteApi = (obj, data = null) => {
    handleMemberList([])
    obj.orgId = userInfo.orgID
    setLoadingGetList(true)
    deleteAccountList(obj)
      .then((res) => {
        if (data) {
          call_saveApi(data, 'edit')
        } else {
          let text = 'User has been deleted!'
          call_getApi(text)
        }
      })
      .catch((err) => {
        console.log('deleteAccountList err', err)
        setLoadingGetList(false)
      })
  }

  const handleUserObservationMode = (selectedAccounts, observationMode) => {
    const userIdArr = selectedAccounts.map((item) => item.userID)
    console.info('userIdArr', observationMode, userIdArr)
    changeUserObservationMode(userIdArr, observationMode)
    updateObservationModeManually(userIdArr, observationMode)
  }

  const updateObservationModeManually = (userIdArr, observationMode) => {
    // update member list om value
    let arr = JSON.parse(JSON.stringify(memberList)).map((item) => {
      if (userIdArr.find((userID) => userID == item.userID)) {
        item.observationMode = omConfig[observationMode]
      }
      return item
    })
    setMemberList(arr)
  }

  const removeSelectedList = (emailList) => {
    let data = { email: emailList }
    console.info('remove Selected list', data)
    call_deleteApi(data)
  }

  const removeSingleAccount = (removeItem) => {
    let arr = []
    let chosenIdx = memberList.findIndex((item) => itemUniqueValue(item) === itemUniqueValue(removeItem))
    arr.push(memberList[chosenIdx].email)
    let data = { email: arr }
    call_deleteApi(data)
  }

  return {
    memberList,
    removeSingleAccount,
    removeSelectedList,
    handleMemberList,
    loadingGetList,
    slotObj,
    call_getApi,
    call_saveApi,
    loadingSaveList,
    openToaster,
    handleCloseToaster,
    getDataError,
    call_editApi,
    originArr,
    reformImportArr,
    toasterText,
    reformCanvasImportArr,
    handleUserObservationMode
  }
}

export default useMemberList
