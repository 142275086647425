import { useContext } from "react"
import { UserContext } from "../../CustomHooks/useAuth"
import styles from './AddClassButton.module.css'

const AddClassButton = ({ handleModal }) => {
  
  const { userInfo } = useContext(UserContext)

  return (
    <button className={`${styles.addClassBtn} ${userInfo.identity === 'student' && styles.disabled}`} onClick={() => handleModal(true)}>+ Add Class</button>
  )
}

export default AddClassButton