import styles from './BreadCrumb.module.css'
import ArrowIcon from '../../assets/icons/breadcrumbArrow.svg'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { UserContext } from '../../CustomHooks/useAuth'

const BreadCrumb = ({ title }) => {
  const navigate = useNavigate()
  const { userInfo } = useContext(UserContext)

  const toHome = () => {
    if (userInfo.identity === 'admin' || userInfo.identity === 'owner') {
      navigate('/dashboard/manageusers')
    }
    if (userInfo.identity === 'teacher') {
      navigate('/dashboard/classroom')
    }
    if (userInfo.identity === 'student') {
      navigate('/dashboard/schedule')
    }
  }

  return (
    <div className={styles.breadCrumb}>
      <div className={styles.home} onClick={toHome}></div>
      <img src={ArrowIcon} alt='' />
      <div style={{ font: 'normal normal normal 24px/29px InterMedium' }}>{title}</div>
    </div>
  )
}

export default BreadCrumb
