import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useContext } from 'react'
import { UserContext } from '../CustomHooks/useAuth'

const style = {
  '& .MuiPaper-root': {
    borderRadius: '8px',
    boxShadow: '0px 0px 6px #00000033',
    '& .MuiMenu-list': {
      padding: '0px'
    },
    '& .MuiMenuItem-root': {
      padding: '15px'
    }
  }
}

const textStyle = {
  font: 'normal normal 600 14px/18px InterMedium'
}

const options = ['Resend Invitation', 'Edit Role', 'Remove User']

const ListContentMenu = ({ switchObservationMode, sameAccount, anchorEl, open, handleClose, handleRemoveModal, handleEditUserModal, resendInvitation, status, item }) => {
  const { userInfo } = useContext(UserContext)

  const showObservationOpt = () => {
    if (!userInfo.observationMode) return false
    if (item.role == 'teacher' || item.role == 'student') return false
    return true
  }

  return (
    <Menu sx={style} anchorEl={anchorEl} open={open} onClose={handleClose}>
      {status === 'UNCONFIRMED' && !sameAccount && (
        <MenuItem
          sx={textStyle}
          onClick={() => {
            handleClose()
            resendInvitation()
          }}
        >
          Resend Invitation
        </MenuItem>
      )}
      <MenuItem
        sx={textStyle}
        onClick={() => {
          handleClose()
          handleEditUserModal(true)
        }}
      >
        Edit User
      </MenuItem>
      {showObservationOpt() && (
        <MenuItem onClick={() => switchObservationMode()} sx={textStyle}>
          {item.observationMode == 'Enabled' ? 'Disable' : 'Enable'} observation mode
        </MenuItem>
      )}
      {!sameAccount && (
        <MenuItem sx={textStyle} onClick={handleRemoveModal}>
          Remove User
        </MenuItem>
      )}
    </Menu>
  )
}

export default ListContentMenu
