import styles from './AddStudentModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useContext, useEffect, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { UserContext } from '../../../CustomHooks/useAuth'
import CircularProgress from '@mui/material/CircularProgress'
import SearchInput from '../../../parts/SearchInput'
import dayjs from 'dayjs'
import PrivacyModal from '../PrivacyModal/PrivacyModal'

const DraggableBlock = ({ item, type, moveItem }) => {
  const { chosenOrg } = useContext(UserContext)
  const [{ isDragging }, dragRef, preview] = useDrag({
    type,
    item,
    collect: (monitor) => ({ isDragging: monitor.isDragging() })
  })
  return (
    <div ref={dragRef} className={styles.draggableItem}>
      <div className={styles.left}>
        <div className={styles.dragHandler}>
          <DragIndicatorIcon sx={{ color: '#888888' }} />
        </div>
        <div>{`${item.display} / ${chosenOrg === 'Free Trial' ? 'freetrial@email.com' : item.email}`}</div>
      </div>
      <div className={styles.icon}>
        {type === 'studentList' && <AddIcon sx={{ color: '#888888' }} onClick={() => moveItem(item, 'Right')} />}
        {type === 'course' && <RemoveIcon sx={{ color: '#888888' }} onClick={() => moveItem(item, 'Left')} />}
      </div>
    </div>
    // <div ref={preview} className={styles.draggableItem}>
    //   <div ref={dragRef} className={styles.dragHandler}><DragIndicatorIcon sx={{ color: '#888888' }} /></div>
    //   <div>{ item }</div>
    // </div>
  )
}

const DropArea = ({ handleDrop, list, studentList, teacherList, identity, counterpart, moveItem }) => {
  const { chosenOrg } = useContext(UserContext)

  const [{ isOver }, dropRef] = useDrop({
    accept: counterpart,
    drop: (item) => handleDrop(item, identity),
    collect: (monitor) => ({ isOver: monitor.isOver() })
  })

  const [searchVal, setSearchVal] = useState('')

  const handleSearchVal = (e) => {
    setSearchVal(e.target.value)
  }

  return (
    <div className={`${styles.content} ${isOver && styles.over} ${chosenOrg === 'Free Trial' && styles.privacy}`} ref={dropRef}>
      {!!list ? (
        <div className={styles.draggableItemsWrap}>
          {list.length !== 0 && list.map((item, idx) => <DraggableBlock key={idx} item={item} type={identity} moveItem={moveItem} />)}
        </div>
      ) : (
        <div>
          <SearchInput searchVal={searchVal} handleSearchVal={handleSearchVal} placeholder='Search People By Name or Email' typ='boxShadow' />
          <div className={styles.subtitle}>Student</div>
          <div className={styles.draggableItemsWrap}>
            {studentList.length !== 0 &&
              studentList
                .filter((item) => item.display.toLowerCase().includes(searchVal.toLowerCase()) || item.email.toLowerCase().includes(searchVal.toLowerCase()) || searchVal === '')
                .map((item, idx) => <DraggableBlock key={idx} item={item} type={identity} moveItem={moveItem} />)}
          </div>
          <div className={styles.subtitle}>Teacher</div>
          <div className={styles.draggableItemsWrap}>
            {teacherList.length !== 0 &&
              teacherList
                .filter((item) => item.display.toLowerCase().includes(searchVal.toLowerCase()) || item.email.toLowerCase().includes(searchVal.toLowerCase()) || searchVal === '')
                .map((item, idx) => <DraggableBlock key={idx} item={item} type={identity} moveItem={moveItem} />)}
          </div>
        </div>
      )}
    </div>
  )
}

const AddStudentModal = ({ memberList, handleModal, updateStudentListByClass, chosenClassItem, loadingGetList }) => {
  const [studentList, setStudentList] = useState([])
  const [teacherList, setTeacherList] = useState([])
  const [selectedList, setSelectedList] = useState([])

  const { userInfo, chosenOrg } = useContext(UserContext)

  useEffect(() => {
    if (chosenClassItem) {
      console.info(memberList)
      // set student list
      let studentMember = memberList.filter((item) => item.role.toLowerCase() === 'student')
      let temp_studentList = studentMember.filter((itemInAllList) => !chosenClassItem.studentList.find((item) => item.email.toLowerCase() === itemInAllList.email.toLowerCase()))
      setStudentList(temp_studentList)
      // set teacher list
      let teacherMember = memberList.filter((item) => item.role.toLowerCase() !== 'student' && item.email !== chosenClassItem.teacherEmail)
      let temp_teacherList = teacherMember.filter((itemInAllList) => !chosenClassItem.studentList.find((item) => item.email.toLowerCase() === itemInAllList.email.toLowerCase()))
      setTeacherList(temp_teacherList)
      // set selected list
      let temp_selectedList = chosenClassItem.studentList.map((chosenItem) => memberList.find((item) => item.email.toLowerCase() === chosenItem.email.toLowerCase()))
      setSelectedList(temp_selectedList)
    }
  }, [chosenClassItem])

  const handleDrop = (dragItem, identity) => {
    if (identity === 'course') {
      moveItem(dragItem, 'Right')
    } else {
      moveItem(dragItem, 'Left')
    }
  }

  const moveItem = (dragItem, from) => {
    let temp_studentList = studentList.map((item) => item)
    let temp_teacherList = teacherList.map((item) => item)
    let temp_SelectedList = selectedList.map((item) => item)
    if (from === 'Left') {
      let index = temp_SelectedList.findIndex((item) => item.email.toLowerCase() === dragItem.email.toLowerCase())
      temp_SelectedList.splice(index, 1)
      if (dragItem.role.toLowerCase() === 'student') {
        temp_studentList.push(dragItem)
      } else {
        temp_teacherList.push(dragItem)
      }
    } else {
      if (selectedList.length >= chosenClassItem.maxPlayer) return
      if (dragItem.role.toLowerCase() === 'student') {
        let index = temp_studentList.findIndex((item) => item.email.toLowerCase() === dragItem.email.toLowerCase())
        temp_studentList.splice(index, 1)
      } else {
        let index = temp_teacherList.findIndex((item) => item.email.toLowerCase() === dragItem.email.toLowerCase())
        temp_teacherList.splice(index, 1)
      }
      temp_SelectedList.push(dragItem)
    }
    setSelectedList(temp_SelectedList)
    setStudentList(temp_studentList)
    setTeacherList(temp_teacherList)
  }

  const reformSelectedList = () => {
    let list = selectedList.map((item) => {
      let isExist = !!chosenClassItem.studentList.find((chosenItem) => chosenItem.email.toLowerCase() === item.email.toLowerCase())
      return {
        email: item.email,
        note: isExist ? chosenClassItem.studentList.find((chosenItem) => chosenItem.email.toLowerCase() === item.email.toLowerCase()).note : ''
      }
    })
    return list
  }

  const reformChatGroupData = () => {
    let newList = selectedList.map((item) => item.email)
    let oldList = chosenClassItem.studentList.map((item) => item.email)
    let list = newList.concat(oldList)
    let chatList = [...new Set(list)]

    const generateData = (email) => {
      let inNewList = !!newList.find((newListEmail) => newListEmail === email)
      let inOldList = !!oldList.find((oldListEmail) => oldListEmail === email)
      let obj = {
        userID: memberList.find((member) => member.email.toLowerCase() === email.toLowerCase()).userID
      }

      if (inNewList && inOldList) {
        obj.typ = null
      } else if (inNewList) {
        obj.typ = 'Join'
      } else {
        obj.typ = 'Leave'
      }
      return obj
    }

    let arr = chatList.map((email) => generateData(email))
    arr = arr.filter((item) => item.typ !== null).map((item) => ({ ...item, timestamp: String(dayjs().unix()) }))

    let data = {
      orgID: userInfo.orgID,
      channelID: chosenClassItem.courseid,
      studentList: arr
    }

    return data
  }

  return (
    <div className={styles.addStudentModalWrap}>
      {loadingGetList && (
        <div className={styles.loadingWrap}>
          <CircularProgress />
        </div>
      )}
      <div className={styles.modalTitle}>
        <div className={styles.title}>Edit Student List</div>
        <div className={styles.cancel} onClick={() => handleModal(false)}>
          <CloseRoundedIcon />
        </div>
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.left}>
          <div className={styles.titleWrap}>Users</div>
          <DropArea handleDrop={handleDrop} studentList={studentList} teacherList={teacherList} identity='studentList' counterpart='course' moveItem={moveItem} />
        </div>
        <div className={styles.right}>
          <div className={styles.titleWrap}>
            <div>Student List</div>
            <div className={`${selectedList.length >= chosenClassItem.maxPlayer && styles.warning}`}>{`Select: ${selectedList.length} / ${chosenClassItem.maxPlayer}`}</div>
          </div>
          <DropArea handleDrop={handleDrop} list={selectedList} identity='course' counterpart='studentList' moveItem={moveItem} />
        </div>
        {chosenOrg == 'Free Trial' && <PrivacyModal typ='studentList' />}
      </div>
      <button className={styles.save} onClick={() => updateStudentListByClass(reformSelectedList(), 'Students have been added to the class successfully!', reformChatGroupData())}>
        Save
      </button>
    </div>
  )
}

export default AddStudentModal
