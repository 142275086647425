import styles from './SignOutModal.module.css'

const SignOutModal = ({ onSignOutUser, onSignOutCancel }) => {
  return (
    <div className={styles.modalWrap}>
      <div className={styles.title}>Sign Out</div>
      <div className={styles.message}>Are you sure you want to sign out this user?</div>
      <div className={styles.btnWrap}>
        <div className={`${styles.signOut} ${styles.btn}`} onClick={() => onSignOutUser()}>
          Sign Out
        </div>
        <div className={`${styles.cancel} ${styles.btn}`} onClick={() => onSignOutCancel()}>
          Cancel
        </div>
      </div>
    </div>
  )
}

export default SignOutModal
