import { useState } from "react"

const useToaster = () => {
  
  const [openToaster, setOpenToaster] = useState(false)
  const [toasterText, setToasterText] = useState('')
  const [toastSeverity, setToastSeverity] = useState('success')

  const handleOpenToaster = (text, severity = 'success') => { 
    setToasterText(text)
    setToastSeverity(severity)
    setOpenToaster(true)
  }

  const handleCloseToaster = () => setOpenToaster(false)
  
  return { openToaster, toasterText, toastSeverity, handleOpenToaster, handleCloseToaster }
}

export default useToaster