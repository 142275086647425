import styles from './ImportQti.module.css'
import ArrowIcon from '../../../assets/icons/breadcrumbArrow.svg'
import NoticeIcon from '../../../assets/icons/notice.svg'
import { useEffect, useState } from 'react'
import { parseString } from 'xml2js'
import JSZip from 'jszip'
import { reformQTIItem_v1p2 } from '../../../utils/qti'
import WindowSelect from '../../../parts/VirtualizedList/VirtualizedList'
import SortByIcon from '../../../assets/icons/sortby.svg'
import Modal from '../../../parts/Modal'
import AddModal from '../../../parts/Modals/Activity/AddModal/AddModal'
import CancelUploadModal from '../../Modals/Activity/CancelUploadModal/CancelUploadModal'
import CircularProgress from '@mui/material/CircularProgress'
import { v4 as uuidv4 } from 'uuid'

const TableHeader = ({ typ, onSortUploadDate }) => {
  return (
    <div className={styles.titleWrap}>
      <div className={styles.tableItem}>{ typ } Name</div>
      <div className={styles.tableItem}>Source File</div>
      <div className={`${styles.tableItem} ${styles.updatedDate}`}>
        Upload Date
        <img alt='sortby' src={SortByIcon} onClick={onSortUploadDate} />
      </div>
      <div className={styles.tableItem}>Status</div>
    </div>
  )
}

const TableContent = ({ typ, item, setStep, setSelectedParent }) => {
  const navigateToDetail = () => {
    if (!item.isExist) return

    let data = {}

    if(typ == 'Assignment') {
      data.assignmentId = item.id
    } else {
      data.quizId = item.id
    }

    setSelectedParent(data)
    setStep(2)
  }

  const getUpdatedDate = (timeStamp) => {
    let updatedDate = new Date(timeStamp * 1000)
    let year = updatedDate.getFullYear()
    let month = ('0' + (updatedDate.getMonth() + 1)).slice(-2)
    let date = ('0' + updatedDate.getDate()).slice(-2)
    return `${year}/${month}/${date}`
  }
  return (
    <div className={`${styles.itemWrap} ${item.isExist ? styles.pointer : undefined}`} onClick={navigateToDetail}>
      <div className={styles.item}>
        <p>{item.name}</p>
      </div>
      <div className={styles.item}>
        <p>{item.fileName}</p>
      </div>
      <div className={styles.item}>{getUpdatedDate(item.uploadDate)}</div>
      <div className={`${styles.item} ${styles.status}`}>
        <p>Completed</p>
      </div>
    </div>
  )
}

const ImportQti = ({
  typ,
  setStep,
  call_getParentListForImportAPI,
  call_getImportHistoryAPI,
  call_importQuestionsAPI,
  parentListForImport,
  setSeverity,
  handleOpenToaster,
  call_createParentImportApi,
  selectVal,
  setSelectVal,
  selectValObj,
  setSelectValObj,
  addModal,
  setAddModal,
  disableAddModalBtn,
  setDisableAddModalBtn,
  isUploading,
  setIsUploading,
  uploadedParentList,
  setUploadedParentList,
  fileName,
  setFileName,
  fileInputRef,
  historyList,
  setHistoryList,
  historyLoadingGetList,
  setSelectedParent
}) => {
  const [parentName, setParentName] = useState('')
  const [disableCancel, setDisableCancel] = useState(true)
  const [disableUpload, setDisableUpload] = useState(true)
  const [cancelUploadModal, setCancelUploadModal] = useState(false)
  const [parentImage, setParentImage] = useState(null)

  const navigateToParent = () => {
    setStep(1)
  }

  const handleChosenParent = (e, value) => {

    if(typ == 'Assignment') {
      if (value.id === 'addAssignment') {
        setParentName('')
        setAddModal(true)
      } else {
        setSelectVal(value.label)
        setSelectValObj(value)
      }
    }

    if(typ == 'Quiz') {
      if (value.id === 'addQuiz') {
        setParentName('')
        setAddModal(true)
      } else {
        setSelectVal(value.label)
        setSelectValObj(value)
      }
    }

  }

  const onSortUploadDate = () => {
    const array = JSON.parse(JSON.stringify(historyList))
    setHistoryList(array.reverse())
  }

  const onCreateParent = () => {
    let data = {
      name: parentName,
      timer: 0,
      courseIds: []
    }
    setDisableAddModalBtn(true)
    call_createParentImportApi(data)
  }

  const getFile = (e) => {
    if (!e.target.files.length) return
    const file = e.target.files[0]
    console.info('file', file)
    const zip = new JSZip()
    let list = []
    setParentImage(null)
    // unzip -> xml to json
    zip.loadAsync(file /* = file blob */).then(function (zip) {
      // process ZIP file content here
      Object.keys(zip.files).forEach((filePath) => {
        if (zip.files[filePath].dir) return
        if (zip.files[filePath].name.includes('imsmanifest.xml')) return
        if (zip.files[filePath].name.includes('assessment_meta.xml')) return

        zip
          .file(filePath)
          .async('string')
          .then(function (data) {
            // xml to json

            try {
              parseString(data, function (err, result) {
                console.info('result', result)
                if (!result) return
                result['questestinterop']['assessment'][0]['section'].forEach((item) => {
                  console.info('parseString', item)
                  list = list.concat(reformQTIItem_v1p2(item))
                })

                if (file.name.length > 30) file.name = file.name.slice(0, 30)

                list.map((item) => {
                  item.questionId = uuidv4()
                  item.image = null
                  if (item.imagePath) {
                    let timestamp = +new Date()
                    item.image = `${item.questionId}-${timestamp}`
                    // parse image
                    zip
                      .file(item.imagePath)
                      .async('blob')
                      .then(function (fileData) {
                        let blob = new File([fileData], zip.files[item.imagePath].name.split('/')[1])
                        item.imageBlob = blob
                      })
                  }
                  return item
                })
                setFileName(file.name)
                setUploadedParentList(list)
                console.log('list', list)
              })
            } catch (err) {
              setSeverity('error')
              handleOpenToaster('Invalid ZIP file')
              fileInputRef.current.value = ''
              setFileName('No File Selected')
              console.log('Invalid zip file', err)
            }
          })
      })
    })
  }

  const onClickCancel = () => {
    setCancelUploadModal(true)
  }

  const onDiscard = () => {
    setCancelUploadModal(false)
    setUploadedParentList([])
    fileInputRef.current.value = ''
    setFileName('No File Selected')
    setSelectVal(null)
    setSelectValObj(null)
    setDisableCancel(true)
    setDisableUpload(true)
  }

  const onClickUpload = () => {
    setIsUploading(true)

    let data = {
      version: '1.2',
      platform: 'Canvas',
      // assignmentId: selectValObj.id,
      question: uploadedParentList,
      fileName: fileName
    }

    if(typ === 'Assignment') {
      data.assignmentId = selectValObj.id
    } else {
      data.quizId = selectValObj.id
    }

    console.info('on click upload', data)
    call_importQuestionsAPI(data)
  }

  const onOpen = () => {
    setSelectVal(null)
    setDisableCancel(true)
    setDisableUpload(true)
  }

  const onCancel = () => {
    setCancelUploadModal(false)
  }

  useEffect(() => {
    call_getParentListForImportAPI()
    call_getImportHistoryAPI()

    return () => {
      setFileName('No File Selected')
      setUploadedParentList([])
      setSelectVal(null)
      setSelectValObj(null)
    }
  }, [])

  useEffect(() => {
    console.log('fileName', fileName)
    console.log('selectVal', selectVal)
    if (fileName !== 'No File Selected' && selectVal !== null && selectVal !== `+ Add ${typ}`) {
      setDisableCancel(false)
      setDisableUpload(false)
    } else {
      setDisableCancel(true)
      setDisableUpload(true)
    }
  }, [fileName, selectVal])

  return (
    <>
      <div className={styles.breadCrumbs}>
        <div className={`${styles.tab} ${styles.pointer}`} onClick={() => navigateToParent()}>
          { typ }
        </div>
        <div>
          <img src={ArrowIcon} alt='' />
        </div>
        <div className={styles.tab}>Import { typ }</div>
      </div>
      <div>
        <div className={styles.importContent}>
          <div className={styles.title}>Import Content</div>
          <div className={styles.step1}>
            <div className={styles.sourceWrap}>
              <p>Step 1: Source</p>
              <div className={styles.fileWrap}>
                <input className={styles.inputFile} id='file' type='file' accept='.zip' onChange={getFile} ref={fileInputRef} />
                <label htmlFor='file'>
                  <div className={styles.uploadFileBtn}>
                    <p>Upload File</p>
                    <p>CANVAS QTI.zip</p>
                  </div>
                </label>
                <p>{fileName}</p>
              </div>
            </div>
            <div className={styles.noticeWrap}>
              <p></p>
              <div>
                <img alt='notice' src={NoticeIcon} />
                <p>Importing the same { typ.toLowerCase() } more than once will overwrite existing content.</p>
              </div>
            </div>
          </div>
          <div className={styles.step2}>
            <p>Step 2: Destination</p>
            <WindowSelect
              type='border'
              label={typ}
              options={parentListForImport}
              handleValChange={(e, value) => handleChosenParent(e, value)}
              renderOptionType='object'
              val={selectVal}
              onOpen={onOpen}
            />
          </div>
          <div className={styles.btnArea}>
            <button className={`${disableCancel || isUploading ? styles.disabled : undefined} ${styles.cancelBtn}`} onClick={onClickCancel} disabled={disableCancel || isUploading}>
              Cancel
            </button>
            {disableUpload ? (
              <button className={`${styles.disabled} ${styles.uploadBtn}`} disabled={true}>
                <div>
                  <p>Upload</p>
                </div>
              </button>
            ) : isUploading ? (
              <button className={`${styles.disabled} ${styles.uploadBtn}`} disabled={true}>
                <div className={styles.uploading}>
                  <CircularProgress sx={{ width: '20px !important', height: '20px !important', color: '#fff', marginRight: '0.5rem' }} />
                  <p>Uploading...</p>
                </div>
              </button>
            ) : (
              <button className={styles.uploadBtn} onClick={onClickUpload}>
                <div>
                  <p>Upload</p>
                </div>
              </button>
            )}
          </div>
        </div>
        <div className={styles.importHistory}>
          <div className={styles.title}>Import History</div>
          <TableHeader typ={typ} onSortUploadDate={onSortUploadDate} />
          {historyLoadingGetList && (
            <div
              style={{
                height: 'calc(352px - 182px)',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </div>
          )}
          {!historyLoadingGetList &&
            historyList.map((item, index) => {
              return <TableContent typ={typ} key={index} item={item} setStep={setStep} setSelectedParent={setSelectedParent} />
            })}
        </div>
      </div>
      {addModal && (
        <Modal>
          <AddModal
            typ={typ}
            name={parentName}
            setName={setParentName}
            setAddModal={setAddModal}
            onCreate={onCreateParent}
            actionType='create'
            disableAddModalBtn={disableAddModalBtn}
          />
        </Modal>
      )}
      {cancelUploadModal && (
        <Modal>
          <CancelUploadModal onDiscard={onDiscard} onCancel={onCancel} />
        </Modal>
      )}
    </>
  )
}

export default ImportQti
