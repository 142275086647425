import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from './RadioGroupBox.module.css'
import SelectFilter from '../SelectFilter'
import { Typography } from '@mui/material'
import CachedIcon from '@mui/icons-material/Cached'

const RadioGroupBox = ({ typ=null, assignRandomly=() => {}, childOption, groupUpMode, handleGroupUpMode, handleChildOption, addGroupMode='none' }) => {

  const disableAutomaticOption = () => {
    return groupUpMode !== 'automatic'
  }

  const disableWhenDuplicate = () => {
    return addGroupMode === 'duplicate'
  }

  const automaticStyle = {
    font: 'normal normal 600 14px/17px InterRegular',
    opacity: disableAutomaticOption() ? 0.6 : 1
  }

  const customizedStyle = {
    font: 'normal normal 600 14px/17px InterRegular',
    opacity: !disableAutomaticOption() ? 0.6 : 1
  }

  return (
    <div className={styles.radioWrap}>
      <RadioGroup>
        <FormControlLabel value="automatic" control={<Radio />} label={<Typography sx={automaticStyle} variant="caption">Automatically assigned group</Typography>} checked={groupUpMode === 'automatic'} onChange={handleGroupUpMode} disabled={disableWhenDuplicate()}/>
        <FormControlLabel value="customized" control={<Radio />} label={<Typography sx={customizedStyle} variant="caption">Create custom group</Typography>} checked={groupUpMode === 'customized'} onChange={handleGroupUpMode} disabled={disableWhenDuplicate()}/>
      </RadioGroup>
      <div className={styles.selectWrap}>
        <div className={`${styles.select} ${disableAutomaticOption() && styles.disabled}`}>
          <SelectFilter 
            options={[2,3,4,5,6,7,8,9,10]}
            val={Number(childOption.split('-')[1])}
            handleValChange={handleChildOption}
            typ='border'
            disabled={disableAutomaticOption() || disableWhenDuplicate()}
          />
        </div>
        <div className={`${styles.text} ${disableAutomaticOption() && styles.disabled}`}>students / per group</div>
        {
          (typ) && (
          <div style={{ marginBottom: 5 }}>
            <button className={`${styles.regroup} ${disableAutomaticOption() && styles.disabled}`} onClick={() => assignRandomly()}>
              <CachedIcon className={styles.icon} />
              Regroup
            </button>
          </div>
          )
        }
      </div> 
    </div>
    )
  }
  
  export default RadioGroupBox