import classStyleData from '../assets/classStyles.json'

/* 
  The configuration object has a format where each key-value pair represents a unique classroom configuration.
  Each key is a unique string generated by concatenating `classRoomType` and `showName` properties with a hyphen.
  
  Example: 
  "0-Chestnut(1~30)": {
    "classRoom": "Chestnut",
    "maxPlayer": 30,
    "classRoomType": 0,
    "showName": "Chestnut(1~30)"
  }
  
  The value is an object containing details about the classroom such as its name, maximum number of players, type, and displayed name.
*/
const getClassroomConfig = (classStyleData) =>
  classStyleData.reduce((acc, cur) => {
    let key = `${cur.classRoomType}-${cur.showName}`
    acc[key] = cur
    return acc
  }, {})
let classroomConfig = getClassroomConfig(classStyleData)

const classRoomTypeDict = {
  STANDARD: 0,
  GROUPUP: 1,
  MEETING: 2,
  COLLABORATIVE: 3
}

const standardConfig = getClassroomConfig(classStyleData.filter((item) => item.classRoomType === classRoomTypeDict.STANDARD))
const groupUpConfig = getClassroomConfig(classStyleData.filter((item) => item.classRoomType === classRoomTypeDict.GROUPUP))
const meetingConfig = getClassroomConfig(classStyleData.filter((item) => item.classRoomType === classRoomTypeDict.MEETING))
const collaborativeConfig = getClassroomConfig(classStyleData.filter((item) => item.classRoomType === classRoomTypeDict.COLLABORATIVE))

export const unpackClassRoomTypeAndClassSize = (key) => {
  let [classRoomType, classSize] = key.split('-')
  if(classRoomType == 1) classSize =  `${classSize}-Group Up`
  return { classRoomType, classSize }
}

export const standard = Object.keys(standardConfig)
export const groupUp = Object.keys(groupUpConfig)
export const meeting = Object.keys(meetingConfig)
export const collaborative = Object.keys(collaborativeConfig)

export const classRoomTypeMapping = { 0: 'Standard', 1: 'GroupUp', 2: 'Meeting', 3: 'Collaborative' }
export const getClassRoomUniqueKey = (classRoomType, classSize) => {
  if(classRoomType === 1 && !classSize.includes('Group')) classSize = `${classSize}-Group Up`
  return `${classRoomType}-${classSize}`
}

export const fixMeetingRoomOldData = (classSize, classRoomType) => {
  const classRoomUniqueKey = getClassRoomUniqueKey(classRoomType, classSize)
  if (classSize === 'Sphere(1~50)') console.info(classSize)
  if (classSize === 'Marine(1~50)') return { type: 'Marine(1~50)', num: 50, classRoomType: 3, showName: 'Marine(1~50)' }
  if (classSize === 'Chestnut(1~5)') return { type: 'Chestnut(1~11)', num: 11, classRoomType: 2, showName: 'Chestnut(1~11)' }
  if (classSize === 'Elements(1~11)') return { type: 'Elements(1~5)', num: 5, classRoomType: 2, showName: 'Elements(1~5' }
  if (classSize === 'Granite(1~5)') return { type: 'Granite(1~11)', num: 11, classRoomType: 2, showName: 'Granite(1~11)' }
  if (classSize === 'Sphere(1~50)') return { type: 'Mocha(1~50)', num: 50, classRoomType: 3, showName: 'Mocha(1~50)' }
  if (classSize === 'Biology(1~50)') return { type: 'Cell Biology(1~50)', num: 50, classRoomType: 0, showName: 'Cell Biology(1~50)' }
  if (classSize === 'Theater(1~50)') return { type: 'Maple(1~50)', num: 50, classRoomType: 0, showName: 'Maple(1~50)' }
  return {
    type: classroomConfig[classRoomUniqueKey].showName,
    num: classroomConfig[classRoomUniqueKey].maxPlayer,
    classRoomType: classroomConfig[classRoomUniqueKey].classRoomType,
    showName: classroomConfig[classRoomUniqueKey].showName
  }
}

export default classroomConfig
