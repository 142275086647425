import styles from './AddClassModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import BorderInput from '../../BorderForm/BorderInput'
import useScheduleForm from '../../../CustomHooks/useScheduleForm'
import dayjs from 'dayjs'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import RemoveIcon from '@mui/icons-material/Remove'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment-timezone'
import BorderWindowSelect from '../../BorderForm/BorderWindowSelect'
import BorderGroupSelect from '../../BorderForm/BorderGroupSelect'
import { useContext } from 'react'
import { UserContext } from '../../../CustomHooks/useAuth'
import { HourMinPicker, LinkDatePicker, CustomDatePicker } from '../../TimePicker/TimePicker'
import SquareCheckbox from '../../SquareCheckbox'

const timeZoneOptions = moment.tz.names()

const Form = ({
  courseName,
  handleCourseNameChange,
  teachers,
  teacherName,
  handleTeacherNameChange,
  sDate,
  handleSdateChange,
  stim,
  handleStimChange,
  etim,
  handleEtimChange,
  handleAccept,
  frequency,
  handleFrequency,
  chosenWeekDayArr,
  handleChosenWeekDayArr,
  disableWeekDayOptions,
  eDate,
  handleEdateChange,
  disableAddBtn,
  addClass,
  classroom,
  handleClassroomChange,
  timeZone,
  handletimeZoneChange
}) => {
  const { userInfo, classroomStyleList } = useContext(UserContext)

  return (
    <div className={styles.formWrap}>
      <div className={styles.upperWrap}>
        <div className={styles.upperLeft}>
          <div className={styles.firstRow}>
            <div className={styles.courseName}>
              <BorderInput inputhVal={courseName} handleInputVal={handleCourseNameChange} placeholder='Course' isValid={true} helperText='' disabled={false} />
            </div>
            <div className={styles.teacherName}>
              <BorderWindowSelect
                label='Teacher'
                options={[...new Set(teachers)].map((item) => item.name)}
                val={teacherName}
                handleValChange={handleTeacherNameChange}
                disabled={userInfo.identity === 'teacher'}
              />
            </div>
            <div className={styles.classroom}>
              <BorderGroupSelect val={classroom} handleValChange={handleClassroomChange} height={60} />
            </div>
          </div>
          <div className={styles.secondRow}>
            <div className={styles.left}>
              <div className={styles.timePickerWrap}>
                <CustomDatePicker value={sDate} handleChange={handleSdateChange} />
              </div>
              <div className={styles.timePickerWrap}>
                <HourMinPicker label='Start Time' value={stim} otherValue={etim} handleChange={handleStimChange} />
              </div>
            </div>
            <div className={styles.timePickerWrap}>
              <HourMinPicker label='End Time' value={etim} otherValue={stim} handleChange={handleEtimChange} />
            </div>
            <div className={styles.timePickerWrap}>
              <BorderWindowSelect label='TimeZone' options={timeZoneOptions} val={timeZone} handleValChange={handletimeZoneChange} disabled={false} />
            </div>
          </div>
        </div>
        <div className={styles.upperRight}>
          <img src={classroomStyleList.find((item) => item.id == classroom).snapshot_url} alt='' />
        </div>
      </div>
      <div className={styles.thirdRow}>
        <div className={styles.left}>
          <div className={`${styles.frequency}`}>
            <BorderWindowSelect label='Frequency' options={['Repeat: Never', 'Every week', 'Every month']} val={frequency} handleValChange={handleFrequency} disabled={false} />
          </div>
          <div className={`${styles.weekdayWrap} ${disableWeekDayOptions ? styles.disabled : ''}`}>
            {chosenWeekDayArr.map((item, idx) => {
              return (
                <div key={idx} className={`${styles.weekDayBtn} ${styles.block} ${item.selected ? styles.active : ''}`} onClick={() => handleChosenWeekDayArr(item)}>
                  {item.text}
                </div>
              )
            })}
          </div>
          <div className={`${styles.timStrWrap} ${disableWeekDayOptions ? styles.disabled : ''}`}>{`From: ${dayjs(sDate).format('YYYY/MM/DD')}`}</div>
          <div className={`${styles.timStrWrap} ${disableWeekDayOptions ? styles.disabled : ''}`}>
            <div>To:</div>
            <div className={styles.linkPicker}>
              <LinkDatePicker typ='linkInput' value={eDate} handleChange={handleEdateChange} />
            </div>
          </div>
        </div>
        <div className={`${styles.addBtn} ${styles.block} ${disableAddBtn ? styles.disabled : ''}`} onClick={addClass}>
          <AddIcon />
        </div>
      </div>
    </div>
  )
}

const TableHeader = () => {
  return (
    <div className={`${styles.tableWrap} ${styles.tableHeader}`}>
      <div className={styles.left}>
        <div className={`${styles.tableItem} ${styles.courseName}`}>Course</div>
        <div className={`${styles.tableItem} ${styles.teacherName}`}>Teacher Name</div>
        <div className={`${styles.tableItem} ${styles.classroom}`}>Classroom</div>
        <div className={`${styles.tableItem} ${styles.date}`}>Date</div>
        <div className={`${styles.tableItem} ${styles.stim}`}>Start Time</div>
        <div className={`${styles.tableItem} ${styles.etim}`}>End Time</div>
        <div className={`${styles.tableItem} ${styles.frequency}`}>Repeat date</div>
      </div>
      <div className={`${styles.tableItem} ${styles.icon}`}></div>
    </div>
  )
}

const TableContent = ({ item, idx, removeSingleClass }) => {
  const { courseName, teacher, beginDate, startTime, endTime, frequency, days, disabled, showName } = item

  return (
    <div className={`${styles.tableWrap} ${styles.tableContent} ${disabled && styles.disabled}`}>
      <div className={styles.left}>
        <div className={`${styles.tableItem} ${styles.courseName}`}>{courseName}</div>
        <div className={`${styles.tableItem} ${styles.teacherName}`}>{teacher}</div>
        <div className={`${styles.tableItem} ${styles.classroom}`}>{showName}</div>
        <div className={`${styles.tableItem} ${styles.date}`}>{beginDate}</div>
        <div className={`${styles.tableItem} ${styles.stim}`}>{dayjs(startTime).format('HH:mm')}</div>
        <div className={`${styles.tableItem} ${styles.etim}`}>{dayjs(endTime).format('HH:mm')}</div>
        <div className={`${styles.tableItem} ${styles.frequency}`}>{days.join(', ') + ' / ' + frequency}</div>
      </div>
      <div className={`${styles.tableItem} ${styles.icon}`}>
        {disabled ? <CheckIcon sx={{ color: '#009135' }} /> : <RemoveIcon sx={{ color: '#FF0000' }} onClick={() => removeSingleClass(idx)} />}
      </div>
    </div>
  )
}

const AddClassModal = ({ handleModal, call_saveApi, loadingSaveList }) => {
  const {
    courseName,
    handleCourseNameChange,
    teachers,
    teacherName,
    handleTeacherNameChange,
    sDate,
    handleSdateChange,
    stim,
    handleStimChange,
    etim,
    handleEtimChange,
    handleAccept,
    frequency,
    handleFrequency,
    chosenWeekDayArr,
    handleChosenWeekDayArr,
    disableWeekDayOptions,
    eDate,
    handleEdateChange,
    disableAddBtn,
    addClass,
    classList,
    removeSingleClass,
    disableCreateBtn,
    handleClassList,
    reformSaveItem,
    classroom,
    handleClassroomChange,
    timeZone,
    handletimeZoneChange,
    orgTeachers,
    autoJoin,
    handleAutoJoin
  } = useScheduleForm()

  const saveAndSetDisabled = () => {
    if (disableCreateBtn) return
    call_saveApi(reformSaveItem())
    let arr = classList.map((item) => item)
    arr.forEach((item) => (item.disabled = true))
    handleClassList(arr)
  }

  return (
    <div className={styles.addClassModalWrap}>
      {loadingSaveList && (
        <div className={styles.loadingWrap}>
          <CircularProgress />
        </div>
      )}
      <div className={styles.modalTitle}>
        <div className={styles.title}>Create New Class</div>
        <div className={styles.cancel} onClick={() => handleModal(false)}>
          <CloseRoundedIcon sx={{ color: '#808080', '&:hover': { color: '#1452CC' } }} />
        </div>
      </div>
      <Form
        courseName={courseName}
        handleCourseNameChange={handleCourseNameChange}
        teachers={teachers}
        orgTeachers={orgTeachers}
        teacherName={teacherName}
        handleTeacherNameChange={handleTeacherNameChange}
        sDate={sDate}
        handleSdateChange={handleSdateChange}
        stim={stim}
        handleStimChange={handleStimChange}
        etim={etim}
        handleEtimChange={handleEtimChange}
        handleAccept={handleAccept}
        frequency={frequency}
        handleFrequency={handleFrequency}
        chosenWeekDayArr={chosenWeekDayArr}
        handleChosenWeekDayArr={handleChosenWeekDayArr}
        disableWeekDayOptions={disableWeekDayOptions}
        eDate={eDate}
        handleEdateChange={handleEdateChange}
        disableAddBtn={disableAddBtn}
        addClass={addClass}
        classroom={classroom}
        handleClassroomChange={handleClassroomChange}
        timeZone={timeZone}
        handletimeZoneChange={handletimeZoneChange}
        autoJoin={autoJoin}
        handleAutoJoin={handleAutoJoin}
      />
      <div className={styles.listWrap}>
        <TableHeader />
        {!!classList.length && classList.map((item, idx) => <TableContent key={idx} idx={idx} item={item} removeSingleClass={removeSingleClass} />)}
      </div>
      <div style={{ marginTop: '10px' }}>
        <SquareCheckbox val={autoJoin} handleValChange={handleAutoJoin} option='Enable students to be joined into the classroom at the scheduled time.' />
      </div>
      <div className={styles.createWrap}>
        <div className={`${styles.createBtn} ${disableCreateBtn && styles.disabled}`} onClick={saveAndSetDisabled}>
          Create New Class
        </div>
      </div>
    </div>
  )
}

export default AddClassModal
