import { Fragment, useEffect, useState } from 'react'
import useQuizList from '../../CustomHooks/useQuizList'
import styles from './Quiz.module.css'
import AddNewQuiz from './AddNewQuiz/AddNewQuiz'
import CreateNewQuestion from '../../parts/Activity/CreateNewQuestion/CreateNewQuestion'
import ImportQti from '../../parts/Activity/ImportQti/ImportQti'
import Toaster from '../../parts/Toaster'
import QuizList from './QuizList/QuizList'
import Results from './Results/Results'
import SingleResult from '../../parts/Activity/SingleResult/SingleResult'

const Quiz = () => {
  const [deleteQuizModal, setDeleteQuizModal] = useState(false)
  const [deleteQuizResultModal, setDeleteQuizResultModal] = useState(false)
  const [quizName, setQuizName] = useState('')
  const [selectedQuestionType, setSelectedQuestionType] = useState(null)
  const [actionType, setActionType] = useState('create')
  const [quizActionType, setQuizActionType] = useState('create')
  const [questionActionType, setQuestionActionType] = useState('create')
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [timerMin, setTimerMin] = useState(0)
  const [timerSec, setTimerSec] = useState(0)
  const [assignedCourse, setAssignedCourse] = useState([])
  const [questions, setQuestions] = useState([])
  const questionTypeList = ['True/False', 'Single Selection', 'Multiple Selection']

  const {
    quizList,
    quizLoadingGetList,
    questionLoadingGetList,
    page,
    quizDetail,
    courseList,
    totalPage,
    handlePage,
    handleTotalPage,
    call_getQuizApi,
    call_createQuizApi,
    call_updateQuizApi,
    call_deleteQuizApi,
    openToaster,
    toasterText,
    handleOpenToaster,
    handleCloseToaster,
    severity,
    setSeverity,
    step,
    setStep,
    call_getCourseApi,
    setCourseList,
    call_getQuizDetail,
    call_createQuestionApi,
    call_updateQuestionApi,
    call_deleteQuestionApi,
    selectedQuiz,
    setSelectedQuiz,
    selectedQuizResult,
    setSelectedQuizResult,
    call_duplicateQuestionApi,
    call_duplicateQuizApi,
    setQuizDetail,
    addQuizModal,
    setAddQuizModal,
    disableAddQuizModalBtn,
    setDisableAddQuizModalBtn,
    disableSaveQuiz,
    setDisableSaveQuiz,
    questionPage,
    questionTotalPage,
    handleQuestionPage,
    handleQuestionTotalPage,
    call_getQuizListForImportAPI,
    call_getImportHistoryAPI,
    call_importQuestionsAPI,
    quizListForImport,
    setQuizListForImport,
    call_createQuizImportApi,
    selectVal,
    setSelectVal,
    selectValObj,
    setSelectValObj,
    isUploading,
    setIsUploading,
    uploadedQuizList,
    setUploadedQuizList,
    fileName,
    setFileName,
    fileInputRef,
    historyList,
    setHistoryList,
    historyLoadingGetList,
    setQuestionLoadingGetList,
    sortBy,
    order,
    handleSortBy,
    handleOrder,
    searchVal,
    setSearchVal,
    resultLoading,
    resultsPage,
    resultsTotalPage,
    handleResultsPage,
    quizResult,
    quizResultQuestions,
    quizResultParticipants,
    call_getQuizResults,
    call_getQuizResultsQuestions,
    call_getQuizResultsParticipants,
    call_deleteQuizResultApi,
    setQuizResultParticipants,
    toSingleResultPage,
    isQuestionSaving,
    setIsQuestionSaving,
    saveImageLoading,
    generateS3ImageId,
    setSaveImageLoading
  } = useQuizList()

  return (
    <div className={styles.quizWrap}>
      {step === 1 && (
        <QuizList
          searchVal={searchVal}
          setQuizName={setQuizName}
          setActionType={setActionType}
          setStep={setStep}
          call_getQuizApi={call_getQuizApi}
          quizLoadingGetList={quizLoadingGetList}
          page={page}
          setAddQuizModal={setAddQuizModal}
          setDeleteQuizModal={setDeleteQuizModal}
          handleOpenToaster={handleOpenToaster}
          setSelectedQuiz={setSelectedQuiz}
          call_deleteQuizApi={call_deleteQuizApi}
          setSeverity={setSeverity}
          call_duplicateQuizApi={call_duplicateQuizApi}
          totalPage={totalPage}
          handlePage={handlePage}
          addQuizModal={addQuizModal}
          quizName={quizName}
          actionType={actionType}
          disableAddQuizModalBtn={disableAddQuizModalBtn}
          deleteQuizModal={deleteQuizModal}
          setSearchVal={setSearchVal}
          setDisableAddQuizModalBtn={setDisableAddQuizModalBtn}
          call_createQuizApi={call_createQuizApi}
          setQuizActionType={setQuizActionType}
          selectedQuiz={selectedQuiz}
          call_updateQuizApi={call_updateQuizApi}
          sortBy={sortBy}
          order={order}
          handleSortBy={handleSortBy}
          handleOrder={handleOrder}
          quizList={quizList}
        ></QuizList>
      )}
      {step === 2 && (
        <Fragment>
          <AddNewQuiz
            setQuizDetail={setQuizDetail}
            setCourseList={setCourseList}
            call_getQuizDetail={call_getQuizDetail}
            call_deleteQuestionApi={call_deleteQuestionApi}
            call_updateQuizApi={call_updateQuizApi}
            timerMin={timerMin}
            setTimerMin={setTimerMin}
            timerSec={timerSec}
            setTimerSec={setTimerSec}
            assignedCourse={assignedCourse}
            setAssignedCourse={setAssignedCourse}
            quizName={quizName}
            setStep={setStep}
            setQuizName={setQuizName}
            setSelectedQuestionType={setSelectedQuestionType}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            quizActionType={quizActionType}
            setQuestionActionType={setQuestionActionType}
            selectedQuiz={selectedQuiz}
            questions={questions}
            setQuestions={setQuestions}
            call_getCourseApi={call_getCourseApi}
            quizDetail={quizDetail}
            questionLoadingGetList={questionLoadingGetList}
            courseList={courseList}
            call_duplicateQuestionApi={call_duplicateQuestionApi}
            disableSaveQuiz={disableSaveQuiz}
            setDisableSaveQuiz={setDisableSaveQuiz}
            questionPage={questionPage}
            questionTotalPage={questionTotalPage}
            handleQuestionPage={handleQuestionPage}
            handleQuestionTotalPage={handleQuestionTotalPage}
            setSeverity={setSeverity}
            handleOpenToaster={handleOpenToaster}
            setQuestionLoadingGetList={setQuestionLoadingGetList}
          />
        </Fragment>
      )}
      {step === 3 && (
        <Fragment>
          <CreateNewQuestion
            typ='Quiz'
            setStep={setStep}
            isQuestionSaving={isQuestionSaving}
            setIsQuestionSaving={setIsQuestionSaving}
            selectedQuestionType={selectedQuestionType}
            questionTypeList={questionTypeList}
            setSelectedQuestionType={setSelectedQuestionType}
            actionType={quizActionType}
            questionActionType={questionActionType}
            selectedParent={selectedQuiz}
            selectedQuestion={selectedQuestion}
            call_createQuestionApi={call_createQuestionApi}
            call_updateQuestionApi={call_updateQuestionApi}
            handleOpenToaster={handleOpenToaster}
            setSeverity={setSeverity}
            saveImageLoading={saveImageLoading}
            generateS3ImageId={generateS3ImageId}
            setSaveImageLoading={setSaveImageLoading}
          />
        </Fragment>
      )}
      {step === 4 && (
        <Fragment>
          <ImportQti
            typ='Quiz'
            setStep={setStep}
            call_getParentApi={call_getQuizApi}
            call_getParentListForImportAPI={call_getQuizListForImportAPI}
            call_getImportHistoryAPI={call_getImportHistoryAPI}
            call_importQuestionsAPI={call_importQuestionsAPI}
            parentListForImport={quizListForImport}
            setParentListForImport={setQuizListForImport}
            setSeverity={setSeverity}
            handleOpenToaster={handleOpenToaster}
            call_createParentImportApi={call_createQuizImportApi}
            selectVal={selectVal}
            setSelectVal={setSelectVal}
            selectValObj={selectValObj}
            setSelectValObj={setSelectValObj}
            addModal={addQuizModal}
            setAddModal={setAddQuizModal}
            disableAddModalBtn={disableAddQuizModalBtn}
            setDisableAddModalBtn={setDisableAddQuizModalBtn}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            uploadedParentList={uploadedQuizList}
            setUploadedParentList={setUploadedQuizList}
            fileName={fileName}
            setFileName={setFileName}
            fileInputRef={fileInputRef}
            historyList={historyList}
            setHistoryList={setHistoryList}
            historyLoadingGetList={historyLoadingGetList}
            setSelectedParent={setSelectedQuiz}
          />
        </Fragment>
      )}
      {step === 6 && (
        <Fragment>
          <Results
            setStep={setStep}
            toSingleResultPage={toSingleResultPage}
            resultLoading={resultLoading}
            call_getQuizResults={call_getQuizResults}
            quizResult={quizResult}
            resultsPage={resultsPage}
            resultsTotalPage={resultsTotalPage}
            handleResultsPage={handleResultsPage}
            call_deleteQuizResultApi={call_deleteQuizResultApi}
            selectedQuizResult={selectedQuizResult}
            setSelectedQuizResult={setSelectedQuizResult}
            deleteQuizResultModal={deleteQuizResultModal}
            setDeleteQuizResultModal={setDeleteQuizResultModal}
          />
        </Fragment>
      )}
      {step === 7 && (
        <SingleResult
          typ='Quiz'
          setStep={setStep}
          selectedParent={selectedQuiz}
          resultLoading={resultLoading}
          call_getResultsQuestions={call_getQuizResultsQuestions}
          call_getResultsParticipants={call_getQuizResultsParticipants}
          questionRes={quizResultQuestions}
          participantRes={quizResultParticipants}
          setResultParticipants={setQuizResultParticipants}
        />
      )}
      <Toaster openToaster={openToaster} handleCloseToaster={handleCloseToaster} severity={severity} message={toasterText} />
    </div>
  )
}

export default Quiz
