import styles from './StudentListByClass.module.css'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import usePopover from '../../../../../CustomHooks/usePopover'
import Popover from '@mui/material/Popover'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import AddIcon from '@mui/icons-material/Add'
import PersonIcon from '@mui/icons-material/Person'
import Checkbox  from '@mui/material/Checkbox'
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Fragment, useContext, useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import useAuth, { UserContext } from '../../../../../CustomHooks/useAuth'

const CustomPopover = ({ open, anchorEl, handleClose, status, resendInvitation, handleDeleteModal, handleEditNoteModal }) => {

  const style = {
    '& .MuiPaper-root': {
      borderRadius: '8px',
      boxShadow: '0px 0px 6px #00000033',
      '& .MuiMenu-list': {
        padding: '0px',
      },
      '& .MuiMenuItem-root': {
        padding: '15px',
      },
    },
  }

  const textStyle = {
    font: 'normal normal 600 14px/18px InterMedium',
    fontSize: 'clamp(1rem, 0.4em + 0.5vw, 1.2rem)' 
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Menu
      sx={style}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      >
        { status === 'UNCONFIRMED' && <MenuItem sx={textStyle} onClick={() => { handleClose(); resendInvitation(); }}>Resend Invitation</MenuItem> }
        <MenuItem sx={textStyle} onClick={() => { handleClose(); handleEditNoteModal(true); }}>Note</MenuItem>
        <MenuItem sx={textStyle} onClick={() => { handleClose(); handleDeleteModal(true); }}>Remove User From Class</MenuItem>
      </Menu>
    </Popover>
  )
}

const TableHeader = ({ selectAll, handleSelectAllChange }) => {

  return (
    <div className={`${styles.tableWrap} ${styles.tableHeader}`}>
      {/* <div className={`${styles.tableItem} ${styles.checkbox}`}>
        <Checkbox 
          checked={selectAll}
          value="All"
          onChange={handleSelectAllChange}
          icon={<RadioButtonUncheckedRoundedIcon />}
          checkedIcon={<CheckCircleRoundedIcon />}
        />
      </div> */}
      <div className={`${styles.tableItem} ${styles.name}`}>Name</div>
      <div className={`${styles.tableItem} ${styles.email}`}>Email</div>
      <div className={`${styles.tableItem} ${styles.displayName}`}>Display Name</div>
      <div className={`${styles.tableItem} ${styles.note}`}>Note</div>
      <div className={`${styles.tableItem} ${styles.status}`}>Status</div>
      <div className={`${styles.tableItem} ${styles.action}`}>Action</div>
    </div>
  )
}

const TableContent = ({ item, handleCheckBoxChange, handleChosenAccount, resendInvitation, handleDeleteModal, handleEditNoteModal, chosenOrg }) => {

  const { open, anchorEl, openPopover, handleClose } = usePopover()
  const { checked, name, email, displayName, note, status } = item

  const handleClick = (e, item) => {
    openPopover(e)
    handleChosenAccount(item)
  }

  return (
    <div className={`${styles.tableWrap} ${styles.tableContent} ${(chosenOrg === 'Free Trial') && styles.privacy}`}>
      <div className={`${styles.tableItem} ${styles.name}`}>{ name }</div>
      <div className={`${styles.tableItem} ${styles.email}`}>{ email }</div>
      <div className={`${styles.tableItem} ${styles.displayName}`}>{ displayName }</div>
      <div className={`${styles.tableItem} ${styles.note}`}>{ note }</div>
      <div className={`${styles.tableItem} ${styles.status}`}>
        { status !== 'UNCONFIRMED' && <div><CheckIcon sx={{ color: '#009135' }}/></div> }  
        { status === 'UNCONFIRMED' && <div className={styles.pending}>pending</div> }
      </div>
      <div className={`${styles.tableItem} ${styles.action}`} onClick={(e) => handleClick(e, item)}><MoreHorizIcon /></div>
      <CustomPopover 
        open={open} 
        anchorEl={anchorEl} 
        handleClose={handleClose} 
        status={status} 
        resendInvitation={resendInvitation} 
        handleDeleteModal={handleDeleteModal} 
        handleEditNoteModal={handleEditNoteModal}
      />
    </div>
  )
}

const StudentListByClass = ({ chosenClassItem, handleAddStudentModal, allStudentListWithCourses, 
  handleDeleteModal, chosenAccount, handleChosenAccount, handleEditNoteModal, memberList }) => {

  const [selectAll, setSelectAll] = useState(false)
  const [studentListByClass, setStudentListByClass] = useState([])
  const { resendCode } = useAuth()
  const { chosenOrg } = useContext(UserContext)

  useEffect(() => {
    if(memberList.length === 0) return
    let list = chosenClassItem.studentList.map((item, idx) => {
      let obj = memberList.find(itemInAllList => itemInAllList.email.toLowerCase() === item.email.toLowerCase())
      let data = {
        checked: false,
        name: (obj.firstName) ? `${obj.firstName} ${obj.lastName}` : obj.display ,
        email: (chosenOrg === 'Free Trial') ? 'freetrial@email.com' : obj.email,
        displayName: obj.display,
        status: obj.status,
      }
      data.note = item.note
      return data
    })
    setStudentListByClass(list)
  }, [chosenClassItem, allStudentListWithCourses])

  useEffect(() => { setSelectAll(isSelectAll()) }, [studentListByClass])

  const handleSelectAllChange = (e) => {
    let checked = e.target.checked
    setSelectAll(checked)
    let arr = studentListByClass.map(item => item)
    arr.forEach(item => item.checked = checked)
    setStudentListByClass(arr)
  }

  const isSelectAll = () => {
    if(!studentListByClass.length) return false
    for(let item of studentListByClass) {
      if(!item.checked) return false
    }
    return true
  }

  const handleCheckBoxChange = (e) => {
    let itemVal = e.target.value
    let itemChecked = e.target.checked
    let arr = studentListByClass.map(item => item)
    let item = arr.find(item => itemVal === item.email)
    item.checked = itemChecked
    setStudentListByClass(arr)
  }

  const resendInvitation = () => {
    resendCode(chosenAccount.email)
  }

  return (
    <div className={styles.StudentListWrap}>
      <div className={styles.titleWrap}>
        <div className={styles.left}>
          <div className={styles.title}>Student List</div>
          <div className={styles.studentList}>
            <PersonIcon />
            <div>{ chosenClassItem.studentList.length }</div>
          </div>
        </div>
        <div className={`${styles.right} ${styles.icon} ${styles.square}`} onClick={() => handleAddStudentModal(true)}><AddIcon sx={{ transform: 'scale(1.2)' }}/></div>
      </div>
      <div className={styles.tableContainer}>
        <TableHeader selectAll={selectAll} handleSelectAllChange={handleSelectAllChange} />
        {
          studentListByClass.length === 0 && <div className={styles.hintText}>Add your member !</div>
        }
        { studentListByClass.length !== 0 && studentListByClass.map((item, idx) => (
          <TableContent 
            key={idx} 
            item={item} 
            chosenOrg={chosenOrg}
            handleDeleteModal={handleDeleteModal} 
            handleCheckBoxChange={handleCheckBoxChange} 
            handleChosenAccount={handleChosenAccount} 
            resendInvitation={resendInvitation} 
            handleEditNoteModal={handleEditNoteModal}
          />
        )) }
      </div>
    </div>
  )
}

export default StudentListByClass