import { useContext, useEffect, useState, useRef } from 'react'
import { UserContext } from './useAuth'
import usePagination from './usePagination'
import { reformOnlineList, reformOnlineObject } from '../utils/handleMemberList'
import useToaster from './useToaster'
import useWebSocket from './useWebSocket'

const useOnlineStatusList = () => {
  const [onlineStatusList, setOnlineStatusList] = useState([])
  const [waitingList, setWaitingList] = useState([])
  const [coolDown, setCoolDown] = useState(false)
  const [countDownSeconds, setCountDownSeconds] = useState(0)
  const [chosenUserID, setChosenUserID] = useState('')
  const [loadingOnlineStatusList, setLoadingOnlineStatusList] = useState(true)
  const { page, totalPage, handlePage, handleTotalPage } = usePagination()
  const cooldown_timeout = useRef(null)
  const countdown_timeout = useRef(null)
  const forceLogout_timeout = useRef(null)
  const { openToaster, toasterText, toastSeverity, handleOpenToaster, handleCloseToaster } = useToaster()
  const { userInfo } = useContext(UserContext)
  const { force_logout, wsMessage } = useWebSocket(`${process.env.REACT_APP_WS_DOMAIN}?userId=${userInfo.userID}&orgId=${userInfo.orgID}&platform=manager`)

  useEffect(() => {
    return () => {
      clearCoolDownTimer()
      clearCountDownTimer()
      clearForceLogOutTimer()
    }
  }, [])

  useEffect(() => {
    handle_ws_action(wsMessage)
  }, [wsMessage])

  const handle_ws_action = (item) => {
    console.info('handle_ws_action', item)
    if (!item.action) return
    switch (item.action) {
      case 'GetOnlineUsers':
        console.info('get online users')
        handle_get_online_users(item)
        break
      case 'connect':
        console.info('connect')
        handle_connect(item)
        break
      case 'disconnect':
        console.info('disconnect')
        handle_disconnect(item)
        break
      case 'UpdateActiveTime':
        console.info('test update active time')
        handle_update(item)
        break
      // case 'ForceLogout':
      //   console.info('forcelogout')
      //   handle_forcelogout(item)
      //   break
    }
  }

  const handle_get_online_users = (item) => {
    let o_list = reformOnlineList(item.data)
    setOnlineStatusList(o_list)
    setLoadingOnlineStatusList(false)
    setCountDownTimer()
  }

  const handle_connect = (item) => {
    let connect_obj = reformOnlineObject(item.connectUnityUserInfo)
    // console.info("connect_obj", connect_obj)
    let connect_arr = waitingList.map((item) => item)
    let connect_index = connect_arr.findIndex((item) => item.userID === connect_obj.userID)
    if (connect_index >= 0) {
      connect_arr[connect_index] = connect_obj
    } else {
      connect_arr.push(connect_obj)
    }
    setWaitingList(connect_arr)
  }

  const handle_disconnect = (item) => {
    // let disconnect_obj = reformOnlineObject(item.connectUnityUserInfo)

    let disconnect_obj = onlineStatusList.find((onlineItem) => onlineItem.userID === item.disconnectUnityUserId)

    if (!disconnect_obj) return

    if (item.disconnectUnityUserId == chosenUserID) {
      // handle force logout
      handle_forcelogout(chosenUserID)
      return
    }

    disconnect_obj.onlineStatus = 'Offline'
    // console.info("obj", disconnect_obj)
    let disconnect_arr = waitingList.map((item) => item)
    let disconnect_index = disconnect_arr.findIndex((item) => item.userID === disconnect_obj.userID)
    if (disconnect_index >= 0) {
      disconnect_arr[disconnect_index] = JSON.parse(JSON.stringify(disconnect_obj))
    } else {
      disconnect_arr.push(JSON.parse(JSON.stringify(disconnect_obj)))
    }
    setWaitingList(disconnect_arr)
  }

  const handle_update = (item) => {
    // let obj = {
    //   "action": "UpdateActiveTime",
    //   "connectUnityUserId": "9b036b16-6230-460f-b961-9bbd9ef95304",
    //   "lastActiveTime": 1675821353
    // }

    let arr = waitingList.map((item) => item)
    let connect_arr = onlineStatusList.map((item) => item)
    let index = arr.findIndex((w_item) => w_item.userID === item.connectUnityUserId)
    let c_idx = connect_arr.findIndex((c_item) => c_item.userID === item.connectUnityUserId)

    console.info(index, c_idx)

    if (index >= 0) {
      arr[index].lastActiveTime = item.lastActiveTime
    } else {
      arr.push(JSON.parse(JSON.stringify(connect_arr[c_idx])))
      arr[arr.length - 1].lastActiveTime = item.lastActiveTime
    }

    setWaitingList(arr)
  }

  const handle_forcelogout = (forced_userId) => {
    // if we cannot find user, that means that user had already disconnected from UNVIERSE, to tackle with this, we make users log out normally.
    // let forced_userId = (item.forcedUserId) ? item.forcedUserId : item.data.split(' ')[4]

    // if user who had been forced logout is not the chosenUser in this webpage, then put to waitlingList(just like another disconnection)
    // if user who had been forced out is the chosenUser, then show User result in real time

    // if(forced_userId !== chosenUserID) {
    //   handle_disconnect({ disconnectUnityUserId: forced_userId })
    //   return
    // }

    clearForceLogOutTimer()

    setOnlineStatusList((prev) => {
      let arr = prev.map((item) => item)
      let index = arr.findIndex((arrItem) => arrItem.userID == forced_userId)
      if (index >= 0) arr.splice(index, 1)
      return arr
    })

    handleOpenToaster('User successfully signed out.')
    setLoadingOnlineStatusList(false)
    setCountDownSeconds(0)
    setCountDownTimer()
    setChosenUserID('')
  }

  const addCountDownSeconds = () => {
    setCountDownSeconds((prev) => prev + 1)
    setCountDownTimer()
  }

  const setCountDownTimer = () => {
    clearCountDownTimer()
    countdown_timeout.current = setTimeout(() => {
      addCountDownSeconds()
    }, 1000)
  }

  const clearCountDownTimer = () => {
    if (countdown_timeout.current) {
      clearTimeout(countdown_timeout.current)
    }
  }

  const setCoolDownTimer = () => {
    clearCoolDownTimer()
    cooldown_timeout.current = setTimeout(() => {
      setCoolDown(false)
    }, 10000)
  }

  const clearCoolDownTimer = () => {
    if (cooldown_timeout.current) {
      clearTimeout(cooldown_timeout.current)
    }
  }

  const setForceLogOutTimer = () => {
    clearForceLogOutTimer()
    forceLogout_timeout.current = setTimeout(() => {
      handleOpenToaster('Action failed. Please try again later.', 'error')
      setLoadingOnlineStatusList(false)
      setChosenUserID('')
    }, 30000)
  }

  const clearForceLogOutTimer = () => {
    if (forceLogout_timeout.current) {
      clearTimeout(forceLogout_timeout.current)
    }
  }

  const empty_waitingList = () => {
    if (!waitingList.length) return

    clearCountDownTimer()

    setCoolDown(true)
    setLoadingOnlineStatusList(true)
    let wait_arr = waitingList.map((item) => item)
    let online_arr = onlineStatusList.map((item) => item)
    wait_arr.forEach((waitItem) => {
      let index = online_arr.findIndex((item) => item.userID === waitItem.userID)
      if (index >= 0) {
        online_arr[index] = waitItem
      } else {
        online_arr.push(waitItem)
      }
    })

    online_arr = online_arr.filter((item) => item.onlineStatus !== 'Offline')
    console.info('online_arr', online_arr)

    setWaitingList([])
    setOnlineStatusList(online_arr)
    setLoadingOnlineStatusList(false)

    setCountDownSeconds(0)
    setCoolDownTimer()
    setCountDownTimer()
  }

  return {
    countDownSeconds,
    coolDown,
    waitingList,
    empty_waitingList,
    onlineStatusList,
    setOnlineStatusList,
    loadingOnlineStatusList,
    setLoadingOnlineStatusList,
    page,
    totalPage,
    handlePage,
    handleTotalPage,
    force_logout,
    openToaster,
    toasterText,
    toastSeverity,
    handleOpenToaster,
    handleCloseToaster,
    setForceLogOutTimer,
    chosenUserID,
    setChosenUserID
  }
}

export default useOnlineStatusList
