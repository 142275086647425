import { getName } from './processName'
import dayjs from 'dayjs'

const sortList = (arr) => {
  console.info('arr', arr)
  arr.map((item) => (item.role = item.role.toLowerCase()))
  arr.sort((a, b) => {
    if (a.role === b.role) return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    // Owner >> Admin >> Teacher >> Student
    if (a.role === 'owner') return -1
    if (b.role === 'owner') return 1
    // Admin
    if (a.role === 'admin') return -1
    if (b.role === 'admin') return 1
    // Student
    if (a.role === 'student') return 1
    if (b.role === 'student') return -1
  })
  return arr
}

export const reformAccountList = (arr) => {
  let m_list = arr.map((item) => {
    let obj = {
      role: item.hasOwnProperty('identity') ? item.identity.S : 'Student',
      name: getName('nickname', item),
      display: getName('name', item),
      email: item.email.S.toLowerCase(),
      origin: item.hasOwnProperty('createFrom') ? item.createFrom.S : 'Universe',
      status: item.status.S,
      action: '',
      checked: false,
      firstName: getName('firstName', item),
      lastName: getName('lastName', item),
      userID: item.UserID.S,
      alive: false,
      observationMode: item.observation_mode.S
    }
    return obj
  })

  m_list = sortList(m_list)

  let t_list = [
    ...new Set(
      m_list
        .filter((item) => item.role.toLowerCase() !== 'student')
        .map((item) => {
          let email = item.email
          let name = item.firstName ? `${item.firstName} ${item.lastName}` : item.name
          let role = item.role
          let userID = item.userID
          return { email, name, role, userID }
        })
    )
  ]

  return { m_list, t_list }
}

export const returnIdleTimeStrings = (lastActiveTime) => {
  let seconds = dayjs().diff(dayjs.unix(lastActiveTime), 'second')
  let min = Math.floor(seconds / 60)
  let sec = seconds % 60
  let timeString = `${sec} seconds`
  if (min) {
    timeString = `${min} minutes ${sec} seconds`
  }

  console.info('dayjs', dayjs().diff(dayjs.unix(lastActiveTime), 'second'))
  return lastActiveTime == 0 ? '-' : timeString
}

export const reformOnlineList = (arr) => {
  let o_list = arr.map((item) => {
    let obj = {
      onlineStatus: 'Online',
      role: item.identity,
      name: `${item.firstName} ${item.lastName}`,
      displayName: item.nickname ? item.nickname : item.name,
      idleTime: returnIdleTimeStrings(item.lastActiveTime),
      lastActiveTime: item.lastActiveTime,
      userID: item.userId
    }
    return obj
  })

  o_list = o_list.sort((a, b) => b.lastActiveTime - a.lastActiveTime)

  return o_list
}

export const reformOnlineObject = (item) => {
  console.info(item)

  let obj = {
    onlineStatus: 'Online',
    role: item.identity,
    name: `${item.firstName} ${item.lastName}`,
    displayName: item.nickname ? item.nickname : item.name,
    idleTime: returnIdleTimeStrings(item.lastActiveTime),
    lastActiveTime: item.lastActiveTime,
    userID: item.userId
  }
  return obj
}
