// timestamp -> python timestamp

// eg. 2023 / 01 / 09
export const convertToDate = (timestamp) => {
  const milliseconds = timestamp * 1000
  return `${new Date(milliseconds).getFullYear()} / ${('0' + (new Date(milliseconds).getMonth() + 1)).slice(-2)} / ${('0' + new Date(milliseconds).getDate()).slice(-2)}`
}

// eg. 2023-01-09
export const convertToDateDash = (timestamp) => {
  const milliseconds = timestamp * 1000
  return `${new Date(milliseconds).getFullYear()}-${('0' + (new Date(milliseconds).getMonth() + 1)).slice(-2)}-${('0' + new Date(milliseconds).getDate()).slice(-2)}`
}

// eg. 09:00 AM
export const convertToAMPMtime = (timestamp, hasSpace = true) => {
  const milliseconds = timestamp * 1000
  let hours = new Date(milliseconds).getHours()
  let minutes = new Date(milliseconds).getMinutes()
  let ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  hours = ('0' + hours).slice(-2)
  minutes = minutes < 10 ? '0' + minutes : minutes
  return hasSpace ? hours + ':' + minutes + ' ' + ampm : hours + minutes + ampm
}

// eg. 09 : 00 : 40 AM
export const convertToAMPMtimeWithSeconds = (timestamp, hasSpace = true) => {
  const milliseconds = timestamp * 1000
  let hours = new Date(milliseconds).getHours()
  let minutes = new Date(milliseconds).getMinutes()
  let seconds = new Date(milliseconds).getSeconds()
  let ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  hours = ('0' + hours).slice(-2)
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds
  return hasSpace ? hours + ' : ' + minutes + ' : ' + seconds + ' ' + ampm : hours + ':' + minutes + ':' + seconds + ' ' + ampm
}

// eg. 13:30 or 13 : 00
export const convertTo24HRMode = (timeStamp, hasSpace = true) => {
  let date = new Date(timeStamp * 1000)
  let hours = date.getHours().toString().padStart(2, '0')
  let minutes = date.getMinutes().toString().padStart(2, '0')
  return hasSpace ? hours + ' : ' + minutes : hours + ':' + minutes
}
