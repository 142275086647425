import styles from './InfoBlock.module.css'
import dayjs from 'dayjs'
import { classRoomTypeMapping } from './../../../../../utils/classroomConfig'
import TooltipIcon from '../../../../../parts/TooltipIcon'
import AutoJoinIcon from '../../../../../assets/icons/AutoJoin.svg'

const InfoBlock = ({ chosenClassItem }) => {
  return (
    <div className={styles.InfoBlockWrap}>
      <div className={styles.title}>Information</div>
      <div className={styles.row}>
        <div className={styles.subtitle}>Date</div>
        <div className={styles.content}>
          {chosenClassItem.frequency === 'Never'
            ? `${dayjs.unix(chosenClassItem.firstDate).format('YYYY/MM/DD')}`
            : `${dayjs.unix(chosenClassItem.firstDate).format('YYYY/MM/DD')}~${dayjs.unix(chosenClassItem.lastDate).format('YYYY/MM/DD')}`}
        </div>
        {chosenClassItem.isAutoJoin && (
          <div style={{ marginLeft: '13px' }}>
            <TooltipIcon IconComp={AutoJoinIcon} title='Auto Join' pos='right' />
          </div>
        )}
      </div>
      <div className={styles.row}>
        <div className={styles.subtitle}>Teacher</div>
        <div className={styles.content}>{chosenClassItem.teacher}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.subtitle}>Student</div>
        <div className={styles.content}>{chosenClassItem.studentList.length}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.subtitle}>Classroom</div>
        <div className={styles.content}>
          <div className={styles.text}>{chosenClassItem.classSize}</div>
          <img className={styles.classImage} src={chosenClassItem.snapshotUrl} alt='' />
        </div>
      </div>
    </div>
  )
}

export default InfoBlock
