import styles from './StudentCard.module.css'
import AttendanceIcon from '../../../assets/icons/portfolio/blackAttendance.svg'
import RaiseHand from '../../../assets/icons/portfolio/blackHand.svg'
import QuizIcon from '../../../assets/icons/portfolio/blackQuiz.svg'
import StarIcon from '../../../assets/icons/portfolio/blackStar.svg'
import { UserContext } from '../../../CustomHooks/useAuth'
import { useContext } from 'react'

const StudentCard = ({ student }) => {
  const { userInfo } = useContext(UserContext)
  const getColor = (type) => {
    switch (type) {
      case 'onTime':
        return styles.onTime
      case 'absence':
        return styles.absence
      case 'late':
        return styles.late
      case 'earlyLeave':
        return styles.early
      default:
        return styles.lateAndEarlyLeave
    }
  }

  const onImageError = (e) => {
    e.target.onerror = null // prevent infinite loop if default image is missing
    e.target.src = 'https://i.imgur.com/sPt8i78.png'
  }

  return (
    <div className={styles.card}>
      <img
        src={`https://savepicture.s3.eu-central-1.amazonaws.com/profile_picture/${student.studentId}--${userInfo.orgID}.png`}
        alt='avatar'
        className={styles.avatarImg}
        onError={(e) => onImageError(e)}
      />
      <h1>{student.name}</h1>
      <div className={styles.content}>
        <div className={styles.attendanceWrap}>
          <img alt='attendance' src={AttendanceIcon} />
          <div className={styles.attendance}>
            <div>
              <div className={`${styles.dot} ${getColor(student.attendance.type)}`}></div>
              {student.attendance.type === 'onTime' && <p>On Time</p>}
              {student.attendance.type === 'absence' && <p>Absence</p>}
              {student.attendance.type === 'lateAndEarlyLeave' && <p>Late and early leave</p>}
              {student.attendance.type === 'earlyLeave' && (
                <p>
                  <span className={styles.minutes}>{student.attendance.minutes}</span> {<span>min{student.attendance.minutes !== 1 && <span>s</span>}</span>} Left Early
                </p>
              )}
              {student.attendance.type === 'late' && (
                <p>
                  <span className={styles.minutes}>{student.attendance.minutes}</span> {<span>min{student.attendance.minutes !== 1 && <span>s</span>}</span>} Late
                </p>
              )}
            </div>
          </div>
        </div>
        <div>
          <img alt='raiseHand' src={RaiseHand} />
          <p>
            <span>{student.raiseHand}</span>Time{student.raiseHand !== 1 && `s`}
          </p>
        </div>
        <div>
          <img alt='points' src={StarIcon} />
          <p>
            <span>{student.points}</span>Point{student.points !== 1 && `s`}
          </p>
        </div>
        <div>
          <img alt='quizCorrectRate' src={QuizIcon} />
          <p>
            <span>{student.quizAccuracyRate}%</span>Accuracy
          </p>
        </div>
      </div>
    </div>
  )
}

export default StudentCard
