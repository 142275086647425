import { Fragment, useEffect, useState } from 'react'
import styles from './LtiCopyLinkModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import BorderInput from '../../BorderForm/BorderInput'

const LtiCopyLinkModal = ({ selectedAssignment, handleLtiCopyLinkModal, onCopyDeployId }) => {

  const [links, setLinks] = useState([])

  useEffect(() => {
    if(selectedAssignment.canvasDeploymentId && selectedAssignment.blackboardDeploymentId) {
      setLinks(['Canvas', 'Blackboard'])
      return
    }
    if(selectedAssignment.canvasDeploymentId) {
      setLinks(['Canvas'])
      return
    }
    if(selectedAssignment.blackboardDeploymentId) {
      setLinks(['Blackboard'])
      return
    }
    setLinks([])
  }, [selectedAssignment])

  return (
    <div className={styles.modalWrap}>
      <div className={styles.titleWrap}>
        <div className={styles.title}>Copy Sharing Link</div>
        <div><CloseRoundedIcon onClick={() => handleLtiCopyLinkModal(false)} sx={{ transform: 'scale(1.5)', cursor: 'pointer', color: '#808080', '&:hover': { color: '#1452CC' }}}/>
        </div>
      </div>
      {
        (links.length == 0) ? (
          <Fragment>
            <div className={styles.content}>
                Your organization has not applied for UNIVERSE LTI on any platform
                Please consult your organization's administrator or our customer service personnel.
            </div>
            <div className={styles.btn} onClick={() => handleLtiCopyLinkModal(false)}>confirm</div>
          </Fragment>
        ) : (
          <Fragment>
            {
              links.map((platform, idx) => (
                <div key={idx}>
                  <div style={{ font: 'normal normal 16px/20px InterMedium', marginBottom: '6px' }}>Copy to { platform } Assignment</div>
                  <div style={{ display: 'flex', gap: '12px' }}>
                    <div style={{ width: 'calc(100% - 144px)' }}>
                      <BorderInput readOnly={true} inputhVal={selectedAssignment[`${platform.toLowerCase()}DeploymentId`]} handleInputVal={() => {}} placeholder="" isValid={true} helperText="" disabled={false} />
                    </div>
                    <div className={styles.btn} style={{ width: '144px' }} onClick={() => { onCopyDeployId(platform); handleLtiCopyLinkModal(false); } }>Copy Sharing Link</div>
                  </div>
                </div>
              ))
            }
          </Fragment>
        )
      }
    </div>
  )
}

export default LtiCopyLinkModal