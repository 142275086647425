import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"

const Modal = ({children}) => {

	const elRef = useRef(null)
	if(!elRef.current) {
		elRef.current = document.createElement('div')
	}

	useEffect(() => {
		const modalRoot = document.querySelector('#modal')
		modalRoot.appendChild(elRef.current)
    document.body.style.overflow = 'hidden'

		return () => {
      document.body.style.overflow = 'unset'
			modalRoot.removeChild(elRef.current)
		}
	}, [])
	return createPortal(<div>{children}</div>, elRef.current)
}

export default Modal