import { useState, useEffect, useContext } from 'react'
import SearchInput from '../../../parts/SearchInput'
import styles from './MainPage.module.css'
import ViewIcon from '../../../assets/icons/view.svg'
import ExportIcon from '../../../assets/icons/export.svg'
import CustomAccordion from '../../../parts/Accordion/Accordion'
import ExcelJs from 'exceljs'
import jsZip from 'jszip'
import { saveAs } from 'file-saver'
import CustomPagination from '../../../parts/CustomPagination'
import CircularProgress from '@mui/material/CircularProgress'
import { convertToDate, convertToAMPMtime, convertTo24HRMode, convertToDateDash, convertToAMPMtimeWithSeconds } from '../../../utils/time'
import { getCourseExcel } from '../../../api'
import { UserContext } from '../../../CustomHooks/useAuth'

const Header = () => {
  return (
    <>
      <div className={styles.titleWrap}>
        <div className={styles.tableItem}>Course Name</div>
        <div className={styles.tableItem}>Report</div>
      </div>
    </>
  )
}

const AccordionTitle = ({ course, setPortfolioFilterList, index, setSeverity, handleOpenToaster, page }) => {
  const { userInfo } = useContext(UserContext)

  const onExportReport = async (e) => {
    e.stopPropagation()

    setPortfolioFilterList((prev) => {
      const originList = JSON.parse(JSON.stringify(prev))
      const newList = originList.map((item, idx) => {
        if (index + (page - 1) * 14 === idx) {
          return Object.assign(item, { exporting: true })
        } else {
          return item
        }
      })
      return newList
    })

    let data = {
      courseId: course.courseId
    }

    getCourseExcel(data).then(async (res) => {
      let result = res.data
      const zip = new jsZip()

      try {
        for (const schedule of result.schedule) {
          const workbook = new ExcelJs.Workbook()
          const courseSheet = workbook.addWorksheet(`COURSE-${convertToDateDash(schedule.begin)}`)

          let courseRows = []

          for (const quiz of schedule.quizList) {
            for (const question of quiz.questions) {
              courseRows.push([
                course.courseName,
                convertToDateDash(schedule.begin),
                `${convertToAMPMtime(schedule.begin)}-${convertToAMPMtime(schedule.end)} UTC${course.gmtOffset}`,
                schedule.totalStudents === 0 ? 0 : Math.round((schedule.attendants / schedule.totalStudents) * 100),
                schedule.attendants,
                schedule.raiseHandTimes,
                schedule.raiseHandStudents,
                quiz.name,
                quiz.answerRate,
                quiz.accuracyRate,
                question.content,
                question.answerRate,
                question.accuracyRate
              ])
            }
          }

          if (!courseRows.length) {
            courseRows.push([
              course.courseName,
              convertToDateDash(schedule.begin),
              `${convertToAMPMtime(schedule.begin)}-${convertToAMPMtime(schedule.end)} UTC${course.gmtOffset}`,
              schedule.totalStudents === 0 ? 0 : Math.round((schedule.attendants / schedule.totalStudents) * 100),
              schedule.attendants,
              schedule.raiseHandTimes,
              schedule.raiseHandStudents,
              'No records found',
              '-',
              '-',
              '-',
              '-',
              '-'
            ])
          }
          courseSheet.addTable({
            name: 'table1',
            ref: 'A1',
            columns: [
              { name: 'Course Name' },
              { name: 'Course Date' },
              { name: 'Course Time' },
              { name: 'Attendance (Percentage)' },
              { name: 'Attendance (Numbers)' },
              { name: 'Times of Raise Hand' },
              { name: 'Students of Raise Hand' },
              { name: 'Quiz Name' },
              { name: 'Quiz Answer Rate' },
              { name: 'Quiz Accuracy Rate' },
              { name: 'Question' },
              { name: 'Question Answer Rate' },
              { name: 'Question Accuracy Rate' }
            ],
            rows: courseRows
          })

          courseSheet.columns.forEach(function (column, i) {
            let maxLength = 0
            column['eachCell']({ includeEmpty: true }, function (cell) {
              var columnLength = cell.value ? cell.value.toString().length : 15
              if (columnLength > maxLength) {
                maxLength = columnLength
              }
            })
            column.width = maxLength < 15 ? 15 : maxLength
          })

          let studentRows = []
          const getAttendanceType = (type) => {
            switch (type) {
              case 'onTime':
                return 'On Time'
              case 'absence':
                return 'Absence'
              case 'late':
                return 'Late'
              case 'earlyLeave':
                return 'Early Leave'
              default:
                return 'Late and Early Leave'
            }
          }

          const getAnswer = (answer) => {
            switch (answer) {
              case true:
                return 'Correct'
              case false:
                return 'Incorrect'
              default:
                return 'Null'
            }
          }

          for (const student of schedule.studentList) {
            let raiseHandTimestamp = student.raiseHand.timeStamp
              .map((item) => {
                return `${convertTo24HRMode(item.time, false)}(${item.count})`
              })
              .join(', ')
            let pointTimestamp = student.points.timeStamp
              .map((item) => {
                return `${convertTo24HRMode(item.time, false)}(${item.count})`
              })
              .join(', ')
            for (const quiz of student.quizList) {
              for (const question of quiz.questions) {
                studentRows.push([
                  student.name,
                  student.joinTime === '-' ? '-' : `${convertToDate(student.joinTime)} ${convertToAMPMtimeWithSeconds(student.joinTime)}`,
                  student.lastSeen === '-' ? '-' : `${convertToDate(student.lastSeen)} ${convertToAMPMtimeWithSeconds(student.lastSeen)}`,
                  getAttendanceType(student.type),
                  student.raiseHand.total,
                  student.raiseHand.total === 0 ? 'No records found' : raiseHandTimestamp,
                  student.points.total,
                  student.points.total === 0 ? 'No records found' : pointTimestamp,
                  quiz.name,
                  quiz.accuracyRate,
                  question.content,
                  getAnswer(question.answer)
                ])
              }
            }

            if (!student.quizList.length) {
              studentRows.push([
                student.name,
                student.joinTime === '-' ? '-' : `${convertToDate(student.joinTime)} ${convertToAMPMtimeWithSeconds(student.joinTime)}`,
                student.lastSeen === '-' ? '-' : `${convertToDate(student.lastSeen)} ${convertToAMPMtimeWithSeconds(student.lastSeen)}`,
                getAttendanceType(student.type),
                student.raiseHand.total,
                student.raiseHand.total === 0 ? 'No records found' : raiseHandTimestamp,
                student.points.total,
                student.points.total === 0 ? 'No records found' : pointTimestamp,
                'No records found',
                '-',
                '-',
                '-'
              ])
            }
          }

          const studentSheet = workbook.addWorksheet(`STUDENT-${convertToDateDash(schedule.begin)}`)
          studentSheet.addTable({
            name: 'table2',
            ref: 'A1',
            columns: [
              { name: 'Student Name' },
              { name: 'Join Time' },
              { name: 'Last Seen' },
              { name: 'Attendance Status' },
              { name: 'Times of Raise Hand' },
              { name: 'Timestamp of Raise Hand' },
              { name: 'Points' },
              { name: 'Timestamp of Getting Points' },
              { name: 'Quiz Name' },
              { name: 'Accuracy Rate' },
              { name: 'Question' },
              { name: 'Answer' }
            ],
            rows: studentRows.length === 0 ? [new Array(9).fill('')] : studentRows
          })

          studentSheet.columns.forEach(function (column, i) {
            let maxLength = 0
            column['eachCell']({ includeEmpty: true }, function (cell) {
              var columnLength = cell.value ? cell.value.toString().length : 15
              if (columnLength > maxLength) {
                maxLength = columnLength
              }
            })
            column.width = maxLength < 15 ? 15 : maxLength
          })

          const blob = await workbook.xlsx.writeBuffer()
          const blobData = new Blob([blob], {
            type: 'application/vnd.ms-excel;charset=utf-8;'
          })
          zip.file(`${course.courseName.replaceAll('/', '-')}-${convertToDateDash(schedule.begin)}-${convertToAMPMtime(schedule.begin, false)}.xlsx`, blobData)
        }

        zip.generateAsync({ type: 'blob' }).then(function (content) {
          // see FileSaver.js
          saveAs(content, `${course.courseName.replaceAll('/', '-')}-${userInfo.nickname.replaceAll('/', '-')}.zip`)
          setPortfolioFilterList((prev) => {
            const originList = JSON.parse(JSON.stringify(prev))
            const newList = originList.map((item, idx) => {
              if (index + (page - 1) * 14 === idx) {
                return Object.assign(item, { exporting: false })
              } else {
                return item
              }
            })
            return newList
          })
        })
      } catch (err) {
        setSeverity('error')
        handleOpenToaster('Failed to export report.')
        setPortfolioFilterList((prev) => {
          const originList = JSON.parse(JSON.stringify(prev))
          const newList = originList.map((item, idx) => {
            if (index === idx) {
              return Object.assign(item, { exporting: false })
            } else {
              return item
            }
          })
          return newList
        })
      }
    })
  }

  return (
    <div className={styles.summary}>
      <div className={styles.title}>
        <p>{course.courseName}</p>
      </div>
      {course.exporting ? (
        <div className={styles.export} onClick={onExportReport}>
          <CircularProgress size={20} />
          <span className={styles.text}>Downloading...</span>
        </div>
      ) : (
        <div className={styles.export} onClick={onExportReport}>
          <img alt='export' src={ExportIcon} />
          <p>Export Report</p>
        </div>
      )}
    </div>
  )
}

const AccordionDetails = ({ course, navigateToDetail }) => {
  return (
    <>
      {course.schedule.map((item, index) => {
        return (
          <div className={styles.details} key={index}>
            <div className={styles.left}>
              <div>
                <p>{convertToDate(item.begin)} </p>
                <p>
                  {convertToAMPMtime(item.begin)} - {convertToAMPMtime(item.end)} {`(UTC${course.gmtOffset})`}
                </p>
              </div>
              <div className={styles.data}>
                <div>
                  <p>Attendance</p>
                  <div>
                    <span className={styles.largeSize}>{item.totalStudents ? Math.round((item.attendants / item.totalStudents) * 100) : 0}%</span>
                    <span className={styles.smallSize}>{`(${item.attendants}`}</span>
                    <span className={styles.smallSize}>/</span>
                    <span className={styles.smallSize}>{`${item.totalStudents})`}</span>
                  </div>
                </div>
                <div>
                  <p>Raise Hand</p>
                  <div className={styles.cardArea}>
                    <div>
                      <span className={styles.largeSize}>{item.raiseHandTimes}</span>
                      <span className={styles.smallSize}>time{item.raiseHandTimes !== 1 && `s`}</span>
                    </div>
                    <div>
                      <span className={styles.largeSize}>{item.raiseHandStudents}</span>
                      <span className={styles.smallSize}>student{item.raiseHandStudents !== 1 && `s`}</span>
                    </div>
                  </div>
                </div>

                <div>
                  <p>Quiz</p>
                  <div className={styles.cardArea}>
                    <div>
                      <span className={styles.largeSize}>{item.totalQuiz}</span>
                      <span className={styles.smallSize}>quiz{item.totalQuiz !== 1 && `zes`}</span>
                    </div>
                    <div>
                      <span className={styles.largeSize}>{item.accuracyRate}%</span>
                      <span className={styles.smallSize}>accuracy rate</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.right} onClick={() => navigateToDetail(course, index)}>
              <img alt='view-detail' src={ViewIcon} />
              <p>View Detail</p>
            </div>
          </div>
        )
      })}
    </>
  )
}

const Content = ({ portfolioFilterList, page, setPortfolioFilterList, navigateToDetail, setSeverity, handleOpenToaster }) => {
  const handleAccordionChange = (e, index) => {
    e.stopPropagation()
    setPortfolioFilterList((prev) => {
      const originList = JSON.parse(JSON.stringify(prev))
      const newList = originList.map((item, idx) => {
        if (index + (page - 1) * 14 === idx) {
          return Object.assign(item, { expanded: !item.expanded })
        } else {
          return Object.assign(item, { expanded: false })
        }
      })
      return newList
    })
  }

  const detailsStyles = {
    maxHeight: '577px',
    overflowY: 'scroll'
  }

  return (
    <>
      {portfolioFilterList
        .filter((item, index) => index >= (page - 1) * 14 && index <= (page - 1) * 14 + 13)
        .map((item, index) => {
          return (
            <div className={styles.accordion} key={index}>
              <CustomAccordion
                expanded={item.expanded}
                handleAccordionChange={(e) => handleAccordionChange(e, index)}
                title={
                  <AccordionTitle
                    page={page}
                    index={index}
                    course={item}
                    setPortfolioFilterList={setPortfolioFilterList}
                    setSeverity={setSeverity}
                    handleOpenToaster={handleOpenToaster}
                  />
                }
                details={<AccordionDetails course={item} navigateToDetail={navigateToDetail} />}
                detailsStyles={detailsStyles}
              />
            </div>
          )
        })}
    </>
  )
}

const MainPage = ({
  portfolioList,
  handleTotalPage,
  handlePage,
  totalPage,
  page,
  setStep,
  call_getPortfolioListApi,
  setSelectedClassList,
  setSelectedClassIndex,
  loadingMainPage,
  setSeverity,
  handleOpenToaster
}) => {
  const [searchVal, setSearchVal] = useState('')
  const [portfolioFilterList, setPortfolioFilterList] = useState([])
  const handleSearchVal = (e) => {
    setSearchVal(e.target.value)
  }

  const filterPortfolioList = () => {
    let arr = portfolioList.filter((item) => item.courseName.toLowerCase().includes(searchVal.toLowerCase()))
    arr.map((item, index) => {
      return index === 0 ? Object.assign(item, { expanded: true, exporting: false }) : Object.assign(item, { expanded: false, exporting: false })
    })
    setPortfolioFilterList(arr)
    handleTotalPage(Math.max(Math.ceil(arr.length / 14), 1))
    handlePage(1)
  }

  const navigateToDetail = (course, index) => {
    setStep(2)
    let classInfo = course.schedule.map((singleSchedule) => {
      return Object.assign(singleSchedule, { courseName: course.courseName, courseId: course.courseId, gmtOffset: course.gmtOffset })
    })
    setSelectedClassList(classInfo)
    setSelectedClassIndex(index)
  }

  useEffect(() => {
    call_getPortfolioListApi()
  }, [])

  useEffect(() => {
    filterPortfolioList()
  }, [searchVal, portfolioList])

  return (
    <>
      <div className={styles.searchInputWrap}>
        <SearchInput searchVal={searchVal} handleSearchVal={handleSearchVal} placeholder='Search Course' />
      </div>
      <div className={styles.tableContainer}>
        <Header />
        {loadingMainPage && (
          <div
            style={{
              height: 'calc(100vh - 330px)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loadingMainPage && (
          <Content
            portfolioFilterList={portfolioFilterList}
            setPortfolioFilterList={setPortfolioFilterList}
            page={page}
            setStep={setStep}
            navigateToDetail={navigateToDetail}
            setSeverity={setSeverity}
            handleOpenToaster={handleOpenToaster}
          />
        )}

        {!loadingMainPage && portfolioFilterList.length === 0 && (
          <div className={styles.noResult}>
            <h2>No records found</h2>
            <p>Sorry, there is no information available at the moment. Once you start your UNIVERSE course, you will be able to see your results here.</p>
          </div>
        )}

        {totalPage > 1 && (
          <div className={styles.paginationWrap}>
            <CustomPagination page={page} totalPage={totalPage} handlePage={handlePage} />
          </div>
        )}
      </div>
    </>
  )
}

export default MainPage
