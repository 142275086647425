import styles from './AddQuestionModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import TrueFalseIcon from '../../../assets/icons/quiz/trueFalse.svg'
import SingleSelectionIcon from '../../../assets/icons/quiz/singleSelection.svg'
import MultipleSelectionIcon from '../../../assets/icons/quiz/multipleSelection.svg'
import AddIcon from '../../../assets/icons/add.svg'
import { useState } from 'react'

const AddQuestionModal = ({ setStep, setNewQuestionModal, setSelectedQuestionType }) => {
  const [isTFHover, setIsTFHover] = useState(false)
  const [isSingleHover, setIsSingleHover] = useState(false)
  const [isMultiHover, setIsMultiHover] = useState(false)
  const navigateTo = (questionType) => {
    setStep(3)
    setSelectedQuestionType(questionType)
  }

  return (
    <div className={styles.modalWrap}>
      <div className={styles.titleWrap}>
        <div className={styles.title}>Create New Question</div>
        <div className={styles.cancel} onClick={() => setNewQuestionModal(false)}>
          <CloseRoundedIcon sx={{ color: '#808080', '&:hover': { color: '#1452CC' } }} />
        </div>
      </div>
      <div className={styles.btnWrap}>
        <button
          onClick={() => navigateTo('True/False')}
          onMouseEnter={() => setIsTFHover(true)}
          onMouseLeave={() => setIsTFHover(false)}
          className={isTFHover ? styles.hover : styles.normal}
        >
          <img alt='True/False' src={isTFHover ? AddIcon : TrueFalseIcon} />
          {isTFHover ? <p>Add</p> : <p>True/False</p>}
        </button>
        <button
          onClick={() => navigateTo('Single Selection')}
          onMouseEnter={() => setIsSingleHover(true)}
          onMouseLeave={() => setIsSingleHover(false)}
          className={isSingleHover ? styles.hover : styles.normal}
        >
          <img alt='Single Selection' className={isSingleHover ? styles.hover : styles.normal} src={isSingleHover ? AddIcon : SingleSelectionIcon} />
          {isSingleHover ? <p>Add</p> : <p>Single Selection</p>}
        </button>
        <button
          onClick={() => navigateTo('Multiple Selection')}
          onMouseEnter={() => setIsMultiHover(true)}
          onMouseLeave={() => setIsMultiHover(false)}
          className={isMultiHover ? styles.hover : styles.normal}
        >
          <img alt='Multiple Selection' className={isMultiHover ? styles.hover : styles.normal} src={isMultiHover ? AddIcon : MultipleSelectionIcon} />
          {isMultiHover ? <p>Add</p> : <p>Multiple Selection</p>}
        </button>
      </div>
    </div>
  )
}

export default AddQuestionModal
