import { useContext, useEffect, useState } from 'react'
import { filterName } from '../utils/processName'
import { UserContext } from './useAuth'
import usePagination from './usePagination'

const useMemberListFilter = () => {
  // const [page, setPage] = useState(1)
  // const [totalPage, setTotalPage] = useState(1)
  const { page, totalPage, handlePage, handleTotalPage } = usePagination()
  const [searchVal, setSearchVal] = useState('')
  const [role, setRole] = useState('All Roles')
  const [createFrom, setCreateFrom] = useState('All Origins')
  const [selectedAccounts, setSelectedAccounts] = useState(0)
  const [memberListByFilter, setMemberListByFilter] = useState([])

  const { userInfo } = useContext(UserContext)

  useEffect(() => {
    countSelectedAccount()
  }, [memberListByFilter])

  const countSelectedAccount = () => {
    let arr = memberListByFilter.filter((item) => item.checked)
    setSelectedAccounts(arr)
  }

  // const handlePage = (p) => {
  //   // if(p < 1) return
  //   setPage(p)
  // }

  const handleSearchVal = (e) => {
    setSearchVal(e.target.value)
  }

  const handleRoleChange = (e) => {
    setRole(e.target.value)
  }

  const handleCreateFromChange = (e) => {
    setCreateFrom(e.target.value)
  }

  const filterMemberList = (memberList, changePage) => {
    if (memberList.length === 0) {
      setMemberListByFilter([])
      handleTotalPage(1)
      handlePage(1)
      return
    }
    const arr = memberList.filter((item, idx) => {
      let roleBoolean = false
      let originBoolean = false
      let searchBoolean = false
      searchBoolean = searchVal === '' || filterName(item).toLowerCase().includes(searchVal.toLowerCase()) || item.email.toLowerCase().includes(searchVal.toLowerCase())
      roleBoolean = role === 'All Roles' || role.toLowerCase() === item.role.toLowerCase()
      originBoolean = createFrom === 'All Origins' || createFrom.toLowerCase() === item.origin.toLowerCase()
      return roleBoolean && originBoolean && searchBoolean
    })
    setMemberListByFilter(arr)
    handleTotalPage(Math.max(Math.ceil(arr.length / 14), 1))
    if (changePage) handlePage(1)
  }

  const filterMemberListByPage = (memberList, page) => {
    if (memberList.length === 0) return
    const arr = memberList.filter((item, idx) => {
      let roleBoolean = false
      let originBoolean = false
      let searchBoolean = false
      let pageBoolean = false
      searchBoolean = searchVal === '' || filterName(item).toLowerCase().includes(searchVal.toLowerCase()) || item.email.toLowerCase().includes(searchVal.toLowerCase())
      roleBoolean = role === 'All Roles' || role.toLowerCase() === item.role.toLowerCase()
      originBoolean = createFrom === 'All Origins' || createFrom.toLowerCase() === item.origin.toLowerCase()
      pageBoolean = idx >= (page - 1) * 14 && idx <= (page - 1) * 14 + 13
      return roleBoolean && originBoolean && searchBoolean && pageBoolean
    })
    setMemberListByFilter(arr)
  }

  return {
    page,
    handlePage,
    role,
    handleRoleChange,
    searchVal,
    handleSearchVal,
    createFrom,
    handleCreateFromChange,
    memberListByFilter,
    filterMemberList,
    filterMemberListByPage,
    selectedAccounts,
    totalPage
  }
}

export default useMemberListFilter
