import styles from './DeleteInstanceModal.module.css'

const DeleteInstanceModal = ({ handleDeleteModal, removeConfig }) => {

  const remove = () => {
    removeConfig()
    handleDeleteModal(false)
  }

  return (
    <div className={styles.wrap}>
      <div style={{ font: 'normal normal 600 24px/29px InterRegular', marginBottom: '19px' }}>Delete Instance</div>
      <div style={{ font: 'normal normal normal 18px/36px InterRegular' }}>Are you sure you want to delete this instance?</div>
      <div className={styles.btnWrap}>
        <button className={`${styles.btn} ${styles.delete}`} onClick={remove}>Delete</button>
        <button className={`${styles.btn} ${styles.cancel}`} onClick={() => handleDeleteModal(false)}>Cancel</button>
      </div>
    </div>
  )
}

export default DeleteInstanceModal