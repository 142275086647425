import styles from './AddUniverseMemberModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import BorderInput from '../../BorderForm/BorderInput'
import BorderWindowSelect from '../../BorderForm/BorderWindowSelect'
import isEmail from 'validator/es/lib/isEmail'
import { useEffect, useState } from 'react'
import AddMemberModal from '../AddMemberModal/AddMemberModal'
import CircularProgress from '@mui/material/CircularProgress'
import useMemberList from '../../../CustomHooks/useMemberList'

const chosenSlot = {
  'Admin': { chosen: 0, saved: 0, left: 0 },
  'Teacher': { chosen: 0, saved: 0, left: 0 },
  'Student': { chosen: 0, saved: 0, left: 0 },
}

const useFormData = (slotObj, orgMemberList) => {
  const [role, setRole] = useState('Admin')
  const [name, setName] = useState('')
  const [firstName, setfirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [email, setEmail] = useState('')
  const [disableAddBtn, setDisableAddBtn] = useState(true)
  const [disableAddAccountBtn, setDisableAddAccountBtn] = useState(true)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [emailHelperText, setEmailHelperText] = useState('')
  const [memberList, setMemberList] = useState([])
  const [chosenSlotObj, setChosenSlotObj] = useState(chosenSlot)

  useEffect(() => {
    let errorText = checkIsEmail() ? (checkSameEmail() ? 'Repeated Email' : '') : 'Invalid Email.'
    setIsEmailValid(!errorText)
    setEmailHelperText(errorText)
  }, [email, memberList])

  useEffect(() => {
    setDisableAddBtn(checkFormat()) 
  }, [role, firstName, lastName, email, isEmailValid, memberList])

  useEffect(() => { setDisableAddAccountBtn(checkDisableAddAccount()) }, [memberList])

  useEffect(() => {
    handleChosenSlotObj()
  }, [slotObj, memberList])

  const handleChosenSlotObj = () => {
    let obj = JSON.parse(JSON.stringify(chosenSlot))
    let role = ['Admin', 'Teacher', 'Student']
    role.forEach(roleText => {
      obj[roleText].left = slotObj[roleText].whole - slotObj[roleText].occupied
    })
    memberList.forEach(item => {
      if(item.disabled) {
        obj[item.role].saved++
      } else {
        obj[item.role].chosen++
      }
    })
    setChosenSlotObj(obj)
  }

  const checkDisableAddAccount = () => {
    for(let item of memberList) {
      if(!item.disabled) return false
    }
    return true
  }

  const checkFormat = () => {
    return !role || !firstName || !lastName || !email || !isEmailValid || memberList.find(item => item.email.toLowerCase() === email.toLowerCase())
  }

  /*
  cannot fill name field with spaces
  */
  const checkEmptyOrNot = (val) => {
    return (!val.trim()) ? '' : val
  }

  const handleRoleChange = (e, value) => {
    setRole(value)
  }

  const handlefirstNameChange = (e) => {
    let val = checkEmptyOrNot(e.target.value)
    setfirstName(val)
  }

  const handlelastNameChange = (e) => {
    let val = checkEmptyOrNot(e.target.value)
    setlastName(val)
  }

  const handleNameChange = (e) => {
    let val = checkEmptyOrNot(e.target.value)
    setName(val)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  const removeMemberList = (idx) => {
    let arr = memberList.map(item => item)
    arr.splice(idx, 1)
    setMemberList(arr)
  }

  const setAllItemDisabled = () => {
    let arr = memberList.map(item => item)
    arr.forEach(item => item.disabled = true)
    setMemberList(arr)
  }

  const handleMemberList = async () => {
    if(disableAddBtn) return
    if((chosenSlotObj[role].chosen - chosenSlotObj[role].left) === 0) return
    if(chosenSlotObj[role].left <= 0) return

    // 同組織不能有重複的使用者(同使用者可以在不同組織裡出現)
    if(orgMemberList.find(member => member.email.toLowerCase() === email.toLowerCase())) {
      setIsEmailValid(false)
      setEmailHelperText("This account has already been created!")
      return
    }

    let arr = memberList.map(item => item)
    /*
      trim name spaces when calling api
    */
    let newObj = {
      role,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email,
      disabled: false
    }

    newObj.name = `${newObj.firstName} ${newObj.lastName}`
    arr.push(newObj)
    setMemberList(arr)
    setEmail('')
    setfirstName('')
    setlastName('')
  }

  const checkSameEmail = () => {
    return memberList.find(item => item.email.toLowerCase() === email.toLowerCase())
  }

  const checkIsEmail = () => {
    if(!email) return true
    return isEmail(email)
  }

  return { role, handleRoleChange, name, handleNameChange, 
    firstName, handlefirstNameChange, lastName, handlelastNameChange, 
    email, handleEmailChange, isEmailValid, emailHelperText, memberList, 
    removeMemberList, setAllItemDisabled, handleMemberList, disableAddBtn, disableAddAccountBtn, chosenSlotObj }

}

const UtilityRow = ({ role, handleRoleChange, name, handleNameChange, email, 
  firstName, handlefirstNameChange, lastName, handlelastNameChange, 
  handleEmailChange, isEmailValid, emailHelperText, disableAddBtn, handleMemberList, chosenSlotObj }) => {

  return (
    <div className={styles.utilityRowWrap}>
      <div className={styles.role}>
        <BorderWindowSelect options={['Admin', 'Teacher', 'Student']} val={role} handleValChange={handleRoleChange} typ="inset" disabled={false} />
      </div>
      <div className={styles.name}>
        <BorderInput inputhVal={firstName} handleInputVal={handlefirstNameChange} placeholder="First Name" isValid={true} helperText="" disabled={false} />
      </div>
      <div className={styles.name}>
        <BorderInput inputhVal={lastName} handleInputVal={handlelastNameChange} placeholder="Last Name" isValid={true} helperText="" disabled={false} />
      </div>
      <div className={styles.email}>
        <BorderInput inputhVal={email} handleInputVal={handleEmailChange} placeholder="Email" isValid={isEmailValid} helperText={emailHelperText} disabled={false}/>
      </div>
      <div className={`${styles.addBtn} ${disableAddBtn || ((chosenSlotObj[role].chosen - chosenSlotObj[role].left) === 0) || (chosenSlotObj[role].left <= 0) ? styles.disabled : ''}`} onClick={handleMemberList}><AddIcon /></div>
    </div>
  )
}

const TableHeader = () => {
  return (
    <div className={`${styles.tableWrap} ${styles.tableHeader}`}>
      <div className={`${styles.left}`}>
        <div className={`${styles.tableItem} ${styles.role}`}>Role</div>
        <div className={`${styles.tableItem} ${styles.name}`}>Name</div>
        <div className={`${styles.tableItem} ${styles.email}`}>Email</div>
      </div>
      <div className={`${styles.tableItem} ${styles.delete}`}></div>
    </div>
  )
}

const TableContent = ({ item, index, removeMemberList }) => {

  const {role, name, email, disabled} = item

  return (
    <div className={`${styles.tableWrap} ${styles.tableContent} ${disabled ? styles.disabled : ''}`}>
      <div className={`${styles.left}`}>
        <div className={`${styles.tableItem} ${styles.role}`}>{role}</div>
        <div className={`${styles.tableItem} ${styles.name}`}>{name}</div>
        <div className={`${styles.tableItem} ${styles.email}`}>{email}</div>
      </div>
      <div className={`${styles.tableItem} ${styles.delete}`} onClick={() => removeMemberList(index)}><RemoveIcon sx={{ color: '#ff0000' }}/></div>
    </div>
  )
}

const Slot = ({ chosenSlotObj }) => {
  return (
    <div className={styles.slotWrap}>
      { Object.keys(chosenSlotObj).map((role, idx) => (
        <div key={idx} className={styles.slotItem}>
          <div className={styles.role}>{`${role}:`}</div>
          <div className={`${styles.slot} ${ (chosenSlotObj[role].chosen >= chosenSlotObj[role].left) && styles.warning }`}>{`${chosenSlotObj[role].chosen}/${chosenSlotObj[role].left}`}</div>
        </div>
      )) }
    </div>
  )
}

const AddUniverseMemberModal = ({ handleModal, slotObj, call_saveApi, loadingSaveList, orgMemberList }) => {

  const { role, handleRoleChange, name, handleNameChange, 
    firstName, handlefirstNameChange, lastName, handlelastNameChange, 
    email, handleEmailChange, isEmailValid, emailHelperText, memberList, removeMemberList, 
    setAllItemDisabled, handleMemberList, disableAddBtn, disableAddAccountBtn, chosenSlotObj }= useFormData(slotObj, orgMemberList)

  const [showChildModal, setShowChildModal] = useState(false)
  const { reformImportArr } = useMemberList()

  const openChildModal = () => {
    if(disableAddAccountBtn) return
    setShowChildModal(true)
  }

  const handleClose = () => {
    setShowChildModal(false);
  }

  const saveAndClose = () => {
    handleClose()
    call_saveApi(reformImportArr(memberList))
    setAllItemDisabled()
  }

  return (
    <div className={styles.modalWrap}>
      { loadingSaveList && <div className={styles.loadingWrap}><CircularProgress /></div> }
      <div className={styles.titleWrap}>
        <div className={styles.title}>Add User</div>
        <div className={styles.cancel} onClick={() => handleModal(false)}><CloseRoundedIcon sx={{ color: '#808080', "&:hover": { color: "#1452CC" } }} /></div>
      </div>
      <UtilityRow 
        role={role}
        handleRoleChange={handleRoleChange}
        name={name}
        handleNameChange={handleNameChange}
        email={email}
        handleEmailChange={handleEmailChange}
        isEmailValid={isEmailValid}
        emailHelperText={emailHelperText}
        disableAddBtn={disableAddBtn}
        handleMemberList={handleMemberList}
        firstName={firstName}
        handlefirstNameChange={handlefirstNameChange}
        lastName={lastName}
        handlelastNameChange={handlelastNameChange}
        chosenSlotObj={chosenSlotObj}
      />
      <div className={styles.tableBoxWrap}>
        <TableHeader />
        { !!memberList.length && memberList.map((item, idx) => <TableContent key={idx} index={idx} item={item} removeMemberList={removeMemberList} />) }
      </div>
      <div className={styles.bottomWrap}>
        <div className={styles.left}>
          <div className={styles.selected}>Selected</div>
          <Slot chosenSlotObj={chosenSlotObj} />
        </div>
        <div className={`${styles.addAccountBtn} ${disableAddAccountBtn ? styles.disabled : ''}`} onClick={openChildModal}>Add User</div>
      </div>
      <AddMemberModal chosenSlotObj={chosenSlotObj} showChildModal={showChildModal} handleClose={handleClose} saveAndClose={saveAndClose} />
      {/* <Toaster openToaster={openToaster} handleCloseToaster={handleCloseToaster} severity="success" message="Verification emails have been sent successfully!"/> */}
    </div>
  )
}

export default AddUniverseMemberModal