import styles from './PrivacyModal.module.css'
import PrivacyIcon from '../../../assets/icons/privacy.svg'
import Privacy_EditStudentListIcon from '../../../assets/icons/Privacy_EditStudentList.svg'

const PrivacyModal = ({ typ }) => {

  const directToPricingPage = () => {
    window.open('https://www.viewsonic.io/pricing', '_blank')
  }

  return (
    <div className={styles.privacyModalWrap}>
      <div className={styles.left}>
        { (typ === 'studentList') ? (
          <img src={Privacy_EditStudentListIcon} />
        ) : (
          <img src={PrivacyIcon} />
        ) }
      </div>
      <div className={styles.right}>
        <div className={styles.title}>Upgrade Your Plan</div>
        <div style={{ textAlign: 'center' }}>
          <div className={styles.content}>Your account has been limited.</div>
          <div className={styles.content}>Upgrade plan to get more features!</div>
        </div>
        <button className={styles.upgrade} onClick={directToPricingPage}>Upgrade Now</button>
      </div>
    </div>
  )
}

export default PrivacyModal