import styles from './RemoveUserModal.module.css'

const RemoveUserModal = ({ setShowModal, removeUser }) => {

  return (
    <div className={styles.modalWrap}>
      <div className={styles.title}>Remove User</div>
      <div className={styles.message}>Are you sure you want to remove user?</div>
      <div className={styles.btnWrap}>
        <div className={`${styles.remove} ${styles.btn}`} onClick={removeUser}>Remove</div>
        <div className={`${styles.cancel} ${styles.btn}`} onClick={() => setShowModal(false)}>Cancel</div>
      </div>
    </div>
  )
}

export default RemoveUserModal