import { useEffect, useState } from 'react'
import styles from './EndAssignmentModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import dayjs from 'dayjs'

const CustomButton = ({ txt, pattern, callback }) => {
  return (
    <button className={`${styles.btn} ${styles[pattern]}`} onClick={() => callback()}>
      { txt }
    </button>
  )
}

const EndAssignmentModal = ({ handleModal, onEndAssignment, onResetDraft, onEndAndViewResult, selectedAssignment }) => {

  const [endMode, setEndMode] = useState('')

  useEffect(() => {
    if(selectedAssignment.startTime > dayjs().unix()) {
      setEndMode('future')
    } else {
      setEndMode('normal')
    }
  }, [selectedAssignment])

  return (
    <div className={styles.modalWrap}>
      <div style={{ height: '104px' }}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>End assignment</div>
          <div className={styles.cancel} onClick={() => handleModal(false)}>
            <CloseRoundedIcon sx={{ color: '#808080', '&:hover': { color: '#1452CC' } }} />
          </div>
        </div>
        <div className={styles.content}>
          {
            (endMode == 'future') ? 
            'The start time of this assignment has not yet arrived. Are you sure you want to end it?' : 
            'Are you sure you want to end this assignment and and view the results?'
          }
        </div>
      </div>
      <div className={styles.btnWrap}>
        { endMode === 'future' ? (
          <CustomButton txt="Reset to Draft" pattern="blue" callback={() => onResetDraft()} />
        ) : (
          <CustomButton txt="End & View Results" pattern="blue" callback={() => onEndAndViewResult()} />
        ) }
        <CustomButton txt="End Assignment" pattern="blueWhite" callback={() => onEndAssignment()} />
        <CustomButton txt="Cancel" pattern="grayWhite" callback={() => handleModal(false)} />
      </div>
    </div>
  )
}

export default EndAssignmentModal
