import { Fragment, useEffect, useState } from 'react'
import useAssignmentList from '../../CustomHooks/useAssignmentList'
import styles from './Assignment.module.css'
import AddNewAssignment from './AddNewAssignment/AddNewAssignment'
import CreateNewQuestion from '../../parts/Activity/CreateNewQuestion/CreateNewQuestion'
import ImportQti from '../../parts/Activity/ImportQti/ImportQti'
import Toaster from '../../parts/Toaster'
import AssignmentList from './AssignmentList/AssignmentList'
import ReadOnlyQuestions from './ReadOnlyQuestion/ReadOnlyQuestion'
import SingleResult from '../../parts/Activity/SingleResult/SingleResult'
import Results from './Results/Results'
import LtiCopyLinkModal from '../../parts/Modals/LtiCopyLinkModal/LtiCopyLinkModal'
import Modal from '../../parts/Modal'

const Assignment = () => {
  const [deleteAssignmentModal, setDeleteAssignmentModal] = useState(false)
  const [endAssignmentModal, setEndAssignmentModal] = useState(false)
  const [assignmentName, setAssignmentName] = useState('')
  const [selectedQuestionType, setSelectedQuestionType] = useState(null)
  const [actionType, setActionType] = useState('create')
  const [assignmentActionType, setAssignmentActionType] = useState('create')
  const [questionActionType, setQuestionActionType] = useState('create')
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [timerMin, setTimerMin] = useState(0)
  const [timerSec, setTimerSec] = useState(0)
  const [questions, setQuestions] = useState([])
  const questionTypeList = ['True/False', 'Single Selection', 'Multiple Selection']

  const {
    assignmentList,
    setAssignmentList,
    assignmentLoadingGetList,
    questionLoadingGetList,
    page,
    assignmentDetail,
    courseList,
    totalPage,
    handlePage,
    call_getAssignmentApi,
    call_createAssignmentApi,
    call_updateAssignmentApi,
    call_deleteAssignmentApi,
    openToaster,
    toasterText,
    handleOpenToaster,
    handleCloseToaster,
    severity,
    setSeverity,
    step,
    setStep,
    call_getCourseApi,
    setCourseList,
    call_getAssignmentDetail,
    call_createQuestionApi,
    call_updateQuestionApi,
    call_deleteQuestionApi,
    selectedAssignment,
    setSelectedAssignment,
    call_duplicateQuestionApi,
    call_duplicateAssignmentApi,
    setAssignmentDetail,
    addAssignmentModal,
    setAddAssignmentModal,
    disableAddAssignmentModalBtn,
    setDisableAddAssignmentModalBtn,
    disableSaveAssignment,
    setDisableSaveAssignment,
    questionPage,
    questionTotalPage,
    handleQuestionPage,
    handleQuestionTotalPage,
    call_uploadImageApi,
    call_getAssignmentListForImportAPI,
    call_getImportHistoryAPI,
    call_importQuestionsAPI,
    assignmentListForImport,
    setAssignmentListForImport,
    call_createAssignmentImportApi,
    selectVal,
    setSelectVal,
    selectValObj,
    setSelectValObj,
    isUploading,
    setIsUploading,
    uploadedAssignmentList,
    setUploadedAssignmentList,
    fileName,
    setFileName,
    fileInputRef,
    historyList,
    setHistoryList,
    historyLoadingGetList,
    setQuestionLoadingGetList,
    call_updateAssignmentStatusApi,
    call_getAssignmentResults,
    resultLoading,
    assignmentResult,
    isQuestionSaving,
    setIsQuestionSaving,
    isAssignmentSaving,
    setIsAssignmentSaving,
    toSingleResultPage,
    sortBy,
    order,
    handleSortBy,
    handleOrder,
    searchVal,
    setSearchVal,
    durationStim,
    durationEtim,
    handleDurationStimChange,
    handleDurationEtimChange,
    setInvalidDuration,
    invalidDuration,
    call_getAssignmentResultsQuestions,
    call_getAssignmentResultsParticipants,
    assignmentResultQuestions,
    assignmentResultParticipants,
    setAssignmentResultParticipants,
    resultsPage,
    resultsTotalPage,
    handleResultsPage,
    ltiCopyLinkModal,
    handleLtiCopyLinkModal,
    onCopyDeployId,
    saveImageLoading,
    setSaveImageLoading
  } = useAssignmentList()

  return (
    <div className={styles.assignmentWrap}>
      {step === 1 && (
        <AssignmentList
          searchVal={searchVal}
          setAssignmentName={setAssignmentName}
          setActionType={setActionType}
          setStep={setStep}
          call_getAssignmentApi={call_getAssignmentApi}
          assignmentLoadingGetList={assignmentLoadingGetList}
          assignmentList={assignmentList}
          setAssignmentList={setAssignmentList}
          page={page}
          setAddAssignmentModal={setAddAssignmentModal}
          setDeleteAssignmentModal={setDeleteAssignmentModal}
          endAssignmentModal={endAssignmentModal}
          setEndAssignmentModal={setEndAssignmentModal}
          handleOpenToaster={handleOpenToaster}
          setSelectedAssignment={setSelectedAssignment}
          call_deleteAssignmentApi={call_deleteAssignmentApi}
          setSeverity={setSeverity}
          call_duplicateAssignmentApi={call_duplicateAssignmentApi}
          totalPage={totalPage}
          handlePage={handlePage}
          addAssignmentModal={addAssignmentModal}
          assignmentName={assignmentName}
          actionType={actionType}
          disableAddAssignmentModalBtn={disableAddAssignmentModalBtn}
          deleteAssignmentModal={deleteAssignmentModal}
          setSearchVal={setSearchVal}
          setDisableAddAssignmentModalBtn={setDisableAddAssignmentModalBtn}
          call_createAssignmentApi={call_createAssignmentApi}
          setAssignmentActionType={setAssignmentActionType}
          selectedAssignment={selectedAssignment}
          call_updateAssignmentApi={call_updateAssignmentApi}
          call_updateAssignmentStatusApi={call_updateAssignmentStatusApi}
          sortBy={sortBy}
          order={order}
          handleSortBy={handleSortBy}
          handleOrder={handleOrder}
        ></AssignmentList>
      )}
      {step === 2 && (
        <Fragment>
          <AddNewAssignment
            setAssignmentDetail={setAssignmentDetail}
            setCourseList={setCourseList}
            call_getAssignmentDetail={call_getAssignmentDetail}
            call_deleteQuestionApi={call_deleteQuestionApi}
            call_updateAssignmentApi={call_updateAssignmentApi}
            timerMin={timerMin}
            setTimerMin={setTimerMin}
            timerSec={timerSec}
            setTimerSec={setTimerSec}
            assignmentName={assignmentName}
            setStep={setStep}
            setAssignmentName={setAssignmentName}
            setSelectedQuestionType={setSelectedQuestionType}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            assignmentActionType={assignmentActionType}
            setQuestionActionType={setQuestionActionType}
            selectedAssignment={selectedAssignment}
            questions={questions}
            setQuestions={setQuestions}
            call_getCourseApi={call_getCourseApi}
            assignmentDetail={assignmentDetail}
            questionLoadingGetList={questionLoadingGetList}
            courseList={courseList}
            call_duplicateQuestionApi={call_duplicateQuestionApi}
            disableSaveAssignment={disableSaveAssignment}
            setDisableSaveAssignment={setDisableSaveAssignment}
            questionPage={questionPage}
            questionTotalPage={questionTotalPage}
            handleQuestionPage={handleQuestionPage}
            handleQuestionTotalPage={handleQuestionTotalPage}
            setSeverity={setSeverity}
            handleOpenToaster={handleOpenToaster}
            setQuestionLoadingGetList={setQuestionLoadingGetList}
            isAssignmentSaving={isAssignmentSaving}
            setIsAssignmentSaving={setIsAssignmentSaving}
            durationStim={durationStim}
            durationEtim={durationEtim}
            handleDurationStimChange={handleDurationStimChange}
            handleDurationEtimChange={handleDurationEtimChange}
            setInvalidDuration={setInvalidDuration}
            invalidDuration={invalidDuration}
          />
        </Fragment>
      )}
      {step === 3 && (
        <Fragment>
          <CreateNewQuestion
            typ='Assignment'
            isQuestionSaving={isQuestionSaving}
            setIsQuestionSaving={setIsQuestionSaving}
            call_uploadImageApi={call_uploadImageApi}
            setStep={setStep}
            selectedQuestionType={selectedQuestionType}
            questionTypeList={questionTypeList}
            setSelectedQuestionType={setSelectedQuestionType}
            actionType={assignmentActionType}
            questionActionType={questionActionType}
            selectedParent={selectedAssignment}
            selectedQuestion={selectedQuestion}
            call_createQuestionApi={call_createQuestionApi}
            call_updateQuestionApi={call_updateQuestionApi}
            handleOpenToaster={handleOpenToaster}
            setSeverity={setSeverity}
            saveImageLoading={saveImageLoading}
            setSaveImageLoading={setSaveImageLoading}
          />
        </Fragment>
      )}
      {step === 4 && (
        <Fragment>
          <ImportQti
            typ='Assignment'
            setStep={setStep}
            call_getParentApi={call_getAssignmentApi}
            call_getParentListForImportAPI={call_getAssignmentListForImportAPI}
            call_getImportHistoryAPI={call_getImportHistoryAPI}
            call_importQuestionsAPI={call_importQuestionsAPI}
            parentListForImport={assignmentListForImport}
            setParentListForImport={setAssignmentListForImport}
            setSeverity={setSeverity}
            handleOpenToaster={handleOpenToaster}
            call_createParentImportApi={call_createAssignmentImportApi}
            selectVal={selectVal}
            setSelectVal={setSelectVal}
            selectValObj={selectValObj}
            setSelectValObj={setSelectValObj}
            addModal={addAssignmentModal}
            setAddModal={setAddAssignmentModal}
            disableAddModalBtn={disableAddAssignmentModalBtn}
            setDisableAddModalBtn={setDisableAddAssignmentModalBtn}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            uploadedParentList={uploadedAssignmentList}
            setUploadedParentList={setUploadedAssignmentList}
            fileName={fileName}
            setFileName={setFileName}
            fileInputRef={fileInputRef}
            historyList={historyList}
            setHistoryList={setHistoryList}
            historyLoadingGetList={historyLoadingGetList}
            setSelectedParent={setSelectedAssignment}
          />
        </Fragment>
      )}
      {step === 5 && (
        <Fragment>
          <ReadOnlyQuestions
            setStep={setStep}
            selectedAssignment={selectedAssignment}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            questions={questions}
          />
        </Fragment>
      )}
      {step === 6 && (
        <Results
          setStep={setStep}
          toSingleResultPage={toSingleResultPage}
          resultLoading={resultLoading}
          call_getAssignmentResults={call_getAssignmentResults}
          assignmentResult={assignmentResult}
          resultsPage={resultsPage}
          resultsTotalPage={resultsTotalPage}
          handleResultsPage={handleResultsPage}
        />
      )}
      {step === 7 && (
        <SingleResult
          typ='Assignment'
          setStep={setStep}
          selectedParent={selectedAssignment}
          resultLoading={resultLoading}
          call_getResultsQuestions={call_getAssignmentResultsQuestions}
          call_getResultsParticipants={call_getAssignmentResultsParticipants}
          questionRes={assignmentResultQuestions}
          participantRes={assignmentResultParticipants}
          setResultParticipants={setAssignmentResultParticipants}
        />
      )}
      {ltiCopyLinkModal && (
        <Modal>
          <LtiCopyLinkModal selectedAssignment={selectedAssignment} handleLtiCopyLinkModal={handleLtiCopyLinkModal} onCopyDeployId={onCopyDeployId} />
        </Modal>
      )}
      <Toaster openToaster={openToaster} handleCloseToaster={handleCloseToaster} severity={severity} message={toasterText} />
    </div>
  )
}

export default Assignment
