import { useContext, useEffect } from 'react'
import styles from './NotFound.module.css'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {

  const navigate = useNavigate()

  useEffect(() => {
    let timeout = setTimeout(() => { 
      navigate('/dashboard', { replace: true })
     }, 3000)
    return () => { clearTimeout(timeout) }
  }, [])

  return (
    <div className={styles.notFoundWrap}>
      <div className={styles.textWrap}>
        <div>This page doesn't exist.</div>
        <div>Redirect to Home page after 3 seconds.</div>
      </div>
    </div>
  )
}

export default NotFound