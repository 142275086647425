import styles from './GroupUp.module.css'
import AddIcon from '@mui/icons-material/Add'
import { Fragment, useEffect, useRef, useState } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import EditIcon from '@mui/icons-material/Edit'
import RadioGroupBox from '../../../../../parts/RadioGroupBox/RadioGroupBox'
import { useDrag, useDrop } from 'react-dnd'
import Modal from '../../../../../parts/Modal'
import QuitEditModal from '../../../../../parts/Modals/QuitEditModal/QuitEditModal'
import DeleteGroupUpListModal from '../../../../../parts/Modals/DeleteGroupUpListModal/DeleteGroupUpListModal'
import useRadioGroupBox from '../../../../../CustomHooks/useRadioGroupBox'
import EditGroupNameModal from '../../../../../parts/Modals/EditGroupNameModal/EditGroupNameModal'
import GroupUpModal from '../../../../../parts/Modals/GroupUpModal/GroupUpModal'
import useGroupUp from '../../../../../CustomHooks/useGroupUp'
import GroupUpWarningModal from '../../../../../parts/Modals/GroupUpWarningModal/GroupUpWarningModal'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Toaster from '../../../../../parts/Toaster'
import SearchInput from '../../../../../parts/SearchInput'

const DragBlock = ({
  item,
  highlighted,
  dragAndDrop,
  groupIndex = -1,
  itemIndex = -1,
  dragData = null,
  handleDragData = null,
  unassignedUsers = null,
  handleUnassignedUsers = null,
  groupUpMode,
  resetGroupMode,
  resetRadioBoxSettings
}) => {
  const ref = useRef(null)

  const handleDrop = (dropItem) => {
    // item => 被hover的 即將被drop的
    // hoverItem => hover別人的 正在被drag的

    // 自己放在自己上面不用處理
    if (item.email.toLowerCase() === dropItem.email.toLowerCase()) return
    // 在Unassigned Users的地方不用處理
    if (groupIndex < 0) return

    if (dropItem.groupIndex >= 0 && groupIndex >= 0 && item.email === '' && groupIndex === dropItem.groupIndex) return

    // when group up mode is automatic and move the data => change mode to customized
    if (groupUpMode === 'automatic') {
      resetGroupMode()
      resetRadioBoxSettings()
    }

    // group裡面把有資料的移動到空的格子裡 同一組不用處理 不同組要處理
    if (dropItem.groupIndex >= 0 && groupIndex >= 0 && item.email === '') {
      // same group then return
      // different group then splice and push
      let spliceItem = dragData[dropItem.groupIndex].splice(dropItem.itemIndex, 1)
      let dataLength = dragData[groupIndex].filter((item) => item.name !== '').length
      dragData[groupIndex][dataLength] = spliceItem[0]
      handleDragData(JSON.parse(JSON.stringify(dragData)))
      return
    }
    // Unassigned Users移動到group裡面
    if (dropItem.groupIndex < 0) {
      let spliceIndex = unassignedUsers.findIndex((unAssignedUser) => unAssignedUser.email.toLowerCase() === dropItem.email.toLowerCase())
      let spliceItem = unassignedUsers.splice(spliceIndex, 1)
      let dataLength = dragData[groupIndex].filter((item) => item.name !== '').length
      dragData[groupIndex][dataLength] = spliceItem[0]
      if (itemIndex < dataLength) {
        // 移動到空格子 splice and push
        // 移動到有資料的地方 push and swap
        console.info('swap from', groupIndex, itemIndex, 'swap to', groupIndex, dragData[groupIndex].filter((item) => item.name !== '').length - 1)
        swap(groupIndex, itemIndex, groupIndex, dragData[groupIndex].filter((item) => item.name !== '').length - 1)
      }
      handleUnassignedUsers(JSON.parse(JSON.stringify(unassignedUsers)))
      handleDragData(JSON.parse(JSON.stringify(dragData)))
      return
    }

    // group之間成員交換
    swap(dropItem.groupIndex, dropItem.itemIndex, groupIndex, itemIndex)
    handleDragData(JSON.parse(JSON.stringify(dragData)))
  }

  const swap = (dropGroupIndex, dropItemIndex, dragGroupIndex, dragItemIndex) => {
    let temp = dragData[dropGroupIndex][dropItemIndex]
    dragData[dropGroupIndex][dropItemIndex] = dragData[dragGroupIndex][dragItemIndex]
    dragData[dragGroupIndex][dragItemIndex] = temp
  }

  const [{ isDragging }, dragRef] = useDrag({
    type: item.email !== '' ? 'drag' : 'empty', // cannot drag empty item
    item: { ...item, groupIndex, itemIndex },
    collect: (monitor) => ({ isDragging: monitor.isDragging() })
  })

  const [{ isOver }, dropRef] = useDrop({
    accept: 'drag',
    drop: (dropItem) => handleDrop(dropItem),
    collect: (monitor) => ({ isOver: monitor.isOver() })
  })

  dragRef(dropRef(ref))

  return (
    <div ref={ref} className={`${styles.dragBox} ${highlighted && styles.highlighted} ${dragAndDrop && isOver && styles.isOver}`}>
      {item.name}
    </div>
  )
}

const DropArea = ({
  groupUpMode,
  resetGroupMode,
  identity,
  index = -1,
  per,
  data,
  dragData,
  handleDragData,
  unassignedUsers,
  handleUnassignedUsers,
  resetRadioBoxSettings,
  searchHighlighted,
  boxPer
}) => {
  const [{ isOver }, dropRef] = useDrop({
    accept: 'drag',
    drop: (item) => handleDrop(item),
    collect: (monitor) => ({ isOver: monitor.isOver() })
  })

  const handleDrop = (item) => {
    if (identity === 'groupBox') {
      // 該格已經有重複的名字 就不能重複放置
      if (data.find((filterItem) => filterItem.name === item.name)) return
      // 超過一組可以放置的最大人數(5 or 10) 就return
      if (data.filter((item) => item.name !== '').length >= boxPer) return
    }
    // when group up mode is automatic and move the data => change mode to customized
    if (groupUpMode === 'automatic') {
      resetGroupMode()
      resetRadioBoxSettings()
    }
    // remove from array
    removeFromArray(item)
    // push to group
    pushToArray(item)
  }

  const pushToArray = (item) => {
    if (identity === 'groupBox') {
      let newData = data.filter((item) => item.name !== '')
      newData.push(item)
      dragData[index] = newData

      handleDragData(JSON.parse(JSON.stringify(dragData)))
      return
    }
    // 該格已經有重複的名字 就不能重複放置
    if (unassignedUsers.find((filterItem) => filterItem.name === item.name)) return
    unassignedUsers.push(item)
    handleUnassignedUsers(unassignedUsers)
  }

  const removeFromArray = (item) => {
    let i = unassignedUsers.findIndex((chosenItem) => chosenItem.name === item.name)
    if (i === -1) {
      // drag from group box
      for (let groupData of dragData) {
        let i = groupData.findIndex((chosenItem) => chosenItem.name === item.name)
        if (i >= 0) {
          groupData.splice(i, 1)
          handleDragData(JSON.parse(JSON.stringify(dragData)))
          break
        }
      }
    } else {
      // drag from unassigned users
      unassignedUsers.splice(i, 1)
      handleUnassignedUsers(unassignedUsers)
    }
  }

  return (
    <Fragment>
      {identity === 'groupBox' ? (
        <div className={`${styles.groupBox} ${boxPer === 10 && styles.bigger}`}>
          <div className={styles.index}>{index + 1}</div>
          <div className={styles.drop}>
            {!!data &&
              data.map((item, idx) => {
                return (
                  <DragBlock
                    key={idx}
                    groupIndex={index}
                    itemIndex={idx}
                    item={item}
                    highlighted={searchHighlighted(item)}
                    dragAndDrop={true}
                    dragData={dragData}
                    handleDragData={handleDragData}
                    unassignedUsers={unassignedUsers}
                    handleUnassignedUsers={handleUnassignedUsers}
                    groupUpMode={groupUpMode}
                    resetGroupMode={resetGroupMode}
                    resetRadioBoxSettings={resetRadioBoxSettings}
                  />
                )
              })}
          </div>
        </div>
      ) : (
        <div className={styles.dragBoxesWrap} ref={dropRef}>
          <div className={styles.dragBoxes}>
            {!!unassignedUsers &&
              unassignedUsers.map((item, index) => {
                return (
                  <DragBlock
                    key={index}
                    item={item}
                    highlighted={searchHighlighted(item)}
                    dragAndDrop={false}
                    dragData={dragData}
                    handleDragData={handleDragData}
                    unassignedUsers={unassignedUsers}
                    handleUnassignedUsers={handleUnassignedUsers}
                    groupUpMode={groupUpMode}
                    resetGroupMode={resetGroupMode}
                    resetRadioBoxSettings={resetRadioBoxSettings}
                  />
                )
              })}
          </div>
        </div>
      )}
    </Fragment>
  )
}

const TableHeader = () => {
  return (
    <div className={`${styles.tableWrap} ${styles.tableHeader}`}>
      <div className={`${styles.tableItem} ${styles.groupUpName}`}>Group Up Name</div>
      <div className={`${styles.tableItem} ${styles.group}`}>Group</div>
      <div className={`${styles.tableItem} ${styles.pergroup}`}>Per Group</div>
      <div className={`${styles.tableItem} ${styles.unassigned}`}>Unassigned Users</div>
      <div className={`${styles.tableItem} ${styles.duplicate}`}>Duplicate</div>
      <div className={`${styles.tableItem} ${styles.delete}`}>Delete</div>
    </div>
  )
}

const TableContent = ({ item, index, editGroupListItem, handleDeleteGroupUpListModal, startDuplicateMode }) => {
  const editDraft = (item, index) => {
    editGroupListItem(item, index)
  }

  return (
    <div className={`${styles.tableWrap} ${styles.tableContent}`} onClick={() => editDraft(item, index)}>
      <div className={`${styles.tableItem} ${styles.groupUpName}`}>
        <div>{item.groupName}</div>
        <div className={`${styles.draft} ${item.unAssignedUsers.length === 0 && styles.disabled}`}>[Draft]</div>
      </div>
      <div className={`${styles.tableItem} ${styles.group}`}>{item.group.length}</div>
      <div className={`${styles.tableItem} ${styles.pergroup}`}>{item.perGroup}</div>
      <div className={`${styles.tableItem} ${styles.unassigned}`}>{item.unAssignedUsers.length}</div>
      <div
        className={`${styles.tableItem} ${styles.icon} ${styles.duplicate}`}
        onClick={(e) => {
          e.stopPropagation()
          startDuplicateMode(item, index)
        }}
      >
        <ContentCopyIcon sx={{ color: '#808080', transform: 'scale(0.7)', '&:hover': { color: '#1452CC' } }} />
      </div>
      <div
        className={`${styles.tableItem} ${styles.icon} ${styles.delete}`}
        onClick={(e) => {
          e.stopPropagation()
          handleDeleteGroupUpListModal(true, index)
        }}
      >
        <DeleteIcon sx={{ color: '#808080', transform: 'scale(0.8)', '&:hover': { color: '#F5052D' } }} />
      </div>
    </div>
  )
}

const SettingPage = ({
  reformCreateGroupItem,
  resetGroupMode,
  memberList,
  childOption,
  chosenClassItem,
  handleToSettingPage,
  groupName,
  handleGroupName,
  groupUpMode,
  handleGroupUpMode,
  handleChildOption,
  setGroupUpParams,
  unassignedUsers,
  handleUnassignedUsers,
  resetUnassignedUsers,
  reformChosenStudentList,
  dragData,
  handleDragData,
  groupNum,
  addUpGroupNum,
  chosenGroupListIndex,
  reformDeleteGroupItem,
  addGroupMode,
  updateGroupUpListByClass,
  deleteGroupListItem,
  boxPer,
  resetGroupUpSettings,
  assignRandomly
}) => {
  const [showQuitEditModal, setShowQuitEditModal] = useState(false)
  const [showEditGroupNameModal, setShowEditGroupNameModal] = useState(false)
  const [searchVal, setSearchVal] = useState('')

  const handleSearchVal = (e) => {
    setSearchVal(e.target.value)
  }

  const searchHighlighted = (item) => {
    return searchVal.trim() !== '' && item.name.toLowerCase().includes(searchVal.toLowerCase())
  }

  const { groupUpModeInRadioBox, handleGroupUpModeInRadioBox, childOptionInRadioBox, handleChildOptionInRadioBox, resetRadioBoxSettings } = useRadioGroupBox({
    groupUpMode,
    childOption,
    setGroupUpParams
  })

  const disableRemoveButton = () => {
    return addGroupMode === 'duplicate' || addGroupMode === 'update-duplicate' || chosenGroupListIndex === -1
  }

  const per = childOption.split('-')[1]

  const handleQuitEditModal = (sts) => {
    setShowQuitEditModal(sts)
  }

  const handleEditGroupNameModal = (sts) => {
    setShowEditGroupNameModal(sts)
  }

  const saveGroupList = (sts) => {
    if (!sts) {
      resetGroupUpSettings()
      return
    }
    // 沒有assign任何小組的話 不能儲存
    if (unassignedUsers.length === reformChosenStudentList().length) return
    let item = {
      groupName,
      group: dragData.filter((item) => item.filter((innerItem) => innerItem.name !== '').length !== 0),
      perGroup: per,
      unAssignedUsers: unassignedUsers,
      mode: childOption,
      groupID: addGroupMode === 'edit' || addGroupMode === 'update-edit' ? chosenClassItem.studyGroup[chosenGroupListIndex].groupID : '0'
    }
    let [dataObj, text] = reformCreateGroupItem(item, sts)
    if (Object.keys(dataObj).length > 0) {
      updateGroupUpListByClass(dataObj, text)
    }
  }

  // const disableUpdateButton = () => {
  //   return (childOption.split('-')[0] === 'customized') ? childOptionInRadioBox.split('-')[0] === childOption.split('-')[0] : childOptionInRadioBox === childOption
  // }

  return (
    <Fragment>
      <div className={styles.GroupUpWrap}>
        <div className={styles.titleWrap}>
          <div className={styles.left}>
            <div className={styles.icon} onClick={(e) => handleQuitEditModal(true)}>
              <ArrowBackIosNewIcon sx={{ color: '#808080', '&:hover': { color: '#1452CC' } }} />
            </div>
            <div className={styles.title}>{groupName}</div>
            <div className={styles.icon} onClick={() => handleEditGroupNameModal(true)}>
              <EditIcon sx={{ color: '#808080', '&:hover': { color: '#1452CC' } }} />
            </div>
          </div>
          <div className={styles.right}>
            <div className={`${styles.remove} ${styles.btn} ${disableRemoveButton() && styles.disabled}`} onClick={() => deleteGroupListItem(true)}>
              Remove
            </div>
            <div className={`${styles.save} ${styles.btn} ${unassignedUsers.length === reformChosenStudentList().length && styles.disabled}`} onClick={saveGroupList}>
              Save
            </div>
          </div>
        </div>
        <div className={styles.radioGroupBg}>
          <RadioGroupBox
            typ='random'
            assignRandomly={assignRandomly}
            childOption={childOptionInRadioBox}
            groupUpMode={groupUpModeInRadioBox}
            handleGroupUpMode={handleGroupUpModeInRadioBox}
            handleChildOption={handleChildOptionInRadioBox}
          />
          {/* <div className={`${styles.update} ${disableUpdateButton() && styles.disabled}`} onClick={() => setGroupUpParams(groupUpModeInRadioBox, childOptionInRadioBox)}>Update</div> */}
        </div>
        <div className={styles.divideline}></div>
        <div className={styles.assignArea}>
          <div className={styles.left}>
            <div className={styles.searchBar}>
              <SearchInput searchVal={searchVal} handleSearchVal={handleSearchVal} placeholder='Search User By Name' typ='boxShadow' />
            </div>
            <div className={styles.subtitle}>Group Up</div>
            <div className={styles.boxes}>
              {new Array(groupNum).fill('').map((item, index) => (
                <DropArea
                  groupUpMode={groupUpMode}
                  resetGroupMode={resetGroupMode}
                  identity='groupBox'
                  key={index}
                  index={index}
                  per={per}
                  data={dragData[index]}
                  dragData={dragData}
                  handleDragData={handleDragData}
                  unassignedUsers={unassignedUsers}
                  handleUnassignedUsers={handleUnassignedUsers}
                  resetRadioBoxSettings={resetRadioBoxSettings}
                  searchHighlighted={searchHighlighted}
                  boxPer={boxPer}
                />
              ))}
              {groupUpMode === 'customized' && (
                <div className={`${styles.groupBox} ${boxPer === 10 && styles.bigger} ${styles.clickable}`} onClick={addUpGroupNum}>
                  +
                </div>
              )}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.subtitle}>Unassigned Users: {unassignedUsers.length}</div>
            <DropArea
              groupUpMode={groupUpMode}
              resetGroupMode={resetGroupMode}
              identity='unassigned'
              per={per}
              data={unassignedUsers}
              dragData={dragData}
              handleDragData={handleDragData}
              unassignedUsers={unassignedUsers}
              handleUnassignedUsers={handleUnassignedUsers}
              resetRadioBoxSettings={resetRadioBoxSettings}
              searchHighlighted={searchHighlighted}
              boxPer={boxPer}
            />
          </div>
        </div>
      </div>
      {showQuitEditModal && (
        <Modal>
          <QuitEditModal handleQuitEditModal={handleQuitEditModal} backToGroupListPage={saveGroupList} />
        </Modal>
      )}
      {showEditGroupNameModal && (
        <Modal>
          <EditGroupNameModal handleEditGroupNameModal={handleEditGroupNameModal} groupName={groupName} handleGroupName={handleGroupName} />
        </Modal>
      )}
    </Fragment>
  )
}

const GroupUp = ({ memberList, chosenClassItem, updateGroupUpListByClass, deleteGroupUpListByClass }) => {
  const [showGroupUpModal, setShowGroupUpModal] = useState(false)
  const [showGroupUpWarningModal, setShowGroupUpWarningModal] = useState(false)
  const [showDeleteGroupUpListModal, setShowDeleteGroupUpListModal] = useState(false)

  const {
    groupName,
    groupUpMode,
    childOption,
    toGroupSettingPage,
    unassignedUsers,
    dragData,
    handleDragData,
    handleUnassignedUsers,
    resetUnassignedUsers,
    reformChosenStudentList,
    handleToSettingPage,
    handleGroupName,
    handleGroupUpMode,
    handleChildOption,
    setGroupUpParams,
    resetGroupMode,
    groupNum,
    addUpGroupNum,
    editGroupListItem,
    groupList,
    reformCreateGroupItem,
    handleAddGroupMode,
    addGroupMode,
    duplicateGroupListItem,
    handleChosenGroupListIndex,
    reformDeleteGroupItem,
    chosenGroupListIndex,
    openToaster,
    toasterText,
    handleOpenToaster,
    handleCloseToaster,
    boxPer,
    resetGroupUpSettings,
    assignRandomly
  } = useGroupUp(chosenClassItem, memberList)

  const startDuplicateMode = (item, index) => {
    duplicateGroupListItem(item, index)
    handleGroupUpModal(true, 'duplicate')
  }

  const handleDeleteGroupUpListModal = (sts, idx) => {
    handleChosenGroupListIndex(idx)
    setShowDeleteGroupUpListModal(sts)
  }

  const handleGroupUpModal = (sts, mode = null) => {
    if (sts && chosenClassItem.studentList.length === 0) {
      handleGroupUpWarningModal(true)
      return
    }

    if (sts && mode == 'create') {
      resetGroupUpSettings()
    }

    // if(userInfo.isExpired) {
    //   handleUpgradePlanModal(true)
    //   return
    // }
    handleAddGroupMode(mode)
    setShowGroupUpModal(sts)
  }

  const handleGroupUpWarningModal = (sts) => {
    setShowGroupUpWarningModal(sts)
  }

  const deleteGroupListItem = () => {
    let [dataObj, text] = reformDeleteGroupItem()
    if (Object.keys(dataObj).length > 0) {
      deleteGroupUpListByClass(dataObj, text)
    }
  }

  return (
    <div className={`${styles.thirdRow} ${!toGroupSettingPage && styles.scroll}`}>
      {toGroupSettingPage ? (
        <SettingPage
          memberList={memberList}
          childOption={childOption}
          chosenClassItem={chosenClassItem}
          handleToSettingPage={handleToSettingPage}
          groupName={groupName}
          groupUpMode={groupUpMode}
          handleGroupUpMode={handleGroupUpMode}
          handleChildOption={handleChildOption}
          resetGroupMode={resetGroupMode}
          reformCreateGroupItem={reformCreateGroupItem}
          updateGroupUpListByClass={updateGroupUpListByClass}
          deleteGroupUpListByClass={deleteGroupUpListByClass}
          setGroupUpParams={setGroupUpParams}
          handleGroupName={handleGroupName}
          unassignedUsers={unassignedUsers}
          handleUnassignedUsers={handleUnassignedUsers}
          resetUnassignedUsers={resetUnassignedUsers}
          reformChosenStudentList={reformChosenStudentList}
          assignRandomly={assignRandomly}
          dragData={dragData}
          handleDragData={handleDragData}
          groupNum={groupNum}
          addUpGroupNum={addUpGroupNum}
          chosenGroupListIndex={chosenGroupListIndex}
          deleteGroupListItem={deleteGroupListItem}
          addGroupMode={addGroupMode}
          boxPer={boxPer}
          resetGroupUpSettings={resetGroupUpSettings}
        />
      ) : (
        <div className={styles.GroupUpWrap}>
          <div className={styles.titleWrap}>
            <div className={styles.title}>Group Up List</div>
            <div className={`${styles.icon} ${styles.square}`} onClick={() => handleGroupUpModal(true, 'create')}>
              <AddIcon sx={{ transform: 'scale(1.2)' }} />
            </div>
          </div>
          <div className={styles.tableContainer}>
            <TableHeader />
            {chosenClassItem.studyGroup.length === 0 && <div className={styles.hintText}>Set up group up list!</div>}
            {chosenClassItem.studyGroup.length !== 0 &&
              chosenClassItem.studyGroup.map((item, idx) => (
                <TableContent
                  key={idx}
                  item={item}
                  index={idx}
                  editGroupListItem={editGroupListItem}
                  handleDeleteGroupUpListModal={handleDeleteGroupUpListModal}
                  startDuplicateMode={startDuplicateMode}
                />
              ))}
          </div>
        </div>
      )}
      {showGroupUpModal && (
        <Modal>
          <GroupUpModal
            handleGroupUpModal={handleGroupUpModal}
            groupName={groupName}
            childOption={childOption}
            groupUpMode={groupUpMode}
            handleGroupName={handleGroupName}
            handleGroupUpMode={handleGroupUpMode}
            handleChildOption={handleChildOption}
            handleToSettingPage={handleToSettingPage}
            setGroupUpParams={setGroupUpParams}
            addGroupMode={addGroupMode}
          />
        </Modal>
      )}
      {showGroupUpWarningModal && (
        <Modal>
          <GroupUpWarningModal handleGroupUpWarningModal={handleGroupUpWarningModal} />
        </Modal>
      )}
      {showDeleteGroupUpListModal && (
        <Modal>
          <DeleteGroupUpListModal handleDeleteGroupUpListModal={handleDeleteGroupUpListModal} deleteGroupListItem={deleteGroupListItem} />
        </Modal>
      )}
      <Toaster openToaster={openToaster} handleCloseToaster={handleCloseToaster} severity='success' message={toasterText} />
    </div>
  )
}

export default GroupUp
