import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import Popper from '@mui/material/Popper'
import { VariableSizeList } from 'react-window'
import ArrowDownIcon from '../icons/ArrowDownIcon'
import { styled } from '@mui/material/styles'

const CustomAutoComplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiInputBase-input': {
    height: 15
  },
  '& .Mui-disabled': {
    backgroundColor: '#F5F8FF',
  },
  boxShadow: 'inset 0px 0px 6px #00000029',
  borderRadius: '8px',
}));

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
    font: 'normal normal normal 16px/20px InterRegular',
    height: 60,
  };

  return (
    <div {...dataSet[0]} style={inlineStyle}>{dataSet[1]}</div>
  )
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const itemCount = itemData.length;
  const itemSize = 60;

  const getChildSize = (child) => {
    return 60;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => 60}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});


const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const borderStyle = {
  bgcolor: '#fff',
  '& 	.MuiOutlinedInput-notchedOutline': {
    // normal status border color
    borderColor: '#B3B3B3',
    borderRadius: '8px'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // input focus border color
    // borderColor: 'transparent'
    border: '2px solid #1452CC',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    // input hover border color => color still invalid now
    border: '2px solid #1452CC',
    // borderColor: '#1452CC'
  }
}

const BorderWindowSelect = ({ label, options, val, handleValChange, disabled }) => {

  return (
    <CustomAutoComplete
      fullWidth
      disabled={disabled}
      disableListWrap
      disableClearable
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={options}
      renderInput={(params) => <TextField {...params} label={label} sx={borderStyle} />}
      renderOption={(props, option) => [props, option]}
      value={val}
      onChange={handleValChange}
      popupIcon={<ArrowDownIcon />}
    />
  );
}

export default BorderWindowSelect