import { useContext, useEffect, useRef, useState } from "react"
import { UserContext } from "./useAuth"
import usePagination from "./usePagination"
import { getLTIConfig, createLTIConfig, updateLTIConfig, deleteLTIConfig, editLTIOrgSettings } from "../api"
import useToaster from "./useToaster"

const useLTISettings = () => {
  const [tab, setTab] = useState('LTI Canvas')
  const [allowCalendar, setAllowCalendar] = useState(true)
  const [allowAssignment, setAllowAssignment] = useState(true)
  const [allowSettings, setAllowSettings] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [addInstanceModal, setAddInstanceModal] = useState(false)
  const [addInstanceType, setAddInstanceType] = useState('Add')
  const [chosenConfig, setChosenConfig] = useState({})
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [ltiSettingsLoading, setLtiSettingsLoading] = useState(true)
  const { userInfo } = useContext(UserContext)
  const { page, totalPage, handlePage, handleTotalPage } = usePagination()
  const [callAPI, setCallAPI] = useState(false)
  const callAPI_ref = useRef(null)
  const a_c_ref = useRef(null)
  const a_a_ref = useRef(null)
  const allow_settings_ref = useRef(null)
  const { openToaster, toasterText, toastSeverity, handleOpenToaster, handleCloseToaster } = useToaster()

  useEffect(() => {
    setLtiSettingsLoading(true)
    get_configs()
    callAPI_ref.current = callAPI
    console.info('get_configs')
  }, [])

  useEffect(() => {
    callAPI_ref.current = callAPI
  }, [callAPI])

  useEffect(() => {
    return () => {
      if(callAPI_ref.current) {
        console.info('allow_settings_ref', allow_settings_ref)
        editLTIOrgSettings({ orgId: userInfo.orgID, allowSettings: allow_settings_ref.current })
        .then(res => console.info('res', res))
        .catch(err => console.info('err', err))
      }
    }
  }, [])

  useEffect(() => {
    if(allowSettings.length === 0) return
    let item = allowSettings.find(item => item.platform === tab)
    setAllowAssignment(item.assignment)
    setAllowCalendar(item.calendar)
  }, [tab, allowSettings])

  useEffect(() => {
    let arr = allowSettings.map(item => {
      let obj = Object.assign({}, item)
      if(obj.platform === tab) {
        obj.calendar = allowCalendar
        obj.assignment = allowAssignment
      }
      return obj
    })
    setAllowSettings(arr)
    allow_settings_ref.current = arr
  }, [allowCalendar, allowAssignment])


  useEffect(() => {
    handlePage(1)
    let arr = list.filter(item => item.tab === tab)
    handleTotalPage(Math.ceil(arr.length / 14))
  }, [tab, list])

  const get_configs = () => {
    setLoading(true)
    getLTIConfig({ org_id: userInfo.orgID })
    .then(res => {
      setAllowSettings(res.data['allow_settings'])
      let arr = res.data.list.map(item => (
        {
          toolTyp: item.type,
          domain: item.domain,
          clientID: item.client_id,
          deployID: item.deployment_id,
          devID: item.developer_id,
          devKey: item.developer_key,
          note: (item.note) ? item.note : '',
          tab: (item.iss === 'Canvas') ? 'LTI Canvas' : 'LTI BlackBoard',
          configId: item.id
        }
      ))
      setList(arr)
    })
    .catch(err => console.info('err', err))
    .finally(() => {
      setLoading(false)
      setLtiSettingsLoading(false)
    })
  }

  const handleList = (dataObj) => {

    const handleFail = (res) => {
      if(res.data.message && res.data.message == 'fail') {
        console.info('a pair of  clientId and deploymentId cannot be registered twice.')
        handleOpenToaster('This config had already been registered.', 'error')
        setLoading(false)
        return
      }
    }

    dataObj.iss = String(tab).split(' ')[1]
    dataObj.orgID = userInfo.orgID

    console.info('add config', dataObj)

    setLoading(true)
    setChosenConfig({})

    if(addInstanceType === 'Add') {
      createLTIConfig(dataObj)
      .then(res => {
        console.info('res', res)
        handleFail(res)
        get_configs()
      })
      .catch(err => setLoading(false))
    } else {
      updateLTIConfig(dataObj)
      .then(res => {
        console.info('res', res)
        handleFail(res)
        get_configs()
      })
      .catch(err => setLoading(false))
    }
  }

  const handleChosenConfig = (item) => {
    setChosenConfig(JSON.parse(JSON.stringify(item)))
  }

  const removeConfig = () => {
    setLoading(true)
    setChosenConfig({})
    deleteLTIConfig({ id: chosenConfig.configId })
    .then(res => {
      console.info('res', res)
      get_configs()
    })
    .catch(err => setLoading(false))
  }

  const handleAllowCalendarChange = (e) => {
    setCallAPI(true)
    console.info('handleAllowCalendarChange', e.target.checked)
    setAllowCalendar(e.target.checked)
  }

  const handleAllowAssignmentChange = (e) => {
    setCallAPI(true)
    console.info('handleAllowAssignmentChange', e.target.checked)
    setAllowAssignment(e.target.checked)
  }

  const handleDeleteModal = (sts) => {
    setDeleteModal(sts)
  }

  const handleAddInstanceModal = (sts, typ) => {
    setAddInstanceModal(sts)
    setAddInstanceType(typ)
    if(!sts) setChosenConfig({})
  }

  return {
    tab, setTab, allowCalendar, handleAllowCalendarChange, allowAssignment, handleAllowAssignmentChange, 
    page, totalPage, handlePage, deleteModal, handleDeleteModal, addInstanceModal, handleAddInstanceModal,
    addInstanceType, list, handleList, chosenConfig, handleChosenConfig, removeConfig, loading, ltiSettingsLoading,
    openToaster, toasterText, toastSeverity, handleCloseToaster
  }
}


export default useLTISettings
