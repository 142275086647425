import styles from './Results.module.css'
import ArrowIcon from '../../../assets/icons/breadcrumbArrow.svg'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import PeopleIcon from '../../../assets/icons/grayPeople.svg'
import QuestionIcon from '../../../assets/icons/question.svg'
import Popover from '@mui/material/Popover'
import usePopover from '../../../CustomHooks/usePopover'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import ViewResultIcon from '../../../assets/icons/quiz/viewResult.svg'
import { Fragment, useEffect } from 'react'
import CustomPagination from '../../../parts/CustomPagination'
import dayjs from 'dayjs'
import CircularProgress from '@mui/material/CircularProgress'
import { AssignmentStatusEnum } from '../../../utils/enum'

const TableHeader = () => {
  return (
    <div className={`${styles.tableWrap} ${styles.tableHeader} ${styles.center}`}>
      <div className={`${styles.tableItem} ${styles.name}`}>Name</div>
      <div className={`${styles.tableItem} ${styles.status}`}>
        <div>Status</div>
        <Tooltip
          title={`Assignments are "${AssignmentStatusEnum.DRAFT}" when created, "${AssignmentStatusEnum.PUBLISHED}" after shared, and "${AssignmentStatusEnum.FINISHED}" when manually ended.`}
          placement='bottom-start'
          componentsProps={{
            popper: {
              sx: {
                [`& .${tooltipClasses.arrow}`]: {
                  color: (theme) => theme.palette.common.black
                },
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: (theme) => theme.palette.common.black,
                  fontWeight: 400
                }
              }
            }
          }}
          arrow
        >
          <div className={styles.statusHint}>
            <QuestionMarkIcon sx={{ fontSize: 16, color: 'white' }} />
          </div>
        </Tooltip>
      </div>
      <div className={`${styles.tableItem} ${styles.time}`}>Time</div>
      <div className={`${styles.tableItem} ${styles.question}`}>Question</div>
      <div className={`${styles.tableItem} ${styles.participant}`}>Participant</div>
      <div className={`${styles.tableItem} ${styles.action}`}>Action</div>
    </div>
  )
}

const TableContent = ({ item, toSingleResultPage }) => {
  const { open, anchorEl, openPopover, handleClose } = usePopover()

  return (
    <div className={`${styles.tableWrap} ${styles.tableContent} ${styles.center}`}>
      <div className={`${styles.tableItem} ${styles.name} ${styles.ellipsis}`}>{item.name}</div>
      <div className={`${styles.tableItem} ${styles.status}`}>
        <div className={styles.published}>{item.status}</div>
      </div>
      <div className={`${styles.tableItem} ${styles.time} ${styles.ellipsis}`}>{`${dayjs(dayjs.unix(item.startTime)).format('YYYY/MM/DD HH:mm A')} - ${dayjs(
        dayjs.unix(item.endTime)
      ).format('YYYY/MM/DD HH:mm A')}`}</div>
      <div className={`${styles.tableItem} ${styles.question}`}>
        <img alt='question' src={QuestionIcon} />
        <div>{item.questionCount}</div>
      </div>
      <div className={`${styles.tableItem} ${styles.participant}`}>
        <img alt='people' src={PeopleIcon} />
        <div>{item.totalParticipants}</div>
      </div>
      <div className={`${styles.tableItem} ${styles.action}`}>
        <div className={`${styles.actionBox} ${open && styles.active}`} onClick={openPopover}>
          <MoreHorizIcon sx={{ color: '#707070', transform: 'scale(1.2)', cursor: 'pointer' }} />
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <div className={styles.menuWrap}>
          <div
            className={`${styles.option} ${styles.blue}`}
            onClick={() => {
              toSingleResultPage(item)
              handleClose()
            }}
          >
            <div className={`${styles.icon} ${styles.resultIcon}`}></div>
            <div>View results</div>
          </div>
          {/* <div
            className={`${styles.option} ${styles.red}`}
            onClick={() => {
              handleClose()
            }}
          >
            <div className={`${styles.icon} ${styles.deleteIcon}`}></div>
            <div>Delete</div>
          </div> */}
        </div>
      </Popover>
    </div>
  )
}

const Results = ({ setStep, toSingleResultPage, call_getAssignmentResults, assignmentResult, resultsPage, resultsTotalPage, handleResultsPage, resultLoading }) => {
  useEffect(() => {
    call_getAssignmentResults()
  }, [])

  return (
    <div>
      {resultLoading ? (
        <div
          style={{
            height: 'calc(100vh - 330px)',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Fragment>
          <div className={styles.utilityRowWrap}>
            <div className={styles.left}>
              <div onClick={() => setStep(1)} style={{ cursor: 'pointer' }}>
                Assignment
              </div>
              <div>
                <img src={ArrowIcon} alt='' />
              </div>
              <div>Assignment Results</div>
            </div>
            <div className={styles.right}>
              <button onClick={() => setStep(1)} className={styles.btn}>
                Back to assignment list
              </button>
            </div>
          </div>
          {assignmentResult.length === 0 && (
            <div className={styles.empty}>
              <div>We're sorry, but we could not find any student records.</div>
              <div>Try to refresh this page again in a few minutes.</div>
            </div>
          )}
          {assignmentResult.length !== 0 && (
            <div className={styles.tableContainer}>
              <TableHeader />
              {assignmentResult.map((item, idx) => (
                <TableContent key={idx} item={item} toSingleResultPage={toSingleResultPage} />
              ))}
            </div>
          )}
          {resultsTotalPage > 1 && (
            <div className={styles.paginationWrap}>
              <CustomPagination page={resultsPage} totalPage={resultsTotalPage} handlePage={handleResultsPage} />
            </div>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default Results
