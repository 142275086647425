import { Amplify, Auth } from 'aws-amplify'
import { createContext, useEffect, useState } from 'react'
import { getPermissionAndOrg, getPublicKey, googleSignIn, renewToken } from '../api/index'
import * as jose from 'jose'
import dayjs from 'dayjs'

/*
amplify configuration example

// const config = {
//   Auth: {
//     identityPoolId: '',
//     region: 'eu-central-1',
//     userPoolId: 'eu-central-1_uZ8MW3lpX',
//     userPoolWebClientId: '3fgsve1aupueoesu5tm4shdk5u',
//   },
//   oauth: {
//     domain: 'universe.auth.eu-central-1.amazoncognito.com',
//     scope: ['aws.cognito.signin.user.admin', 'email', 'openid'],
//     redirectSignIn: 'http://localhost:3000/',
//     responseType: 'token',
//   }
// }

*/

Amplify.configure({
  Auth: {
    userPoolId: 'eu-central-1_uZ8MW3lpX', //UserPool ID
    region: 'eu-central-1',
    userPoolWebClientId: '3fgsve1aupueoesu5tm4shdk5u' //WebClientId
  }
})

const orgSlot = { Admin: { occupied: 0, whole: 20 }, Teacher: { occupied: 0, whole: 325 }, Student: { occupied: 0, whole: 40 } }

const useAuth = () => {
  const [userInfo, setUserInfo] = useState({})
  const [orgOptions, setOrgOptions] = useState([])
  const [chosenOrg, setChosenOrg] = useState('')
  const [authLoading, setAuthLoading] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [forceRefreshKey, setForceRefreshKey] = useState(0)

  useEffect(() => {
    console.info('useAuth userInfo', userInfo)
    if (!userInfo.userID) return
    console.info('send GTM event', userInfo.userID)
    window.dataLayer.push({
      event: 'userDataSet',
      username: userInfo.userID,
      language: 'en',
      signUpAt: userInfo.signUpAt,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName
    })
  }, [userInfo])

  const handleErrorText = (txt) => {
    setErrorText(txt)
  }

  const handleUserInfo = async (userInfo, typ) => {
    const perOrg = await getPermissionAndOrg(userInfo, typ)

    // if google user doesn't have an account in database, redirect them to product page
    if (!perOrg.data.content.Items.length && typ === 'google') {
      clearAllRecord()
      window.location.replace(`${process.env.REACT_APP_PRODUCT_PAGE_DOMAIN}/account/thirdsignup?email=${userInfo.email}`)
      return
    }

    let orgID = localStorage.getItem('orgID')
    let res
    if (orgID) {
      res = perOrg.data.content.Items.find((item) => item.orgID.S === orgID)
    } else {
      res = perOrg.data.content.Items[0]
    }
    setOrgOptions(JSON.parse(JSON.stringify(perOrg.data.content.Items)))
    setChosenOrg(res.orgDisplayName.S)
    localStorage.setItem('orgID', res.orgID.S)
    let orgInfo = {
      nickname: res.nickname.S,
      orgID: res.orgID.S,
      userID: res.UserID.S,
      payment: res.payment.BOOL,
      isExpired: res.is_expired.BOOL,
      slot:
        res.users.M === ''
          ? orgSlot
          : { Admin: { occupied: 0, whole: res.users.M.admin }, Teacher: { occupied: 0, whole: res.users.M.teacher }, Student: { occupied: 0, whole: res.users.M.student } },
      // identity: 'teacher',
      identity: res.identity ? res.identity.S : 'admin',
      onlineNum: res.onlineNum.N,
      expireDate: new Date(dayjs.unix(res.expire_date.N)),
      observationMode: res.observation_mode.BOOL,
      signUpAt: res.signUpAt.N,
      firstName: res.firstName.S,
      lastName: res.lastName.S
    }

    if (!orgInfo.onlineNum) {
      orgInfo.onlineNum = orgInfo.slot.Admin.whole + orgInfo.slot.Teacher.whole + orgInfo.slot.Student.whole
    }

    return { ...userInfo, ...orgInfo }
  }

  const setLocalStorage = (userInfo) => {
    Object.keys(userInfo).forEach((key) => {
      if (key.includes('Token')) return
      localStorage.setItem(key, userInfo[key])
    })
    localStorage.setItem('refreshToken', userInfo.refreshToken)
  }

  const clearLocalStorage = () => {
    localStorage.removeItem('username')
    localStorage.removeItem('email')
    localStorage.removeItem('email_verified')
    localStorage.removeItem('refreshToken')
  }

  const signIn = async (username, password) => {
    setAuthLoading(true)
    try {
      const user = await Auth.signIn(username, password)
      let userInfo = {
        username: user.attributes.name,
        email: user.attributes.email.toLowerCase(),
        email_verified: user.attributes.email_verified,
        accessToken: user.signInUserSession.accessToken.jwtToken,
        idToken: user.signInUserSession.idToken.jwtToken,
        refreshToken: user.signInUserSession.refreshToken.token
      }
      reformUserInfo(userInfo, 'normal')
    } catch (error) {
      handleErrorText('Incorrect username or password.')
      setAuthLoading(false)
    }
  }

  const reformUserInfo = async (userData, typ) => {
    let userInfo = {
      username: userData.username,
      email: userData.email.toLowerCase(),
      email_verified: userData.email_verified,
      accessToken: userData.accessToken,
      idToken: userData.idToken,
      refreshToken: userData.refreshToken
    }
    setLocalStorage(userInfo)
    let obj = await handleUserInfo(userInfo, typ)
    setUserInfo(obj)
    setAuthLoading(false)
  }

  const googleSSOGrantCodeFlow = (code) => {
    setAuthLoading(true)
    googleSignIn(code).then((res) => verifyToken(res.data.access_token, res.data.id_token, res.data.refresh_token))
  }

  const verifyToken = async (access_token, id_token, refresh_token) => {
    let res = await getPublicKey()
    let jwk = res.data.keys.find((item) => item.kid === 'GaL0C5y8IwZyPULZRHx0IjwQAO0jDt4fgrXqbTKWMF8=')
    const josePublicKey = await jose.importJWK(jwk, 'RS256')
    try {
      const { payload, protectedHeader } = await jose.jwtVerify(id_token, josePublicKey, {
        issuer: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_uZ8MW3lpX',
        audience: '50b4at73t6jchcmtorl4nkp46f'
      })
      let userInfo = {
        username: payload['cognito:username'],
        email: payload.email.toLowerCase(),
        email_verified: payload.email_verified,
        accessToken: access_token,
        idToken: id_token,
        refreshToken: refresh_token
      }
      reformUserInfo(userInfo, 'google')
    } catch {
      handleErrorText('Incorrect username or password.')
      setAuthLoading(false)
    }
  }

  const getNewTokensForGoogleUser = async () => {
    // when reload page or token expire, re-get token by refresh token
    try {
      const res = await renewToken()
      console.info('get new tokens', res)
      let username = localStorage.getItem('username')
      let email = localStorage.getItem('email').toLowerCase()
      let email_verified = localStorage.getItem('email_verified')
      let refreshToken = localStorage.getItem('refreshToken')
      let userInfo = {
        username: username,
        email: email,
        email_verified: email_verified === 'true' ? true : false,
        accessToken: res.data.access_token,
        idToken: res.data.id_token,
        refreshToken: refreshToken
      }
      reformUserInfo(userInfo, 'google')
    } catch (err) {
      window.location.replace('/')
      setAuthLoading(false)
      clearLocalStorage()
    }
  }

  const getCurrentSession = async () => {
    /*
    if login through google oauth, we cannot re-get userinfo through amplify api
    instead, we re-get userinfo through refresh token!
    */
    setAuthLoading(true)
    try {
      const user = await Auth.currentSession()
      let username = localStorage.getItem('username')
      let email = localStorage.getItem('email').toLowerCase()
      let email_verified = localStorage.getItem('email_verified')
      let userInfo = {
        username: username,
        email: email,
        email_verified: email_verified === 'true' ? true : false,
        accessToken: user.accessToken.jwtToken,
        idToken: user.idToken.jwtToken,
        refreshToken: user.refreshToken.token
      }
      reformUserInfo(userInfo, 'normal')
    } catch (error) {
      getNewTokensForGoogleUser()
    }
  }

  const clearAllRecord = () => {
    setUserInfo({})
    setOrgOptions([])
    localStorage.removeItem('orgID') // only clear this when signOut not refresh
    clearLocalStorage()
  }

  const signOut = async (setUserInfo) => {
    setUserInfo({})
    setAuthLoading(true)
    try {
      await Auth.signOut()
      clearAllRecord()
    } catch (error) {
      console.log('error signing out: ', error)
    } finally {
      setAuthLoading(false)
    }
  }

  const resendCode = async (email) => {
    try {
      await Auth.resendSignUp(email)
    } catch (error) {
      console.log('error resend code: ', error)
    }
  }

  const updateObservationMode = (val) => {
    let obj = JSON.parse(JSON.stringify(userInfo))
    obj.observationMode = val
    setUserInfo(obj)
  }

  const updateOrgId = (orgID) => {
    let obj = JSON.parse(JSON.stringify(userInfo))
    let chosenOrg = orgOptions.find((item) => item.orgDisplayName.S === orgID)
    obj.nickname = chosenOrg.nickname.S
    obj.orgID = chosenOrg.orgID.S
    obj.userID = chosenOrg.UserID.S
    obj.payment = chosenOrg.payment.BOOL
    obj.isExpired = chosenOrg.is_expired.BOOL
    obj.identity = chosenOrg.identity ? chosenOrg.identity.S : 'admin'
    obj.expireDate = new Date(dayjs.unix(chosenOrg.expire_date.N))
    obj.slot =
      chosenOrg.users.M === ''
        ? orgSlot
        : {
            Admin: { occupied: 0, whole: chosenOrg.users.M.admin },
            Teacher: { occupied: 0, whole: chosenOrg.users.M.teacher },
            Student: { occupied: 0, whole: chosenOrg.users.M.student }
          }
    obj.observationMode = chosenOrg.observation_mode.BOOL
    // when change userInfo, remove cache so users wouldn't see old teacher lists in add class modal
    localStorage.removeItem('teacherCache')
    localStorage.setItem('orgID', chosenOrg.orgID.S)
    setUserInfo(obj)
    setForceRefreshKey(forceRefreshKey + 1)
  }

  const handleChosenOrg = (e) => {
    setChosenOrg(e.target.value)
    updateOrgId(e.target.value)
  }

  return {
    signIn,
    signOut,
    resendCode,
    getCurrentSession,
    authLoading,
    errorText,
    handleErrorText,
    userInfo,
    setUserInfo,
    chosenOrg,
    handleChosenOrg,
    orgOptions,
    forceRefreshKey,
    googleSSOGrantCodeFlow,
    updateObservationMode
  }
}

export default useAuth

export const UserContext = createContext()
