import styles from './EditStudentNoteModal.module.css'
import ClearIcon from '@mui/icons-material/Clear'
import { useState } from 'react'

const EditStudentNoteModal = ({ handleEditNoteModal, chosenAccount, editNoteByStudent }) => {

  const [note, setNote] = useState(chosenAccount.note)

  const handleNote = (e) => {
    setNote(e.target.value)
  }

  return (
    <div className={styles.modalWrap}>
      <div className={styles.titleWrap}>
        <div className={styles.title}>Note</div>
        <div className={styles.icon} onClick={() => handleEditNoteModal(false)}><ClearIcon /></div>
      </div>
      <div className={styles.textarea}>
        <textarea style={{ width: '918px', height: '144px' }} value={note} onChange={handleNote} placeholder="Note" maxLength={50}/>
        <div className={styles.lengthHint}>{note.length}/50</div>
      </div>
      <button className={styles.update} onClick={() => editNoteByStudent(note)}>update</button>
    </div>
  )
}

export default EditStudentNoteModal