import styles from './QuizDetailModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ArrowLeftIcon from '../../../assets/icons/arrowLeft.svg'
import ArrowRightIcon from '../../../assets/icons/arrowRight.svg'
import PeopleIcon from '../../../assets/icons/portfolio/coloredPeople.svg'
import TimerIcon from '../../../assets/icons/timer.svg'
import AlarmIcon from '../../../assets/icons/alarm.svg'
import DoneIcon from '@mui/icons-material/Done'
import { convertToDate, convertToAMPMtime } from '../../../utils/time'
import { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

const QuizDetailModal = ({ setQuizDetailModal, quizDetail, selectedPopQuiz, loadingPopQuiz }) => {
  const [index, setIndex] = useState(0)
  useState(() => {
    console.log('quizDetail', quizDetail)
  }, [])

  const onChangeIndex = (type) => {
    console.log('test')
    if ((type === 'prev' && index === 0) || (type === 'next' && index === quizDetail?.questions.length - 1)) return
    setIndex((prev) => {
      console.log('1', prev)
      if (type === 'prev') {
        console.log('2', prev + 1)
        return prev - 1
      } else {
        return prev + 1
      }
    })
  }

  const questionType = (type) => {
    console.log('type', type)
    switch (type) {
      case 'tf':
        return 'True/False'
      case 'single':
        return 'Single Selection'
      default:
        return 'Multiple Selection'
    }
  }

  return (
    <div className={styles.modalWrap}>
      {loadingPopQuiz && (
        <div
          style={{
            height: 'calc(100vh - 330px)',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loadingPopQuiz && (
        <div>
          <div className={styles.titleArea}>
            <div className={styles.title}>
              <h1>{quizDetail?.name}</h1>
              <div className={styles.cancel} onClick={() => setQuizDetailModal(false)}>
                <CloseRoundedIcon sx={{ color: '#808080', '&:hover': { color: '#1452CC' } }} />
              </div>
            </div>

            <div className={styles.detail}>
              <div>
                <img alt='answerRate' src={PeopleIcon} />
                <p>{selectedPopQuiz.answerRate}% Answer Rate</p>
              </div>
              <div>
                <img alt='timer' src={AlarmIcon} />
                <p>{quizDetail?.timer === 0 ? '-' : `${Math.floor(quizDetail?.timer / 60)}m ${quizDetail?.timer % 60}s`}</p>
              </div>
            </div>
            <div className={styles.timer}>
              <img alt='timer' src={TimerIcon} />
              <p>
                {`Quiz start time: ${convertToDate(selectedPopQuiz.startTime)} ${convertToAMPMtime(selectedPopQuiz.startTime)} (UTC${new Date()
                  .toString()
                  .split('GMT')[1]
                  .substring(0, 5)})`}
              </p>
            </div>
          </div>
          <div className={styles.questionArea}>
            <div className={styles.questionType}>
              <p>Question {index + 1}</p>
              <div className={styles.questionTypeIcon}>{questionType(quizDetail?.questions[index].type)}</div>
            </div>
            <p>{quizDetail?.questions[index].content}</p>
            {quizDetail?.questions[index].image && <img alt='questionImage' className={styles.questionImage} src={quizDetail?.questions[index].image} />}
            <div className={styles.optionArea}>
              {quizDetail?.questions[index].options.map((option) => {
                return (
                  <div className={styles.option}>
                    <span>{option.content}</span>
                    {option.isAnswer && (
                      <div className={styles.check}>
                        <DoneIcon sx={{ color: '#fff' }} />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
            <div className={styles.paginator}>
              <img className={index === 0 ? styles.disabled : undefined} alt='previous' src={ArrowLeftIcon} onClick={() => onChangeIndex('prev')} />
              <span>{index + 1}</span>
              <span>/</span>
              <span>{quizDetail?.questions.length}</span>
              <img className={index === quizDetail?.questions.length - 1 ? styles.disabled : undefined} alt='next' src={ArrowRightIcon} onClick={() => onChangeIndex('next')} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default QuizDetailModal
