import styles from './Portfolio.module.css'
import MainPage from './MainPage/MainPage'
import usePortfolioList from '../../CustomHooks/usePortfolioList'
import Dashboard from './Dashboard/Dashboard'
import AttendedStudent from './AttendedStudent/AttendedStudent'
import { useState } from 'react'
import StudentPortfolio from './StudentPortfolio/StudentPortfolio'
import Toaster from '../../parts/Toaster'

const Portfolio = () => {
  const [step, setStep] = useState(1)
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [selectedClassList, setSelectedClassList] = useState([])
  const [selectedClassIndex, setSelectedClassIndex] = useState(null)
  const {
    page,
    totalPage,
    handlePage,
    handleTotalPage,
    portfolioList,
    studentQuizList,
    setStudentQuizList,
    call_getPortfolioListApi,
    call_getDashboardApi,
    loadingMainPage,
    loadingDashboard,
    setLoadingDashboard,
    dashboardData,
    call_getAttendedStudentsApi,
    attendedStudents,
    setAttendedStudents,
    loadingAttendedStudents,
    call_getStudentPortfolio,
    loadingStudentPortfolio,
    studentPortfolio,
    openToaster,
    toasterText,
    handleOpenToaster,
    handleCloseToaster,
    severity,
    setSeverity,
    call_getPopQuizDetail,
    quizDetail,
    setLoadingPopQuiz,
    loadingPopQuiz,
    call_deleteQuizRecordApi
  } = usePortfolioList()

  return (
    <>
      <div className={styles.portfolioWrap}>
        {step === 1 && (
          <MainPage
            portfolioList={portfolioList}
            handleTotalPage={handleTotalPage}
            handlePage={handlePage}
            totalPage={totalPage}
            page={page}
            setStep={setStep}
            call_getPortfolioListApi={call_getPortfolioListApi}
            setSelectedClassList={setSelectedClassList}
            setSelectedClassIndex={setSelectedClassIndex}
            loadingMainPage={loadingMainPage}
            setSeverity={setSeverity}
            handleOpenToaster={handleOpenToaster}
          />
        )}
        {step === 2 && (
          <Dashboard
            setStep={setStep}
            selectedClassList={selectedClassList}
            selectedClassIndex={selectedClassIndex}
            setSelectedClassIndex={setSelectedClassIndex}
            call_getDashboardApi={call_getDashboardApi}
            setLoadingDashboard={setLoadingDashboard}
            loadingDashboard={loadingDashboard}
            dashboardData={dashboardData}
            setSeverity={setSeverity}
            handleOpenToaster={handleOpenToaster}
            call_getPopQuizDetail={call_getPopQuizDetail}
            quizDetail={quizDetail}
            setLoadingPopQuiz={setLoadingPopQuiz}
            loadingPopQuiz={loadingPopQuiz}
            call_deleteQuizRecordApi={call_deleteQuizRecordApi}
          />
        )}
        {step === 3 && (
          <AttendedStudent
            setStep={setStep}
            setSelectedStudent={setSelectedStudent}
            selectedClassList={selectedClassList}
            selectedClassIndex={selectedClassIndex}
            setSelectedClassIndex={setSelectedClassIndex}
            call_getAttendedStudentsApi={call_getAttendedStudentsApi}
            attendedStudents={attendedStudents}
            setAttendedStudents={setAttendedStudents}
            loadingAttendedStudents={loadingAttendedStudents}
          />
        )}
        {step === 4 && (
          <StudentPortfolio
            setStep={setStep}
            selectedStudent={selectedStudent}
            studentQuizList={studentQuizList}
            setStudentQuizList={setStudentQuizList}
            selectedClassList={selectedClassList}
            selectedClassIndex={selectedClassIndex}
            setSelectedClassIndex={setSelectedClassIndex}
            call_getStudentPortfolio={call_getStudentPortfolio}
            loadingStudentPortfolio={loadingStudentPortfolio}
            studentPortfolio={studentPortfolio}
          />
        )}
        <Toaster openToaster={openToaster} handleCloseToaster={handleCloseToaster} severity={severity} message={toasterText} />
      </div>
    </>
  )
}

export default Portfolio
