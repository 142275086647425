import { useEffect, useState } from 'react'
import { getAccountList } from '../api/index'
import { getMonth } from '../parts/Calendar/utils'
import moment from 'moment-timezone'
import { UserContext } from './useAuth'
import { useContext } from 'react'

import dayjs from 'dayjs'
import { reformAccountList } from '../utils/handleMemberList'

const weekDay = [
  { text: 'Sun', selected: false },
  { text: 'Mon', selected: false },
  { text: 'Tue', selected: false },
  { text: 'Wed', selected: false },
  { text: 'Thu', selected: false },
  { text: 'Fri', selected: false },
  { text: 'Sat', selected: false }
]

const useScheduleForm = (editInfoItem = {}, editInfoMode = {}) => {
  const [courseName, setCourseName] = useState('')
  const [teachers, setTeachers] = useState([])
  const [teacherName, setTeacherName] = useState('')
  const [classroom, setClassroom] = useState(1)
  const [timeZone, settimeZone] = useState(moment.tz.guess())
  const [sDate, setSdate] = useState(dayjs().hour(0).minute(0).second(0))
  const [eDate, setEdate] = useState(dayjs(dayjs(sDate).add(3, 'month')))
  const [stim, setStim] = useState('')
  const [etim, setEtim] = useState('')
  const [frequency, setFrequency] = useState('Repeat: Never')
  const [chosenWeekDayArr, setChosenWeekDayArr] = useState(JSON.parse(JSON.stringify(weekDay)))
  const [disableWeekDayOptions, setDisableWeekDayOptions] = useState(true)
  const [disableAddBtn, setDisableAddBtn] = useState(true)
  const [disableCreateBtn, setDisableCreateBtn] = useState(true)
  const [classList, setClassList] = useState([])
  const [autoJoin, setAutoJoin] = useState(true)

  const { userInfo, openUpgradePlan, classroomStyleList } = useContext(UserContext)

  useEffect(() => {
    // edit mode data
    reAssignEditInfoData()
  }, [])

  useEffect(() => {
    if (dayjs(sDate).isSame(dayjs(eDate)) || dayjs(sDate).isAfter(dayjs(eDate))) {
      setEdate(dayjs(sDate).add(3, 'month'))
    }
  }, [eDate, frequency])

  useEffect(() => {
    // when frequency is never, don't have to care about end Date
    if (frequency === 'Never' || frequency === 'Repeat: Never') return
    if (dayjs(sDate).isSame(dayjs(eDate)) || dayjs(sDate).isAfter(dayjs(eDate))) {
      setEdate(dayjs(sDate).add(3, 'month'))
    }
  }, [sDate])

  useEffect(() => {
    // edit mode data
    reAssignEditInfoData()
  }, [editInfoItem])

  useEffect(() => {
    getTeachers()
  }, [])

  useEffect(() => {
    setDisableWeekDayOptions(frequency === 'Repeat: Never')
  }, [frequency])

  useEffect(() => {
    setDisableAddBtn(checkDisableAddBtn())
  }, [courseName, teacherName, stim, etim, chosenWeekDayArr, frequency, classroom, timeZone, teachers])

  useEffect(() => {
    setDisableCreateBtn(checkDisableCreateBtn())
  }, [classList])

  const reAssignEditInfoData = () => {
    if (Object.keys(editInfoItem).length === 0) return
    console.info('editInfoItem', editInfoItem)
    setCourseName(editInfoItem.courseName)
    setTeacherName(editInfoItem.teacherName)
    setStim(editInfoItem.begin)
    setEtim(editInfoItem.end)
    setClassroom(editInfoItem.roomStyleConfigId)
    settimeZone(editInfoItem.timeZone)
    // multiple or never => group first date ; single => begin
    setSdate(editInfoMode === 'Single' ? dayjs(editInfoItem.begin) : dayjs(editInfoItem.firstDate))
    setEdate(dayjs(editInfoItem.lastDate))
    setFrequency(frequency === 'Never' ? 'Repeat: Never' : editInfoItem.frequency)
    setChosenWeekDayArr(JSON.parse(JSON.stringify(editInfoItem.days)))
    setAutoJoin(editInfoItem.isAutoJoin)
  }

  const checkDisableCreateBtn = () => {
    for (let item of classList) {
      if (!item.disabled) return false
    }
    return true
  }

  const checkIdentity = (teacherTempList) => {
    return userInfo.identity.toLowerCase() === 'teacher' ? teacherTempList.filter((item) => item.email.toLowerCase() === userInfo.email.toLowerCase()) : teacherTempList
  }

  const handleTeachers = (list) => {
    console.info('handleTeachers', list)
    setTeachers(list)
    // teacher list is disabled and the field is filled with username when user is teacher
    if (userInfo.identity === 'teacher') {
      setTeacherName(list[0].name)
    }
  }

  const getTeachers = () => {
    if (localStorage.getItem('teacherCache')) {
      let teacherTempList = checkIdentity(JSON.parse(localStorage.getItem('teacherCache')))
      handleTeachers(teacherTempList)
      return
    }
    getAccountList({ email: userInfo.email, orgID: userInfo.orgID })
      .then((res) => {
        let arr = JSON.parse(res.data.body)
        let { t_list } = reformAccountList(arr)
        let teacherTempList = checkIdentity(t_list)
        handleTeachers(teacherTempList)
      })
      .catch((err) => {
        console.log('get member list error', err)
      })
  }

  const checkDisableAddBtn = () => {
    let selectedWeekDay = chosenWeekDayArr.filter((item) => item.selected)
    let repeatedArr = frequency !== 'Repeat: Never' ? selectedWeekDay.length === 0 : false
    let seCheck = dayjs(stim).isAfter(dayjs(etim))
    return !courseName || !teacherName || !stim || !etim || !classroom || !timeZone || repeatedArr || teachers.length === 0 || seCheck
  }

  const handletimeZoneChange = (e, value) => {
    settimeZone(value)
  }

  const handleAutoJoin = (e) => {
    setAutoJoin(e.target.checked)
  }

  const handleClassroomChange = (e) => {
    setClassroom(e.target.value)
  }

  const handleCourseNameChange = (e) => {
    setCourseName(e.target.value)
  }

  const handleTeacherNameChange = (e, value) => {
    setTeacherName(value)
  }

  const handleSdateChange = (newValue) => {
    // forbid editting by typing
    if (isNaN(newValue.$D)) return
    setSdate(newValue)
  }

  const handleEdateChange = (newValue) => {
    // forbid editting by typing
    if (isNaN(newValue.$D)) return
    setEdate(newValue)
  }

  const handleStimChange = (newValue) => {
    setStim(newValue)
  }

  const handleEtimChange = (newValue) => {
    setEtim(newValue)
  }

  const handleAccept = (newValue, label) => {
    // if stim > etim => exchange automatically
    if (label === 'Start Time' && !etim) {
      setStim(newValue)
      return
    }
    if (label === 'End Time' && !stim) {
      setEtim(newValue)
      return
    }
    if (label === 'Start Time' && dayjs(newValue).isBefore(dayjs(etim))) {
      setStim(newValue)
      return
    }
    if (label === 'Start Time' && dayjs(newValue).isAfter(dayjs(etim))) {
      setStim(etim)
      setEtim(newValue)
      return
    }
    if (label === 'End Time' && dayjs(newValue).isAfter(dayjs(stim))) {
      setEtim(newValue)
      return
    }
    if (label === 'End Time' && dayjs(newValue).isBefore(dayjs(stim))) {
      setEtim(stim)
      setStim(newValue)
      return
    }
  }

  const handleFrequency = (e, value) => {
    setFrequency(value)
  }

  const handleChosenWeekDayArr = (chosenItem) => {
    let arr = chosenWeekDayArr.map((item) => item)
    let obj = arr.find((item) => item.text === chosenItem.text)
    obj.selected = !obj.selected
    setChosenWeekDayArr(arr)
  }

  const addClass = () => {
    if (disableAddBtn) return
    let isRepeated = frequency !== 'Repeat: Never'
    let frequencyText = isRepeated ? frequency : 'Never'
    let styleConfigObj = classroomStyleList.find((styleItem) => styleItem.id == classroom)
    let showName = `${styleConfigObj.display_name}(1~${styleConfigObj.capacity})`
    if (styleConfigObj.room_type == 1) showName += '-Group Up'
    const obj = {
      autoJoin,
      courseName,
      roomStyleConfigId: classroom,
      showName,
      timeZone,
      teacher: teacherName,
      beginDate: dayjs(sDate).format('YYYY/MM/DD'),
      endDate: dayjs(eDate).format('YYYY/MM/DD'),
      startTime: dayjs(stim),
      endTime: dayjs(etim),
      frequency: frequencyText,
      days: chosenWeekDayArr.filter((item) => item.selected).map((item) => item.text),
      disabled: false
    }
    let arr = classList.map((item) => item)
    arr.push(obj)
    resetFormData()
    setClassList(arr)
  }

  const removeSingleClass = (idx) => {
    let arr = classList.map((item) => item)
    arr.splice(idx, 1)
    setClassList(arr)
  }

  const resetFormData = () => {
    // if user is teacher, select is disabled and chosenTeacher is always the user
    if (userInfo.identity.toLowerCase() !== 'teacher') {
      setTeacherName('')
    }
    setCourseName('')
    setFrequency('Repeat: Never')
    setChosenWeekDayArr(JSON.parse(JSON.stringify(weekDay)))
  }

  const handleClassList = (arr) => {
    setClassList(arr)
  }

  const getRepeatingDates = (frequency, startDate, endDate, days, time) => {
    // never
    if (frequency === 'Never' || frequency === 'Repeat: Never') {
      let h = dayjs(time).hour()
      let m = dayjs(time).minute()
      let timestamp = dayjs(startDate).hour(h).minute(m).second(0)
      return [toTimestamp(timestamp)]
    }
    // every month
    if (frequency === 'Every month') {
      let repeatingDateArr = []
      let chosenMonthMatrix = getMonth(dayjs(startDate))
      let h = dayjs(time).hour()
      let m = dayjs(time).minute()
      let weekOfMonth = 0
      for (let i = 0; i < chosenMonthMatrix.length; i++) {
        for (let dateItem of chosenMonthMatrix[i]) {
          if (dayjs(startDate).format('YYYY/MM/DD') === dayjs(dateItem).format('YYYY/MM/DD')) {
            weekOfMonth = i
            break
          }
        }
      }
      for (let i = 0; i < dayjs(endDate).diff(startDate, 'month') + 1; i++) {
        let nowYearMonth = dayjs(startDate).add(i, 'month').format('YYYY/MM')
        let monthMatrix = getMonth(dayjs(startDate).add(i, 'month'))
        for (let dateItem of monthMatrix[weekOfMonth]) {
          if (dayjs(dateItem).isBefore(dayjs(startDate))) continue
          if (dayjs(dateItem).isAfter(dayjs(endDate))) continue
          // same week but not the same month
          if (dayjs(dateItem).format('YYYY/MM') !== nowYearMonth) continue
          if (days.includes(dayjs(dateItem).format('ddd'))) {
            let timestamp = dayjs(dateItem).hour(h).minute(m).second(0)
            repeatingDateArr.push(toTimestamp(timestamp))
          }
        }
      }
      return repeatingDateArr
    }
    // every week
    let len = dayjs(endDate).diff(dayjs(startDate), 'day')
    let repeatingDateArr = []
    for (let i = 0; i < len + 1; i++) {
      let date = dayjs(startDate).add(i, 'day')
      let day = dayjs(date).format('ddd')
      let h = dayjs(time).hour()
      let m = dayjs(time).minute()
      let timestamp = dayjs(date).hour(h).minute(m).second(0)
      if (dayjs(timestamp).isBefore(dayjs(startDate))) continue
      if (dayjs(timestamp).isAfter(dayjs(endDate))) continue
      if (days.includes(day)) {
        repeatingDateArr.push(toTimestamp(timestamp))
      }
    }
    return repeatingDateArr
  }

  const toTimestamp = (time) => {
    let timWithZone = moment.tz(dayjs(time).format('YYYY-MM-DD HH:mm'), timeZone)
    return Math.floor(new Date(timWithZone).getTime() / 1000)
  }

  const reformSaveItem = () => {
    let dataArr = []
    classList.forEach((item) => {
      if (item.disabled) return
      let dataObj = {}
      dataObj.isAutoJoin = autoJoin
      dataObj.teacher = item.teacher
      dataObj.teacherEmail = teachers.find((listItem) => listItem.name === item.teacher).email
      dataObj.userID = teachers.find((listItem) => listItem.name === item.teacher).userID
      dataObj.courseName = item.courseName
      dataObj.courseNumber = 0 // String(Math.floor(Math.random() * 1000000))
      dataObj.frequency = item.frequency === 'Repeat: Never' ? 'Never' : item.frequency
      dataObj.timeZone = item.timeZone
      dataObj.begin = getRepeatingDates(item.frequency, item.beginDate, item.endDate, item.days, item.startTime)
      dataObj.end = getRepeatingDates(item.frequency, item.beginDate, item.endDate, item.days, item.endTime)
      dataObj.weekday = item.days
      dataObj.firstDate = toTimestamp(item.beginDate)
      dataObj.lastDate = item.frequency === 'Never' ? toTimestamp(item.beginDate) + 1 : toTimestamp(item.endDate)
      dataObj.orgId = userInfo.orgID
      dataObj.studentList = []
      dataObj.roomStyleConfigId = item.roomStyleConfigId
      dataObj.classSize = item.showName
      dataArr.push(dataObj)
    })
    return JSON.stringify(dataArr)
  }

  const reformEditItem = () => {
    let singleMode = editInfoMode === 'Single'
    let days = chosenWeekDayArr.filter((item) => item.selected).map((item) => item.text)
    let newStim = dayjs(stim).year(dayjs(sDate).year()).month(dayjs(sDate).month()).date(dayjs(sDate).date())
    let newEtim = dayjs(etim).year(dayjs(sDate).year()).month(dayjs(sDate).month()).date(dayjs(sDate).date())
    if (singleMode) {
      return {
        courseid: editInfoItem.courseid,
        oldBegin: Math.floor(new Date(moment.tz(dayjs(editInfoItem.begin).format('YYYY-MM-DD HH:mm'), editInfoItem.timeZone)).getTime() / 1000),
        //new info
        teacher: teacherName,
        teacherEmail: teachers.find((listItem) => listItem.name === teacherName).email,
        courseName: courseName,
        courseNumber: 0,
        frequency: frequency == 'Repeat: Never' ? 'Never' : frequency,
        timeZone,
        roomStyleConfigId: classroom,
        weekday: days,
        begin: toTimestamp(newStim),
        end: toTimestamp(newEtim),
        orgId: userInfo.orgID,
        studentList: editInfoItem.studentList
      }
    }
    return {
      courseid: editInfoItem.courseid,
      firstDate: toTimestamp(sDate),
      lastDate: toTimestamp(eDate),
      teacher: teacherName,
      teacherEmail: teachers.find((listItem) => listItem.name === teacherName).email,
      courseName,
      courseNumber: 0,
      frequency: frequency === 'Repeat: Never' ? 'Never' : frequency,
      timeZone,
      roomStyleConfigId: classroom,
      weekday: days,
      begin: getRepeatingDates(frequency, sDate, eDate, days, stim),
      end: getRepeatingDates(frequency, sDate, eDate, days, etim),
      orgId: userInfo.orgID,
      studentList: editInfoItem.studentList,
      isAutoJoin: autoJoin
    }
  }

  return {
    courseName,
    handleCourseNameChange,
    teachers,
    teacherName,
    handleTeacherNameChange,
    sDate,
    handleSdateChange,
    stim,
    handleStimChange,
    etim,
    handleEtimChange,
    handleAccept,
    frequency,
    handleFrequency,
    chosenWeekDayArr,
    handleChosenWeekDayArr,
    disableWeekDayOptions,
    eDate,
    handleEdateChange,
    disableAddBtn,
    addClass,
    classList,
    removeSingleClass,
    disableCreateBtn,
    handleClassList,
    reformSaveItem,
    reformEditItem,
    classroom,
    handleClassroomChange,
    timeZone,
    handletimeZoneChange,
    autoJoin,
    handleAutoJoin
  }
}

export default useScheduleForm
