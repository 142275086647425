import styles from './ImportFileWarningModal.module.css'
import Modal from '@mui/material/Modal'
import { Fragment, useState, useEffect } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

const ImportFileWarningModal = ({ generateCSVTemplate, showWarningHint, setShowWarningHint }) => {

  const [fileURL, setFileURL] = useState('#')

  useEffect(() => {
    setFileURL(generateCSVTemplate())
  }, [])

  return (
    <Fragment>
      <Modal
        disableAutoFocus
        sx={{ border: 'none' }}
        open={showWarningHint}
        onClose={() => setShowWarningHint(false)}>
          <div className={styles.modalWrap}>
            <div className={styles.titleRow}>
              <div className={styles.title}>Upload Error</div>
              <div onClick={() => setShowWarningHint(false)}><CloseRoundedIcon sx={{ color: '#808080', "&:hover": { color: "#1452CC" }, cursor: 'pointer', transform: 'scale(1.5)' }} /></div>
            </div>
            <div className={styles.message}>The file cannot be opened because there are problems with the content.</div>
            <div className={styles.message}>Download <a className={styles.link} href={fileURL} download="template.csv">template.csv</a> file and upload again. Please choose only .csv, .xls or .xlsx file to upload.</div>
            {/* <div className={styles.btnWrap}>
              <div className={`${styles.confirm}`} onClick={() => setShowWarningHint(false)}>Confirm</div>
            </div> */}
          </div>
      </Modal>
    </Fragment>
  )
}

export default ImportFileWarningModal