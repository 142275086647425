import dayjs from 'dayjs'

export function getMonth(time = dayjs()) {
  const year = dayjs(time).year()
  const month = dayjs(time).month()
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day(); // 1st of this month
  let currentMonthCount = 0 - firstDayOfTheMonth // how many days should I get from last month
  const daysMatrix = new Array(6).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount)) // api from new Date() Object
    });
  });
  daysMatrix[5][6] = dayjs(daysMatrix[5][6]).hour(23).minute(59).second(59)
  return daysMatrix;
}

export const getWeek = (time = dayjs()) => {
  const dayOfDate = dayjs(time).get('day')
  let weekCount = -1 - dayOfDate
  const weekDates = new Array(7).fill(null).map((item, idx) => {
    weekCount++
    let tim = dayjs(time).add(weekCount, 'day')
    return (idx == 6) ? dayjs(tim).hour(23).minute(59).second(0) : dayjs(tim).hour(0).minute(0).second(0)
    // 00:00 of SUN - 23:59 of SAT
  })
  return weekDates
}

// const isOverlap = (a, b) => {
//   let result = (new Date(a.start) <= new Date(b.end)) && (new Date(b.start) < new Date(a.end))
//   return result
// }

export const getIntersections = (orgArr, intervals, N) => {
  if(N < 2) return [0, [], []]
  // First interval
  let l = dayjs(intervals[0].start)
  let r = dayjs(intervals[0].end)
  let maxOverlap = 0
  // Check rest of the intervals
  // and find the intersection
  for (let i = 1; i < N; i++) {
    // If no intersection exists(before or after [l,r] period)
    // if(!isOverlap(intervals[0], intervals[i])) continue
    if((dayjs(intervals[i].start).isAfter(r) || dayjs(intervals[i].start).isSame(r)) || dayjs(intervals[i].end).isBefore(l)) continue

    // Else update the intersection
    maxOverlap++
    l = dayjs(intervals[i].start).isAfter(l) ? dayjs(intervals[i].start) : l // dayjs(intervals[i].start).isAfter(l) ? dayjs(intervals[i].start) : l
    r = dayjs(intervals[i].end).isBefore(r) ? dayjs(intervals[i].end) : r // dayjs(intervals[i].end).isBefore(r) ? dayjs(intervals[i].end) : r
  }
  return maxOverlap
}

export const newCountCSSGridValue = (date, renderItem, groupIdx, groupLength) => {
  let portion = Math.floor(20 / (groupLength))

  let hs = 2 + dayjs(renderItem.begin).hour()*12 + (dayjs(renderItem.begin).minute() / 5)
  let he = 2 + dayjs(renderItem.end).hour()*12 + (dayjs(renderItem.end).minute() / 5)
  // if cross day only show until 0am
  if(dayjs(renderItem.end).date() !== dayjs(renderItem.begin).date()) {
    if(dayjs(date).date() === dayjs(renderItem.begin).date()) {
      he = 290
    }
    if(dayjs(date).date() === dayjs(renderItem.end).date()) {
      hs = 2
    }
  }
  let gridStyle = {
    // draw item to the far right
    gridColumn: `${1 + groupIdx * portion} / ${(groupIdx === groupLength - 1) ? 21 : 1 + (groupIdx+renderItem.expandWidth+1) * portion}`,
    gridRow: `${hs} / ${he}`
  }
  if(gridStyle.gridColumn === 'NaN / NaN') { gridStyle.gridColumn = '1 / 21' }
  return gridStyle
}

export const countCSSGridValue = (date, renderItem, groupIdx, groupLength) => {
  // 先按照group個數算最大寬度 但之後要修改成 依照可以有的最大寬度畫上去！
  let portion = Math.floor(20 / (groupLength))
  let hs = 2 + dayjs(renderItem.begin).hour()*12 + (dayjs(renderItem.begin).minute() / 5)
  let he = 2 + dayjs(renderItem.end).hour()*12 + (dayjs(renderItem.end).minute() / 5)
  // if cross day only show until 0am
  if(dayjs(renderItem.end).date() !== dayjs(renderItem.begin).date()) {
    if(dayjs(date).date() === dayjs(renderItem.begin).date()) {
      he = 290
    }
    if(dayjs(date).date() === dayjs(renderItem.end).date()) {
      hs = 2
    }
  }
  let gridStyle = {
    gridColumn: `${1 + groupIdx * portion} / ${1 + (groupIdx+1) * portion}`,
    gridRow: `${hs} / ${he}`
  }
  if(gridStyle.gridColumn === 'NaN / NaN') { gridStyle.gridColumn = '1 / 21' }
  return gridStyle
}

export const testCountCSSGridValue = (date, renderItem) => {
  // 先按照group個數算最大寬度 但之後要修改成 依照可以有的最大寬度畫上去！
  let groupIdx = renderItem.group
  let startPoint = 1 + groupIdx * Math.floor((21 / renderItem.groupLength))
  let endPoint = (renderItem.groupLength === renderItem.group+1) ? 21 : startPoint + Math.floor(21 * renderItem.width / renderItem.groupLength)
  let hs = 2 + dayjs(renderItem.begin).hour()*12 + Math.round((dayjs(renderItem.begin).minute() / 5))
  let he = 2 + dayjs(renderItem.end).hour()*12 + Math.round((dayjs(renderItem.end).minute() / 5))
  // console.info('hs he', hs, he, (dayjs(renderItem.begin).minute() / 5), (dayjs(renderItem.end).minute() / 5))
  // if cross day only show until 0am
  if(dayjs(renderItem.end).date() !== dayjs(renderItem.begin).date()) {
    if(dayjs(date).date() === dayjs(renderItem.begin).date()) {
      he = 290
    }
    if(dayjs(date).date() === dayjs(renderItem.end).date()) {
      hs = 2
    }
  }
  let gridStyle = {
    gridColumn: `${startPoint} / ${endPoint}`,
    gridRow: `${hs} / ${he}`
  }
  if(gridStyle.gridColumn === 'NaN / NaN') { gridStyle.gridColumn = '1 / 21' }
  return gridStyle
}

export const hourGridStyle = (hour) => {
  let hs = 2 + hour*12
  let he = 2 + (hour+1)*12
  let gridStyle = {
    gridColumn: '1 / 21',
    gridRow: `${hs} / ${he}`
  }
  return gridStyle
}

export const axisTextStyle = (hour) => {
  let hs = Math.max(2 + hour*12 - 6, 1)
  let he = 2 + hour*12 + 6
  let gridStyle = {
    gridColumn: '1 / 21',
    gridRow: `${hs} / ${he}`
  }
  return gridStyle
}

export const axisLineStyle = (hour) => {
  let hs = 2 + hour*12
  let he = 2 + (hour+1)*12
  let gridStyle = {
    gridColumn: '1 / 21',
    gridRow: `${hs} / ${he}`
  }
  return gridStyle
}

////////////////////////////////////////
