import styles from './DeleteQuestionModal.module.css'

const DeleteQuestionModal = ({ onDeleteQuestion, setDeleteQuestionModal }) => {
  return (
    <div className={styles.modalWrap}>
      <div className={styles.title}>Delete Question</div>
      <div className={styles.message}>Are you sure you want to delete this Question?</div>
      <div className={styles.btnWrap}>
        <button
          className={`${styles.delete} ${styles.btn}`}
          onClick={() => {
            setDeleteQuestionModal(false)
            onDeleteQuestion()
          }}
        >
          Delete
        </button>
        <button className={`${styles.cancel} ${styles.btn}`} onClick={() => setDeleteQuestionModal(false)}>
          Cancel
        </button>
      </div>
    </div>
  )
}

export default DeleteQuestionModal
