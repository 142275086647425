import styles from './CancelUploadModal.module.css'

const CancelUploadModal = ({ onDiscard, onCancel }) => {
  return (
    <div className={styles.modalWrap}>
      <div className={styles.title}>Cancel</div>
      <div className={styles.message}>Are you sure you want to cancel your upload?</div>
      <div className={styles.btnWrap}>
        <button className={`${styles.delete} ${styles.btn}`} onClick={() => onDiscard()}>
          Confirm
        </button>
        <button className={`${styles.cancel} ${styles.btn}`} onClick={() => onCancel()}>
          Cancel
        </button>
      </div>
    </div>
  )
}

export default CancelUploadModal
