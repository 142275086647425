import styles from './Dashboard.module.css'
import ArrowIcon from '../../../assets/icons/breadcrumbArrow.svg'
import InformationIcon from '../../../assets/icons/information.svg'
import AttendanceIcon from '../../../assets/icons/attendance.svg'
import RaiseHandIcon from '../../../assets/icons/raiseHand.svg'
import QuizIcon from '../../../assets/icons/quiz.svg'
import PercentageIcon from '../../../assets/icons/portfolio/percentage.svg'
import PeopleIcon from '../../../assets/icons/portfolio/people.svg'
import ExportIcon from '../../../assets/icons/export.svg'
import ColoredPeopleIcon from '../../../assets/icons/portfolio/coloredPeople.svg'
import ColoredPercentageIcon from '../../../assets/icons/portfolio/coloredPercentage.svg'
import CustomAccordion from '../../../parts/Accordion/Accordion'
import { useEffect, useState } from 'react'
import whiteEye from '../../../assets/icons/portfolio/whiteEye.svg'
import blueEye from '../../../assets/icons/portfolio/blueEye.svg'
import ChangeDateCard from '../ChangeDateCard/ChangeDateCard'
import CircularProgress from '@mui/material/CircularProgress'
import { UserContext } from '../../../CustomHooks/useAuth'
import { useContext } from 'react'
import { convertToDate, convertToAMPMtime, convertTo24HRMode, convertToDateDash } from '../../../utils/time'
import { getClassExcel } from '../../../api'
import ExcelJs from 'exceljs'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import DeleteQuizRecordModal from '../../../parts/Modals/DeleteQuizRecordModal/DeleteQuizRecordModal'
import Modal from '../../../parts/Modal'
import QuizDetailModal from '../../../parts/Modals/QuizDetailModal/QuizDetailModal'
import ImageIcon from '../../../assets/icons/image.svg'

const Information = ({ selectedClass, setSeverity, handleOpenToaster }) => {
  const [exporting, setExporting] = useState(false)
  const onExportReport = async () => {
    setExporting(true)
    let data = {
      startTime: selectedClass.begin,
      endTime: selectedClass.end,
      courseId: selectedClass.courseId
    }

    getClassExcel(data).then(async (res) => {
      let result = res.data
      const workbook = new ExcelJs.Workbook()
      try {
        let courseRows = []
        for (const quiz of result.quizList) {
          for (const question of quiz.questions) {
            courseRows.push([
              selectedClass.courseName,
              convertToDate(selectedClass.begin),
              `${convertToAMPMtime(selectedClass.begin)} - ${convertToAMPMtime(selectedClass.end)} (UTC${selectedClass.gmtOffset})`,
              selectedClass.totalStudents === 0 ? 0 : Math.round((selectedClass.attendants / selectedClass.totalStudents) * 100),
              selectedClass.attendants,
              selectedClass.raiseHandTimes,
              selectedClass.raiseHandStudents,
              quiz.name,
              quiz.answerRate,
              quiz.accuracyRate,
              question.content,
              question.answerRate,
              question.accuracyRate
            ])
          }
        }

        if (!courseRows.length) {
          courseRows.push([
            selectedClass.courseName,
            convertToDate(selectedClass.begin),
            `${convertToAMPMtime(selectedClass.begin)} - ${convertToAMPMtime(selectedClass.end)} (UTC${selectedClass.gmtOffset})`,
            selectedClass.totalStudents === 0 ? 0 : Math.round((selectedClass.attendants / selectedClass.totalStudents) * 100),
            selectedClass.attendants,
            selectedClass.raiseHandTimes,
            selectedClass.raiseHandStudents,
            'No records found',
            '-',
            '-',
            '-',
            '-',
            '-'
          ])
        }
        const courseSheet = workbook.addWorksheet(`COURSE-${convertToDateDash(selectedClass.begin)}`)
        courseSheet.addTable({
          name: 'table1',
          ref: 'A1',
          columns: [
            { name: 'Course Name' },
            { name: 'Course Date' },
            { name: 'Course Time' },
            { name: 'Attendance (Percentage)' },
            { name: 'Attendance (Numbers)' },
            { name: 'Times of Raise Hand' },
            { name: 'Students of Raise Hand' },
            { name: 'Quiz Name' },
            { name: 'Quiz Answer Rate' },
            { name: 'Quiz Accuracy Rate' },
            { name: 'Question' },
            { name: 'Question Answer Rate' },
            { name: 'Question Accuracy Rate' }
          ],
          rows: courseRows
        })

        courseSheet.columns.forEach(function (column, i) {
          let maxLength = 0
          column['eachCell']({ includeEmpty: true }, function (cell) {
            var columnLength = cell.value ? cell.value.toString().length : 15
            if (columnLength > maxLength) {
              maxLength = columnLength
            }
          })
          column.width = maxLength < 15 ? 15 : maxLength
        })

        let studentRows = []
        const getAttendanceType = (type) => {
          switch (type) {
            case 'onTime':
              return 'On Time'
            case 'absence':
              return 'Absence'
            case 'late':
              return 'Late'
            case 'earlyLeave':
              return 'Early Leave'
            default:
              return 'Late and Early Leave'
          }
        }

        const getAnswer = (answer) => {
          switch (answer) {
            case true:
              return 'Correct'
            case false:
              return 'Incorrect'
            default:
              return 'Null'
          }
        }

        for (const student of result.studentList) {
          let raiseHandTimestamp = student.raiseHand.timeStamp.map((item) => {
            return `${convertTo24HRMode(item.time, false)}(${item.count})`
          })
          let pointTimestamp = student.points.timeStamp.map((item) => {
            return `${convertTo24HRMode(item.time, false)}(${item.count})`
          })

          for (const quiz of student.quizList) {
            for (const question of quiz.questions) {
              studentRows.push([
                student.name,
                student.joinTime === '-' ? '-' : `${convertToDate(student.joinTime)} ${convertToAMPMtime(student.joinTime)}`,
                student.lastSeen === '-' ? '-' : `${convertToDate(student.lastSeen)} ${convertToAMPMtime(student.lastSeen)}`,
                getAttendanceType(student.type),
                student.raiseHand.total,
                student.raiseHand.total === 0 ? 'No records found' : raiseHandTimestamp.join(', '),
                student.points.total,
                student.points.total === 0 ? 'No records found' : pointTimestamp.join(', '),
                quiz.name,
                quiz.accuracyRate,
                question.content,
                getAnswer(question.answer)
              ])
            }
          }

          if (!student.quizList.length) {
            studentRows.push([
              student.name,
              student.joinTime === '-' ? '-' : `${convertToDate(student.joinTime)} ${convertToAMPMtime(student.joinTime)}`,
              student.lastSeen === '-' ? '-' : `${convertToDate(student.lastSeen)} ${convertToAMPMtime(student.lastSeen)}`,
              getAttendanceType(student.type),
              student.raiseHand.total,
              student.raiseHand.total === 0 ? 'No records found' : raiseHandTimestamp.join(', '),
              student.points.total,
              student.points.total === 0 ? 'No records found' : pointTimestamp.join(', '),
              'No records found',
              '-',
              '-',
              '-'
            ])
          }
        }

        const studentSheet = workbook.addWorksheet(`STUDENT-${convertToDateDash(selectedClass.begin)}`)
        studentSheet.addTable({
          name: 'table2',
          ref: 'A1',
          columns: [
            { name: 'Student Name' },
            { name: 'Join Time' },
            { name: 'Last Seen' },
            { name: 'Attendance Status' },
            { name: 'Times of Raise Hand' },
            { name: 'Timestamp of Raise Hand' },
            { name: 'Points' },
            { name: 'Timestamp of Getting Points' },
            { name: 'Quiz Name' },
            { name: 'Accuracy Rate' },
            { name: 'Question' },
            { name: 'Answer' }
          ],
          rows: studentRows.length === 0 ? [new Array(9).fill('')] : studentRows
        })

        studentSheet.columns.forEach(function (column, i) {
          let maxLength = 0
          column['eachCell']({ includeEmpty: true }, function (cell) {
            var columnLength = cell.value ? cell.value.toString().length : 15
            if (columnLength > maxLength) {
              maxLength = columnLength
            }
          })
          column.width = maxLength < 15 ? 15 : maxLength
        })

        const blob = await workbook.xlsx.writeBuffer()
        const blobData = new Blob([blob], {
          type: 'application/vnd.ms-excel;charset=utf-8;'
        })

        const link = document.createElement('a')
        link.download = `${selectedClass.courseName}-${convertToDateDash(selectedClass.begin)}.xlsx`
        link.href = URL.createObjectURL(blobData)
        link.click()
        setExporting(false)
      } catch (err) {
        setExporting(false)
        setSeverity('error')
        handleOpenToaster('Failed to export report.')
      }
    })
  }

  return (
    <div className={`${styles.whiteBackground} ${styles.information}`}>
      <div>
        <div className={styles.iconWrap}>
          <img alt='information' src={InformationIcon} />
        </div>
        <div className={styles.contentWrap}>
          <h2 className={styles.title}>Information</h2>
          <div className={styles.contentGrid}>
            <div>
              <span>Course:</span>
              <span>{selectedClass.courseName}</span>
            </div>
            <div>
              <span>Date:</span>
              <span>{convertToDate(selectedClass.begin)}</span>
            </div>
            <div>
              <span>Time:</span>
              <span>
                {convertToAMPMtime(selectedClass.begin)} - {convertToAMPMtime(selectedClass.end)} {`(UTC${selectedClass.gmtOffset})`}
              </span>
            </div>
          </div>
          {exporting ? (
            <button className={styles.disabledBtn}>
              <CircularProgress size={20} color='inherit' />
              <span className={styles.text}>Downloading...</span>
            </button>
          ) : (
            <button className={styles.blueBtn} onClick={onExportReport}>
              <img alt='export' src={ExportIcon} />
              <span>Export Report</span>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

const Attendance = ({ setStep, attendance }) => {
  const [attendanceType, setAttendanceType] = useState('percentage')
  // const handleAttendanceChange = (e, value) => {
  //   if (value !== null) {
  //     setAttendanceType(value)
  //   }
  // }

  // const toggleButtonStyles = {
  //   '& .MuiButtonBase-root': {
  //     width: '82px',
  //     height: '36px'
  //   }
  // }

  // const buttonStyles = {
  //   '& .Mui-selected': {
  //     borderTop: '1px solid #B3B3B3',
  //     borderLeft: '1px solid #B3B3B3',
  //     borderBottom: '1px solid #B3B3B3'
  //   }
  // }

  const onAttendanceTypeChange = (e, value) => {
    setAttendanceType(value)
  }

  const navigateToAttendedStudent = () => {
    setStep(3)
  }

  return (
    <div className={`${styles.whiteBackground} ${styles.attendance}`}>
      <div>
        <div className={styles.iconWrap}>
          <img alt='attendance' src={AttendanceIcon} />
        </div>
        <div className={styles.contentWrap}>
          <div className={styles.titleWrap}>
            <h2 className={styles.title}>Attendance</h2>
            <div className={styles.toggleBtnWrap}>
              <button
                className={`${styles.percentageBtn} ${attendanceType === 'percentage' ? styles.selected : undefined}`}
                onClick={(e) => onAttendanceTypeChange(e, 'percentage')}
              >
                <img alt='percentage' src={attendanceType === 'percentage' ? ColoredPercentageIcon : PercentageIcon} />
              </button>
              <button className={`${styles.peopleBtn} ${attendanceType === 'number' ? styles.selected : undefined}`} onClick={(e) => onAttendanceTypeChange(e, 'number')}>
                <img alt='number' src={attendanceType === 'number' ? ColoredPeopleIcon : PeopleIcon} />
              </button>
              {/* <ToggleButtonGroup color='primary' value={attendanceType} exclusive onChange={handleAttendanceChange} aria-label='Platform' sx={toggleButtonStyles}>
                <ToggleButton value='percentage' sx={buttonStyles}>
                  <img alt='percentage' src={PercentageIcon} />
                </ToggleButton>
                <ToggleButton value='number'>
                  <img alt='number' src={PeopleIcon} />
                </ToggleButton>
              </ToggleButtonGroup> */}
            </div>
          </div>
          {attendanceType === 'percentage' && (
            <>
              <p>
                <span>{attendance.totalStudents ? Math.round(((attendance.totalStudents - attendance.absence) / attendance.totalStudents) * 100) : 0}</span>
                <span>%</span>
              </p>
              <div className={styles.content}>
                <div>
                  <div>
                    <div className={styles.onTime}></div>
                    <span>{attendance.totalStudents ? Math.round((attendance.onTime / attendance.totalStudents) * 100) : 0}</span>
                    <span>%</span>
                    <p>On Time</p>
                  </div>
                  <div>
                    <div className={styles.absence}></div>
                    <span>{attendance.totalStudents ? Math.round((attendance.absence / attendance.totalStudents) * 100) : 0}</span>
                    <span>%</span>
                    <p>Absence</p>
                  </div>
                </div>
                <div>
                  <div>
                    <div className={styles.late}></div>
                    <span>{attendance.totalStudents ? Math.round((attendance.late / attendance.totalStudents) * 100) : 0}</span>
                    <span>%</span>
                    <p>Late</p>
                  </div>
                  <div>
                    <div className={styles.earlyLeave}></div>
                    <span>{attendance.totalStudents ? Math.round((attendance.earlyLeave / attendance.totalStudents) * 100) : 0}</span>
                    <span>%</span>
                    <p>Early Leave</p>
                  </div>
                </div>
                <div>
                  <div>
                    <div className={styles.lateAndEarlyLeave}></div>
                    <span>{attendance.totalStudents ? Math.round((attendance.lateAndEarlyLeave / attendance.totalStudents) * 100) : 0}</span>
                    <span>%</span>
                    <p>Late and Early Leave</p>
                  </div>
                </div>
              </div>
            </>
          )}
          {attendanceType === 'number' && (
            <>
              <p>
                <span>{attendance.totalStudents - attendance.absence}</span>
                <span>/</span>
                <span>{attendance.totalStudents}</span>
              </p>
              <div className={styles.content}>
                <div>
                  <div>
                    <div className={styles.onTime}></div>
                    <span>{attendance.onTime}</span>
                    <p>On Time</p>
                  </div>
                  <div>
                    <div className={styles.absence}></div>
                    <span>{attendance.absence}</span>
                    <p>Absence</p>
                  </div>
                </div>
                <div>
                  <div>
                    <div className={styles.late}></div>
                    <span>{attendance.late}</span>
                    <p>Late</p>
                  </div>
                  <div>
                    <div className={styles.earlyLeave}></div>
                    <span>{attendance.earlyLeave}</span>
                    <p>Early Leave</p>
                  </div>
                </div>
                <div>
                  <div>
                    <div className={styles.lateAndEarlyLeave}></div>
                    <span>{attendance.lateAndEarlyLeave}</span>
                    <p>Late and Early Leave</p>
                  </div>
                </div>
              </div>
            </>
          )}
          <button
            className={attendance.totalStudents === 0 ? styles.disabled : styles.blueBtn}
            onClick={navigateToAttendedStudent}
            disabled={attendance.totalStudents === 0 ? true : false}
          >
            <span>View Student Profile</span>
          </button>
        </div>
      </div>
    </div>
  )
}

const RaiseHand = ({ raiseHand }) => {
  const [selectedAvatarIndex, setSelectedAvatarIndex] = useState(0)
  const { userInfo } = useContext(UserContext)
  const onAvatarChange = (index) => {
    setSelectedAvatarIndex(index)
  }

  const getRaiseHandTotal = function () {
    let total = 0
    raiseHand.forEach((student) => {
      student.timeStamp.forEach((item) => {
        total += item.count
      })
    })
    return total
  }

  return (
    <>
      <div className={`${styles.whiteBackground} ${styles.raiseHand}`}>
        <div>
          <div className={styles.iconWrap}>
            <img alt='raiseHand' src={RaiseHandIcon} />
          </div>
          <div className={styles.contentWrap}>
            <div className={styles.titleWrap}>
              <h2 className={styles.title}>Raise Hand</h2>
            </div>
            <div className={styles.content}>
              <div>
                <span>{getRaiseHandTotal()}</span>
                <span>time{getRaiseHandTotal() !== 1 && <span>s</span>}</span>
              </div>
              <div>
                <span>{raiseHand.length}</span>
                <span>student{raiseHand.length !== 1 && `s`}</span>
              </div>
              {raiseHand.length > 0 && (
                <div className={styles.avatarArea}>
                  <div>
                    <div className={styles.avatarWrap}>
                      {raiseHand.map((item, index) => {
                        return (
                          <div key={index} onClick={() => onAvatarChange(index)} className={selectedAvatarIndex === index ? styles.selected : styles.normal}>
                            <object
                              data={`https://savepicture.s3.eu-central-1.amazonaws.com/profile_picture/${item.studentId}--${userInfo.orgID}.png`}
                              type='image/png'
                              className={styles.avatarImg}
                            >
                              <img src='https://i.imgur.com/sPt8i78.png' alt='avatar' className={styles.avatarImg} />
                            </object>
                            <img alt='view' className={styles.blueEyeImg} src={blueEye} />
                            {selectedAvatarIndex === index && <img alt='view' className={styles.whiteEyeImg} src={whiteEye} />}
                            {selectedAvatarIndex === index && <div className={styles.arrow}></div>}
                          </div>
                        )
                      })}
                    </div>
                    <div className={styles.timestampWrap}>
                      <div className={styles.name}>
                        <p>{raiseHand[selectedAvatarIndex].name}</p>
                        <p>
                          <span>{raiseHand[selectedAvatarIndex].total}</span>
                          <span>time{raiseHand[selectedAvatarIndex].total !== 1 && <span>s</span>}</span>
                        </p>
                      </div>
                      <div className={styles.time}>
                        {raiseHand[selectedAvatarIndex].timeStamp.map((item, index) => {
                          return (
                            <div key={index}>
                              <span>{convertTo24HRMode(item.time, true)}</span>
                              {item.count !== 1 && <div>{item.count}</div>}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {raiseHand.length === 0 && (
                <div className={styles.noRecordsFound}>
                  <div>
                    <p>No records found.</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const AccordionTitle = ({
  quiz,
  setDeleteQuizRecordModal,
  setQuizDetailModal,
  call_getPopQuizDetail,
  setSelectedPopQuiz,
  setLoadingPopQuiz,
  open,
  anchorEl,
  setAnchorEl,
  selectedPopQuiz
}) => {
  const iconStyles = { color: '#707070', transform: 'scale(1.2)', padding: '0.5rem 0.5rem 0.5rem 1rem', cursor: 'pointer' }

  const handleClick = (e) => {
    setSelectedPopQuiz(quiz)
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={styles.summary}>
      <div className={`${styles.quizName} ${styles.largeCol}`}>
        <p>{quiz.name}</p>
      </div>
      <div className={styles.smallCol}>
        <p>{quiz.answerRate}%</p>
      </div>
      <div className={styles.smallCol}>
        <p>{quiz.accuracyRate}%</p>
      </div>
      <div className={`${styles.item} ${styles.more} ${styles.smallCol}`}>
        <MoreHorizIcon
          sx={iconStyles}
          onClick={(e) => {
            handleClick(e)
          }}
        />
        <ListContentMenu
          quiz={quiz}
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          setDeleteQuizRecordModal={setDeleteQuizRecordModal}
          setQuizDetailModal={setQuizDetailModal}
          call_getPopQuizDetail={call_getPopQuizDetail}
          setSelectedPopQuiz={setSelectedPopQuiz}
          selectedPopQuiz={selectedPopQuiz}
          setLoadingPopQuiz={setLoadingPopQuiz}
        />
      </div>
    </div>
  )
}

const QuizHeader = () => {
  return (
    <div className={styles.tableTitleWrap}>
      <div className={styles.largeCol}>Quiz</div>
      <div className={styles.smallCol}>Answer Rate</div>
      <div className={styles.smallCol}>Accuracy Rate</div>
      <div className={styles.smallCol}></div>
    </div>
  )
}

const QuizContent = ({ quizList, setQuizList, setDeleteQuizRecordModal, setQuizDetailModal, call_getPopQuizDetail, setSelectedPopQuiz, setLoadingPopQuiz, selectedPopQuiz }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleAccordionChange = (e, index) => {
    console.log('target', e.target.tagName)
    if (e.target.tagName === 'svg' || e.target.tagName === 'path' || anchorEl) return
    setQuizList((prev) => {
      const originList = JSON.parse(JSON.stringify(prev))
      const newList = originList.map((item, idx) => {
        if (index === idx) {
          return Object.assign(item, { expanded: !item.expanded })
        } else {
          return Object.assign(item, { expanded: false })
        }
      })
      return newList
    })
  }

  return (
    <>
      {quizList.map((quiz, index) => {
        return (
          <div className={styles.accordion} key={index}>
            <CustomAccordion
              expanded={quiz.expanded}
              handleAccordionChange={(e) => handleAccordionChange(e, index)}
              title={
                <AccordionTitle
                  quiz={quiz}
                  setDeleteQuizRecordModal={setDeleteQuizRecordModal}
                  setQuizDetailModal={setQuizDetailModal}
                  call_getPopQuizDetail={call_getPopQuizDetail}
                  setSelectedPopQuiz={setSelectedPopQuiz}
                  setLoadingPopQuiz={setLoadingPopQuiz}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  open={open}
                  selectedPopQuiz={selectedPopQuiz}
                />
              }
              details={<AccordionDetails questions={quiz.questions} />}
            />
          </div>
        )
      })}
    </>
  )
}

const AccordionDetails = ({ questions }) => {
  return (
    <>
      <div className={styles.details}>
        {questions.map((question, index) => {
          return (
            <div className={styles.question} key={index}>
              <div className={`${styles.questionContent} ${styles.largeCol}`}>
                <p>
                  {question.content ? (
                    <div>
                      Question {index + 1}: {question.content}
                    </div>
                  ) : (
                    <div className={styles.imageContent}>
                      Question {index + 1}: <img alt='quizImage' src={ImageIcon} />
                    </div>
                  )}
                </p>
              </div>
              <div className={styles.smallCol}>
                <p>{question.answerRate}%</p>
              </div>
              <div className={styles.smallCol}>
                <p>{question.accuracyRate}%</p>
              </div>
              <div className={styles.smallCol}></div>
            </div>
          )
        })}
      </div>
    </>
  )
}

const ListContentMenu = ({
  quiz,
  anchorEl,
  open,
  handleClose,
  setDeleteQuizRecordModal,
  setQuizDetailModal,
  call_getPopQuizDetail,
  setSelectedPopQuiz,
  selectedPopQuiz,
  setLoadingPopQuiz
}) => {
  const style = {
    '& .MuiPaper-root': {
      borderRadius: '8px',
      boxShadow: '0px 0px 6px #00000011',
      '& .MuiMenu-list': {
        padding: '0px'
      },
      '& .MuiMenuItem-root': {
        padding: '15px'
      }
    }
  }

  const blueTextStyle = {
    font: 'normal normal 600 14px/18px InterMedium',
    '&:hover': {
      color: '#1452CC'
    }
  }

  const redTextStyle = {
    font: 'normal normal 600 14px/18px InterMedium',
    '&:hover': {
      color: '#E60026'
    }
  }

  const openQuizDetailModal = () => {
    setLoadingPopQuiz(true)
    call_getPopQuizDetail({
      popQuizId: selectedPopQuiz.popQuizId
    })
    setQuizDetailModal(true)
    handleClose()
  }

  const handleDeleteQuizRecord = () => {
    handleClose()
    setDeleteQuizRecordModal(true)
  }

  return (
    <Menu sx={style} anchorEl={anchorEl} open={open} onClose={handleClose}>
      <MenuItem sx={blueTextStyle} onClick={() => openQuizDetailModal()}>
        Quiz Information
      </MenuItem>
      <MenuItem sx={redTextStyle} onClick={() => handleDeleteQuizRecord()}>
        Delete this record
      </MenuItem>
    </Menu>
  )
}

// const QuizContent = ({ quizList }) => {
//   return (
//     <>
//       {quizList.map((quiz, index) => {
//         return (
//           <div className={styles.quizRow} key={index}>
//             <div className={styles.largeCol}>{quiz.content}</div>
//             <div className={styles.smallCol}>{quiz.answerRate}%</div>
//             <div className={styles.smallCol}>{quiz.accuracyRate}%</div>
//           </div>
//         )
//       })}
//     </>
//   )
// }

const Quiz = ({ quizList, setQuizList, setDeleteQuizRecordModal, setQuizDetailModal, call_getPopQuizDetail, setSelectedPopQuiz, setLoadingPopQuiz, selectedPopQuiz }) => {
  const getAccuracyRate = () => {
    let total = 0
    total = quizList.reduce((a, b) => a + b.accuracyRate, 0)
    if (quizList.length) {
      // console.log('total', total)
      return Math.round(total / quizList.length)
    } else {
      return total
    }
  }

  return (
    <>
      <div className={`${styles.whiteBackground} ${styles.quiz}`}>
        <div>
          <div className={styles.iconWrap}>
            <img alt='quiz' src={QuizIcon} />
          </div>
          <div className={styles.contentWrap}>
            <div className={styles.titleWrap}>
              <h2 className={styles.title}>Quiz</h2>
            </div>
            <div className={styles.content}>
              <div className={styles.metrics}>
                <div>
                  <span>{quizList.length}</span>
                  <span>quiz{quizList.length !== 1 && `zes`}</span>
                </div>
                <div>
                  <span>{getAccuracyRate()}%</span>
                  <span>accuracy rate</span>
                </div>
              </div>
              <div>
                <QuizHeader />
                {quizList.length > 0 && (
                  <QuizContent
                    quizList={quizList}
                    setQuizList={setQuizList}
                    setDeleteQuizRecordModal={setDeleteQuizRecordModal}
                    setQuizDetailModal={setQuizDetailModal}
                    call_getPopQuizDetail={call_getPopQuizDetail}
                    setSelectedPopQuiz={setSelectedPopQuiz}
                    setLoadingPopQuiz={setLoadingPopQuiz}
                    selectedPopQuiz={selectedPopQuiz}
                  />
                )}
                {quizList.length === 0 && (
                  <div className={styles.noRecordsFound}>
                    <div>
                      <p>No records found.</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Dashboard = ({
  setStep,
  selectedClassList,
  selectedClassIndex,
  setSelectedClassIndex,
  call_getDashboardApi,
  loadingDashboard,
  dashboardData,
  setSeverity,
  handleOpenToaster,
  call_getPopQuizDetail,
  quizDetail,
  setLoadingPopQuiz,
  loadingPopQuiz,
  call_deleteQuizRecordApi,
  setLoadingDashboard
}) => {
  const [selectedClass, setSelectedClass] = useState({})
  const [quizList, setQuizList] = useState([])
  const [deleteQuizRecordModal, setDeleteQuizRecordModal] = useState(false)
  const [quizDetailModal, setQuizDetailModal] = useState(false)
  const [selectedPopQuiz, setSelectedPopQuiz] = useState(null)

  const navigateToMainPage = () => {
    setStep(1)
  }

  const onClickArrow = (type, valid) => {
    if (valid) {
      if (type === 'prev') {
        setSelectedClassIndex((prev) => {
          let newIndex = prev + 1
          let classInfo = selectedClassList[newIndex]
          let data = {
            courseId: classInfo.courseId,
            startTime: classInfo.begin,
            endTime: classInfo.end
          }
          call_getDashboardApi(data)
          return newIndex
        })
      } else {
        setSelectedClassIndex((prev) => {
          let newIndex = prev - 1
          let classInfo = selectedClassList[newIndex]
          let data = {
            courseId: classInfo.courseId,
            startTime: classInfo.begin,
            endTime: classInfo.end
          }
          call_getDashboardApi(data)
          return newIndex
        })
      }
    }
  }

  const onDeleteRecordQuiz = () => {
    setLoadingDashboard(true)
    call_deleteQuizRecordApi({
      popQuizId: selectedPopQuiz.popQuizId,
      courseId: selectedClass.courseId,
      startTime: selectedClass.begin,
      endTime: selectedClass.end
    })
  }

  useEffect(() => {
    console.log('selectedClassList', selectedClassList)
    console.log('selectedClassIndex', selectedClassIndex)
    let classInfo = selectedClassList[selectedClassIndex]
    setSelectedClass(classInfo)
    let data = {
      courseId: classInfo.courseId,
      startTime: classInfo.begin,
      endTime: classInfo.end
    }
    call_getDashboardApi(data)
  }, [selectedClassIndex])

  useEffect(() => {
    if (dashboardData) {
      dashboardData.quizList.map((quiz, index) => {
        if (index === 0) {
          return Object.assign(quiz, { expanded: true })
        } else {
          return Object.assign(quiz, { expanded: false })
        }
      })
      setQuizList(dashboardData.quizList)
    }
  }, [dashboardData])

  return (
    <>
      <div>
        <div className={styles.breadCrumbs}>
          <div className={`${styles.tab} ${styles.pointer}`} onClick={() => navigateToMainPage()}>
            Portfolio
          </div>
          <div>
            <img src={ArrowIcon} alt='arrow' />
          </div>
          <div className={styles.tab}>Dashboard</div>
        </div>
        <div className={styles.titleWrap}>
          <h1>{selectedClass.courseName}</h1>
          <ChangeDateCard
            hasPrev={selectedClassIndex !== selectedClassList.length - 1}
            date={convertToDate(selectedClassList[selectedClassIndex].begin)}
            hasNext={selectedClassIndex !== 0}
            onClickArrow={onClickArrow}
          />
        </div>
        {(loadingDashboard || !dashboardData) && (
          <div
            style={{
              height: 'calc(100vh - 330px)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loadingDashboard && dashboardData && (
          <>
            <div className={styles.firstRow}>
              <Information selectedClass={selectedClass} setSeverity={setSeverity} handleOpenToaster={handleOpenToaster} />
              <Attendance setStep={setStep} attendance={dashboardData?.attendance} />
            </div>
            <RaiseHand raiseHand={dashboardData?.raiseHand} />
            <Quiz
              quizList={quizList}
              setQuizList={setQuizList}
              setDeleteQuizRecordModal={setDeleteQuizRecordModal}
              setQuizDetailModal={setQuizDetailModal}
              call_getPopQuizDetail={call_getPopQuizDetail}
              setSelectedPopQuiz={setSelectedPopQuiz}
              setLoadingPopQuiz={setLoadingPopQuiz}
              selectedPopQuiz={selectedPopQuiz}
            />
          </>
        )}
      </div>
      {deleteQuizRecordModal && (
        <Modal>
          <DeleteQuizRecordModal onDeleteRecordQuiz={onDeleteRecordQuiz} setDeleteQuizRecordModal={setDeleteQuizRecordModal} />
        </Modal>
      )}
      {quizDetailModal && (
        <Modal>
          <QuizDetailModal setQuizDetailModal={setQuizDetailModal} quizDetail={quizDetail} selectedPopQuiz={selectedPopQuiz} loadingPopQuiz={loadingPopQuiz} />
        </Modal>
      )}
    </>
  )
}

export default Dashboard
