import styles from './CustomBtn.module.css'

const CustomBtn = ({ txt, callback, colorStyle, disabled = false }) => {
  return (
    <button className={`${styles.btn} ${styles[colorStyle]} ${disabled && styles.disabled}`} onClick={() => callback()}>
      {txt}
    </button>
  )
}

export default CustomBtn
