import styles from './Classroom.module.css'
import MyClasses from './MyClasses/MyClasses'
import useStudentList from '../../CustomHooks/useStudentList'
import useClassroomList from '../../CustomHooks/useClassroomList'
import Toaster from '../../parts/Toaster'

const Classroom = () => {
  const { allStudentListWithCourses, memberList } = useStudentList()
  const {
    teachers,
    chosenTeacher,
    handleChosenTeacherWindowSelect,
    call_saveApi,
    scheduleData,
    loadingGetList,
    loadingSaveList,
    courseInfoData,
    chosenClassId,
    handleChosenClassId,
    chosenClassItem,
    openToaster: openScheduleToaster,
    handleCloseToaster: handleCloseScheduleToaster,
    updateStudentListByClass,
    updateRoomByClass,
    toasterText,
    updateGroupUpListByClass,
    deleteGroupUpListByClass,
    setSearchVal,
    setChosenTeacher,
    searchVal,
    handleSearchVal,
    call_getClassroomList,
    filterCourseInfoList,
    page,
    totalPage,
    handlePage,
    loadingGetCourseDetail,
    call_courseDetailApi,
    selectedDatesArr
  } = useClassroomList()

  const updateClassroomData = (studentList, text, chatGroupList) => {
    updateStudentListByClass(studentList, text, chatGroupList)
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.title}>Classroom</div>
      <MyClasses
        memberList={memberList}
        allStudentListWithCourses={allStudentListWithCourses}
        openToaster={openScheduleToaster}
        handleCloseToaster={handleCloseScheduleToaster}
        call_saveApi={call_saveApi}
        loadingGetCourseDetail={loadingGetCourseDetail}
        loadingGetList={loadingGetList}
        loadingSaveList={loadingSaveList}
        scheduleData={scheduleData}
        courseInfoData={courseInfoData}
        teachers={teachers}
        chosenTeacher={chosenTeacher}
        handleChosenTeacherWindowSelect={handleChosenTeacherWindowSelect}
        chosenClassItem={chosenClassItem}
        chosenClassId={chosenClassId}
        handleChosenClassId={handleChosenClassId}
        updateStudentListByClass={updateClassroomData}
        updateRoomByClass={updateRoomByClass}
        updateGroupUpListByClass={updateGroupUpListByClass}
        deleteGroupUpListByClass={deleteGroupUpListByClass}
        searchVal={searchVal}
        handleSearchVal={handleSearchVal}
        call_getClassroomList={call_getClassroomList}
        filterCourseInfoList={filterCourseInfoList}
        page={page}
        handlePage={handlePage}
        totalPage={totalPage}
        call_courseDetailApi={call_courseDetailApi}
        selectedDatesArr={selectedDatesArr}
        setSearchVal={setSearchVal}
        setChosenTeacher={setChosenTeacher}
      />
      <Toaster openToaster={openScheduleToaster} handleCloseToaster={handleCloseScheduleToaster} severity='success' message={toasterText} />
    </div>
  )
}

export default Classroom
