import { Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './SideBar.module.css'
import { UserContext } from '../CustomHooks/useAuth'
import { useContext, useState } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useEffect } from 'react'

const SideBar = ({ tabNames, open, handleMenuClose }) => {
  const { pathname } = useLocation()
  const { userInfo, openUpgradePlan } = useContext(UserContext)
  const [openPath, setOpenPath] = useState('')

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'scroll'
    }
  }, [open])

  useEffect(() => {
    let identity = userInfo.identity
    if (identity == 'student') {
      setOpenPath('Course')
      return
    }
    if (identity !== 'student') {
      if (pathname == '/dashboard/classroom' || pathname == '/dashboard/schedule' || pathname == '/dashboard/portfolio') {
        setOpenPath('Course')
      }
      if (pathname == '/dashboard/quiz' || pathname == '/dashboard/assignment') {
        setOpenPath('Activity')
      }
    }
    if (identity == 'admin' || identity == 'owner') {
      if (pathname == '/dashboard/ltisettings') {
        setOpenPath('Setting')
      }
      if (pathname == '/dashboard/permission') {
        setOpenPath('Setting')
      }
    }
  }, [])

  const handleOpenPath = (txt) => {
    if (!openPath) {
      console.info('!openpath', openPath, txt)
      setOpenPath(txt)
      return
    }

    if (openPath && openPath == txt) {
      console.info('openPath && openPath == txt', openPath, txt)
      setOpenPath('')
      return
    }
    console.info('setOpenPath(openPath)', openPath, txt)
    setOpenPath(txt)
  }

  let filterTabs = tabNames.filter((item) => {
    let identity = userInfo.identity
    if (identity === 'admin' || identity === 'owner') {
      return true
    }
    if (identity === 'teacher') {
      return item.text === 'Setting' ? false : true
    }
    if (identity === 'student') {
      return item.text === 'Course' ? true : false
    }
  })

  let filterChildren = (childItem) => {
    let identity = userInfo.identity
    if (identity == 'admin' || identity == 'teacher') {
      return childItem.path != 'permission'
    }
    if (identity == 'student') {
      return childItem.path == 'schedule'
    }
    return true
  }

  let matchPath = (path) => {
    let identity = userInfo.identity
    let new_pathname = pathname
    if (identity == 'student') {
      if (pathname == '/dashboard') new_pathname = pathname + '/schedule'
    } else {
      if (pathname == '/dashboard') new_pathname = pathname + '/manageusers'
    }
    return new_pathname === `/dashboard/${path}`
  }

  return (
    <Fragment>
      <div className={`${styles.bg} ${open && styles.open}`} onClick={handleMenuClose}></div>
      <div className={`${styles.sidebarWrap} ${open && styles.open}`}>
        {filterTabs.map((tabObj, idx) => {
          return (
            <div key={idx}>
              {tabObj.children.length !== 0 ? (
                <div className={styles.tab} onClick={() => handleOpenPath(tabObj.text)}>
                  <div>{tabObj.text}</div>
                  {openPath == tabObj.text ? (
                    <div>
                      <ExpandLessIcon />
                    </div>
                  ) : (
                    <div>
                      <ExpandMoreIcon />
                    </div>
                  )}
                </div>
              ) : (
                <Link to={`/dashboard/${tabObj.path}`} onClick={handleMenuClose} className={`${styles.tab} ${matchPath(tabObj.path) && styles.active}`}>
                  {tabObj.text}
                </Link>
              )}
              <div className={styles.options}>
                {openPath == tabObj.text &&
                  tabObj.children
                    .filter((childItem) => filterChildren(childItem))
                    .map((item, idx) => (
                      <Link
                        key={idx}
                        to={`/dashboard/${item.path}`}
                        onClick={handleMenuClose}
                        style={{ paddingLeft: '30px' }}
                        className={`${styles.tab} ${matchPath(item.path) && styles.active}`}
                      >
                        {item.text}
                      </Link>
                    ))}
              </div>
            </div>
          )
        })}
      </div>
    </Fragment>
  )
}

export default SideBar
