import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CircularProgress from '@mui/material/CircularProgress'
import styles from './ImportFileModal.module.css'
import template from  '../../../assets/template.csv'
import { Fragment, useEffect, useState, useRef } from 'react'
import * as XLSX from 'xlsx'
import Papa from "papaparse"
import isEmail from 'validator/es/lib/isEmail'
import Modal from '../../Modal'
import ImportFileWarningModal from '../ImportFileWarningModal/ImportFileWarningModal'
import SmallBorderInput from '../../SmallBorderInput'
import Checkbox  from '@mui/material/Checkbox'
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import AddMemberModal from '../AddMemberModal/AddMemberModal'
import useMemberList from '../../../CustomHooks/useMemberList'
import { FixedSizeList as List } from "react-window"


const RoleOption = ({ role, handleRoleChange }) => {
  return (
    <RadioGroup
        row
      >
        <FormControlLabel value="Admin" control={<Radio />} label="Admin" checked={role === 'Admin'} onChange={handleRoleChange} />
        <FormControlLabel value="Teacher" control={<Radio />} label="Teacher" checked={role === 'Teacher'} onChange={handleRoleChange} />
        <FormControlLabel value="Student" control={<Radio />} label="Student" checked={role === 'Student'} onChange={handleRoleChange} />
      </RadioGroup>
  )
}

const UtilityRow = ({ generateCSVTemplate, inputValue, setInputValue, handleImportList, setShowWarningHint, getStatus, role, handleRoleChange, setWarningTyp }) => {

  const [fileURL, setFileURL] = useState('#')

  useEffect(() => {
    setFileURL(generateCSVTemplate())
  }, [])

  // const generateCSVTemplate = () => {
  //   let headers = ["\uFEFF"+'firstName,lastName,email'] 
  //   let data = headers
  //   let fileType = 'text/csv;charset=utf-8'
  //   let blob = new Blob([data], { type: fileType })
  //   setFileURL(window.URL.createObjectURL(blob))
  // }

  const downloadExcel = () => {
    let data = [{'firstName': '', 'lastName': '', 'email': ''}] 
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "template.xlsx");
  };

  const parseCSV = file => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      encoding: 'big5',
      complete: results => {
        if(!results.data.length) {
          setShowWarningHint(true)
          handleImportList([])
          return
        }
        const data = results?.data
        const columns = Object.keys(data[0])
        if(columns.length !== 3 || !columns.includes('firstName') || !columns.includes('lastName') || !columns.includes('email')) {
          setShowWarningHint(true)
          handleImportList([])
          return
        }

        let arr = data.filter((item, idx) => Object.keys(item).length === 3).map((item) => {
          let stringEmail = (item.email) ? String(item.email).toLowerCase() : ''
          return {
            checked: false,
            disabled: false,
            role,
            firstName: item.firstName,
            lastName: item.lastName,
            email: stringEmail,
            status: getStatus(item.firstName, item.lastName, stringEmail, false),
          }
        })

        console.info('setImportList', arr)
        handleImportList(arr)
      },
    })
  }

 const parseXLS = (bstr) => {
    const wb = XLSX.read(bstr, { type: 'binary' })
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false })

    if(data[0].length !== 3 || !data[0].includes('firstName') || !data[0].includes('lastName') || !data[0].includes('email')) {
      setShowWarningHint(true)
      handleImportList([])
      return
    }

    let firstNameIndex = data[0].findIndex(item => item === 'firstName')
    let lastNameIndex = data[0].findIndex(item => item === 'lastName')
    let emailIndex = data[0].findIndex(item => item === 'email')

    const blankrows = (item) => {
      if(!item[firstNameIndex] && !item[lastNameIndex] && !item[emailIndex]) return true
      return false
    }

    let arr = data.filter((item, idx) => idx !== 0 && !blankrows(item)).map((item) => {
      let stringEmail = (item[emailIndex]) ? String(item[emailIndex]).toLowerCase() : ''
      return {
        checked: false,
        disabled: false,
        role,
        firstName: item[firstNameIndex],
        lastName: item[lastNameIndex],
        email: stringEmail,
        status: getStatus(item[firstNameIndex], item[lastNameIndex], stringEmail, false),
      }
    })

    console.info('setImportList', arr)
    handleImportList(arr)
  }

  const getFile = (e) => {
    if(!e.target.files.length) return
    const file = e.target.files[0]
    const type = file.type // "text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"
    console.info('getfile', type)
    handleImportList([])
    const reader = new FileReader()
    
    reader.onload = (e) => {

      if(type === 'text/csv') {
        parseCSV(e.target.result)
      } else {
        parseXLS(e.target.result)
      }
    }

    if(type === 'text/csv') {
      reader.readAsText(file)
    } else {
      reader.readAsArrayBuffer(file)
    }
  }

  return (
    <div className={styles.utilityRowWrap}>
      <div className={styles.left}>
        <RoleOption role={role} handleRoleChange={handleRoleChange} />
      </div>
      <div className={styles.right}>
        <a className={`${styles.btn} ${styles.download}`} href={fileURL} download="template.csv">
          <div>Download Template</div>
          <div>(csv.)</div>
        </a>
        {/* <button className={`${styles.btn} ${styles.download}`} onClick={downloadExcel}>
          <div>Download Template</div>
          <div>(xlsx.)</div>
        </button> */}
        <Fragment>
          <input className={styles.inputFile} id="file" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv" value={inputValue} onChange={getFile}/>
          <label htmlFor="file">Upload file<br />(csv., xls, xlsx)</label>
        </Fragment>
      </div>
    </div>
  )
}

const TableHeader = ({ selectAll, handleSelectAllChange }) => {
  return (
    <div className={`${styles.tableWrap} ${styles.tableHeader}`}>
      <div className={`${styles.tableItem} ${styles.checkBox}`}>
        <Checkbox
          checked={selectAll}
          onChange={handleSelectAllChange}
          icon={<RadioButtonUncheckedRoundedIcon />}
          checkedIcon={<CheckCircleRoundedIcon />}
        />
      </div>
      <div className={`${styles.tableItem} ${styles.firstName}`}>First Name</div>
      <div className={`${styles.tableItem} ${styles.lastName}`}>Last Name</div>
      <div className={`${styles.tableItem} ${styles.email}`}>Email</div>
      <div className={`${styles.tableItem} ${styles.status}`}>Status</div>
    </div>
  )
}

const TableContent = ({ item, index, importList, handleImportList, orgMemberList, getStatus, handleCheckBoxChange, checkRepeatEmail }) => {

  const [firstName, setFirstName] = useState((item.firstName !== '') ? item.firstName : '')
  const [lastName, setLastName] = useState((item.lastName !== '') ? item.lastName : '')
  const [email, setEmail] = useState((item.email !== '') ? item.email : '')

  const checkEmptyOrNot = (val) => {
    return (!val.trim()) ? '' : val
  }

  const handlefirstNameChange = (e) => {
    let val = checkEmptyOrNot(e.target.value)
    setFirstName(val)
  }

  const handlelastNameChange = (e) => {
    let val = checkEmptyOrNot(e.target.value)
    setLastName(val)
  }

  const handleEmailChange = (e) => {
    let lowerCaseEmail = e.target.value.toLowerCase()
    setEmail(lowerCaseEmail)
    checkRepeatEmail(lowerCaseEmail,  index)
  }

  const checkEmail = () => {
    if(!email) return false
    if(!isEmail(email)) return false
    if(orgMemberList.find(existingMember => existingMember.email.toLowerCase() === email.toLowerCase())) return false
    return true
  }

  const resetStatus = () => {
    let arr = importList.map(item => item)
    let status = getStatus(firstName, lastName, email, item.disabled)
    arr[index].firstName = firstName
    arr[index].lastName = lastName
    arr[index].email = email
    arr[index].status = status
    arr[index].checked = (status === '') ? arr[index].checked : false // when disabled, cancel selection
    handleImportList(arr)
  }

  useEffect(() => {
    resetStatus()
  }, [firstName, lastName, email])

  return (
    <div className={`${styles.tableWrap} ${styles.tableContent} ${(!!item.status || item.disabled) && styles.disabled}`}>
      <div className={`${styles.tableItem} ${styles.checkBox} ${(!!item.status || item.disabled) && styles.disabled}`}>
        <Checkbox
          checked={item.checked}
          value={`${item.email}-${index}`}
          onChange={handleCheckBoxChange}
          icon={<RadioButtonUncheckedRoundedIcon />}
          checkedIcon={<CheckCircleRoundedIcon />}
        />
      </div>
      {
        item.disabled ? (
          <Fragment>
            <div className={`${styles.tableItem} ${styles.firstName}`}>{ item.firstName }</div>
            <div className={`${styles.tableItem} ${styles.lastName}`}>{ item.lastName }</div>
            <div className={`${styles.tableItem} ${styles.email}`}>{ item.email }</div>
          </Fragment>
        ) : (
          <Fragment>
            <div className={`${styles.tableItem} ${styles.firstName}`}>
              <SmallBorderInput type="border" inputhVal={firstName} handleInputVal={handlefirstNameChange} placeholder="First Name" isValid={!!firstName} helperText="" disabled={false} />
            </div>
            <div className={`${styles.tableItem} ${styles.lastName}`}>
              <SmallBorderInput type="border" inputhVal={lastName} handleInputVal={handlelastNameChange} placeholder="Last Name" isValid={!!lastName} helperText="" disabled={false} />
            </div>
            <div className={`${styles.tableItem} ${styles.email}`}>
              <SmallBorderInput type="border" inputhVal={email} handleInputVal={handleEmailChange} placeholder="Email" isValid={checkEmail()} helperText="" disabled={false} />
            </div>
          </Fragment>
        )
      }
      <div className={`${styles.tableItem} ${styles.status} ${item.disabled && styles.saved}`}>{ item.status }</div>
    </div>
  )
}

const Slot = ({ role, chosenSlotObj }) => {
  return (
    <div className={styles.slotWrap}>
      { Object.keys(chosenSlotObj).map((roleTxt, idx) => (
        <div key={idx} className={`${styles.slotItem} ${role !== roleTxt && styles.fade}`}>
          <div className={styles.role}>{`${roleTxt}:`}</div>
          <div className={`${styles.slot} ${ (chosenSlotObj[roleTxt].chosen >= chosenSlotObj[roleTxt].left) && styles.warning }`}>{`${chosenSlotObj[roleTxt].chosen}/${chosenSlotObj[roleTxt].left}`}</div>
        </div>
      )) }
    </div>
  )
}

const renderRow = ({ data, index, style }) => {
  const { importList, handleImportList, orgMemberList, getStatus, handleCheckBoxChange, checkRepeatEmail } = data

  return (
    <div style={style} key={index}>
      {/* hi */}
      <TableContent 
        // key={idx}
        index={index}
        item={importList[index]}
        importList={importList}
        handleImportList={handleImportList}
        orgMemberList={orgMemberList}
        getStatus={getStatus}
        handleCheckBoxChange={handleCheckBoxChange}
        checkRepeatEmail={checkRepeatEmail}
      />
    </div>
  )
};

const ImportFileModal = ({ loadingSaveList, call_saveApi, slotObj, orgMemberList, handleModal }) => {

  const [importList, setImportList] = useState([])
  const [showWarningHint, setShowWarningHint] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [role, setRole] = useState('Admin')
  const [chosenSlotObj, setChosenSlotObj] = useState(slotObj)
  const [showChildModal, setShowChildModal] = useState(false)
  const { reformImportArr } = useMemberList()
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    handleChosenSlotObj()
  }, [slotObj])

  useEffect(() => {
    handleChosenSlotObj()
  }, [slotObj, importList])

  useEffect(() => {
    if(!importList.length) return
    // reset all status after get memberlist
    let arr = importList.map(item => item)
    arr.forEach(item => {
      item.status = getStatus(item.firstName, item.lastName, item.email, item.disabled)
    })
    handleImportList(arr)
  }, [orgMemberList])

  useEffect(() => {
    setSelectAll(isSelectAll())
  }, [importList])

  useEffect(() => {
    resetRole()
  }, [role])

  const generateCSVTemplate = () => {
    let headers = ["\uFEFF"+'firstName,lastName,email'] 
    let data = headers
    let fileType = 'text/csv;charset=utf-8'
    let blob = new Blob([data], { type: fileType })
    return window.URL.createObjectURL(blob)
  }

  const handleClose = () => {
    setShowChildModal(false);
  }

  const saveAndClose = () => {
    handleClose()
    call_saveApi(reformImportArr(importList.filter(item => item.checked && item.status === '')))
    setAllItemDisabled()
  }

  const checkRepeatEmail = (email, index) => {
    let tempArr = importList.map(item => item)
    tempArr[index].email = email
    let listMap = generateListMap(tempArr)
    if(listMap[email] && listMap[email].length > 1) {
      for(let i = 0; i < listMap[email].length; i++) {
        if(index === listMap[email][i]) continue
        if(tempArr[listMap[email][i]].checked) {
          tempArr[index].checked = false
          break
        }
      }
    }
    handleImportList(tempArr)
  }

  const setAllItemDisabled = () => {
    let arr = importList.map(item => item)
    for(let item of arr) {
      if(item.checked && item.status === '') {
        item.disabled = true
        item.status = getStatus(item.firstName, item.lastName, item.email, item.disabled)
      }
    }
    setImportList(arr)
  }

  const handleChosenSlotObj = () => {
    let obj = JSON.parse(JSON.stringify(chosenSlotObj))
    let role = ['Admin', 'Teacher', 'Student']
    role.forEach(roleText => {
      obj[roleText].left = slotObj[roleText].whole - slotObj[roleText].occupied
      obj[roleText].saved = 0
      obj[roleText].chosen = 0
    })
    if(!importList.length) {
      setChosenSlotObj(obj)
      return
    }
    for(let item of importList) {
      if(item.disabled) {
        obj[item.role].saved++
        continue
      }
      if(item.checked && item.status === '') {
        obj[item.role].chosen++
      }
    }
    setChosenSlotObj(obj)
  }

  const resetRole = () => {
    if(!importList.length) return
    let arr = importList.map(item => item)
    arr.forEach(item => {
      item.role = role
    })
    handleImportList(arr)
  }

  const handleRoleChange = (e) => {
    setRole(e.target.value)
  }

  const generateListMap = (arr=[]) => {
    let tempArr = (!!arr.length) ? arr : importList
    const listMap = {}
    tempArr.forEach((item, index) => {
      if(listMap[item.email] === undefined) {
        listMap[item.email] = []
        listMap[item.email].push(index)
      } else {
        listMap[item.email].push(index)
      }
    })
    return listMap
  }

  const isSelectAll = () => {
    let arr = importList.map(item => item)
    if(!arr.length) return false
    for(let item of arr) {
      if(item.status !== '') continue //disabled
      if(!item.checked) return false
    }
    return true
  }

  const handleImportList = (arr) => {
    setImportList(arr)
    setInputValue('')
  }

  const handleSelectAllChange = (e) => {
    let arr = importList.map(item => item)
    let listMap = generateListMap()
    for(let item of arr) {
      if(item.status !== '') continue // disabled
      if(listMap[item.email].length > 1 && e.target.checked) continue
      item.checked = e.target.checked
    }
    handleImportList(arr)
  }

  const handleCheckBoxChange = (e) => {
    let val = e.target.value.split('-')
    let listMap = generateListMap()
    let email = val[0]
    let index = val[1]
    let repeat = false
    // check if there are repeated emails
    if(e.target.checked && listMap[email].length > 1) {
      for(let i = 0; i < listMap[email].length; i++) {
        if(listMap[email][i] == index) continue
        if(importList[listMap[email][i]].checked) {
          repeat = true
          break
        }
      }
    }
    if(repeat) return
    //
    let arr = importList.map(item => item)
    arr[index].checked = e.target.checked
    handleImportList(arr)
  }

  const getStatus = (firstName, lastName, email, disabled) => {
    let sts = []
    if(!disabled) {
      let emptyString = []
      if(!firstName) emptyString.push('First Name')
      if(!lastName) emptyString.push('Last Name')
      if(!email) emptyString.push('Email')
      if(!!emptyString.length) sts.push(`${emptyString.join('/')} can not be empty.`)
      if(orgMemberList.find(existingMember => existingMember.email.toLowerCase() === email.toLowerCase())) sts.push('This email already exists in this organization.')
      if(!!email && !isEmail(email)) sts.push('Incorrect email format.')
    }
    if(disabled) sts.push('Saved.')
    return (!!sts.length) ? sts.join(' / ') : ''
  }

  const disableAddUser = () => {
     return (chosenSlotObj[role].chosen > chosenSlotObj[role].left) || chosenSlotObj[role].chosen === 0
  }

  return (
    <Fragment>
      <div className={styles.modalWrap}>
        { loadingSaveList && <div className={styles.loadingWrap}><CircularProgress /></div> }
        <div className={styles.titleWrap}>
          <div className={styles.title}>Import From File</div>
          <div className={styles.cancel} onClick={() => handleModal(false)}><CloseRoundedIcon sx={{ color: '#808080', "&:hover": { color: "#1452CC" } }} /></div>
        </div>
        <UtilityRow generateCSVTemplate={generateCSVTemplate} inputValue={inputValue} setInputValue={setInputValue} role={role} handleRoleChange={handleRoleChange} handleImportList={handleImportList} setShowWarningHint={setShowWarningHint} getStatus={getStatus} />
        <div className={styles.container}>
          <TableHeader selectAll={selectAll} handleSelectAllChange={handleSelectAllChange} />
          {
            importList.length !== 0 && (
              <List
                className="List"
                itemData={{ importList, handleImportList, orgMemberList, getStatus, handleCheckBoxChange, checkRepeatEmail }}
                height={245}
                itemCount={importList.length}
                itemSize={65}
                width={1102}
              >
                {renderRow}
              </List>
            )
          }
        </div>
        <div className={styles.bottomWrap}>
          <div className={styles.left}><Slot role={role} chosenSlotObj={chosenSlotObj} /></div>
          <div className={styles.right}>
            <button className={`${styles.btn} ${styles.addUser} ${disableAddUser() && styles.disabled}`} onClick={() => setShowChildModal(true)} >Add User</button>
          </div>
        </div>
      </div>
      {
        showWarningHint && (
          <Modal>
            <ImportFileWarningModal generateCSVTemplate={generateCSVTemplate} showWarningHint={showWarningHint} setShowWarningHint={setShowWarningHint} />
          </Modal>
        )
      }
      <AddMemberModal chosenSlotObj={chosenSlotObj} showChildModal={showChildModal} handleClose={handleClose} saveAndClose={saveAndClose} />
    </Fragment>
  )
}

export default ImportFileModal