import styles from './QuitEditModal.module.css'

const QuitEditModal = ({ handleQuitEditModal, backToGroupListPage }) => {

  return (
    <div className={styles.modalWrap}>
      <div className={styles.title}>Leave Now</div>
      <div className={styles.message}>It has been modified, do you want to save?</div>
      <div className={styles.btnWrap}>
        <div className={`${styles.remove} ${styles.btn}`} onClick={() => { backToGroupListPage(true); handleQuitEditModal(false);}}>Yes</div>
        <div className={`${styles.no} ${styles.btn}`} onClick={() => { backToGroupListPage(false); handleQuitEditModal(false);}}>No</div>
        <div className={`${styles.cancel} ${styles.btn}`} onClick={() => handleQuitEditModal(false)}>Cancel</div>
      </div>
    </div>
  )
}

export default QuitEditModal