import styles from './WeekTimeline.module.css'
import dayjs from 'dayjs'
import { testCountCSSGridValue, countCSSGridValue, hourGridStyle, axisTextStyle, axisLineStyle } from '../../../parts/Calendar/utils'
import CircularProgress from '@mui/material/CircularProgress'

const itemUniqueValue = (item) => {
  return item.courseid+dayjs(item.begin).format('YYYY/MM/DD HH:mm')+dayjs(item.end).format('YYYY/MM/DD HH:mm')
}

const isOverlap = (a, b) => {
  let result = (new Date(a.begin) < new Date(b.end)) && (new Date(b.begin) < new Date(a.end))
  return result
}

const separateArr = (seatPlan, indexArr, orgArr) => {

  if(indexArr.length < 2) {
    seatPlan.push(indexArr)
    return
  }
  /*
  這邊好像對了
  但寬度gridcolumn判斷有問題
  */
  
  let group = [indexArr[0]]
  let copy_group = [indexArr[0]]
  let removeArr = []
  for(let i = 1; i < indexArr.length; i++) {
    group.forEach(groupIdx => {
      if(isOverlap(orgArr[groupIdx], orgArr[indexArr[i]])) {
        removeArr.push(indexArr[i])
        let removedIndex = copy_group.findIndex(item => item === indexArr[i])
        if(removedIndex >= 0) copy_group.splice(removedIndex, 1)
      } else {
        let itemIndex = copy_group.findIndex(item => item === indexArr[i])
        if(itemIndex < 0) copy_group.push(indexArr[i])
      }
    })
    group = copy_group.map(item => item)
  }
  group = [ ...new Set(group) ].sort()
  let nextArr = [ ...new Set(removeArr) ].sort()
  seatPlan.push(group)
  if(nextArr.length > 0) separateArr(seatPlan, nextArr, orgArr)
}

// optimize algorithm from O(n^3??) to amotized O(3n)
const testGrouping = (date, data) => {
  data.sort((a, b) => {
    return (dayjs(a.begin).isBefore(dayjs(b.begin))) ? -1 : 1
  })

  let ans = []
  let target = []
  let targetMap = []
  
  for(let right = 0; right < data.length; right++) {

    if(right === 0) {
      data[right].group = 0
      ans.push([data[right]])
      target.push(data[right].end)
      continue
    }

    // check overlap => push item to the leftmost
    for(let g = 0; g < target.length; g++) {
      // if conflict with this group
        // check next group(continue)
        // if there is no next group, create a new group(push)
      // if no overlap, push this item to this group
      if(dayjs(data[right].begin).isBefore(dayjs(target[g]))) {

        if(g === target.length - 1) {
          ans.push([data[right]])
          data[right].group = g + 1
          target.push(data[right].end)
          break
        } else {
          continue
        }
        
      } else {
        ans[g].push(data[right])
        data[right].group = g
        if(dayjs(data[right].end).isAfter(dayjs(target[g]))) {
          target[g] = data[right].end
        }
        break
      }
    }   
  }

  // expand: see items in one group as a whole, count boundary
  for(let a = 0; a < ans.length; a++) {
    targetMap.push([ans[a][0].begin, ans[a][ans[a].length-1].end])
  }

  // expand: see items in one group as a whole, if conflict with boundary of this group, cannot expand
  for(let i = 0; i < data.length; i++) {
    data[i].width = 1
    data[i].groupLength = ans.length
    for(let t = data[i].group; t < targetMap.length; t++) {
      if(t === data[i].group) continue
      // no overlap
      
      if(dayjs(data[i].end).isBefore(dayjs(targetMap[t][0])) || dayjs(data[i].begin).isAfter(dayjs(targetMap[t][1]))) {
        data[i].width++
      } else {
        break
      }
    }
    data[i].gridStyle = testCountCSSGridValue(date, data[i])
  }

}

const countOrgWidth = (data, resultArr, date) => {
  let arr = data.map(item => item)
  resultArr.forEach((dataIdxArr, groupIdx) => {
    dataIdxArr.forEach(dataIdx => {
      arr[dataIdx].gridStyle = countCSSGridValue(date, arr[dataIdx], groupIdx, resultArr.length)
    })
  })
}

const conflict = (data, temp, pointer) => {
  for(let i = 0; i < temp.length; i++) {
    if(isOverlap(data[pointer], data[temp[i]])) {
      return true
    }
  }
  return false
}

const grouping = (data, nextArr, resultArr, date) => {
  let temp = []
  let remainArr = []

  for(let i = 0; i < nextArr.length; i++) {
    if(temp.length === 0) {
      temp.push(nextArr[i])
      continue
    }
    if(!conflict(data, temp, nextArr[i])) {
      temp.push(nextArr[i])
    } else {
      remainArr.push(nextArr[i])
    }
  }
  resultArr.push(temp)

  console.info('resultArr', resultArr, 'remainArr', remainArr)
  if(remainArr.length === 0) {
    countOrgWidth(data, resultArr, date)
    return
  }
  if(remainArr.length <= 1) {
    resultArr.push(remainArr)
    countOrgWidth(data, resultArr, date)
    return
  }
  grouping(data, remainArr, resultArr, date)
  // console.info('grouping', resultArr)
}

const weekDay = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

const WeekDay = () => {
  return (
    <div className={styles.weekDayTextWrap}>
      { weekDay.map((dayText, idx) => <div key={idx} className={styles.weekDayTextNum}>{dayText}</div>) }
    </div>
  )
}

const axisArr = ['12am', '1am', '2am', '3am', '4am', '5am'
, '6am', '7am', '8am', '9am', '10am', '11am'
, '12pm', '1pm', '2pm', '3pm', '4pm', '5pm'
, '6pm', '7pm', '8pm', '9pm', '10pm', '11pm', '12am']

const hourGrid = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
const hourTextGrid = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]

const WeekTimeline = ({ weekRange, scheduleData, handleScheduleInfoModal, chosenSchedule, loadingGetList }) => {

  const filterDayData = (date) => {
    // data => show correct schedule time on timeline
    // arr => if cross date divide schedule into two parts on timeline
    let data = scheduleData.filter(item => {
    let endTimeCrossDay = dayjs(date).format('YYYY/MM/DD') === dayjs(item.end).format('YYYY/MM/DD') && dayjs(item.end).format('HH:mm') !== '00:00'
      return dayjs(date).format('YYYY/MM/DD') === dayjs(item.begin).format('YYYY/MM/DD') || endTimeCrossDay
    })
    testGrouping(date, data)
    return data
  }

  return (
    <div className={styles.weekTimelineWrap}>
      <div style={{ marginLeft: '142px' }}>
        <div className={styles.weekDayTextWrap}>
          { weekRange.map((dayText, idx) => <div key={idx} className={styles.weekDayText}>{dayjs(dayText).date()}</div>) }
        </div>
        <WeekDay />
      </div>
      <div className={styles.wrap}>
        <div className={styles.axisLeftWrap}>
          { hourTextGrid.map((tim, idx) => <div key={idx} className={styles.axis} style={axisTextStyle(tim)}>{axisArr[tim]}</div>) }
        </div>
        <div className={styles.axisRightWrap}>
          { hourTextGrid.map((tim, idx) => <div key={idx} className={styles.axisLine} style={axisLineStyle(tim)}></div>) }
        </div>
        { weekRange.map((date, idx) => (
          <div key={idx} className={styles.gridWrap}>
            { hourGrid.map((item, idx) => (
              <div key={idx} className={styles.grid} style={hourGridStyle(item)}></div>
            )) }
            { !loadingGetList && filterDayData(date).map((item, idx) => (
              <div 
                key={idx}
                className={`${styles.singleClass} ${itemUniqueValue(item) === itemUniqueValue(chosenSchedule) && styles.active}`}
                style={item.gridStyle} title={`${dayjs(item.begin).format('HH:mm')} ~ ${dayjs(item.end).format('HH:mm')} ${item.courseName}`}
                onClick={() => handleScheduleInfoModal(true, item)}
              >
                {/* <div className={styles.duration}>{`${dayjs(item.begin).format('HH:mm')} ~ ${dayjs(item.end).format('HH:mm')}`}</div> */}
                <div className={styles.course}>{item.courseName}</div>
              </div>
            )) }
          </div>
        )) }
      </div>
      { loadingGetList && <div className={styles.spinnerWrap}><CircularProgress /></div> }
    </div>
  )
}

export default WeekTimeline