import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useState } from 'react';

const TextSwitch = styled(Switch)(({ theme }) => ({
  width: 77,
  height: 36,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(40px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#1452CC',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 32,
    height: 32,
  },
  '& .MuiSwitch-track': {
    borderRadius: 36 / 2,
    backgroundColor: '#b1b1b1',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const CustomSwitch = ({ checked, handleChange }) => {

  return (
    <FormControlLabel
      control={<TextSwitch checked={checked} onChange={handleChange} />}
    />
  )
}

export default CustomSwitch