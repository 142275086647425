import { Popover } from '@mui/material'
import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchInput from '../../../parts/SearchInput'
import Modal from '../../../parts/Modal'
import usePopover from '../../../CustomHooks/usePopover'
import CustomPagination from '../../../parts/CustomPagination'
import SortByIcon from '../../../assets/icons/sortby.svg'
import QuestionIcon from '../../../assets/icons/question.svg'
import styles from './QuizList.module.css'
import UtilityRow from '../../../parts/Activity/UtilityRow/UtilityRow'
import AddModal from '../../../parts/Modals/Activity/AddModal/AddModal'
import DeleteParentModal from '../../../parts/Modals/Activity/DeleteParentModal/DeleteParentModal'
import QuizCard from '../QuizCard/QuizCard'

const QuizList = ({
  searchVal,
  setQuizName,
  setActionType,
  setStep,
  call_getQuizApi,
  quizLoadingGetList,
  page,
  setAddQuizModal,
  setDeleteQuizModal,
  handleOpenToaster,
  setSelectedQuiz,
  call_deleteQuizApi,
  setSeverity,
  call_duplicateQuizApi,
  totalPage,
  handlePage,
  addQuizModal,
  quizName,
  actionType,
  disableAddQuizModalBtn,
  deleteQuizModal,
  setSearchVal,
  setDisableAddQuizModalBtn,
  call_createQuizApi,
  setQuizActionType,
  selectedQuiz,
  call_updateQuizApi,
  sortBy,
  order,
  handleSortBy,
  handleOrder,
  quizList
}) => {
  const [isInit, setIsInit] = useState(true)

  useEffect(() => {
    call_getQuizApi()
  }, [])

  const handleSearchVal = (e) => {
    setSearchVal(e.target.value)
    if (!e.target.value) {
      setIsInit(true)
    } else {
      setIsInit(false)
    }
  }

  const handleAddManuallyModal = (status) => {
    setAddQuizModal(status)
  }

  const onCreateQuiz = () => {
    if (!quizName.trim()) {
      setSeverity('error')
      handleOpenToaster('Quiz name cannot be empty.')
      return
    }
    setDisableAddQuizModalBtn(true)
    if (actionType === 'create') {
      let data = {
        name: quizName.trim(),
        // timer: 0,
        // courseIds: []
      }
      call_createQuizApi(data)
      setQuizActionType('create')
    } else {
      let copyObj = JSON.parse(JSON.stringify(selectedQuiz))
      let data = {
        quizId: copyObj.quizId,
        name: quizName.trim(),
        timer: copyObj.timer,
        courseIds: copyObj.assignedCourses.map((course) => course.id),
        isUpdateName: true
      }
      call_updateQuizApi(data)
    }
  }

  const onDeleteQuiz = () => {
    let data = {
      quizId: selectedQuiz.quizId
    }
    call_deleteQuizApi(data)
    setDeleteQuizModal(false)
  }

  return (
    <>
      <UtilityRow
        typ='Quiz'
        searchVal={searchVal}
        handleSearchVal={handleSearchVal}
        handleAddManuallyModal={handleAddManuallyModal}
        setName={setQuizName}
        setActionType={setActionType}
        setStep={setStep}
        sortBy={sortBy}
        order={order}
        handleSortBy={handleSortBy}
        handleOrder={handleOrder}
      />
      { quizLoadingGetList && (
          <div
            style={{
              height: 'calc(100vh - 330px)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </div>
      )}
      { !quizLoadingGetList && !quizList.length && (
        <div className={styles.noItems}>
          <p>Create your new quiz!</p>
        </div>
      )}
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        { !quizLoadingGetList && quizList.map((item, idx) => (
          <QuizCard 
            key={idx} 
            typ='list' 
            item={item}
            setSelectedQuiz={setSelectedQuiz}
            setQuizName={setQuizName}
            setQuizActionType={setQuizActionType}
            setStep={setStep}
            setDeleteQuizModal={setDeleteQuizModal}
            call_duplicateQuizApi={call_duplicateQuizApi}
          /> 
        ))
        }
      </div>
      {totalPage > 1 && (
        <div className={styles.paginationWrap}>
          <CustomPagination page={page} totalPage={totalPage} handlePage={handlePage} />
        </div>
      )}
      {addQuizModal && (
          <Modal>
            <AddModal
              typ='Quiz'
              name={quizName}
              setName={setQuizName}
              setAddModal={setAddQuizModal}
              onCreate={onCreateQuiz}
              actionType={actionType}
              disableAddModalBtn={disableAddQuizModalBtn}
            />
          </Modal>
        )}
        {deleteQuizModal && (
          <Modal>
            <DeleteParentModal typ='Quiz' setDeleteModal={setDeleteQuizModal} onDelete={onDeleteQuiz} />
          </Modal>
        )}
    </>
  )
}

export default QuizList
