import { useState, useRef, useEffect } from 'react'
import axios from '../api/axios'
import {
  getAssignmentList,
  createAssignment,
  updateAssignment,
  deleteAssignment,
  getAssignmentDetail,
  createAssignmentQuestion as createQuestion,
  updateAssignmentQuestion as updateQuestion,
  deleteAssignmentQuestion as deleteQuestion,
  duplicateAssignmentQuestion as duplicateQuestion,
  duplicateAssignment,
  getAssignmentListForImport,
  importAssignmentQuestion,
  getImportAssignmentHistory,
  uploadImage,
  updateAssignmentStatus,
  getAssignmentResults,
  getAssignmentResultsQuestions,
  getAssignmentResultsParticipants
} from '../api/index'
import { UserContext } from './useAuth'
import { useContext } from 'react'
import usePagination from './usePagination'
import useToaster from './useToaster'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

const PER_PAGE = 14
let getDataTimeout = null

const useAssignmentList = () => {
  const { userInfo } = useContext(UserContext)
  const [assignmentList, setAssignmentList] = useState([])
  const [questionLoadingGetList, setQuestionLoadingGetList] = useState(false)
  const [addAssignmentModal, setAddAssignmentModal] = useState(false)
  const [disableAddAssignmentModalBtn, setDisableAddAssignmentModalBtn] = useState(false)
  const [disableSaveAssignment, setDisableSaveAssignment] = useState(false)
  const [step, setStep] = useState(1)
  const { page, totalPage, handlePage, handleTotalPage } = usePagination()
  const { page: resultsPage, totalPage: resultsTotalPage, handlePage: handleResultsPage, handleTotalPage: handleResultsTotalPage } = usePagination()
  const { page: questionPage, totalPage: questionTotalPage, handlePage: handleQuestionPage, handleTotalPage: handleQuestionTotalPage } = usePagination()
  const [severity, setSeverity] = useState('success')
  const [assignmentDetail, setAssignmentDetail] = useState(null)
  const [courseList, setCourseList] = useState([])
  const [selectedAssignment, setSelectedAssignment] = useState(null)
  const [assignmentLoadingGetList, setAssignmentLoadingGetList] = useState(false)
  const [assignmentListForImport, setAssignmentListForImport] = useState([])
  const [selectVal, setSelectVal] = useState(null)
  const [selectValObj, setSelectValObj] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadedAssignmentList, setUploadedAssignmentList] = useState([])
  const [fileName, setFileName] = useState('No File Selected')
  const [historyList, setHistoryList] = useState([])
  const { openToaster, toasterText, handleOpenToaster, handleCloseToaster } = useToaster()
  const [historyLoadingGetList, setHistoryLoadingGetList] = useState(false)
  const [assignmentResult, setAssignmentResult] = useState([])
  const [assignmentResultQuestions, setAssignmentResultQuestions] = useState([])
  const [assignmentResultParticipants, setAssignmentResultParticipants] = useState([])
  const [resultLoading, setResultLoading] = useState(true)
  const [isQuestionSaving, setIsQuestionSaving] = useState(false)
  const [isAssignmentSaving, setIsAssignmentSaving] = useState(false)
  const fileInputRef = useRef()
  const [searchVal, setSearchVal] = useState('')
  const [sortBy, setSortBy] = useState('Updated date')
  const [order, setOrder] = useState('Descending')
  const abortControllerRef = useRef()
  const [durationStim, setDurationStim] = useState(dayjs().add(5, 'minute'))
  const [durationEtim, setDurationEtim] = useState(dayjs().add(1, 'month'))
  const [invalidDuration, setInvalidDuration] = useState(false)
  const [ltiCopyLinkModal, setLtiCopyLinkModal] = useState(false)
  const [saveImageLoading, setSaveImageLoading] = useState(false)

  useEffect(() => {
    call_getAssignmentResults()
  }, [resultsPage])

  const onCopyDeployId = (typ) => {
    const deploymentId = typ == 'Canvas' ? selectedAssignment.canvasDeploymentId : selectedAssignment.blackboardDeploymentId
    navigator.clipboard.writeText(`https://www.assignment.io?assignmentId=${selectedAssignment.assignmentId}&deploymentId=${deploymentId}`)
    setLtiCopyLinkModal(false)
  }

  const handleLtiCopyLinkModal = (sts) => {
    setLtiCopyLinkModal(sts)
    if (!sts) {
      setStep(1)
    }
  }

  const handleDurationStimChange = (val) => {
    setDurationStim(val)
  }

  const handleDurationEtimChange = (val) => {
    setDurationEtim(val)
  }

  const handleSortBy = (e, value) => {
    setSortBy(value)
  }

  const handleOrder = (e, value) => {
    setOrder(value)
  }

  useEffect(() => {
    if (getDataTimeout) clearTimeout(getDataTimeout)
    getDataTimeout = setTimeout(() => {
      call_getAssignmentApi()
    }, 200)
  }, [searchVal, sortBy, page, order])

  const call_getAssignmentApi = () => {
    if (abortControllerRef.current) abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()

    const queryConfig = {
      Ascending: 'asc',
      Descending: 'desc',
      'Updated date': 'updatedDate',
      'Assignment name (A-Z)': 'name',
      'Quiz name (A-Z)': 'name',
      Status: 'status',
      'Number of questions': 'questionNum'
    }

    setAssignmentLoadingGetList(true)
    if (!userInfo.userID) return
    let data = {
      orgId: userInfo.orgID,
      userId: userInfo.userID,
      searchVal,
      sortBy: queryConfig[sortBy],
      order: queryConfig[order]
    }

    let params = {
      offset: (page - 1) * PER_PAGE,
      limit: PER_PAGE
    }
    // console.log("data", data);
    getAssignmentList(data, params, abortControllerRef.current.signal)
      .then((res) => {
        let list = res.data.assignments
        setAssignmentList(list)
        setAssignmentLoadingGetList(false)
        handleTotalPage(Math.max(Math.ceil(res.data.totalCount / PER_PAGE), 1))
      })
      .catch((err) => console.log('err', err))
  }

  const call_createAssignmentApi = (data) => {
    data = Object.assign(data, {
      userId: userInfo.userID,
      orgId: userInfo.orgID
    })
    createAssignment(data)
      .then((res) => {
        setAddAssignmentModal(false)
        setDisableAddAssignmentModalBtn(false)
        setStep(2)
        setSelectedAssignment({
          assignmentId: res.data.assignmentId
        })
        handleOpenToaster('Assignment has been saved successfully!')
      })
      .catch((err) => console.log('err', err))
  }

  const call_deleteQuestionApi = (data) => {
    setQuestionLoadingGetList(true)
    deleteQuestion(data)
      .then((res) => {
        setSeverity('success')
        handleOpenToaster('Question has been deleted successfully!')
        call_getAssignmentDetail({
          assignmentId: data.assignmentId
        })
      })
      .catch((err) => console.log('err', err))
  }

  const call_duplicateQuestionApi = (data) => {
    setQuestionLoadingGetList(true)
    duplicateQuestion(data)
      .then((res) => {
        setSeverity('success')
        handleOpenToaster('Question has been duplicated successfully!')
        call_getAssignmentDetail(data)
      })
      .catch((err) => console.log('err', err))
  }

  const call_updateAssignmentApi = (data, toResult = false) => {
    setAssignmentLoadingGetList(true)
    updateAssignment(data)
      .then((res) => {
        call_getAssignmentApi()
        setSeverity('success')

        switch (data.action) {
          case 'publish':
            setLtiCopyLinkModal(true)
            handleOpenToaster('Assignment has been copied and published successfully, you can share to other platforms now.')
            break
          case 'finish':
            handleOpenToaster('Assignment has been successfully ended.')
            break
          default:
            handleOpenToaster('Assignment has been updated successfully!')
            setStep(1)
        }

        if (toResult) setStep(7)
      })
      .catch((err) => {
        console.log('err', err)
        setSeverity('error')
        handleOpenToaster(err.response.data.message)
      })
      .finally(() => {
        setIsAssignmentSaving(false)
        setAddAssignmentModal(false)
        setDisableAddAssignmentModalBtn(false)
        setDisableSaveAssignment(false)
      })
  }

  const call_deleteAssignmentApi = (data) => {
    setAssignmentLoadingGetList(true)
    deleteAssignment(data)
      .then((res) => {
        setSeverity('success')
        handleOpenToaster('Assignment has been deleted successfully!')
        call_getAssignmentApi()
      })
      .catch((err) => console.log('err', err))
  }

  const call_duplicateAssignmentApi = (data) => {
    setAssignmentLoadingGetList(true)
    duplicateAssignment(data)
      .then((res) => {
        setSeverity('success')
        handleOpenToaster('Assignment has been duplicated successfully!')
        call_getAssignmentApi(data)
      })
      .catch((err) => console.log('err', err))
  }

  const call_getAssignmentDetail = (data) => {
    setQuestionLoadingGetList(true)
    getAssignmentDetail(data)
      .then((res) => {
        setQuestionLoadingGetList(false)
        let response = res.data
        // console.log('response', response)
        setAssignmentDetail(response)
      })
      .catch((err) => console.log('err', err))
  }

  const call_createQuestionApi = (data) => {
    setQuestionLoadingGetList(true)
    data.userId = userInfo.userID
    createQuestionApi(data)
  }

  const createQuestionApi = (data) => {
    // data = Object.assign(data, {
    //   userId: userInfo.userID
    // })
    // console.log("data", data);
    createQuestion(data)
      .then((res) => {
        setStep(2)
        setIsQuestionSaving(false)
        setSeverity('success')
        handleOpenToaster('Assignment has been saved successfully!')
      })
      .catch((err) => console.log('err', err))
  }

  const call_updateQuestionApi = (data) => {
    setQuestionLoadingGetList(true)
    data.userId = userInfo.userID
    updateQuestionApi(data)
  }

  const updateQuestionApi = (data) => {
    console.log('2')
    data = Object.assign(data, {
      userId: userInfo.userID
    })
    // console.log("data", data);
    updateQuestion(data)
      .then((res) => {
        console.log('3')
        setStep(2)
        setIsQuestionSaving(false)
        setSeverity('success')
        handleOpenToaster('Question has been edited successfully!')
      })
      .catch((err) => console.log('err', err))
  }

  const call_createAssignmentImportApi = (data) => {
    data = Object.assign(data, {
      userId: userInfo.userID,
      orgId: userInfo.orgID
    })
    createAssignment(data)
      .then((assignmentRes) => {
        setAddAssignmentModal(false)
        setDisableAddAssignmentModalBtn(false)
        getAssignmentListForImport(data)
          .then((listRes) => {
            let list = listRes.data
            list.unshift({ id: 'addAssignment', label: '+ Add Assignment' })
            setAssignmentListForImport(list)
            setSelectVal(data.name)
            let valObj = {
              id: assignmentRes.data.body.assignmentId,
              label: data.name
            }
            setSelectValObj(valObj)
          })
          .catch((err) => console.log('err', err))
      })
      .catch((err) => console.log('err', err))
  }

  const call_getAssignmentListForImportAPI = () => {
    let data = {
      userId: userInfo.userID,
      orgId: userInfo.orgID
    }
    getAssignmentListForImport(data)
      .then((res) => {
        let list = res.data
        list.unshift({ id: 'addAssignment', label: '+ Add Assignment' })
        setAssignmentListForImport(res.data)
      })
      .catch((err) => console.log('err', err))
  }

  const call_getImportHistoryAPI = () => {
    setHistoryLoadingGetList(true)
    let data = {
      userId: userInfo.userID,
      orgId: userInfo.orgID
    }
    getImportAssignmentHistory(data)
      .then((res) => {
        setHistoryLoadingGetList(false)
        setHistoryList(res.data)
      })
      .catch((err) => {
        console.info('err', err)
      })
  }

  const call_importQuestionsAPI = (data) => {
    // console.log('data', data)
    data = Object.assign(data, {
      userId: userInfo.userID,
      orgId: userInfo.orgID
    })

    const uploadPromises = []
    for (const question of data.question) {
      if (question.image) {
        const imageData = {
          folderName: 'assignment',
          imageId: question.image
        }
        let headers = {
          'Content-Type': 'multipart/form-data'
        }
        uploadImage(imageData).then((res) => {
          const s3Url = res.data.s3_signed_url
          const myPromise = axios.put(s3Url, question.imageBlob, headers)
          uploadPromises.push(myPromise)
        })
      }
    }

    Promise.all(uploadPromises)
      .then((res) => {
        return importAssignmentQuestion(data)
      })
      .then((res) => {
        setSeverity('success')
        handleOpenToaster('Assignment has been imported successfully!')
        call_getImportHistoryAPI()
      })
      .catch((err) => {
        setSeverity('error')
        handleOpenToaster('Fail to import assignment.')
        console.info('err', err)
      })
      .finally(() => {
        setIsUploading(false)
        setUploadedAssignmentList([])
        setSelectVal(null)
        setSelectValObj(null)
        fileInputRef.current.value = ''
        setFileName('No File Selected')
      })
  }

  const call_updateAssignmentStatusApi = (data) => {
    updateAssignmentStatus(data)
      .then((res) => {
        if (data.action === 'publish') {
          setSeverity('success')
          handleOpenToaster('Assignment has been copied and published successfully, you can share to other platforms now.')
        } else {
          setSeverity('success')
          handleOpenToaster('Assignment has been successfully ended.')
        }
        call_getAssignmentApi()
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const call_getAssignmentResults = () => {
    let data = {
      orgId: userInfo.orgID,
      userId: userInfo.userID
    }

    let params = {
      offset: (resultsPage - 1) * PER_PAGE,
      limit: PER_PAGE
    }

    setResultLoading(true)
    getAssignmentResults(data, params)
      .then((res) => {
        console.info('call_getAssignmentResults res', res)
        setResultLoading(false)
        setAssignmentResult(res.data.results)
        handleResultsTotalPage(Math.max(Math.ceil(res.data.totalCount / PER_PAGE), 1))
      })
      .catch((err) => console.log('err', err))
  }

  const call_getAssignmentResultsQuestions = (data) => {
    setResultLoading(true)
    getAssignmentResultsQuestions(data)
      .then((res) => {
        setResultLoading(false)
        setAssignmentResultQuestions(res.data)
      })
      .catch((err) => console.log('err', err))
  }

  const call_getAssignmentResultsParticipants = (data) => {
    setResultLoading(true)
    getAssignmentResultsParticipants(data)
      .then((res) => {
        setResultLoading(false)
        setAssignmentResultParticipants(
          res.data.map((item) => {
            item.open = false
            return item
          })
        )
      })
      .catch((err) => console.log('err', err))
  }

  const toSingleResultPage = (item) => {
    setStep(7)
    console.info('navigate to result in results page => item', item)
    setSelectedAssignment(item)
  }

  return {
    assignmentList,
    setAssignmentList,
    assignmentLoadingGetList,
    questionLoadingGetList,
    page,
    assignmentDetail,
    courseList,
    totalPage,
    handlePage,
    handleTotalPage,
    resultsPage,
    resultsTotalPage,
    handleResultsPage,
    call_getAssignmentApi,
    call_createAssignmentApi,
    call_updateAssignmentApi,
    call_deleteAssignmentApi,
    openToaster,
    toasterText,
    handleOpenToaster,
    handleCloseToaster,
    severity,
    setSeverity,
    step,
    setStep,
    setCourseList,
    call_getAssignmentDetail,
    call_createQuestionApi,
    call_updateQuestionApi,
    call_deleteQuestionApi,
    selectedAssignment,
    setSelectedAssignment,
    call_duplicateAssignmentApi,
    setAssignmentDetail,
    addAssignmentModal,
    setAddAssignmentModal,
    disableAddAssignmentModalBtn,
    setDisableAddAssignmentModalBtn,
    disableSaveAssignment,
    setDisableSaveAssignment,
    questionPage,
    questionTotalPage,
    handleQuestionPage,
    handleQuestionTotalPage,
    call_getAssignmentListForImportAPI,
    call_getImportHistoryAPI,
    call_importQuestionsAPI,
    assignmentListForImport,
    setAssignmentListForImport,
    call_createAssignmentImportApi,
    selectVal,
    setSelectVal,
    selectValObj,
    setSelectValObj,
    isUploading,
    setIsUploading,
    uploadedAssignmentList,
    setUploadedAssignmentList,
    fileName,
    setFileName,
    fileInputRef,
    historyList,
    setHistoryList,
    historyLoadingGetList,
    setQuestionLoadingGetList,
    call_duplicateQuestionApi,
    call_updateAssignmentStatusApi,
    call_getAssignmentResults,
    resultLoading,
    assignmentResult,
    isQuestionSaving,
    setIsQuestionSaving,
    isAssignmentSaving,
    setIsAssignmentSaving,
    toSingleResultPage,
    sortBy,
    order,
    handleSortBy,
    handleOrder,
    searchVal,
    setSearchVal,
    durationStim,
    durationEtim,
    handleDurationStimChange,
    handleDurationEtimChange,
    setInvalidDuration,
    invalidDuration,
    call_getAssignmentResultsQuestions,
    call_getAssignmentResultsParticipants,
    assignmentResultQuestions,
    assignmentResultParticipants,
    setAssignmentResultParticipants,
    ltiCopyLinkModal,
    handleLtiCopyLinkModal,
    onCopyDeployId,
    saveImageLoading,
    setSaveImageLoading
  }
}

export default useAssignmentList
