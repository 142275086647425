import styles from './DeleteGroupUpListModal.module.css'

const DeleteGroupUpListModal = ({ handleDeleteGroupUpListModal, deleteGroupListItem }) => {

  return (
    <div className={styles.modalWrap}>
      <div className={styles.title}>Delete Group Up List</div>
      <div className={styles.message}>Are you sure you want to delete group up list?</div>
      <div className={styles.btnWrap}>
        <div className={`${styles.delete} ${styles.btn}`} onClick={() => { handleDeleteGroupUpListModal(false); deleteGroupListItem() }}>Delete</div>
        <div className={`${styles.cancel} ${styles.btn}`} onClick={() => handleDeleteGroupUpListModal(false)}>Cancel</div>
      </div>
    </div>
  )
}

export default DeleteGroupUpListModal