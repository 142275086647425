import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { forwardRef } from 'react'

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const Toaster = ({ openToaster, handleCloseToaster, severity, message }) => {
  const successStyle = {
    minWidth: '25vw',
    maxWidth: '75vw',
    minHeight: '48px',
    backgroundColor: '#fff',
    border: '4px solid #009135',
    borderRadius: '8px',
    color: '#009135',
    display: 'flex',
    alignItems: 'center',
    justiyContent: 'center'
  }

  const errorStyle = {
    width: '25vw',
    height: '48px',
    backgroundColor: '#fff',
    border: '4px solid #E60026',
    borderRadius: '8px',
    color: '#E60026',
    display: 'flex',
    alignItems: 'center',
    justiyContent: 'center'
  }

  const styleConfig = {
    success: successStyle,
    error: errorStyle
  }

  const messageStyle = { color: '#000000', font: 'normal normal normal 16px/21px InterLight', fontSize: 'clamp(1rem, 0.4rem + 0.7vw, 1.8rem)' }

  return (
    <Snackbar open={openToaster} autoHideDuration={3000} onClose={handleCloseToaster} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={handleCloseToaster} severity={severity} sx={styleConfig[severity]}>
        <div style={messageStyle}>{message}</div>
      </Alert>
    </Snackbar>
  )
}

export default Toaster
