import styles from './OnlineStatus.module.css'
import Modal from '../../../parts/Modal'
import { Fragment, useContext, useEffect, useState } from 'react'
import PeopleIcon from '../../../assets/icons/people.svg'
import CircularProgress from '@mui/material/CircularProgress'
import useOnlineStatusList from '../../../CustomHooks/useOnlineStatusList'
import CustomPagination from '../../../parts/CustomPagination'
import SelectFilter from '../../../parts/SelectFilter'
import SignOutModal from '../../../parts/Modals/SignOutModal/SignOutModal'
import { UserContext } from '../../../CustomHooks/useAuth'
import CachedIcon from '@mui/icons-material/Cached'
import Toaster from  '../../../parts/Toaster'
import { returnIdleTimeStrings } from '../../../utils/handleMemberList'

const TableHeader = ({ role, handleRoleChange, idleSortType, handleIdleSortTypeChange, onlineStatusList }) => {

  const { userInfo } = useContext(UserContext)

  return (
    <div className={`${styles.tableWrap} ${styles.tableHeader}`}>
      <div className={`${styles.tableItem} ${styles.onlineStatus}`}>
        <span>Online Status</span>
        <img alt='people' src={PeopleIcon} />
        <span>{`${onlineStatusList.length} ${ (userInfo.onlineNum >= 0) ? '/'+userInfo.onlineNum : '' }` }</span>
      </div>
      <div className={`${styles.tableItem} ${styles.role}`}>
        <SelectFilter options={['All Roles', 'Owner', 'Admin', 'Teacher', 'Student']} val={role} handleValChange={handleRoleChange} typ='white' disabled={false} />
      </div>
      <div className={`${styles.tableItem}`}>Name</div>
      <div className={`${styles.tableItem}`}>Display Name</div>
      <div className={`${styles.tableItem} ${styles.idle}`}>
        <SelectFilter options={['Idle Time(Sort Descending)', 'Idle Time(Sort Ascending)']} val={idleSortType} handleValChange={handleIdleSortTypeChange} typ='white' disabled={false} />
      </div>
      <div className={`${styles.tableItem}`}>Action</div>
    </div>
  )
}

const TableContent = ({ item, handleSignOutModal }) => {
  const { onlineStatus, role, name, displayName, idleTime, userID, lastActiveTime } = item
  const [timeString, setTimeString] = useState('')

  const onSignOut = () => {
    handleSignOutModal(true, userID)
  }

  useEffect(() => {
    setTimeString(returnIdleTimeStrings(lastActiveTime))
  }, [lastActiveTime])

  return (
    <div className={`${styles.tableWrap} ${styles.tableContent} ${styles.center}`}>
      <div className={`${styles.tableItem} ${styles.onlineStatus}`}>
        <div>
          <div className={`${styles.statusColor} ${styles.online}`}></div>
          <div>{onlineStatus}</div>
        </div>
      </div>
      <div className={`${styles.tableItem}`}>{role}</div>
      <div className={`${styles.name} ${styles.tableItem}`}>{name}</div>
      <div className={`${styles.tableItem}`}>{displayName}</div>
      <div className={`${styles.tableItem}`}>{timeString}</div>
      <div className={`${styles.tableItem}`}>
        <span className={`${styles.signOut}`} onClick={onSignOut}>
          Sign out
        </span>
      </div>
    </div>
  )
}

const OnlineStatus = () => {
  const [role, setRole] = useState('All Roles')
  const [idleSortType, setIdleSortType] = useState('Idle Time(Sort Descending)')
  const [onlineFilterList, setOnlineFilterList] = useState([])
  const [signOutModal, setSignOutModal] = useState(false)
  const { countDownSeconds, coolDown, waitingList, empty_waitingList, onlineStatusList, setOnlineStatusList,
    loadingOnlineStatusList, setLoadingOnlineStatusList, page, totalPage, handlePage, handleTotalPage, force_logout,
    openToaster, toasterText, toastSeverity, handleCloseToaster, setForceLogOutTimer, chosenUserID, setChosenUserID } = useOnlineStatusList()


  const handleRoleChange = (e) => {
    setRole(e.target.value)
  }

  const handleIdleSortTypeChange = (e) => {
    setIdleSortType(e.target.value)
  }

  const handleSignOutModal = (sts, userID = '') => {
    if(sts) {
      setSignOutModal(true)
      setChosenUserID(userID)
    } else {
      setSignOutModal(false)
      // setChosenUserID('')
    }
  }

  const onSignOutUser = () => {
    setForceLogOutTimer()
    setLoadingOnlineStatusList(true)
    force_logout(chosenUserID)
    handleSignOutModal(false)
  }

  const onSignOutCancel = () => {
    handleSignOutModal(false)
  }

  const filterStudentList = () => {
    console.info("filter student list", onlineStatusList)
    let arr
    if (role === 'All Roles') {
      arr = onlineStatusList
    } else {
      arr = onlineStatusList.filter((item) => item.role.toLowerCase() === role.toLowerCase())
    }
    
    arr = arr.sort((a, b) => {
      return (idleSortType === 'Idle Time(Sort Descending)') 
      ? a.lastActiveTime - b.lastActiveTime 
      : b.lastActiveTime - a.lastActiveTime
    })

    arr.forEach(item => item.idleTime = returnIdleTimeStrings(item.lastActiveTime))
    
    setOnlineFilterList(arr)
    handleTotalPage(Math.max(Math.ceil(arr.length / 14), 1))
    handlePage(1)
  }

  const returnCountDownStrings = () => {
    let min = Math.floor(countDownSeconds / 60)
    let sec = countDownSeconds % 60

    if(min) return `${min} minutes ${sec} seconds`
    return `${sec} seconds`
  }

  useEffect(() => {
    filterStudentList()
  }, [role, onlineStatusList, idleSortType])

  return (
    <div className={styles.OnlineStatusListWrap}>
      <div className={styles.tableContainer}>
        <div className={styles.refreshRow}>
          <div className={styles.updateText}>Last Update: {`${countDownSeconds ? returnCountDownStrings() : `0 second`} ago`}</div>
          <div className={styles.updateNum}>{ (waitingList.length) ? `${waitingList.length} updates` : '' }</div>
          <button className={`${styles.refresh} ${(!waitingList.length || coolDown) && styles.disabled}`} onClick={empty_waitingList}>
            <CachedIcon className={styles.icon} />
            Refresh
          </button>
        </div>
        <TableHeader handleRoleChange={handleRoleChange} role={role} idleSortType={idleSortType} handleIdleSortTypeChange={handleIdleSortTypeChange} onlineStatusList={onlineStatusList} />
        {loadingOnlineStatusList && (
          <div style={{ height: 'calc(100vh - 330px)', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        )}
        {(!loadingOnlineStatusList && onlineFilterList.length !== 0) && (
          <Fragment>
            {onlineFilterList
              .filter((item, idx) => idx >= (page - 1) * 14 && idx <= (page - 1) * 14 + 13)
              .map((item, idx) => (
                <TableContent key={idx} item={item} handleSignOutModal={handleSignOutModal} />
              ))}
          </Fragment>
        )}
        {!loadingOnlineStatusList && onlineFilterList.length === 0 && <div className={styles.messageBox}>No online members!</div>}
        {totalPage > 1 && (
          <div className={styles.paginationWrap}>
            <CustomPagination page={page} totalPage={totalPage} handlePage={handlePage} />
          </div>
        )}
      </div>
      {signOutModal && (
        <Modal>
          <SignOutModal onSignOutUser={onSignOutUser} onSignOutCancel={onSignOutCancel} />
        </Modal>
      )}
      <Toaster openToaster={openToaster} handleCloseToaster={handleCloseToaster} severity={toastSeverity} message={toasterText} />
    </div>
  )
}

export default OnlineStatus
