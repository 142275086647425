import { Fragment } from 'react'
import styles from './QuizCard.module.css'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import QuestionIcon from '../../../assets/icons/question.svg'
import PeopleIcon from '../../../assets/icons/grayPeople.svg'
import usePopover from '../../../CustomHooks/usePopover'
import Popover from '@mui/material/Popover'
import dayjs from 'dayjs'

const MenuItem = ({ text, iconName, callback, colorStyle }) => {
  return (
    <div className={`${styles.option} ${styles[colorStyle]}`} onClick={() => callback()}>
      <div className={`${styles.icon} ${styles[iconName]}`}></div>
      <div>{ text }</div>
    </div>
  )
}

const ListContentMenu = ({ typ, open, anchorEl, handleClose, onEditQuiz, onDeleteQuiz, onDuplicateQuiz, 
  onViewResult, onDeleteQuizResult }) => {

  const handleEditQuiz = () => {
    onEditQuiz()
    handleClose()
  }

  const handleDeleteQuiz = () => {
    onDeleteQuiz()
    handleClose()
  }

  const handleDuplicateQuiz = () => {
    onDuplicateQuiz()
    handleClose()
  }

  const handleViewResult = () => {
    onViewResult()
    handleClose()
  }

  const handleDeleteQuizResult = () => {
    onDeleteQuizResult()
    handleClose()
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className={styles.menuWrap}>
        {
          (typ === 'result') ? (
            <Fragment>
              <MenuItem text='View Result' iconName='result' callback={handleViewResult} colorStyle='blue'/>
              <MenuItem text='Delete' iconName='delete' callback={handleDeleteQuizResult} colorStyle='red'/>
            </Fragment>
          ) : (
            <Fragment>
              <MenuItem text='Edit' iconName='edit' callback={handleEditQuiz} colorStyle='blue'/>
              <MenuItem text='Duplicate' iconName='duplicate' callback={handleDuplicateQuiz} colorStyle='blue'/>
              <MenuItem text='Delete' iconName='delete' callback={handleDeleteQuiz} colorStyle='red'/>
            </Fragment>
          )
        }
      </div>
    </Popover>
  )
}

const QuizCard = ({ typ, item, setSelectedQuiz, setQuizName, setQuizActionType, setStep, setDeleteQuizModal,
  call_duplicateQuizApi, toSingleResultPage, setSelectedQuizResult, setDeleteQuizResultModal }) => {

  const { open, anchorEl, openPopover, handleClose } = usePopover()

  const onEditQuiz = () => {
    setSelectedQuiz(item)
    setQuizName(item.name)
    setQuizActionType('edit')
    setStep(2)
  }

  const onDeleteQuiz = () => {
    setSelectedQuiz(item)
    setDeleteQuizModal(true)
  }

  const onDuplicateQuiz = () => {
    call_duplicateQuizApi({ quizId: item.quizId })
  }

  const onViewResult = () => {
    toSingleResultPage(item)
  }

  const onDeleteQuizResult = () => {
    setSelectedQuizResult(item)
    setDeleteQuizResultModal(true)
  }

  return (
    <div className={styles.card}>
      <div className={styles.titleWrap}>
        <div className={styles.ellipsis}>
          { item.name }
        </div>
        <div className={`${styles.moreIcon} ${open && styles.active}`} onClick={openPopover}>
          <MoreHorizIcon sx={{ color: '#707070', transform: 'scale(1.2)', cursor: 'pointer' }} />
        </div>
      </div>
      <div className={styles.question_people_date}>
        <div className={styles.question_people}>
          { (typ === 'list') ? (
            <Fragment>
              <img alt='question count' src={QuestionIcon} />
              <div>{ item.questionCount }</div>
            </Fragment>
          ) : (
            <Fragment>
              <img alt='participants count' src={PeopleIcon} />
              <div>{ item.totalParticipants } participants</div>
            </Fragment>
          ) }
        </div>
        <div className={styles.date}>
          {
            (typ === 'result') ? 
            `Course Date: ${dayjs(dayjs.unix(item.courseDate)).format('YYYY/MM/DD')}` : 
            `Updated Date: ${dayjs(dayjs.unix(item.updatedDate)).format('YYYY/MM/DD')}`
          }
        </div>
      </div>
      <div 
        className={`${styles.assigned_classrooms} ${typ === 'result' && styles.center} ${(typ !== 'result' && item.assignedCourses.length === 0) && styles.center}`}>
        {
          (typ === 'result') ? (
            <div style={{ minWidth: '95px' }}>Classroom:</div>
          ) : (
            <div style={{ minWidth: '167px' }}>Assigned Classrooms:</div>
          )
        }
        <div className={styles.classrooms}>
          {
            typ === 'result' ? (
              <div className={styles.classroom}>{ item.course }</div>
            ) : (
              <Fragment>
              { item.assignedCourses.length == 0 && <div className={styles.emptyHint}>Has not been assigned to any course yet.</div> }
              {
                item.assignedCourses.length !== 0 && item.assignedCourses.filter((item, index) => index < 5).map((item, idx) => (
                  <div key={idx} className={styles.classroom}>{ item.name }</div>
                ))
              }
              { item.assignedCourses.length > 5 && <div className={styles.classroom}>+{ item.assignedCourses.length - 5 }</div> }
              </Fragment>
            )
          }
        </div>
      </div>
      <ListContentMenu 
        typ={typ} 
        open={open} 
        anchorEl={anchorEl} 
        handleClose={handleClose} 
        onEditQuiz={onEditQuiz}
        onDeleteQuiz={onDeleteQuiz}
        onDuplicateQuiz={onDuplicateQuiz}
        onViewResult={onViewResult}
        onDeleteQuizResult={onDeleteQuizResult}
      />
    </div>
  )
}

export default QuizCard