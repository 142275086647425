import styles from './EditUserModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../../CustomHooks/useAuth'
import BorderInput from '../../BorderForm/BorderInput'
import BorderWindowSelect from '../../BorderForm/BorderWindowSelect'

const roleConfig = {
  admin: 'Admin',
  student: 'Student',
  teacher: 'Teacher'
}

const useFormData = (chosenAccount, slotObj) => {
  const [role, setRole] = useState(chosenAccount.role.toLowerCase())
  const [firstName, setfirstName] = useState(chosenAccount.firstName ? chosenAccount.firstName : chosenAccount.name)
  const [displayName, setDisplayName] = useState(chosenAccount.display)
  const [lastName, setlastName] = useState(chosenAccount.lastName ? chosenAccount.lastName : '')
  const [email, setEmail] = useState(chosenAccount.email)
  const [disableUpdateButton, setDisableUpdateButton] = useState(true)

  useEffect(() => {
    setDisableUpdateButton(checkDisableUpdateAccount())
  }, [role, firstName, lastName, displayName, email])

  const checkDisableUpdateAccount = () => {
    let firstNameBool = chosenAccount.firstName ? chosenAccount.firstName === firstName : chosenAccount.name === firstName
    let lastNameBool = chosenAccount.lastName === lastName
    let displayNameBool = chosenAccount.display === displayName
    let emailBool = chosenAccount.email.toLowerCase() === email.toLowerCase()
    let roleBool = chosenAccount.role.toLowerCase() === role.toLowerCase()
    return (firstNameBool && lastNameBool && displayNameBool && emailBool && roleBool) || firstName == '' || lastName == '' || email == '' || displayName == ''
  }

  /*
  cannot fill name field with spaces
  */
  const checkEmptyOrNot = (val) => {
    return !val.trim() ? '' : val
  }

  const handleDisplayNameChange = (e) => {
    let val = checkEmptyOrNot(e.target.value)
    setDisplayName(val)
  }

  const handlefirstNameChange = (e) => {
    let val = checkEmptyOrNot(e.target.value)
    setfirstName(val)
  }

  const handlelastNameChange = (e) => {
    let val = checkEmptyOrNot(e.target.value)
    setlastName(val)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleRoleChange = (e, value) => {
    setRole(value)
  }

  return {
    role,
    handleRoleChange,
    firstName,
    handlefirstNameChange,
    lastName,
    handlelastNameChange,
    displayName,
    handleDisplayNameChange,
    email,
    handleEmailChange,
    disableUpdateButton
  }
}

const EditUserModal = ({ slotObj, handleEditUserModal, chosenAccount, setUpdateItem }) => {
  const {
    role,
    handleRoleChange,
    firstName,
    handlefirstNameChange,
    lastName,
    handlelastNameChange,
    displayName,
    handleDisplayNameChange,
    email,
    handleEmailChange,
    disableUpdateButton
  } = useFormData(chosenAccount, slotObj)

  const editUser = () => {
    if (disableUpdateButton) return
    /*
      trim name spaces when calling api
    */
    let editObj = {
      userID: chosenAccount.userID,
      role,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email,
      createFrom: chosenAccount.origin,
      displayName: displayName.trim()
    }

    editObj.name = `${editObj.firstName} ${editObj.lastName}`

    setUpdateItem(editObj)
    handleEditUserModal(false)
  }

  const { userInfo } = useContext(UserContext)
  // only admin and owner could edit other users
  const disabled = userInfo.identity.toLowerCase() !== 'admin' && userInfo.identity !== 'owner'

  return (
    <div className={styles.modalWrap}>
      <div className={styles.titleWrap}>
        <div className={styles.title}>Edit User</div>
        <div className={styles.icon} onClick={() => handleEditUserModal(false)}>
          <CloseRoundedIcon sx={{ color: '#808080', '&:hover': { color: '#1452CC' } }} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.left}>
          <div className={styles.item}>
            <BorderInput inputhVal={firstName} handleInputVal={handlefirstNameChange} placeholder='First Name' isValid={firstName !== ''} helperText='' disabled={disabled} />
          </div>
          <div className={styles.item}>
            <BorderInput inputhVal={lastName} handleInputVal={handlelastNameChange} placeholder='Last Name' isValid={lastName !== ''} helperText='' disabled={disabled} />
          </div>
        </div>
        <div className={styles.item}>
          <BorderInput inputhVal={displayName} handleInputVal={handleDisplayNameChange} placeholder='Display Name' isValid={displayName !== ''} helperText='' disabled={disabled} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.left}>
          <BorderInput inputhVal={email} handleInputVal={handleEmailChange} placeholder='Email' isValid={true} helperText={''} disabled={true} />
        </div>
        {/* <div className={styles.left}><InsetInput inputhVal={email} handleInputVal={handleEmailChange} placeholder="Email" isValid={isEmailValid} helperText={emailHelperText} disabled={false}/></div> */}
        <div className={`${styles.right} ${userInfo.identity === 'owner' && chosenAccount.email.toLowerCase() === userInfo.email.toLowerCase() && styles.hide}`}>
          <BorderWindowSelect
            options={['admin', 'teacher', 'student'].filter((item) => slotObj[roleConfig[item]].occupied < slotObj[roleConfig[item]].whole || role === item)}
            val={role}
            handleValChange={handleRoleChange}
            typ='inset'
            disabled={disabled}
          />
        </div>
      </div>
      <div className={styles.btnWrap}>
        <button className={`${styles.update} ${disableUpdateButton && styles.disabled}`} onClick={editUser}>
          Update
        </button>
      </div>
    </div>
  )
}

export default EditUserModal
