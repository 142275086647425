import styles from './DeleteQuizResultModal.module.css'

const DeleteQuizResultModal = ({ setDeleteQuizResultModal, call_deleteQuizResultApi, selectedQuizResult }) => {

  const onDeleteQuizResult = () => {
    call_deleteQuizResultApi(selectedQuizResult)
  }

  return (
    <div className={styles.modalWrap}>
      <div className={styles.title}>Delete Quiz Results</div>
      <div className={styles.message}>
        Are you sure you want to delete this quiz result? This action will also delete any associated portfolio records.
      </div>
      <div className={styles.btnWrap}>
        <div
          className={`${styles.delete} ${styles.btn}`}
          onClick={() => {
            setDeleteQuizResultModal(false)
            onDeleteQuizResult()
          }}
        >
          Delete
        </div>
        <div className={`${styles.cancel} ${styles.btn}`} onClick={() => setDeleteQuizResultModal(false)}>
          Cancel
        </div>
      </div>
    </div>
  )
}

export default DeleteQuizResultModal
