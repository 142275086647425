import styles from './AddCanvasMemberModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Checkbox  from '@mui/material/Checkbox'
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import SelectFilter from '../../SelectFilter'
import AddMemberModal from '../AddMemberModal/AddMemberModal'
import useMemberList from '../../../CustomHooks/useMemberList'
import { checkAccountExistsOrNot } from '../../../api'

const TableHeader = ({ selectAll, handleSelectAllChange }) => {
  return (
    <div className={`${styles.tableWrap} ${styles.tableHeader}`}>
      <div className={`${styles.checkbox} ${styles.tableItem}`}>
        <Checkbox 
          checked={selectAll}
          onChange={handleSelectAllChange}
          icon={<RadioButtonUncheckedRoundedIcon />}
          checkedIcon={<CheckCircleRoundedIcon />}
        />
      </div>
      <div className={styles.right}>
        <div className={`${styles.role} ${styles.tableItem}`}>Role</div>
        <div className={`${styles.name} ${styles.tableItem}`}>Name</div>
        <div className={`${styles.email} ${styles.tableItem}`}>Email</div>
      </div>
    </div>
  )
}

const TableContent = ({ item, handleCheckboxChange }) => {
  return (
    <div className={`${styles.tableWrap} ${styles.tableContent} ${item.disabled && styles.disabled}`}>
      <div className={`${styles.checkbox} ${styles.tableItem}`}>
        <Checkbox 
          checked={item.checked}
          onChange={handleCheckboxChange}
          value={item.email}
          disabled={item.disabled}
          icon={<RadioButtonUncheckedRoundedIcon />}
          checkedIcon={<CheckCircleRoundedIcon />}
        />
      </div>
      <div className={styles.right}>
        <div className={`${styles.role} ${styles.tableItem}`}>{ item.role }</div>
        <div className={`${styles.name} ${styles.tableItem}`}>{ item.name }</div>
        <div className={`${styles.email} ${styles.tableItem}`}>{ item.email }</div>
      </div>
    </div>
  )
}

const chosenSlot = {
  'Admin': { chosen: 0, saved: 0, left: 0 },
  'Teacher': { chosen: 0, saved: 0, left: 0 },
  'Student': { chosen: 0, saved: 0, left: 0 },
}

const Slot = ({ chosenSlotObj }) => {
  return (
    <div className={styles.slotWrap}>
      { Object.keys(chosenSlotObj).map((role, idx) => (
        <div key={idx} className={styles.slotItem}>
          <div className={styles.role}>{`${role}:`}</div>
          <div className={`${styles.slot} ${ (chosenSlotObj[role].chosen >= chosenSlotObj[role].left) && styles.warning }`}>{`${chosenSlotObj[role].chosen}/${chosenSlotObj[role].left}`}</div>
        </div>
      )) }
    </div>
  )
}

const AddCanvasMemberModal = ({ handleAddCanvasMemberModal, canvasMemberList, handleCanvasMemberList, slotObj, call_saveApi, loadingSaveList }) => {

  const [role, setRole] = useState('All Roles')
  const [selectAll, setSelectAll] = useState(false)
  const [chosenSlotObj, setChosenSlotObj] = useState(chosenSlot)
  const [showChildModal, setShowChildModal] = useState(false)
  const { reformCanvasImportArr } = useMemberList()

  const openChildModal = () => {
    setShowChildModal(true)
  }

  const closeChildModal = () => {
    setShowChildModal(false);
  }

  const saveAndClose = () => {
    closeChildModal()
    call_saveApi(reformCanvasImportArr(canvasMemberList))
    setAllItemDisabled()
  }

  const setAllItemDisabled = () => {
    let arr = canvasMemberList.map(item => item)
    arr.forEach(item => {
      if(item.checked) item.disabled = true
    })
    handleCanvasMemberList(arr)
  }

  useEffect(() => {
    handleChosenSlotObj()
  }, [slotObj, canvasMemberList])

  useEffect(() => {
    setSelectAll(isSelectAll())
  }, [canvasMemberList])

  const handleChosenSlotObj = () => {
    let obj = JSON.parse(JSON.stringify(chosenSlot))
    let role = ['Admin', 'Teacher', 'Student']
    role.forEach(roleText => {
      obj[roleText].left = slotObj[roleText].whole - slotObj[roleText].occupied
    })
    canvasMemberList.forEach(item => {
      if(item.disabled) {
        obj[item.role].saved++
      }
      if(item.checked && !item.disabled) {
        obj[item.role].chosen++
      }
    })
    setChosenSlotObj(obj)
  }

  const isSelectAll = () => {
    if(!canvasMemberList.length) return false
    for(let item of canvasMemberList) {
      if(!item.checked) return false
    }
    return true
  }

  const handleSelectAllChange = (e) => {
    let checked = e.target.checked
    if(checked) {
      if(chosenSlotObj.Student.left - chosenSlotObj.Student.chosen < canvasMemberList.length) return
    }
    setSelectAll(checked)
    let arr = canvasMemberList.map(item => item)
    arr.forEach(item => item.checked = checked)
    handleCanvasMemberList(arr)
  }

  const handleCheckboxChange = async (e) => {
    let checked = e.target.checked
    let email = e.target.value
    if(checked) {
      if(chosenSlotObj.Student.left - chosenSlotObj.Student.chosen === 0) return
      // const { data } = await checkAccountExistsOrNot(JSON.stringify({email}))
      // if(data !== 'notExist') {
      //   alert('repeated!!!')
      //   return
      // }
    }
    let arr = canvasMemberList.map(item => item)
    let item = arr.find(item => item.email.toLowerCase() === email.toLowerCase())
    item.checked = checked
    handleCanvasMemberList(arr)
  }

  const handleRoleChange = (e) => {
    setRole(e.target.value)
  }

  return (
    <div className={styles.wrap}>
      { false && <div className={styles.loadingWrap}><CircularProgress /></div> }
      {/* { loadingSaveList && <div className={styles.loadingWrap}><CircularProgress /></div> } */}
      <div className={styles.titleWrap}>
        <div className={styles.title}>Import Account From Canvas</div>
        <div className={styles.cancel} onClick={() => handleAddCanvasMemberModal(false)}><CloseRoundedIcon /></div>
      </div>
      <div className={styles.utilityWrap}>
        <div className={styles.select}>
          {/* <SelectFilter 
            options={['All Roles', 'Admin', 'Teacher', 'Student']}
            val={role}
            handleValChange={handleRoleChange}
            typ='inset'
            disabled={false}
          /> */}
        </div>
        <div className={styles.right}>
          <div className={styles.text}>Total User:</div>
          <div className={styles.num}>{ canvasMemberList.length }</div>
        </div>
      </div>
      <div className={styles.contentWrap}>
        <TableHeader selectAll={selectAll} handleSelectAllChange={handleSelectAllChange} />
        { canvasMemberList.map((item, idx) => (
          <TableContent key={idx} item={item} handleCheckboxChange={handleCheckboxChange} />
        )) }
      </div>
      <div className={styles.importRow}>
        <div className={styles.left}>
          <div>Selected</div>
          <Slot chosenSlotObj={chosenSlotObj} />
        </div>
        <div className={styles.right}>
          <button className={styles.import} onClick={openChildModal}>Import</button>
        </div>
      </div>
      <AddMemberModal chosenSlotObj={chosenSlotObj} showChildModal={showChildModal} handleClose={closeChildModal} saveAndClose={saveAndClose} />
    </div>
  )
}

export default AddCanvasMemberModal