import InsetInput from '../../InsetInput'
import styles from './CanvasDomainModal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useState } from 'react'
import { authorizeByUser } from '../../../api/canvas'

const CanvasDomainModal = ({ handleCanvasLinkModal }) => {

  const [canvasDomain, setCanvasDomain] = useState('')

  const handleCanvasDomainChange = (e) => {
    setCanvasDomain(e.target.value)
  }

  const call_canvasApi = () => {
    authorizeByUser(canvasDomain)
    handleCanvasLinkModal(false)
  } 

  return (
    <div className={styles.wrap}>
      <div className={styles.modalTitle}>
        <div className={styles.title}>Log In to Your Canvas Account</div>
        <div className={styles.cancel} onClick={() => handleCanvasLinkModal(false)}><CloseRoundedIcon /></div>
      </div>
      <div className={styles.linkInput}>
        <InsetInput type="shadow" inputhVal={canvasDomain} handleInputVal={handleCanvasDomainChange} placeholder="Canvas Login Link" isValid={true} helperText="" disabled={false} />
      </div>
      <div className={styles.content}>If you are an educator at a School that uses Canvas, please tap the link to access the login page.</div>
      <button className={styles.confirm} onClick={call_canvasApi}>Confirm</button>
    </div>
  )
}

export default CanvasDomainModal