import styles from './Schedule.module.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import useScheduleData from '../../CustomHooks/useScheduleData'
import SelectFilter from '../../parts/SelectFilter'
import MonthCalendar from './MonthCalendar/MonthCalendar'
import WeekTimeline from './WeekTimeline/WeekTimeline'
import AddClassModal from '../../parts/Modals/AddClassModal/AddClassModal'
import EditSchedule from './EditSchedule/EditSchedule'
import Modal from '../../parts/Modal'
import { useEffect, useState } from 'react'
import ScheduleInfo from './ScheduleInfo/ScheduleInfo'
import { useContext } from 'react'
import { UserContext } from '../../CustomHooks/useAuth'
import AddClassButton from '../../parts/AddClassButton/AddClassButton'
import Toaster from '../../parts/Toaster'
import { LinkDatePicker } from '../../parts/TimePicker/TimePicker'
import dayjs from 'dayjs'
import { getWeek } from '../../parts/Calendar/utils'

const UtilityRow = ({ userInfo, viewMode, handleViewMode, teachers, chosenTeacher, handleChosenTeacher, handleChosenDateByArrow, weekRange, handleModal, handleChosenDateByMonthCalendar, arrowDate }) => {

  let identity = userInfo.identity

  const [displayTime, setDisplayTime] = useState()

  useEffect(() => {
    if(viewMode === 'Month') {
      setDisplayTime(dayjs(arrowDate).format('MMMM YYYY'))
      return
    }
    let weekDates = getWeek(dayjs(arrowDate))
    setDisplayTime(`${dayjs(weekDates[0]).format('YYYY MMM. D')} - ${dayjs(weekDates[6]).format('MMM. D')}`)
  }, [viewMode, arrowDate])

  return (
    <div className={styles.utilityRowWrap}>
      <div className={styles.left}>
        <AddClassButton handleModal={handleModal} />
        <div className={styles.changeDateBtn}>
          <div className={styles.arrow} onClick={() => handleChosenDateByArrow(-1)}><ArrowForwardIosIcon sx={{ transform: 'rotate(180deg)' }}/></div>
          <div className={styles.date}>
            <div>{ displayTime }</div>
            { viewMode === 'Month' && (
              <LinkDatePicker typ='monthInput' value={dayjs(new Date(arrowDate))} handleChange={handleChosenDateByMonthCalendar} />
            ) }
          </div>
          <div className={styles.arrow} onClick={() => handleChosenDateByArrow(1)}><ArrowForwardIosIcon /></div>
        </div>
      </div>
      <div className={styles.right}>
        { !!teachers.length && (
          <div className={`${styles.teacherSelect} ${identity === 'student' && styles.disabled}`}>
            <SelectFilter label='All' options={teachers} val={chosenTeacher} handleValChange={handleChosenTeacher} typ="outside" disabled={false} />
          </div>)
        }
        <div className={styles.modeToggle}>
          { ['Week', 'Month'].map((mode, idx) => (
            <div 
              key={idx}
              className={`${styles.toggle} ${styles[mode]} ${(viewMode === mode) && styles.active}`}
              onClick={() => handleViewMode(mode)}>
                {mode}
            </div>
          )) }
        </div>
      </div>
    </div>
  )
}

const Schedule = () => {

  const {viewMode, handleViewMode, teachers, chosenTeacher, handleChosenTeacher, 
    monthRange, weekRange, handleChosenDateByArrow, call_saveApi, scheduleData, loadingGetList, loadingSaveList,
    openToaster, toasterText, handleCloseToaster, chosenSchedule, closeEditModal, call_editApi, call_deleteApi,
    openScheduleInfoModal, openScheduleEditModal, handleScheduleInfoModal, handleScheduleEditModal, reformEditInfo, editInfoItem, editInfoMode,
    handleChosenDateByMonthCalendar, arrowDate, courseInfoData
   } = useScheduleData()
  const [showModal, setShowModal] = useState(false)
  
  const { userInfo, handleUpgradePlanModal } = useContext(UserContext)

  const handleModal = (sts) => {
    if(userInfo.isExpired) {
      handleUpgradePlanModal(true)
      return
    }
    setShowModal(sts)
    if(!sts) {
      if(openToaster) handleCloseToaster()
    }
  }

	return (
		<div className={styles.scheduleWrap}>
      <UtilityRow 
        viewMode={viewMode}
        handleViewMode={handleViewMode}
        teachers={teachers}
        chosenTeacher={chosenTeacher}
        handleChosenTeacher={handleChosenTeacher}
        handleChosenDateByArrow={handleChosenDateByArrow}
        weekRange={weekRange}
        handleModal={handleModal}
        userInfo={userInfo}
        handleChosenDateByMonthCalendar={handleChosenDateByMonthCalendar}
        arrowDate={arrowDate}
      />
      { (viewMode === 'Week') ? 
          <WeekTimeline weekRange={weekRange} scheduleData={scheduleData.filter(item => (chosenTeacher === 'All') ? true : item.teacher === chosenTeacher)} handleScheduleInfoModal={handleScheduleInfoModal} chosenSchedule={chosenSchedule} loadingGetList={loadingGetList} />  :
          <MonthCalendar monthRange={monthRange} scheduleData={scheduleData.filter(item => (chosenTeacher === 'All') ? true : item.teacher === chosenTeacher)} handleScheduleInfoModal={handleScheduleInfoModal} chosenSchedule={chosenSchedule} loadingGetList={loadingGetList} /> 
      }
      {
        showModal && (
          <Modal>
            <AddClassModal 
              handleModal={handleModal}
              call_saveApi={call_saveApi}
              loadingSaveList={loadingSaveList}
            />
          </Modal>
        )
      }
      { openScheduleInfoModal && <ScheduleInfo courseInfoData={courseInfoData} chosenSchedule={chosenSchedule} handleScheduleInfoModal={handleScheduleInfoModal} handleScheduleEditModal={handleScheduleEditModal} reformEditInfo={reformEditInfo} /> }
      { openScheduleEditModal && <EditSchedule closeEditModal={closeEditModal} call_editApi={call_editApi} call_deleteApi={call_deleteApi} editInfoItem={editInfoItem} editInfoMode={editInfoMode} handleScheduleInfoModal={handleScheduleInfoModal} /> }
      {/* { <EditSchedule chosenSchedule={chosenSchedule} openConfirmModal={openConfirmModal} /> } */}
      <Toaster openToaster={openToaster} handleCloseToaster={handleCloseToaster} severity="success" message={toasterText} />
		</div>
	)
}

export default Schedule