import { Popover } from '@mui/material'
import UtilityRow from '../../../parts/Activity/UtilityRow/UtilityRow'
import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchInput from '../../../parts/SearchInput'
import Modal from '../../../parts/Modal'
import usePopover from '../../../CustomHooks/usePopover'
import CustomPagination from '../../../parts/CustomPagination'
import SortByIcon from '../../../assets/icons/sortby.svg'
import QuestionIcon from '../../../assets/icons/question.svg'
import styles from './AssignmentList.module.css'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import AddModal from '../../../parts/Modals/Activity/AddModal/AddModal'
import DeleteParentModal from '../../../parts/Modals/Activity/DeleteParentModal/DeleteParentModal'
import EndAssignmentModal from '../../../parts/Modals/EndAssignmentModal/EndAssignmentModal'
import { AssignmentStatusEnum } from '../../../utils/enum'
import dayjs from 'dayjs'

const TableContent = ({
  index,
  item,
  setStep,
  setAddAssignmentModal,
  setAssignmentName,
  setActionType,
  setDeleteAssignmentModal,
  onClickAssignmentItem,
  setSelectedAssignment,
  call_deleteAssignmentApi,
  setSeverity,
  handleOpenToaster,
  call_duplicateAssignmentApi,
  call_updateAssignmentApi,
  setEndAssignmentModal
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (e, item) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getUpdatedDate = (timeStamp) => {
    let updatedDate = new Date(timeStamp * 1000)
    let year = updatedDate.getFullYear()
    let month = ('0' + (updatedDate.getMonth() + 1)).slice(-2)
    let date = ('0' + updatedDate.getDate()).slice(-2)
    return `${year}/${month}/${date}`
  }

  const iconStyles = { color: '#707070', transform: 'scale(1.2)' }

  return (
    <div className={styles.itemWrap}>
      <div className={`${styles.item} ${styles.assignmentName}`}>
        <p>{item.name}</p>
      </div>
      <div className={`${styles.courseWrap} ${styles.item} ${styles.status} ${styles[item.status]}`}>
        <p>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</p>
      </div>
      <div className={`${styles.item} ${styles.question}`}>
        <img alt='question' src={QuestionIcon} />
        {item.questionCount}
      </div>
      <div className={styles.item}>
        <p>{getUpdatedDate(item.updatedDate)}</p>
      </div>
      <div className={`${styles.item} ${styles.more}`}>
        <div className={`${styles.actionIcon} ${ open && styles.active }`} onClick={(e) => {handleClick(e, item)}}>
          <MoreHorizIcon sx={iconStyles} />
        </div>
        <ListContentMenu
          setStep={setStep}
          index={index}
          setSeverity={setSeverity}
          handleOpenToaster={handleOpenToaster}
          item={item}
          setSelectedAssignment={setSelectedAssignment}
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          setAddAssignmentModal={setAddAssignmentModal}
          assignmentName={item.assignmentName}
          setAssignmentName={setAssignmentName}
          setActionType={setActionType}
          setDeleteAssignmentModal={setDeleteAssignmentModal}
          call_deleteAssignmentApi={call_deleteAssignmentApi}
          call_duplicateAssignmentApi={call_duplicateAssignmentApi}
          call_updateAssignmentApi={call_updateAssignmentApi}
          onClickAssignmentItem={onClickAssignmentItem}
          setEndAssignmentModal={setEndAssignmentModal}
        />
      </div>
    </div>
  )
}

const TableHeader = ({ onSortUpdatedDate, call_getAssignmentApi }) => {
  useEffect(() => {
    call_getAssignmentApi()
  }, [])

  return (
    <div className={styles.titleWrap}>
      <div className={styles.tableItem}>Assignment Name</div>
      <div className={`${styles.tableItem} ${styles.status}`}>
        <span>Status</span>
        <div className={styles.questionIcon}>
          <Tooltip
            title={`Assignments are "${AssignmentStatusEnum.DRAFT}" when created, "${AssignmentStatusEnum.PUBLISHED}" after shared, and "${AssignmentStatusEnum.FINISHED}" when manually ended.`}
            placement='bottom-start'
            componentsProps={{
              popper: {
                sx: {
                  [`& .${tooltipClasses.arrow}`]: {
                    color: (theme) => theme.palette.common.black
                  },
                  [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: (theme) => theme.palette.common.black,
                    fontWeight: 400
                  }
                }
              }
            }}
            arrow
          >
            <QuestionMarkIcon sx={{ fontSize: 16, color: 'white' }} />
          </Tooltip>
        </div>
      </div>
      <div className={styles.tableItem}>Questions</div>
      <div className={`${styles.tableItem} ${styles.updatedDate}`}>
        Updated Date
        <img alt='sortby' src={SortByIcon} onClick={onSortUpdatedDate} />
      </div>
      <div className={styles.tableItem}>Action</div>
    </div>
  )
}

const ListContentMenu = ({
  index,
  item,
  anchorEl,
  open,
  handleClose,
  setAddAssignmentModal,
  setEndAssignmentModal,
  setStep,
  setAssignmentName,
  setActionType,
  setDeleteAssignmentModal,
  setSelectedAssignment,
  call_duplicateAssignmentApi,
  call_updateAssignmentApi,
  onClickAssignmentItem
}) => {
  
  const style = {
    '& .MuiPaper-root': {
      borderRadius: '8px',
      boxShadow: '0px 0px 6px #00000033',
      '& .MuiMenu-list': {
        padding: '0px'
      },
      '& .MuiMenuItem-root': {
        // padding: '15px'
      }
    }
  }

  const handleEditAssignment = () => {
    onClickAssignmentItem('', item)
    handleClose()
  }

  const handleDeleteAssignment = () => {
    setSelectedAssignment(item)
    setDeleteAssignmentModal(true)
    handleClose()
  }

  const handleDuplicateAssignment = () => {
    handleClose()
    call_duplicateAssignmentApi({
      assignmentId: item.assignmentId
    })
  }

  const handleCloseAssignment = () => {
    handleClose()
    setSelectedAssignment(item)
    setEndAssignmentModal(true)
  }

  const handleShareAssignment = () => {
    setSelectedAssignment(item)
    call_updateAssignmentApi({
      assignmentId: item.assignmentId,
      name: item.name,
      timer: item.timer,
      startTime: item.startTime,
      endTime: item.endTime,
      action: 'publish'
    })
    handleClose()
  }

  const navigateToResult = () => {
    setStep(7)
    console.info('navigate to result in list page => item', item)
    setSelectedAssignment(item)
  }

  return (
    <Menu sx={style} anchorEl={anchorEl} open={open} onClose={handleClose}>
      {item.status === AssignmentStatusEnum.DRAFT && (
        <div className={styles.option} onClick={handleEditAssignment}>
          <div className={`${styles.optionIcon} ${styles.editIcon}`}></div>
          <div>Edit</div>
        </div>
      )}
      <div className={styles.option} onClick={handleDuplicateAssignment}>
        <div className={`${styles.optionIcon} ${styles.duplicateIcon}`}></div>
        <div>Duplicate</div>
      </div>
      {(item.status === AssignmentStatusEnum.DRAFT || item.status === AssignmentStatusEnum.PUBLISHED) && (
        <div className={`${styles.option} ${item.questionCount === 0 && styles.disabled}`} onClick={handleShareAssignment}>
          <div className={`${styles.optionIcon} ${styles.publishIcon}`}></div>
          <div>Publish</div>
        </div>
      )}
      {item.status === AssignmentStatusEnum.PUBLISHED && (
        <div className={styles.option} onClick={handleCloseAssignment}>
          <div className={`${styles.optionIcon} ${styles.endIcon}`}></div>
          <div>End</div>
        </div>
      )}
      {item.status === AssignmentStatusEnum.FINISHED && (
        <div className={styles.option} onClick={navigateToResult}>
          <div className={`${styles.optionIcon} ${styles.resultsIcon}`}></div>
          <div>Results</div>
        </div>
      )}
      {item.status === AssignmentStatusEnum.DRAFT && (
        <div className={`${styles.option} ${styles.delete}`} onClick={handleDeleteAssignment}>
          <div className={`${styles.optionIcon} ${styles.deleteIcon}`}></div>
          <div>Delete</div>
        </div>
      )}
    </Menu>
  )
}

const AssignmentList = ({
  assignmentList,
  setAssignmentList,
  searchVal,
  setAssignmentName,
  setActionType,
  setStep,
  call_getAssignmentApi,
  assignmentLoadingGetList,
  page,
  setAddAssignmentModal,
  setDeleteAssignmentModal,
  endAssignmentModal,
  setEndAssignmentModal,
  handleOpenToaster,
  setSelectedAssignment,
  call_deleteAssignmentApi,
  setSeverity,
  call_duplicateAssignmentApi,
  totalPage,
  handlePage,
  addAssignmentModal,
  assignmentName,
  actionType,
  disableAddAssignmentModalBtn,
  deleteAssignmentModal,
  setSearchVal,
  setDisableAddAssignmentModalBtn,
  call_createAssignmentApi,
  setAssignmentActionType,
  selectedAssignment,
  call_updateAssignmentApi,
  sortBy,
  order,
  handleSortBy,
  handleOrder
}) => {
  const [isInit, setIsInit] = useState(true)

  const handleSearchVal = (e) => {
    setSearchVal(e.target.value)
    if (!e.target.value) {
      setIsInit(true)
    } else {
      setIsInit(false)
    }
  }

  const handleAddManuallyModal = (status) => {
    setAddAssignmentModal(status)
  }
  const onCreateAssignment = () => {
    if (!assignmentName.trim()) {
      setSeverity('error')
      handleOpenToaster('Assignment name cannot be empty.')
      return
    }
    setDisableAddAssignmentModalBtn(true)
    if (actionType === 'create') {
      let data = {
        name: assignmentName.trim()
      }
      call_createAssignmentApi(data)
      setAssignmentActionType('create')
    } else {
      let copyObj = JSON.parse(JSON.stringify(selectedAssignment))
      let data = {
        assignmentId: copyObj.assignmentId,
        name: assignmentName.trim(),
        timer: copyObj.timer,
        startTime: copyObj.startTime ? copyObj.startTime : dayjs(dayjs().subtract(1, 'month')).unix(),
        endTime: copyObj.endTime ? copyObj.endTime : dayjs(dayjs().add(1, 'month')).unix(),
        action: null, 
      }
      call_updateAssignmentApi(data)
    }
  }

  const onSortUpdatedDate = () => {
    const array = JSON.parse(JSON.stringify(assignmentList))
    setAssignmentList(array.reverse())
  }

  const onDeleteAssignment = () => {
    let data = {
      assignmentId: selectedAssignment.assignmentId
    }
    call_deleteAssignmentApi(data)
    setDeleteAssignmentModal(false)
  }

  const onClickAssignmentItem = (e, item) => {
    setSelectedAssignment(item)
    setAssignmentName(item.name)
    setAssignmentActionType('edit')
    setStep(2)
  }

  const onEndAssignment = () => {
    setEndAssignmentModal(false)
    call_updateAssignmentApi(
      {
        assignmentId: selectedAssignment.assignmentId,
        name: selectedAssignment.name,
        timer: selectedAssignment.timer,
        startTime: selectedAssignment.startTime,
        endTime: selectedAssignment.endTime,
        action: 'finish'
      }
    )
  }

  const onResetDraft = () => {
    setEndAssignmentModal(false)
    call_updateAssignmentApi(
      {
        assignmentId: selectedAssignment.assignmentId,
        name: selectedAssignment.name,
        timer: selectedAssignment.timer,
        startTime: selectedAssignment.startTime,
        endTime: selectedAssignment.endTime,
        action: 'unpublish'
      }
    )
  }

  const onEndAndViewResult = () => {
    setEndAssignmentModal(false)
    call_updateAssignmentApi(
      {
        assignmentId: selectedAssignment.assignmentId,
        name: selectedAssignment.name,
        timer: selectedAssignment.timer,
        startTime: selectedAssignment.startTime,
        endTime: selectedAssignment.endTime,
        action: 'finish'
      },
      true
    )
  }

  return (
    <>
      <UtilityRow
        typ='Assignment'
        searchVal={searchVal}
        handleSearchVal={handleSearchVal}
        handleAddManuallyModal={handleAddManuallyModal}
        setName={setAssignmentName}
        setActionType={setActionType}
        setStep={setStep}
        sortBy={sortBy}
        order={order}
        handleSortBy={handleSortBy}
        handleOrder={handleOrder}
      />
      <div className={styles.tableContainer}>
        <TableHeader onSortUpdatedDate={onSortUpdatedDate} call_getAssignmentApi={call_getAssignmentApi} />
        {assignmentLoadingGetList && (
          <div
            style={{
              height: 'calc(100vh - 330px)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!assignmentLoadingGetList && isInit && !assignmentList.length && (
          <div className={styles.noItems}>
            <p>Create your new assignment!</p>
          </div>
        )}
        {!assignmentLoadingGetList &&
          assignmentList
            .map((item, index) => {
              return (
                <TableContent
                  key={index}
                  index={index}
                  item={item}
                  setStep={setStep}
                  setAddAssignmentModal={setAddAssignmentModal}
                  setEndAssignmentModal={setEndAssignmentModal}
                  setAssignmentName={setAssignmentName}
                  setActionType={setActionType}
                  setDeleteAssignmentModal={setDeleteAssignmentModal}
                  onClickAssignmentItem={onClickAssignmentItem}
                  setSelectedAssignment={setSelectedAssignment}
                  call_deleteAssignmentApi={call_deleteAssignmentApi}
                  setSeverity={setSeverity}
                  handleOpenToaster={handleOpenToaster}
                  call_duplicateAssignmentApi={call_duplicateAssignmentApi}
                  call_updateAssignmentApi={call_updateAssignmentApi}
                />
              )
            })}
        {totalPage > 1 && (
          <div className={styles.paginationWrap}>
            <CustomPagination page={page} totalPage={totalPage} handlePage={handlePage} />
          </div>
        )}
        {addAssignmentModal && (
          <Modal>
            <AddModal
              typ='Assignment'
              name={assignmentName}
              setName={setAssignmentName}
              setAddModal={setAddAssignmentModal}
              onCreate={onCreateAssignment}
              actionType={actionType}
              disableAddModalBtn={disableAddAssignmentModalBtn}
            />
          </Modal>
        )}
        {deleteAssignmentModal && (
          <Modal>
            <DeleteParentModal typ='Assignment' setDeleteModal={setDeleteAssignmentModal} onDelete={onDeleteAssignment} />
          </Modal>
        )}
        {endAssignmentModal && (
          <Modal>
            <EndAssignmentModal 
              handleModal={setEndAssignmentModal} 
              onEndAssignment={onEndAssignment} 
              onResetDraft={onResetDraft} 
              onEndAndViewResult={onEndAndViewResult}
              selectedAssignment={selectedAssignment}
            />
          </Modal>
        )}
      </div>
    </>
  )
}

export default AssignmentList
