import { Fragment } from 'react'
import styles from './Questions.module.css'
import PeopleIcon from '../../../../assets/icons/grayPeople.svg'

const Questions = ({ typ, result }) => {
  const getQuestionType = (type) => {
    switch (type) {
      case 'tf':
        return 'True/False'
      case 'single':
        return 'Single Selection'
      default:
        return 'Multiple Selection'
    }
  }

  return (
    <Fragment>
      {result.length === 0 && (
        <div className={styles.noResults}>
          <p>We are sorry, but we could not find any student records.</p>
          <p>You can duplicate this {typ.toLowerCase()} and re-publish it.</p>
        </div>
      )}
      {result.length !== 0 && (
        <div className={styles.questionArea}>
          {result &&
            result.map((question, index) => {
              return (
                <div key={index} className={styles.question}>
                  <div className={styles.title}>
                    <h1>Question {index + 1}</h1>
                    <div className={styles.questionTypeIcon}>{getQuestionType(question.type)}</div>
                  </div>
                  <h2>{question.content}</h2>
                  {question.image && <img alt='question' src={question.image} />}
                  {question.options.map((option, idx) => {
                    return (
                      <div key={idx} className={styles.option}>
                        <div>{option.optionId === 'null' ? 'Null' : `Option ${idx + 1}`}</div>
                        <div>{option.optionId === 'null' ? '' : option.content}</div>
                        <div className={styles.studentCount}>
                          <img alt='studentCount' src={PeopleIcon} />
                          {option.studentCount}
                        </div>
                        <div>{option.percentage}%</div>
                        <div className={`${styles.barCol} ${option.optionId === 'null' ? styles.null : option.answer ? styles.correct : styles.incorrect}`}>
                          <div style={{ width: `${option.percentage}%` }}></div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
        </div>
      )}
    </Fragment>
  )
}

export default Questions
