import { useState, useEffect } from "react"
import useToaster from "./useToaster"

const useGroupUp = (chosenClassItem, memberList) => {
  const [groupName, setGroupName] = useState('')
  const [groupUpMode, setGroupUpMode] = useState('') // customized or automatic
  const [childOption, setChildOption] = useState('-')
  const [toGroupSettingPage, setToGroupSettingPage] = useState(false)
  const [unassignedUsers, setUnassignedUsers] = useState([])
  const [dragData, setDragData] = useState([])
  const [groupNum, setGroupNum] = useState(0)
  const [addGroupMode, setAddGroupMode] = useState('create')
  const [chosenGroupListIndex, setChosenGroupListIndex] = useState(-1)
  const { openToaster, toasterText, handleOpenToaster, handleCloseToaster } = useToaster()

  const per = (childOption.split('-')[0] === 'customized') ? 10 : childOption.split('-')[1]
  const boxPer = (per > 5 || childOption.split('-')[0] === 'customized') ? 10 : 5

  useEffect(() => {
    if(!toGroupSettingPage && childOption === '-') return
    if(addGroupMode === 'edit' || addGroupMode === 'duplicate') {
      // console.info('do not do anything, only fill up the space', "addGroupMode === 'edit' || addGroupMode === 'duplicate'", addGroupMode)
      fillUpSpace()
      return
    }

    let num = Math.ceil(chosenClassItem.studentList.length / per)

    // automatic
    if(childOption.split('-')[0] === 'automatic') {
      // console.info('assign everything automatically', "childOption.split('-')[0] === 'automatic'")
      assignRandomly(num)
      setGroupNum(num)
      return
    }

    // customize
    if(childOption.split('-')[0] === 'customized') {
      // console.info('customized => if no data beforehead, member / 10, if there is data, do not do anything')
      if(dragData.length !== 0) {
        // with existing data
        // console.info('do not do anything, only fill up the space', "childOption.split('-')[0] === 'customized'")
        num = dragData.length
        fillUpSpace()
      } else {
        // with empty data
        // console.info('if no data beforehead, member / 10', "childOption.split('-')[0] === 'customized'")
        resetDragData(num)
        resetUnassignedUsers()
      }
      setGroupNum(num)
      return
    }
  }, [childOption])

  const handleAddGroupMode = (mode) => {
    setAddGroupMode(mode)
  }

  const addUpGroupNum = () => {
    // 新增一個group 然後給予空的陣列資料
    if(addGroupMode === 'edit') setAddGroupMode('update-edit')
    if(addGroupMode === 'duplicate') setAddGroupMode('update-duplicate')

    dragData.push(new Array(boxPer).fill('').map(item => ({ name: '', email: '' })))
    let newData = JSON.parse(JSON.stringify(dragData))
    setGroupNum(groupNum+1)
    handleDragData(JSON.parse(JSON.stringify(newData)))
  }

  const assignRandomly = (num=null) => {

    let typ = ''

    if(!num) {
      typ = 'random'
      num = Math.ceil(chosenClassItem.studentList.length / per)
    }


    let list = reformChosenStudentList(typ)
    let arr = []
    new Array(num).fill('').forEach((item, index) => {
      if(per*index > list.length - 1) return
      let tempList = list.slice(per*index, per*(index+1))
      arr.push(tempList)
    })
    handleDragData(arr)
    handleUnassignedUsers([])
  }

  const resetDragData = (num) => {
    let arr = []
    new Array(num).fill('').forEach((item, index) => {
      arr.push(new Array(boxPer).fill('').map(item => ({ name: '', email: '' })))
    })
    handleDragData(arr)
  }

  const fillUpSpace = () => {
    let newData = JSON.parse(JSON.stringify(dragData))
    for(let groupIndex = 0; groupIndex < newData.length; groupIndex++) {
      let group = newData[groupIndex]
      let emptyArr = []
      if(boxPer - group.length) emptyArr = new Array(boxPer - group.length).fill('').map(item => ({ name: '', email: '' }))
      newData[groupIndex] = group.concat(emptyArr)
    }
    setDragData(newData)
  }

  const handleDragData = (newData) => {
    for(let groupIndex = 0; groupIndex < newData.length; groupIndex++) {
      let group = newData[groupIndex]
      let emptyArr = []
      if(boxPer - group.length) emptyArr = new Array(boxPer - group.length).fill('').map(item => ({ name: '', email: '' }))
      newData[groupIndex] = group.concat(emptyArr)
    }
    setDragData(newData)
  }

  const handleUnassignedUsers = (newData) => {
    setUnassignedUsers(newData)
  }

  const resetUnassignedUsers = () => {
    handleUnassignedUsers(reformChosenStudentList())
  }

  const reformChosenStudentList = (typ='') => {
    // 可以分配進小組的學生清單
    let list = chosenClassItem.studentList.map(item => {
      let chosenMember = memberList.find(member => member.email.toLowerCase() === item.email.toLowerCase())
      return { name:  `${chosenMember.firstName} ${chosenMember.lastName}`, email: chosenMember.email, id: (typ) ? Math.random()*1000 : 1 }
    })
    list = list.sort((a,b) => (a.id - b.id)).map(item => ({ name: item.name, email: item.email }))
    return list
  }

  const handleChosenGroupListIndex = (idx) => {
    setChosenGroupListIndex(idx)
  }

  const resetGroupUpSettings = () => {
    setGroupUpMode('')
    setChildOption('-')
    setGroupName('')
    setAddGroupMode('create')
    setChosenGroupListIndex(-1)
    setDragData([])
    setUnassignedUsers([])
    setToGroupSettingPage(false)
  }

  const handleToSettingPage = (sts, mode) => {
    setToGroupSettingPage(sts)
    setAddGroupMode(mode)
  }

  const handleGroupName = (e, sts) => {
    if(sts) {
      setGroupName(e)
      handleOpenToaster('Group Up List Name has been updated successfully!')
      return
    }
    setGroupName(e.target.value)
  }

  const handleGroupUpMode = (value) => {
    setGroupUpMode(value)
  }

  const handleChildOption = (value) => {
    setChildOption(value)
  }

  const setGroupUpParams = (groupUpModeInRadioBox, childOptionInRadioBox) => {
    // 更新radiobox的選項時(按下group up modal的save或setting page的update)
    // 如果一開始進來是edit或duplicate模式 是不會根據childOption重新分配資料的 所以要更改mode 才能讓資料開始跟著childOption重新分配
    if(addGroupMode === 'edit') setAddGroupMode('update-edit')
    if(addGroupMode === 'duplicate') setAddGroupMode('update-duplicate')
    setGroupUpMode(groupUpModeInRadioBox)
    setChildOption(childOptionInRadioBox)
  }

  const resetGroupMode = () => {
    setGroupUpMode('customized')
    setChildOption(`customized-${childOption.split('-')[1]}`)
  }

  const editGroupListItem = (item, index) => {
    setChosenGroupListIndex(index)
    setAddGroupMode('edit')
    setGroupUpMode(item.mode.split('-')[0])
    setChildOption(item.mode)
    setGroupName(item.groupName)
    setGroupNum(item.group.length)
    setDragData(JSON.parse(JSON.stringify(item.group)))
    handleUnassignedUsers(JSON.parse(JSON.stringify(item.unAssignedUsers)))
    setToGroupSettingPage(true)
  }

  const duplicateGroupListItem = (item, index) => {
    setChosenGroupListIndex(index)
    setAddGroupMode('duplicate')
    setGroupUpMode(item.mode.split('-')[0])
    setChildOption(item.mode)
    setGroupName(item.groupName+'(Duplicate)')
    setGroupNum(item.group.length)
    setDragData(JSON.parse(JSON.stringify(item.group)))
    handleUnassignedUsers(JSON.parse(JSON.stringify(item.unAssignedUsers)))
  }

  const reformDeleteGroupItem = () => {
    if(addGroupMode === 'duplicate' || addGroupMode === 'update-duplicate' || chosenGroupListIndex === -1) return [{}, '']
    let dataObj = {
      groupID: chosenClassItem.studyGroup[chosenGroupListIndex].groupID
    }
    handleChosenGroupListIndex(-1)
    return [dataObj, 'Group Up List has been deleted successfully!']
  }

  const reformCreateGroupItem = (item, sts) => {
    let text = ''

    if(!sts) {
      resetGroupUpSettings()
      return [{}, text]
    }

    text = (addGroupMode === 'update-edit' || addGroupMode === 'edit') ? 'Group Up List has been updated successfully!' : 'Group Up List has been saved successfully!'

    for(let groupIndex = 0; groupIndex < item.group.length; groupIndex++) {
      let groupArr = item.group[groupIndex]
      let newArr = groupArr.map(item => item.email).filter(item => item !== '')
      item.group[groupIndex] = newArr
    }

    let newArr = item.unAssignedUsers.map(item => item.email)
    item.unAssignedUsers = newArr
    
    const dataObj = {
      courseID: chosenClassItem.courseid,
      studyGroup: item
    }

    return [dataObj, text]
    // console.log('reform data format', dataObj)
  }

  return { groupName, groupUpMode, childOption, toGroupSettingPage, unassignedUsers, dragData,
    handleDragData, handleUnassignedUsers, resetUnassignedUsers, reformChosenStudentList,
    resetGroupUpSettings, handleToSettingPage, handleGroupName, handleGroupUpMode,
    handleChildOption, setGroupUpParams, resetGroupMode, groupNum, addUpGroupNum,
    editGroupListItem, reformCreateGroupItem, handleAddGroupMode, addGroupMode, duplicateGroupListItem,
    handleChosenGroupListIndex, reformDeleteGroupItem, chosenGroupListIndex,
    openToaster, toasterText, handleOpenToaster, handleCloseToaster, boxPer, assignRandomly
   }
}

export default useGroupUp