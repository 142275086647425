import { Select, MenuItem } from '@mui/material'
import ArrowDownIcon from './icons/ArrowDownIcon'
import { styled } from '@mui/material/styles'

const CustomSelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#fff',
  '& .Mui-disabled': {}
}))

// select with no border color in hover/focus/normal state
const TransparentStyle = {
  // width: 330,
  height: 50,
  bgcolor: '#fff',
  borderRadius: '8px',
  font: 'normal normal normal 16px/21px InterBold',
  fontSize: 'clamp(1rem, 0.4em + 0.5vw, 1.2rem)',
  '& 	.MuiOutlinedInput-notchedOutline': {
    // normal status border color
    borderColor: 'transparent',
    borderRadius: '8px'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // input focus border color
    borderColor: 'transparent'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    // input hover border color
    borderColor: 'transparent'
  }
}

const BorderStyle = {
  // width: 330,
  height: 50,
  bgcolor: '#fff',
  border: '1px solid #B3B3B3',
  borderRadius: '8px',
  font: 'normal normal normal 16px/21px InterMedium',
  fontSize: 'clamp(1rem, 0.4em + 0.5vw, 1.2rem)',
  '& 	.MuiOutlinedInput-notchedOutline': {
    // normal status border color
    borderColor: 'transparent',
    borderRadius: '8px'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // input focus border color
    borderColor: 'transparent'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    // input hover border color
    borderColor: 'transparent'
  }
}

// select with inside box-shadow
const InsetShadowStyle = {
  // width: 330,
  height: 60,
  bgcolor: '#fff',
  borderRadius: '8px',
  font: 'normal normal normal 18px/21px InterMedium',
  fontSize: 'clamp(1rem, 0.4em + 0.5vw, 1.2rem)',
  '& 	.MuiOutlinedInput-notchedOutline': {
    // normal status border color
    borderColor: 'transparent',
    boxShadow: 'inset 0px 0px 6px #00000029',
    borderRadius: '8px'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // input focus border color
    borderColor: 'transparent'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    // input hover border color
    borderColor: 'transparent'
  }
}

// select with outside box-shadow
const OutsideShadowStyle = {
  // width: 330,
  height: 60,
  bgcolor: '#fff',
  borderRadius: '8px',
  font: 'normal normal normal 24px/29px InterRegular',
  fontSize: 'clamp(1rem, 0.4em + 0.5vw, 1.2rem)',
  '& 	.MuiOutlinedInput-notchedOutline': {
    // normal status border color
    borderColor: 'transparent',
    boxShadow: '0px 0px 6px #00000029',
    borderRadius: '8px'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // input focus border color
    borderColor: 'transparent'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    // input hover border color
    borderColor: 'transparent'
  }
}

const SelectFilterIcon = ({ options, icons, val, handleValChange, typ, width, disabled }) => {
  // options & icons should in the same orders
  // eg. optionList = ['True/False', 'Single Selection', 'Multiple Selection']
  // eg. iconList = [TrueFalseIcon, SingleSelectionIcon, MultipleSelectionIcon]

  const styleConfig = {
    white: TransparentStyle,
    inset: InsetShadowStyle,
    outside: OutsideShadowStyle,
    border: BorderStyle
  }

  const style = styleConfig[typ]
  style.width = width

  return (
    <>
      <CustomSelect disabled={disabled} fullWidth sx={style} disableunderline='true' value={val} onChange={handleValChange} IconComponent={ArrowDownIcon}>
        {options.map((item, idx) => (
          <MenuItem key={idx} value={item} sx={{ font: 'normal normal normal 16px/21px InterMedium', height: '60px', fontSize: 'clamp(1rem, 0.4em + 0.5vw, 1.2rem)' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img style={{ width: '20px', marginRight: '1rem' }} alt='true/false' src={icons[idx]} />
              <p style={{ margin: 0 }}>{item}</p>
            </div>
          </MenuItem>
        ))}
      </CustomSelect>
    </>
  )
}

export default SelectFilterIcon
