import styles from './AttendedStudent.module.css'
import ArrowIcon from '../../../assets/icons/breadcrumbArrow.svg'
import ChangeDateCard from '../ChangeDateCard/ChangeDateCard'
import SelectFilterIcon from '../../../parts/SelectFilterIcon'
import SelectFilter from '../../../parts/SelectFilter'
import { useEffect, useState } from 'react'
import NameIcon from '../../../assets/icons/portfolio/blackName.svg'
import AttendanceIcon from '../../../assets/icons/portfolio/blackAttendance.svg'
import RaiseHand from '../../../assets/icons/portfolio/blackHand.svg'
import QuizIcon from '../../../assets/icons/portfolio/blackQuiz.svg'
import StarIcon from '../../../assets/icons/portfolio/blackStar.svg'
import StudentCard from '../StudentCard/StudentCard'
import CircularProgress from '@mui/material/CircularProgress'
import { convertToDate } from '../../../utils/time'

const FilterArea = ({ chosenSortType, setChosenSortType, chosenSortOrder, setChosenSortOrder, sortList }) => {
  const sortTypeList = ['Name (A-Z)', 'Attendance Status', 'Raise Hand', 'Points', 'Quiz Correct Rate']
  const sortTypeIconList = [NameIcon, AttendanceIcon, RaiseHand, QuizIcon, StarIcon]
  const sortOrderList = ['Descending', 'Ascending']

  const onSortTypeChange = (e) => {
    setChosenSortType(e.target.value)
    sortList(e.target.value, chosenSortOrder)
  }

  const onSortOrderChange = (e) => {
    setChosenSortOrder(e.target.value)
    sortList(chosenSortType, e.target.value)
  }

  return (
    <div className={styles.filterArea}>
      <p>Sort by: </p>
      <div>
        <SelectFilterIcon
          options={sortTypeList}
          icons={sortTypeIconList}
          val={chosenSortType}
          handleValChange={onSortTypeChange}
          typ='white'
          renderOptionType='object'
          width='15rem'
        />
      </div>
      <div>
        <SelectFilter options={sortOrderList} val={chosenSortOrder} handleValChange={onSortOrderChange} typ='white' renderOptionType='object' width='10rem' />
      </div>
    </div>
  )
}

const CardList = ({ attendedStudents, setAttendedStudents, setStep, setSelectedStudent }) => {
  const onClickCard = (e, student) => {
    setStep(4)
    setSelectedStudent(student)
  }

  return (
    <div className={styles.cardList}>
      {attendedStudents.map((student) => {
        return (
          <div key={student.studentId} onClick={(e) => onClickCard(e, student)}>
            <StudentCard student={student} />
          </div>
        )
      })}
    </div>
  )
}

const AttendedStudent = ({
  setStep,
  setSelectedStudent,
  setSelectedClassIndex,
  selectedClassList,
  selectedClassIndex,
  call_getAttendedStudentsApi,
  attendedStudents,
  setAttendedStudents,
  loadingAttendedStudents
}) => {
  const [chosenSortType, setChosenSortType] = useState('Name (A-Z)')
  const [chosenSortOrder, setChosenSortOrder] = useState('Descending')
  const [sortedStudents, setSortedStudents] = useState([])
  const [selectedClass, setSelectedClass] = useState({})

  const navigateToMainPage = () => {
    setStep(1)
  }

  const navigateToDashboard = () => {
    setStep(2)
  }

  const sortList = (type, order) => {
    switch (type) {
      case 'Name (A-Z)':
        if (order === 'Descending') {
          setSortedStudents(attendedStudents.sort((a, b) => a.name.localeCompare(b.name)))
        } else {
          setSortedStudents(attendedStudents.sort((a, b) => b.name.localeCompare(a.name)))
        }
        break
      case 'Attendance Status':
        if (order === 'Descending') {
          setSortedStudents(attendedStudents.sort((a, b) => a.order - b.order))
        } else {
          setSortedStudents(attendedStudents.sort((a, b) => b.order - a.order))
        }
        break
      case 'Raise Hand': {
        if (order === 'Descending') {
          setSortedStudents(attendedStudents.sort((a, b) => b.raiseHand - a.raiseHand))
        } else {
          setSortedStudents(attendedStudents.sort((a, b) => a.raiseHand - b.raiseHand))
        }
        break
      }
      case 'Points': {
        if (order === 'Descending') {
          setSortedStudents(attendedStudents.sort((a, b) => b.points - a.points))
        } else {
          setSortedStudents(attendedStudents.sort((a, b) => a.points - b.points))
        }
        break
      }
      default:
        if (order === 'Descending') {
          setSortedStudents(attendedStudents.sort((a, b) => b.quizAccuracyRate - a.quizAccuracyRate))
        } else {
          setSortedStudents(attendedStudents.sort((a, b) => a.quizAccuracyRate - b.quizAccuracyRate))
        }
        break
    }
  }

  const onClickArrow = (type, valid) => {
    if (valid) {
      if (type === 'prev') {
        setSelectedClassIndex((prev) => {
          let newIndex = prev + 1
          let classInfo = selectedClassList[newIndex]
          let data = {
            courseId: classInfo.courseId,
            startTime: classInfo.begin,
            endTime: classInfo.end
          }
          call_getAttendedStudentsApi(data)
          return newIndex
        })
      } else {
        setSelectedClassIndex((prev) => {
          let newIndex = prev - 1
          let classInfo = selectedClassList[newIndex]
          let data = {
            courseId: classInfo.courseId,
            startTime: classInfo.begin,
            endTime: classInfo.end
          }
          call_getAttendedStudentsApi(data)
          return newIndex
        })
      }
    }
  }

  useEffect(() => {
    console.log('selectedClassList', selectedClassList)
    console.log('selectedClassIndex', selectedClassIndex)
    let classInfo = selectedClassList[selectedClassIndex]
    setSelectedClass(classInfo)
    let data = {
      courseId: classInfo.courseId,
      startTime: classInfo.begin,
      endTime: classInfo.end
    }
    call_getAttendedStudentsApi(data)
  }, [])

  useEffect(() => {
    sortList(chosenSortType, chosenSortOrder)
  }, [attendedStudents])

  return (
    <>
      <div>
        <div className={styles.breadCrumbs}>
          <div className={`${styles.tab} ${styles.pointer}`} onClick={() => navigateToMainPage()}>
            Portfolio
          </div>
          <div>
            <img src={ArrowIcon} alt='arrow' />
          </div>
          <div className={`${styles.tab} ${styles.pointer}`} onClick={() => navigateToDashboard()}>
            Dashboard
          </div>
          <div>
            <img src={ArrowIcon} alt='arrow' />
          </div>
          <div className={styles.tab}>Attended Student</div>
        </div>
        <div className={styles.titleWrap}>
          <h1>{selectedClass.courseName}</h1>
          <div>
            <FilterArea
              chosenSortType={chosenSortType}
              setChosenSortType={setChosenSortType}
              chosenSortOrder={chosenSortOrder}
              setChosenSortOrder={setChosenSortOrder}
              sortList={sortList}
            />
            <ChangeDateCard
              hasPrev={selectedClassIndex !== selectedClassList.length - 1}
              date={convertToDate(selectedClassList[selectedClassIndex].begin)}
              hasNext={selectedClassIndex !== 0}
              onClickArrow={onClickArrow}
            />
          </div>
        </div>
        {(loadingAttendedStudents || !attendedStudents) && (
          <div
            style={{
              height: 'calc(100vh - 330px)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loadingAttendedStudents && attendedStudents && (
          <CardList attendedStudents={sortedStudents} setAttendedStudents={setAttendedStudents} setStep={setStep} setSelectedStudent={setSelectedStudent} />
        )}
      </div>
    </>
  )
}

export default AttendedStudent
