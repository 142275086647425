import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import ArrowDownIcon from './icons/ArrowDownIcon'
import { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import classroomConfig from '../utils/classroomConfig'
import { standard, groupUp, meeting, collaborative } from '../utils/classroomConfig'

const CustomSelect = styled(Select)(({ theme }) => ({
  height: 60,
  bgcolor: '#fff',
  borderRadius: '8px',
  maxHeight: 100,
  font: 'normal normal normal 18px/21px InterMedium',
  '& 	.MuiOutlinedInput-notchedOutline': {
    // normal status border color
    borderColor: 'transparent',
    boxShadow: 'inset 0px 0px 6px #00000029',
    borderRadius: '8px'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // input focus border color
    borderColor: 'transparent'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    // input hover border color
    borderColor: 'transparent',
  },
  '& .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation1': {
    maxHeight: '250px'
  }
}));

// MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiMenu-paper MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation8 MuiPopover-paper css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper

const optionStyle = { font: 'normal normal normal 18px/21px InterMedium', height: '60px' }

export default function GroupSelect({ val, handleValChange, disabled, studentNum=0 }) {

  return (
    <FormControl fullWidth>
        <InputLabel htmlFor="grouped-select">Classroom</InputLabel>
        <CustomSelect 
            disabled={disabled}
            defaultValue='Chestnut(1~30)'
            id="grouped-select"
            label="Classroom"
            value={val}
            onClick={(e) => e.stopPropagation()}
            onChange={handleValChange}
            IconComponent={ArrowDownIcon}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 400,
                },
              }
            }}
        >
          <ListSubheader sx={{ display: (standard.filter(item => classroomConfig[item].maxPlayer >= studentNum).length === 0) ? 'none' : 'block' }} >Classroom</ListSubheader>
          {
            standard.filter(item => classroomConfig[item].maxPlayer >= studentNum).map((item, idx) => <MenuItem key={item+idx} sx={optionStyle} value={item}>{item}</MenuItem>)
          }
          <ListSubheader sx={{ display: (groupUp.filter(item => classroomConfig[item].maxPlayer >= studentNum).length === 0) ? 'none' : 'block' }} >Classroom-Group Up</ListSubheader>
          {
            groupUp.filter(item => classroomConfig[item].maxPlayer >= studentNum).map((item, idx) => <MenuItem key={item+idx} sx={optionStyle} value={item}>{item}</MenuItem>)
          }
          <ListSubheader sx={{ display: (meeting.filter(item => classroomConfig[item].maxPlayer >= studentNum).length === 0) ? 'none' : 'block' }} >Meeting Room</ListSubheader>
          {
            meeting.filter(item => classroomConfig[item].maxPlayer >= studentNum).map((item, idx) => <MenuItem key={item+idx} sx={optionStyle} value={item}>{item}</MenuItem>)
          }
          <ListSubheader sx={{ display: (collaborative.filter(item => classroomConfig[item].maxPlayer >= studentNum).length === 0) ? 'none' : 'block' }} >Collaborative Space</ListSubheader>
          {
            collaborative.filter(item => classroomConfig[item].maxPlayer >= studentNum).map((item, idx) => <MenuItem key={item+idx} sx={optionStyle} value={item}>{item}</MenuItem>)
          }
        </CustomSelect>
      </FormControl>
  );
}