import styles from './ReadOnlyQuestion.module.css'
import ArrowIcon from '../../../assets/icons/breadcrumbArrow.svg'
import RadioGroup from '@mui/material/RadioGroup'
import { useEffect, useState } from 'react'
import BackIcon from '../../../assets/icons/back.svg'
import ArrowLeftIcon from '../../../assets/icons/arrowLeft.svg'
import ArrowRightIcon from '../../../assets/icons/arrowRight.svg'
import DisabledRadioSelected from '../../../assets/icons/quiz/disabledRadioSelected.svg'
import DisabledRadioUnselected from '../../../assets/icons/quiz/disabledRadioUnselected.svg'
import DisabledCheckboxChecked from '../../../assets/icons/quiz/disabledCheckboxChecked.svg'
import DisabledCheckboxUnchecked from '../../../assets/icons/quiz/disabledCheckboxUnchecked.svg'

const TrueFalse = ({ selectedTFValue }) => {
  return (
    <RadioGroup aria-labelledby='demo-controlled-radio-buttons-group' name='controlled-radio-buttons-group' value={selectedTFValue}>
      <div className={styles.trueFalseOptions}>
        <img alt='radioButton' src={selectedTFValue ? DisabledRadioSelected : DisabledRadioUnselected} />
        <label>True</label>
      </div>
      <div className={styles.trueFalseOptions}>
        <img alt='radioButton' src={selectedTFValue ? DisabledRadioUnselected : DisabledRadioSelected} />
        <label>False</label>
      </div>
    </RadioGroup>
  )
}

const SingleSelection = ({ selectedSingleValue, singleOptions }) => {
  return (
    <RadioGroup aria-labelledby='demo-controlled-radio-buttons-group' name='controlled-radio-buttons-group' value={selectedSingleValue}>
      {singleOptions.map((option, index) => {
        return (
          <div key={index} className={styles.singleSelection}>
            <img alt='radioButton' src={index === selectedSingleValue ? DisabledRadioSelected : DisabledRadioUnselected} />
            <label>{option}</label>
          </div>
        )
      })}
    </RadioGroup>
  )
}

const MultipleSelection = ({ multipleOptions }) => {
  return (
    <>
      {multipleOptions.map((option, index) => {
        return (
          <>
            <div key={index} className={styles.multipleSelection}>
              <img alt='checked' src={option.checked ? DisabledCheckboxChecked : DisabledCheckboxUnchecked} />
              <label>{option.input}</label>
            </div>
          </>
        )
      })}
    </>
  )
}

const ReadOnlyQuestions = ({ setStep, selectedAssignment, selectedQuestion, setSelectedQuestion, questions }) => {
  const [selectedTFValue, setSelectedTFValue] = useState(false)
  const [singleOptions, setSingleOptions] = useState(['', '', ''])
  const [selectedSingleValue, setSelectedSingleValue] = useState(0)
  const [multipleOptions, setMultipleOptions] = useState([
    { checked: true, input: '' },
    { checked: false, input: '' },
    { checked: false, input: '' }
  ])
  const [selectedMultipleValue, setSelectedMultipleValue] = useState([])

  const navigateToAssignment = () => {
    setStep(1)
  }

  const navigateToQuestionList = () => {
    setStep(2)
  }

  const questionType = (type) => {
    switch (type) {
      case 'tf':
        return 'True/False'
      case 'single':
        return 'Single Selection'
      default:
        return 'Multiple Selection'
    }
  }

  const onChangeNext = () => {
    if (selectedQuestion.index === questions.length) return
    setSelectedQuestion((prev) => questions[prev.index])
  }

  const onChangePrevious = () => {
    if (selectedQuestion.index === 1) return
    setSelectedQuestion((prev) => questions[prev.index - 2])
  }

  useEffect(() => {
    // console.log('selectedAssignment', selectedAssignment)
    // console.log('selectedQuestion', selectedQuestion)

    let options = selectedQuestion.options
    switch (selectedQuestion.type) {
      case 'tf':
        options.forEach((option) => {
          if (option.text === 'True') {
            setSelectedTFValue(option.correct)
          }
        })
        break
      case 'single':
        options = options.map((option, index) => {
          if (option.correct) {
            setSelectedSingleValue(index)
          }
          return option.text
        })
        setSingleOptions(options)
        break
      default:
        options = options.map((option) => {
          return {
            input: option.text,
            checked: option.correct
          }
        })
        setMultipleOptions(options)
    }
  }, [selectedQuestion])

  return (
    <>
      <div className={styles.breadCrumbs}>
        <div className={`${styles.tab} ${styles.clickable}`} onClick={() => navigateToAssignment()}>
          Assignment
        </div>
        <div>
          <img src={ArrowIcon} alt='' />
        </div>
        <div className={`${styles.tab} ${styles.clickable}`} onClick={() => navigateToQuestionList()}>
          {selectedAssignment.name}
        </div>
        <div>
          <img src={ArrowIcon} alt='' />
        </div>
        <div className={styles.tab}>Question {selectedQuestion.index}</div>
        {/* <button className={styles.backBtn} onClick={navigateToQuestionList}>
          <img alt='Back' src={BackIcon} />
          <p>Back</p>
        </button> */}
      </div>
      <div className={styles.container}>
        <div className={styles.contentWrap}>
          <div className={styles.titleWrap}>
            <div className={styles.questionTypeIcon}>{questionType(selectedQuestion.type)}</div>
            <p>Question {selectedQuestion.index}</p>
          </div>
          <p>{selectedQuestion.content}</p>
          {selectedQuestion.image && <img alt='question' className={styles.image} src={selectedQuestion.image} />}
          <div className={styles.optionsWrap}>
            <p>Options</p>
          </div>
          <div className={styles.options}>
            {selectedQuestion.type === 'tf' && <TrueFalse selectedTFValue={selectedTFValue} />}
            {selectedQuestion.type === 'single' && <SingleSelection selectedSingleValue={selectedSingleValue} singleOptions={singleOptions} />}
            {selectedQuestion.type === 'multiple' && <MultipleSelection multipleOptions={multipleOptions} />}
          </div>
        </div>
      </div>
      <div className={styles.arrowWrap}>
        <div onClick={onChangePrevious} className={selectedQuestion.index === 1 ? styles.disabled : undefined}>
          <img alt='leftArrow' src={ArrowLeftIcon} />
        </div>
        <div onClick={onChangeNext} className={selectedQuestion.index === questions.length ? styles.disabled : undefined}>
          <img alt='rightArrow' src={ArrowRightIcon} />
        </div>
      </div>
    </>
  )
}

export default ReadOnlyQuestions
