import styles from './MonthCalendar.module.css'
import dayjs from 'dayjs'
import WeekDay from '../WeekDay/WeekDay'
import CircularProgress from '@mui/material/CircularProgress'


const itemUniqueValue = (item) => {
  return item.courseid+dayjs(item.begin).format('YYYY/MM/DD HH:mm')+dayjs(item.end).format('YYYY/MM/DD HH:mm')
}

const Day = ({ month, day, data, handleScheduleInfoModal, chosenSchedule, loadingGetList }) => {

  return (
    <div className={`${styles.dayWrap} ${month !== dayjs(day).month() && styles.grayText}`}>
      <div className={`${styles.dateText} ${month !== dayjs(day).month() && styles.grayText}`}>{ dayjs(day).date() }</div>
      <div className={styles.singleClassWrap}>
        { (!!data.length && !loadingGetList) && data.map((item, idx) => (
          <div 
            key={idx}
            className={`${styles.singleClass} ${(itemUniqueValue(item) === itemUniqueValue(chosenSchedule)) && styles.active}`}
            title={`${dayjs(item.begin).format('HH:mm')} ~ ${dayjs(item.end).format('HH:mm')} ${item.courseName}`}
            onClick={() => handleScheduleInfoModal(true, item)}
          >
            <div className={styles.firstRow}>
              <div className={`${styles.circle} ${month !== dayjs(day).month() && styles.grayText}`}></div>
              <div className={styles.courseName}>{item.courseName}</div>
            </div>
            <div className={styles.secondRow}>{`${dayjs(item.begin).format('HH:mm')} ~ ${dayjs(item.end).format('HH:mm')}`}</div>
          </div>
        )) }
      </div>
    </div>
  )
}

const MonthCalendar = ({ monthRange, scheduleData, handleScheduleInfoModal, chosenSchedule, loadingGetList }) => {

  const filterDayData = (day) => {
    // if cross date show same data in two days
    let arr = scheduleData.filter(item => dayjs(item.begin).format('YYYY/MM/DD') === dayjs(day).format('YYYY/MM/DD') || dayjs(item.end).format('YYYY/MM/DD') === dayjs(day).format('YYYY/MM/DD'))
    arr.sort((a, b) => {
      if(dayjs(a.begin) > dayjs(b.begin)) return 1
      return -1
    })
    return arr
  }

  return (
    <div className={styles.monthCalendarWrap}>
      <WeekDay />
      <div className={styles.blocksWrap}>
        { monthRange.map((weekArr, idx) => (
          <div key={idx} className={styles.weekWrap}>
            {
              weekArr.map((day, i) => {
                return (
                  <Day key={`${idx}${i}`} month={dayjs(monthRange[2][0]).month()} day={day} data={filterDayData(day)} handleScheduleInfoModal={handleScheduleInfoModal} chosenSchedule={chosenSchedule} loadingGetList={loadingGetList} />
                )
              })
            }
          </div>
        )) }
        { loadingGetList && <div className={styles.spinnerWrap}><CircularProgress /></div> }
      </div>
    </div>
  )
}

export default MonthCalendar