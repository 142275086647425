
import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as ArrowDown } from '../../assets/icons/arrowDown.svg'

const ArrowDownIcon = (props) => {
  return (
    <SvgIcon {...props} component={ArrowDown}></SvgIcon>
  )
}

export default ArrowDownIcon