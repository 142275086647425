import styles from './ManageUser.module.css'
import { Fragment, useEffect, useState } from 'react'
import SearchInput from '../../../parts/SearchInput'
import SelectFilter from '../../../parts/SelectFilter'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Checkbox from '@mui/material/Checkbox'
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import useMemberList from '../../../CustomHooks/useMemberList'
import useMemberListFilter from '../../../CustomHooks/useMemberListFilter'
import CustomPagination from '../../../parts/CustomPagination'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '../../../parts/Modal'
import AddUniverseMemberModal from '../../../parts/Modals/AddAccountModal/AddUniverseMemberModal'
import RemoveUserModal from '../../../parts/Modals/RemoveUserModal/RemoveUserModal'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ListContentMenu from '../../../parts/ListContentMenu'
import RefreshIcon from '@mui/icons-material/Refresh'
import CheckIcon from '@mui/icons-material/Check'
import ImportCanvas from '../../../parts/ImportCanvas/ImportCanvas'
import { useSearchParams } from 'react-router-dom'
import useAuth, { UserContext } from '../../../CustomHooks/useAuth'
import { useContext } from 'react'
import EditUserModal from '../../../parts/Modals/EditUserModal/EditUserModal'
import Toaster from '../../../parts/Toaster'
import CanvasDomainModal from '../../../parts/Modals/CanvasDomainModal/CanvasDomainModal'
import { sendCanvasCodeToBackEnd, getCanvasMember, editTeacherByClass } from '../../../api'
import AddCanvasMemberModal from '../../../parts/Modals/AddCanvasMemberModal/AddCanvasMemberModal'
import CustomSwitch from '../../../parts/CustomSwitch'
import DisplayNameHintModal from '../../../parts/Modals/DisplayNameHintModal/DisplayNameHintModal'
import useTeacherList from '../../../CustomHooks/useTeacherList'
import ReAssignTeacherModal from '../../../parts/Modals/ReAssignTeacherModal/ReAssignTeacherModal'
import usePopover from '../../../CustomHooks/usePopover'
import { Popover } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ImportFileModal from '../../../parts/Modals/ImportFileModal/ImportFileModal'
import { ObservationModeEnum } from '../../../utils/enum'
import QuestionIcon from '../../../parts/QuestionIcon/QuestionIcon'
import { toFaqPage } from '../../../utils/sharedMethod'
import CustomBtn from '../../../parts/CustomBtn/CustomBtn'

const useModals = (allTeacherListWithCourses, chosenAccount) => {
  const [showAddAccountModal, setShowAddAccountModal] = useState(false)
  const [showAddCanvasMemberModal, setShowAddCanvasMemberModal] = useState(false)
  const [showCanvasLinkModal, setShowCanvasLinkModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [reAssignTeacherModal, setReAssignTeacherModal] = useState(false)
  const [reAssignTeacherMode, setReAssignTeacherMode] = useState(false)
  const [showEditUserModal, setShowEditUserModal] = useState(false)
  const [showDisplayNameHintModal, setShowDisplayNameHintModal] = useState(false)
  const [showimportFileModal, setShowimportFileModal] = useState(false)
  const { userInfo, handleUpgradePlanModal } = useContext(UserContext)

  const handleReAssignTeacherModal = (sts, reAssignMode = '') => {
    setReAssignTeacherModal(sts)
    setReAssignTeacherMode(reAssignMode)
  }

  const handleDisplayNameHintModal = (sts) => {
    setShowDisplayNameHintModal(sts)
  }

  const handleAddCanvasMemberModal = (sts) => {
    setShowAddCanvasMemberModal(sts)
  }

  const handleDeleteModal = (sts) => {
    // if the user is admin or teacher, we need to re-assign class for them
    if (allTeacherListWithCourses.find((item) => item.email.S === chosenAccount.email && item.course.L.length !== 0)) {
      handleReAssignTeacherModal(true, 'delete')
      return
    }
    setShowDeleteModal(sts)
  }

  const handleEditUserModal = (sts) => {
    if (userInfo.isExpired) {
      handleUpgradePlanModal(true)
      return
    }
    setShowEditUserModal(sts)
  }

  const handleCanvasLinkModal = (sts) => {
    // if(userInfo.isExpired) {
    //   handleUpgradePlanModal(true)
    //   return
    // }
    setShowCanvasLinkModal(sts)
  }

  const handleAddAccountModal = (sts) => {
    if (userInfo.isExpired) {
      handleUpgradePlanModal(true)
      return
    }
    setShowAddAccountModal(sts)
    if (!sts) {
      // if(openToaster) handleCloseToaster()
    }
  }

  const handleImportFileModal = (sts) => {
    if (userInfo.isExpired) {
      handleUpgradePlanModal(true)
      return
    }
    setShowimportFileModal(sts)
    if (!sts) {
      // if(openToaster) handleCloseToaster()
    }
  }

  return {
    showAddAccountModal,
    handleAddAccountModal,
    showAddCanvasMemberModal,
    handleAddCanvasMemberModal,
    showCanvasLinkModal,
    handleCanvasLinkModal,
    showDeleteModal,
    handleDeleteModal,
    showEditUserModal,
    handleEditUserModal,
    showDisplayNameHintModal,
    handleDisplayNameHintModal,
    reAssignTeacherModal,
    handleReAssignTeacherModal,
    reAssignTeacherMode,
    showimportFileModal,
    handleImportFileModal
  }
}

const Slot = ({ slotObj }) => {
  // need payment slot restriction to show how many slots could be and list to calculate the sum of occupied

  return (
    <div className={styles.slotWrap}>
      {Object.keys(slotObj).map((role, idx) => (
        <div key={idx} className={styles.slotItem}>
          <div className={styles.role}>{`${role}:`}</div>
          <div className={`${styles.slot} ${slotObj[role].occupied >= slotObj[role].whole && styles.warning}`}>{`${slotObj[role].occupied}/${slotObj[role].whole}`}</div>
        </div>
      ))}
    </div>
  )
}

const CustomPopover = ({ open, anchorEl, handleClose, handleModal, handleImportFileModal }) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <div
        className={styles.menuOption}
        onClick={() => {
          handleImportFileModal(true)
          handleClose()
        }}
      >
        Import From File
      </div>
      <div
        className={styles.menuOption}
        onClick={() => {
          handleModal(true)
          handleClose()
        }}
      >
        Add Manually
      </div>
    </Popover>
  )
}

const UtilityRow = ({ searchVal, handleSearchVal, handleModal, getCanvasAccount, handleImportFileModal }) => {
  const { open, anchorEl, openPopover, handleClose } = usePopover()

  return (
    <div className={styles.utilityRowWrap}>
      <div className={styles.left}>
        <div className={styles.searchInputWrap}>
          <SearchInput searchVal={searchVal} handleSearchVal={handleSearchVal} placeholder='Search People By Name or Email' />
        </div>
      </div>
      <div className={styles.right}>
        {/* <ImportCanvas getCanvasAccount={getCanvasAccount} /> */}
        <button className={styles.addMemberBtn} onClick={openPopover}>
          <div style={{ marginRight: '15px' }}>Add User</div>
          <ExpandMoreIcon />
        </button>
        <CustomPopover open={open} anchorEl={anchorEl} handleClose={handleClose} handleModal={handleModal} handleImportFileModal={handleImportFileModal} />
      </div>
    </div>
  )
}

const defaultActOptions = {
  Enable: { disabled: false, val: 'Enable the observation mode for selected admin(s)' },
  Disable: { disabled: false, val: 'Disable the observation mode for selected admin(s)' },
  Remove: { disabled: false, val: 'Remove users' }
}

const DeleteRow = ({ handleUserObservationMode, selectedAccounts, handleDeleteModal, cancelSelectedList, userInfo, handleUpgradePlanModal }) => {
  const [action, setAction] = useState('')
  const [actOptions, setActOptions] = useState([])
  const [warning, setWarning] = useState('')

  useEffect(() => {
    handleActOptions()
  }, [selectedAccounts])

  const handleDeleteMultiple = () => {
    if (userInfo.isExpired) {
      handleUpgradePlanModal(true)
      return
    }
    handleDeleteModal(true)
  }

  const handleAction = (e) => {
    setAction(e.target.value)
  }

  const handleActOptions = () => {
    setWarning('')
    const isStudent = selectedAccounts.filter((item) => item.role == 'student').length
    const isTeacher = selectedAccounts.filter((item) => item.role == 'teacher').length
    const isAdmin = selectedAccounts.filter((item) => item.role == 'admin' || item.role == 'owner').length
    const isOwner = selectedAccounts.filter((item) => item.role == 'owner').length
    let tempOptions = []
    let defaultOpts = JSON.parse(JSON.stringify(defaultActOptions))

    if (isStudent || isTeacher) {
      // 多選清單中有老師或學生
      defaultOpts['Enable'].disabled = true
      defaultOpts['Disable'].disabled = true
      setWarning('* Due to distinct permissions among the selected roles, simultaneous editing is not feasible.')
    }
    if (isAdmin || isTeacher) {
      // 多選清單中有admin或老師
      defaultOpts['Remove'].disabled = true
      setWarning('* Due to distinct permissions among the selected roles, simultaneous editing is not feasible.')
    }

    if (userInfo.identity == 'admin' && isOwner) {
      // admin多選時 選到owner
      defaultOpts['Enable'].disabled = true
      defaultOpts['Disable'].disabled = true
      setWarning('* Due to distinct permissions among the selected roles, simultaneous editing is not feasible.')
    }

    tempOptions = userInfo.observationMode ? [defaultOpts['Enable'], defaultOpts['Disable'], defaultOpts['Remove']] : [defaultOpts['Remove']]

    setActOptions(tempOptions)
  }

  const handleMultipleChoice = () => {
    if (!action) return
    switch (action) {
      case 'Remove users':
        handleDeleteMultiple()
        break
      case 'Enable the observation mode for selected admin(s)':
        cancelSelectedList()
        handleUserObservationMode(selectedAccounts, ObservationModeEnum.Enabled) // 2
        break
      case 'Disable the observation mode for selected admin(s)':
        cancelSelectedList()
        handleUserObservationMode(selectedAccounts, ObservationModeEnum.Disabled) // 1
        break
    }
  }

  return (
    <div className={styles.deleteRowWrap}>
      <div className={styles.left}>
        <div className={styles.selected}>{`${selectedAccounts.length} Selected`}</div>
        <div className={styles.cancel} onClick={cancelSelectedList}>
          Cancel
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.rightTop}>
          <div style={{ width: '427px' }}>
            <SelectFilter options={actOptions} val={action} handleValChange={handleAction} typ='border' disabled={false} optionTyp='object' />
          </div>
          <CustomBtn txt='confirm' colorStyle='blue' disabled={!action} callback={handleMultipleChoice} />
        </div>
        <div className={styles.warning}>{warning}</div>
      </div>
      {/* <div className={styles.removeBtn} onClick={handleDeleteMultiple}>
        <div className={styles.removeIcon}>
          <DeleteOutlineIcon sx={{ color: '#ff0000' }} />
        </div>
        <div className={styles.removeText}>Remove</div>
      </div> */}
    </div>
  )
}

const TableHeader = ({ permissionToSeeUserOm, role, handleRoleChange, createFrom, handleCreateFromChange, selectAll, handleSelectAllChange, originArr }) => {
  const { userInfo } = useContext(UserContext)

  return (
    <div className={`${styles.tableWrap} ${permissionToSeeUserOm && styles.visible}`}>
      <div className={`${styles.checkbox} ${styles.tableItem} ${styles.center}`}>
        <Checkbox checked={selectAll} onChange={handleSelectAllChange} icon={<RadioButtonUncheckedRoundedIcon />} checkedIcon={<CheckCircleRoundedIcon />} />
      </div>
      <div className={`${styles.role} ${styles.tableItem} ${styles.center}`}>
        <SelectFilter options={['All Roles', 'Owner', 'Admin', 'Teacher', 'Student']} val={role} handleValChange={handleRoleChange} typ='white' disabled={false} />
      </div>
      <div className={`${styles.name} ${styles.tableItem}`}>Name</div>
      <div className={`${styles.email} ${styles.tableItem}`}>Email</div>
      <div className={`${styles.displayName} ${styles.tableItem}`}>Display Name</div>
      <div className={`${styles.createFrom} ${styles.tableItem} ${styles.center}`}>
        <SelectFilter options={originArr} val={createFrom} handleValChange={handleCreateFromChange} typ='white' disabled={false} />
      </div>
      <div className={`${styles.status} ${styles.tableItem} ${styles.center}`}>Status</div>
      {permissionToSeeUserOm && (
        <div className={`${styles.observationMode} ${styles.tableItem} ${styles.center}`}>
          <div>Observation</div>
          <QuestionIcon callback={toFaqPage} />
        </div>
      )}
      <div className={`${styles.action} ${styles.tableItem} ${styles.center}`}>Action</div>
    </div>
  )
}

const itemUniqueValue = (item) => {
  return item.role + item.name + item.email + item.origin
}

const omStyleConfig = {
  Disabled: 'disabled',
  Enabled: 'enabled',
  'Not allowed': 'notallowed'
}

const TableContent = ({
  permissionToSeeUserOm,
  item,
  handleCheckBoxChange,
  handleDeleteModal,
  handleChosenAccount,
  userInfo,
  handleUpgradePlanModal,
  handleEditUserModal,
  resendInvitation,
  handleUserObservationMode
}) => {
  const { role, name, display, email, origin, firstName, lastName, status, observationMode } = item
  const val = itemUniqueValue(item)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget)
    handleChosenAccount(item)
  }

  const handleClose = () => {
    if (userInfo.isExpired) {
      handleUpgradePlanModal(true)
      setAnchorEl(null)
      return
    }
    setAnchorEl(null)
  }

  const handleRemoveModal = () => {
    if (userInfo.isExpired) {
      handleUpgradePlanModal(true)
      setAnchorEl(null)
      return
    }
    setAnchorEl(null)
    handleDeleteModal(true)
  }

  const firstUpperCase = (role) => {
    let resultStr = role.charAt(0).toUpperCase() + role.slice(1)
    return resultStr
  }

  const switchObservationMode = () => {
    handleClose()
    const observationMode = item.observationMode == 'Enabled' ? ObservationModeEnum.Disabled : ObservationModeEnum.Enabled
    handleUserObservationMode([{ userID: item.userID }], observationMode)
  }

  return (
    <div className={`${styles.tableWrap} ${permissionToSeeUserOm && styles.visible}`} style={{ marginTop: '20px', backgroundColor: '#fff', borderRadius: '8px' }}>
      <div className={`${styles.checkbox} ${styles.tableItem} ${styles.center}`}>
        {/* {userInfo.email !== email && role === 'student' && (
          <Checkbox checked={item.checked} value={val} onChange={handleCheckBoxChange} icon={<RadioButtonUncheckedRoundedIcon />} checkedIcon={<CheckCircleRoundedIcon />} />
        )} */}
        <Checkbox checked={item.checked} value={val} onChange={handleCheckBoxChange} icon={<RadioButtonUncheckedRoundedIcon />} checkedIcon={<CheckCircleRoundedIcon />} />
      </div>
      <div className={`${styles.role} ${styles.tableItem} ${styles.lightText}`}>{firstUpperCase(role)}</div>
      {/* <div className={`${styles.name} ${styles.tableItem} ${styles.lightText} ${alive && styles.highlight}`}>{ firstName ? `${firstName} ${lastName}` : name }</div> */}
      <div className={`${styles.name} ${styles.tableItem} ${styles.lightText}`}>{firstName ? `${firstName} ${lastName}` : name}</div>
      <div className={`${styles.email} ${styles.tableItem} ${styles.lightText}`}>{email}</div>
      <div className={`${styles.displayName} ${styles.tableItem} ${styles.lightText}`}>{display}</div>
      <div className={`${styles.createFrom} ${styles.tableItem} ${styles.lightText}`}>{origin}</div>
      <div className={`${styles.status} ${styles.tableItem} ${styles.center} ${styles.lightText}`}>
        {status !== 'UNCONFIRMED' && (
          <div>
            <CheckIcon sx={{ color: '#009135' }} />
          </div>
        )}
        {status === 'UNCONFIRMED' && <div className={styles.pending}>pending</div>}
      </div>
      {permissionToSeeUserOm && (
        <div className={`${styles.observationMode} ${styles.tableItem} ${styles.center} ${styles.lightText} ${styles[omStyleConfig[observationMode]]}`}>{observationMode}</div>
      )}
      <div
        className={`${styles.action} ${styles.tableItem} ${styles.center} ${styles.lightText} ${styles.more} ${role === 'owner' && userInfo.identity !== 'owner' && styles.hide}`}
      >
        <MoreHorizIcon
          onClick={(e) => {
            handleClick(e, item)
          }}
          sx={{ color: '#707070', transform: 'scale(1.2)' }}
        />
        <ListContentMenu
          sameAccount={userInfo.email.toLowerCase() === email.toLowerCase()}
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          handleRemoveModal={handleRemoveModal}
          handleEditUserModal={handleEditUserModal}
          resendInvitation={resendInvitation}
          status={status}
          item={item}
          switchObservationMode={switchObservationMode}
        />
      </div>
    </div>
  )
}

const GetDataError = ({ call_getApi }) => {
  return (
    <div className={styles.errorWrap}>
      <div>There seems to be an error!</div>
      <div className={styles.refreshIconWrap} onClick={call_getApi}>
        <div className={styles.refresh}>
          <RefreshIcon sx={{ transform: 'scale(2)' }} />
        </div>
        <div>Click Here to Refresh</div>
      </div>
    </div>
  )
}

const ManageUser = () => {
  const {
    memberList,
    removeSingleAccount,
    removeSelectedList,
    handleMemberList,
    loadingGetList,
    slotObj,
    call_getApi,
    call_saveApi,
    loadingSaveList,
    openToaster,
    toasterText,
    handleCloseToaster,
    getDataError,
    call_editApi,
    originArr,
    handleUserObservationMode
  } = useMemberList()
  const {
    page,
    handlePage,
    role,
    handleRoleChange,
    searchVal,
    handleSearchVal,
    createFrom,
    handleCreateFromChange,
    memberListByFilter,
    filterMemberList,
    filterMemberListByPage,
    selectedAccounts,
    totalPage
  } = useMemberListFilter()
  const { allTeacherListWithCourses, loadingGetAllTeacherList } = useTeacherList()
  const [chosenAccount, setChosenAccount] = useState({})
  const {
    showAddAccountModal,
    handleAddAccountModal,
    showAddCanvasMemberModal,
    handleAddCanvasMemberModal,
    showCanvasLinkModal,
    handleCanvasLinkModal,
    showDeleteModal,
    handleDeleteModal,
    showEditUserModal,
    handleEditUserModal,
    showDisplayNameHintModal,
    handleDisplayNameHintModal,
    reAssignTeacherModal,
    handleReAssignTeacherModal,
    reAssignTeacherMode,
    showimportFileModal,
    handleImportFileModal
  } = useModals(allTeacherListWithCourses, chosenAccount)
  const [updateItem, setUpdateItem] = useState({})

  const [selectAll, setSelectAll] = useState(false)
  const [deleteMode, setDeleteMode] = useState(false)
  const [canvasMemberList, setCanvasMemberList] = useState([])
  const [loadCanvasList, setLoadCanvasList] = useState(false)
  const [loadEditTeacherByClass, setLoadEditTeacherByClass] = useState(false)
  const [allowChangeDisplayName, setAllowChangeDisplayName] = useState(false)
  const [permissionToSeeUserOm, setPermissionToSeeUserOm] = useState(false)
  const { resendCode } = useAuth()
  let [searchParams, setSearchParams] = useSearchParams()

  const { userInfo, handleUpgradePlanModal } = useContext(UserContext)

  useEffect(() => {
    let isEnabled = userInfo.observationMode
    setPermissionToSeeUserOm(isEnabled)
  }, [userInfo])

  useEffect(() => {
    // when turn pagem reset chosen user
    cancelSelectedList()
    setSelectAll(isSelectAll())
  }, [page, role, createFrom])

  useEffect(() => {
    if (Object.keys(updateItem).length !== 0) updateUser()
  }, [updateItem])

  useEffect(() => {
    // for canvas
    let query_code = searchParams.get('code')
    if (query_code) {
      setLoadCanvasList(true)
      sendCanvasCodeToBackEnd({ code: query_code, email: userInfo.email })
        .then((res) => {
          console.log('sendCanvasCodeToBackEnd', res)
          getCanvasAccount()
        })
        .catch((err) => {
          console.log('sendCanvasCodeToBackEnd', err)
          setLoadCanvasList(false)
        })
    }
  }, [])

  useEffect(() => {
    filterMemberList(memberList, true)
  }, [role, searchVal, createFrom])

  useEffect(() => {
    filterMemberList(memberList)
  }, [memberList])

  useEffect(() => {
    setSelectAll(isSelectAll())
    setDeleteMode(checkDeleteMode())
  }, [memberListByFilter])

  const handleCanvasMemberList = (arr) => {
    setCanvasMemberList(arr)
  }

  const handleSwitchChange = (e) => {
    let checked = e.target.checked
    if (!checked) {
      handleDisplayNameHintModal(true)
      return
    }
    setAllowChangeDisplayName(checked)
  }

  const toggleToDisallow = () => {
    handleDisplayNameHintModal(false)
    setAllowChangeDisplayName(false)
  }

  const resendInvitation = () => {
    resendCode(chosenAccount.email)
  }

  const handleChosenAccount = (item) => {
    setChosenAccount(JSON.parse(JSON.stringify(item)))
  }

  const cancelSelectedList = () => {
    let parent_arr = memberList.map((item) => item)
    parent_arr.forEach((parentItem) => {
      memberListByFilter.forEach((childItem) => {
        childItem.checked = false
        if (itemUniqueValue(parentItem) === itemUniqueValue(childItem)) {
          parentItem.checked = false
        }
      })
    })
    handleMemberList(parent_arr)
  }

  const getCanvasAccount = () => {
    setLoadCanvasList(true)
    getCanvasMember({ email: userInfo.email })
      .then((res) => {
        console.info('getCanvasMember res', res)
        if (res.data === 'InvalidAccessToken') {
          // open input domain modal
          handleCanvasLinkModal(true)
        } else {
          // open canvas member modal
          console.info('JSON.parse(res.body)', res.data.body)
          setCanvasMemberList(
            res.data.body.map((item) => {
              return { role: 'Student', name: item.name, email: item.login_id, checked: false, disabled: false }
            })
          )
          handleAddCanvasMemberModal(true)
        }
      })
      .catch((err) => {
        // console.info('getCanvasMember err', err)
      })
      .finally(() => {
        setLoadCanvasList(false)
      })
  }

  const checkDeleteMode = () => {
    let arr = memberListByFilter.filter((item, idx) => idx >= (page - 1) * 14 && idx <= (page - 1) * 14 + 13)
    for (let item of arr) {
      if (item.checked) {
        return true
      }
    }
    return false
  }

  const handleCheckBoxChange = (e) => {
    let itemVal = e.target.value
    let itemChecked = e.target.checked
    let arr = memberList.map((item) => item)
    let item = arr.find((item) => itemVal === itemUniqueValue(item))
    item.checked = itemChecked
    handleMemberList(arr)
  }

  const handleSelectAllChange = (e) => {
    console.info('handleSelectAllChange')
    let selectAllChecked = e.target.checked
    let parent_arr = memberList.map((item) => item)
    parent_arr.forEach((parentItem) => {
      memberListByFilter.forEach((childItem, idx) => {
        // only choose users who are on that specific page
        if (itemUniqueValue(parentItem) === itemUniqueValue(childItem) && idx >= (page - 1) * 14 && idx <= (page - 1) * 14 + 13) {
          parentItem.checked = selectAllChecked
        }
      })
    })
    setSelectAll(selectAllChecked)
    handleMemberList(parent_arr)
  }

  const isSelectAll = () => {
    let arr = memberListByFilter.filter((item, idx) => idx >= (page - 1) * 14 && idx <= (page - 1) * 14 + 13)
    // only choose users who are on that specific page
    if (!arr.length) return false
    for (let item of arr) {
      if (!item.checked) return false
    }
    return true
  }

  const removeData = () => {
    handleDeleteModal(false)

    if (!deleteMode) {
      removeSingleAccount(chosenAccount)
    }
    // remove multiple or single
    if (deleteMode) {
      // if show delete row => delete multiple accounts
      removeSelectedList(selectedAccounts.map((item) => item.email))
      cancelSelectedList()
    }
  }

  const updateUser = () => {
    if ((chosenAccount.role === 'admin' || chosenAccount.role === 'teacher') && updateItem.role === 'student') {
      if (allTeacherListWithCourses.find((item) => item.email.S === chosenAccount.email && item.course.L.length !== 0)) {
        handleReAssignTeacherModal(true, 'update')
        return
      }
    }
    call_editApi(updateItem)
  }

  const updateTeacher = (classList) => {
    setLoadEditTeacherByClass(true)
    let arr = classList.map((item) => {
      return {
        courseId: item.courseId,
        teacherEmail: item.teacherEmail,
        teacherName: item.teacherName
      }
    })

    editTeacherByClass(arr)
      .then((res) => {
        handleReAssignTeacherModal(false)
        if (reAssignTeacherMode === 'delete') {
          removeSingleAccount(chosenAccount)
        }
        if (reAssignTeacherMode === 'update') {
          call_editApi(updateItem)
        }
      })
      .catch((err) => {
        console.info(err)
      })
      .finally(() => {
        setLoadEditTeacherByClass(false)
      })
  }

  return (
    <div className={styles.manageUserWrap}>
      {loadCanvasList && (
        <div className={styles.loadingWrap}>
          <CircularProgress />
        </div>
      )}
      <div className={styles.titleWrap}>
        <div className={styles.title}></div>
        <Slot slotObj={slotObj} />
      </div>
      {deleteMode && (
        <DeleteRow
          handleUserObservationMode={handleUserObservationMode}
          selectedAccounts={selectedAccounts}
          handleDeleteModal={handleDeleteModal}
          cancelSelectedList={cancelSelectedList}
          userInfo={userInfo}
          handleUpgradePlanModal={handleUpgradePlanModal}
        />
      )}
      {!deleteMode && (
        <UtilityRow
          searchVal={searchVal}
          handleSearchVal={handleSearchVal}
          handleModal={handleAddAccountModal}
          handleImportFileModal={handleImportFileModal}
          getCanvasAccount={getCanvasAccount}
        />
      )}
      <div className={styles.tableContainer}>
        <TableHeader
          role={role}
          handleRoleChange={handleRoleChange}
          createFrom={createFrom}
          handleCreateFromChange={handleCreateFromChange}
          selectAll={selectAll}
          handleSelectAllChange={handleSelectAllChange}
          originArr={originArr}
          permissionToSeeUserOm={permissionToSeeUserOm}
        />
        {getDataError && <GetDataError call_getApi={call_getApi} />}
        {(loadingGetAllTeacherList || loadingGetList) && (
          <div style={{ height: 'calc(100vh - 330px)', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        )}
        {!(loadingGetAllTeacherList || loadingGetList) && (
          <div className={styles.tableContentWrapper}>
            {!!memberListByFilter.length &&
              memberListByFilter
                .filter((item, idx) => idx >= (page - 1) * 14 && idx <= (page - 1) * 14 + 13)
                .map((item, idx) => (
                  <TableContent
                    key={idx}
                    item={item}
                    handleCheckBoxChange={handleCheckBoxChange}
                    handleDeleteModal={handleDeleteModal}
                    handleChosenAccount={handleChosenAccount}
                    userInfo={userInfo}
                    handleUpgradePlanModal={handleUpgradePlanModal}
                    handleEditUserModal={handleEditUserModal}
                    resendInvitation={resendInvitation}
                    permissionToSeeUserOm={permissionToSeeUserOm}
                    handleUserObservationMode={handleUserObservationMode}
                  />
                ))}
          </div>
        )}
      </div>
      <div className={styles.bottomRow}>
        {/* <div className={styles.switch}>
            <div>Allow/Disallow organization member change their Display Name</div>
            <CustomSwitch checked={allowChangeDisplayName} handleChange={handleSwitchChange} />
          </div> */}
        {totalPage > 1 && (
          <div className={styles.paginationWrap}>
            <CustomPagination page={page} totalPage={totalPage} handlePage={handlePage} />
          </div>
        )}
      </div>
      {showAddAccountModal && (
        <Modal>
          <AddUniverseMemberModal handleModal={handleAddAccountModal} slotObj={slotObj} call_saveApi={call_saveApi} loadingSaveList={loadingSaveList} orgMemberList={memberList} />
        </Modal>
      )}
      {showimportFileModal && (
        <Modal>
          <ImportFileModal call_saveApi={call_saveApi} loadingSaveList={loadingSaveList} handleModal={handleImportFileModal} orgMemberList={memberList} slotObj={slotObj} />
        </Modal>
      )}
      {showDeleteModal && (
        <Modal>
          <RemoveUserModal setShowModal={handleDeleteModal} removeUser={removeData} />
        </Modal>
      )}
      {showEditUserModal && (
        <Modal>
          <EditUserModal
            handleUserObservationMode={handleUserObservationMode}
            slotObj={slotObj}
            handleEditUserModal={handleEditUserModal}
            chosenAccount={chosenAccount}
            updateUser={updateUser}
            setUpdateItem={setUpdateItem}
            reAssignTeacherMode={reAssignTeacherMode}
          />
        </Modal>
      )}
      {showCanvasLinkModal && (
        <Modal>
          <CanvasDomainModal handleCanvasLinkModal={handleCanvasLinkModal} />
        </Modal>
      )}
      {showAddCanvasMemberModal && (
        <Modal>
          <AddCanvasMemberModal
            handleAddCanvasMemberModal={handleAddCanvasMemberModal}
            canvasMemberList={canvasMemberList}
            handleCanvasMemberList={handleCanvasMemberList}
            slotObj={slotObj}
            call_saveApi={call_saveApi}
            loadingSaveList={loadingSaveList}
          />
        </Modal>
      )}
      {showDisplayNameHintModal && (
        <Modal>
          <DisplayNameHintModal handleModal={handleDisplayNameHintModal} toggleToDisallow={toggleToDisallow} />
        </Modal>
      )}
      {reAssignTeacherModal && (
        <Modal>
          <ReAssignTeacherModal
            reAssignTeacherMode={reAssignTeacherMode}
            handleReAssignTeacherModal={handleReAssignTeacherModal}
            allTeacherListWithCourses={allTeacherListWithCourses}
            chosenAccount={chosenAccount}
            updateTeacher={updateTeacher}
            loadEditTeacherByClass={loadEditTeacherByClass}
          />
        </Modal>
      )}
      <Toaster openToaster={openToaster} handleCloseToaster={handleCloseToaster} severity='success' message={toasterText} />
    </div>
  )
}

export default ManageUser
