import { useState, useEffect } from "react"

const useRadioGroupBox = ({ groupUpMode, childOption, setGroupUpParams }) => {

  const [groupUpModeInRadioBox, setGroupUpModeInRadioBox] = useState('automatic') // customized or automatic
  const [childOptionInRadioBox, setChildOptionInRadioBox] = useState('automatic-2')

  useEffect(() => {
    if(groupUpMode) {
      setGroupUpModeInRadioBox(groupUpMode)
      setChildOptionInRadioBox(childOption)
    }
  }, [])

  const handleGroupUpModeInRadioBox = (e) => {
    if(e.target.checked) {
      setGroupUpModeInRadioBox(e.target.value)
      setChildOptionInRadioBox(`${e.target.value}-${childOptionInRadioBox.split('-')[1]}`)
      setGroupUpParams(e.target.value, `${e.target.value}-${childOptionInRadioBox.split('-')[1]}`)
    }
  }

  const handleChildOptionInRadioBox = (e) => { 
    setChildOptionInRadioBox(`${groupUpModeInRadioBox}-${e.target.value}`)
    setGroupUpParams(groupUpModeInRadioBox, `${groupUpModeInRadioBox}-${e.target.value}`)
  }

  const resetRadioBoxSettings = () => {
    // setGroupUpModeInRadioBox('automatic')
    // setChildOptionInRadioBox('automatic-2')
    setGroupUpModeInRadioBox('customized')
    setChildOptionInRadioBox(`customized-${childOptionInRadioBox.split('-')[1]}`)
    setGroupUpParams('customized', `customized-${childOptionInRadioBox.split('-')[1]}`)

  }

  return { groupUpModeInRadioBox, handleGroupUpModeInRadioBox, childOptionInRadioBox, handleChildOptionInRadioBox, resetRadioBoxSettings }
}

export default useRadioGroupBox