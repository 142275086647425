import { FormControl, Input, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';



const SearchInput = ({ searchVal, handleSearchVal, placeholder, typ }) => {

  const style = { bgcolor: '#fff', height: '60px', paddingLeft: '20px', borderRadius: '8px' }
  if(typ === 'boxShadow') {
    style.boxShadow = '0px 0px 6px #00000029'
  }
    return (
      <FormControl fullWidth variant="standard">
        <Input
          sx={style}
          disableUnderline
          id="standard-adornment-password"
          type="text"
          value={searchVal}
          onChange={handleSearchVal}
          placeholder={placeholder}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: '#E3E3E3', transform: 'scale(1.2)' }}/>
            </InputAdornment>
          }
        />
      </FormControl>
    )
}

export default SearchInput