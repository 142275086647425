import { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import {
  getQuizList,
  createQuiz,
  updateQuiz,
  deleteQuiz,
  getQuizDetail,
  createQuizQuestion as createQuestion,
  updateQuizQuestion as updateQuestion,
  deleteQuizQuestion as deleteQuestion,
  duplicateQuizQuestion as duplicateQuestion,
  getCourseByTeacher,
  duplicateQuiz,
  getQuizListForImport,
  importQuizQuestion,
  getImportQuizHistory,
  getQuizResults,
  getQuizResultsQuestions,
  getQuizResultsParticipants,
  uploadImage,
  deleteQuizResult
} from '../api/index'
import { UserContext } from './useAuth'
import { useContext } from 'react'
import usePagination from './usePagination'
import useToaster from './useToaster'
import { v4 as uuidv4 } from 'uuid'

const PER_PAGE = 14
let getDataTimeout

const useQuizList = () => {
  const { userInfo } = useContext(UserContext)
  const [quizList, setQuizList] = useState([])
  const [questionLoadingGetList, setQuestionLoadingGetList] = useState(false)
  const [addQuizModal, setAddQuizModal] = useState(false)
  const [disableAddQuizModalBtn, setDisableAddQuizModalBtn] = useState(false)
  const [disableSaveQuiz, setDisableSaveQuiz] = useState(false)
  const [step, setStep] = useState(1)
  const { page, totalPage, handlePage, handleTotalPage } = usePagination()
  const { page: resultsPage, totalPage: resultsTotalPage, handlePage: handleResultsPage, handleTotalPage: handleResultsTotalPage } = usePagination()
  const { page: questionPage, totalPage: questionTotalPage, handlePage: handleQuestionPage, handleTotalPage: handleQuestionTotalPage } = usePagination()
  const [severity, setSeverity] = useState('success')
  const [quizDetail, setQuizDetail] = useState(null)
  const [courseList, setCourseList] = useState([])
  const [selectedQuiz, setSelectedQuiz] = useState(null)
  const [selectedQuizResult, setSelectedQuizResult] = useState(null)
  const [quizLoadingGetList, setQuizLoadingGetList] = useState(false)
  const [quizListForImport, setQuizListForImport] = useState([])
  const [selectVal, setSelectVal] = useState(null)
  const [selectValObj, setSelectValObj] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadedQuizList, setUploadedQuizList] = useState([])
  const [fileName, setFileName] = useState('No File Selected')
  const [historyList, setHistoryList] = useState([])
  const { openToaster, toasterText, handleOpenToaster, handleCloseToaster } = useToaster()
  const [historyLoadingGetList, setHistoryLoadingGetList] = useState(false)
  const fileInputRef = useRef()
  const [searchVal, setSearchVal] = useState('')
  const [sortBy, setSortBy] = useState('Updated date')
  const [order, setOrder] = useState('Descending')
  const abortControllerRef = useRef()
  const [resultLoading, setResultLoading] = useState(true)
  const [quizResult, setQuizResult] = useState([])
  const [quizResultQuestions, setQuizResultQuestions] = useState([])
  const [quizResultParticipants, setQuizResultParticipants] = useState([])
  const [isQuestionSaving, setIsQuestionSaving] = useState(false)
  const [saveImageLoading, setSaveImageLoading] = useState(false)

  useEffect(() => {
    if (getDataTimeout) clearTimeout(getDataTimeout)
    getDataTimeout = setTimeout(() => {
      call_getQuizApi()
    }, 200)
  }, [searchVal, sortBy, page, order])

  useEffect(() => {
    call_getQuizResults()
  }, [resultsPage])

  const handleSortBy = (e, value) => {
    setSortBy(value)
  }

  const handleOrder = (e, value) => {
    setOrder(value)
  }

  const call_getQuizApi = () => {
    if (abortControllerRef.current) abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()

    const queryConfig = {
      Ascending: 'asc',
      Descending: 'desc',
      'Updated date': 'updatedDate',
      'Assignment name (A-Z)': 'name',
      'Quiz name (A-Z)': 'name',
      'Number of questions': 'questionNum'
    }

    setQuizLoadingGetList(true)
    // console.log("userInfo", userInfo);
    if (!userInfo.userID) return
    let data = {
      orgId: userInfo.orgID,
      userId: userInfo.userID,
      searchVal,
      sortBy: queryConfig[sortBy],
      order: queryConfig[order]
    }

    let params = {
      offset: (page - 1) * PER_PAGE,
      limit: PER_PAGE
    }

    getQuizList(data, params, abortControllerRef.current.signal)
      .then((res) => {
        let list = res.data.quizzes
        setQuizList(list)
        setQuizLoadingGetList(false)
        handleTotalPage(Math.max(Math.ceil(res.data.totalCount / PER_PAGE), 1))
        console.info('get quiz list', res.data)
      })
      .catch((err) => console.log('err', err))
  }

  const call_createQuizApi = (data) => {
    data = Object.assign(data, {
      userId: userInfo.userID,
      orgId: userInfo.orgID
    })
    createQuiz(data)
      .then((res) => {
        setAddQuizModal(false)
        setDisableAddQuizModalBtn(false)
        setStep(2)
        setSelectedQuiz({
          quizId: res.data.quizId
        })
        handleOpenToaster('Quiz has been saved successfully!')
      })
      .catch((err) => console.log('err', err))
  }

  const call_deleteQuestionApi = (data) => {
    setQuestionLoadingGetList(true)
    data = Object.assign(data, {
      userId: userInfo.userID
    })
    // console.log("data", data);
    deleteQuestion(data)
      .then((res) => {
        setSeverity('success')
        handleOpenToaster('Question has been deleted successfully!')
        call_getQuizDetail({
          quizId: data.quizId
        })
      })
      .catch((err) => console.log('err', err))
  }

  const call_duplicateQuestionApi = (data) => {
    setQuestionLoadingGetList(true)
    duplicateQuestion(data)
      .then((res) => {
        setSeverity('success')
        handleOpenToaster('Question has been duplicated successfully!')
        call_getQuizDetail(data)
      })
      .catch((err) => console.log('err', err))
  }

  const call_updateQuizApi = (data) => {
    setQuizLoadingGetList(true)
    data = Object.assign(data, {
      userId: userInfo.userID
    })
    // console.log("data", data);
    updateQuiz(data)
      .then((res) => {
        setAddQuizModal(false)
        setDisableAddQuizModalBtn(false)
        setDisableSaveQuiz(false)
        call_getQuizApi()
        setSeverity('success')
        handleOpenToaster('Quiz has been updated successfully!')
        setStep(1)
      })
      .catch((err) => {
        setSeverity('error')
        handleOpenToaster(err.response.data.message)
      })
  }

  const call_deleteQuizApi = (data) => {
    setQuizLoadingGetList(true)
    data = Object.assign(data, {
      userId: userInfo.userID
    })
    // console.log("data", data);
    deleteQuiz(data)
      .then((res) => {
        setSeverity('success')
        handleOpenToaster('Quiz has been deleted successfully!')
        call_getQuizApi()
      })
      .catch((err) => console.log('err', err))
  }

  const call_deleteQuizResultApi = (data) => {
    setResultLoading(true)
    deleteQuizResult(data)
      .then((res) => {
        setSeverity('success')
        handleOpenToaster('Quiz Result has been deleted successfully!')
        call_getQuizResults()
      })
      .catch((err) => console.log('err', err))
  }

  const call_duplicateQuizApi = (data) => {
    setQuizLoadingGetList(true)
    duplicateQuiz(data)
      .then((res) => {
        setSeverity('success')
        handleOpenToaster('Quiz has been duplicated successfully!')
        call_getQuizApi(data)
      })
      .catch((err) => console.log('err', err))
  }

  const call_getQuizDetail = (data) => {
    setQuestionLoadingGetList(true)
    data = Object.assign(data, {
      userId: userInfo.userID
    })

    getQuizDetail(data)
      .then((res) => {
        setQuestionLoadingGetList(false)
        let response = res.data
        setQuizDetail(response)

        let assignedCourseIds
        let set
        if (response.assignedCourses.length) {
          assignedCourseIds = response.assignedCourses.map((course) => course.id)
          set = new Set(assignedCourseIds)
        } else {
          assignedCourseIds = []
          set = new Set([])
        }

        let data = {
          userId: userInfo.userID,
          orgId: userInfo.orgID
        }

        getCourseByTeacher(data)
          .then((res) => {
            console.info('get courese by teacher', res)
            let courses = res.data.map((course) => {
              return {
                label: course.label,
                id: course.id
              }
            })

            courses = courses.filter((course) => {
              if (set.has(course.id)) {
                return false
              } else {
                return true
              }
            })
            setCourseList(courses)
          })
          .catch((err) => console.log('err', err))
      })
      .catch((err) => console.log('err', err))
  }

  const call_getCourseApi = () => {
    let data = {
      userId: userInfo.userID,
      orgId: userInfo.orgID
    }
    getCourseByTeacher(data)
      .then((res) => {
        let courses = res.data.map((course) => {
          return {
            label: course.label,
            id: course.id
          }
        })
        setCourseList(courses)
      })
      .catch((err) => console.log('err', err))
  }

  const call_createQuestionApi = (data) => {
    setQuestionLoadingGetList(true)
    data.userId = userInfo.userID
    createQuestionApi(data)
  }

  const createQuestionApi = (data) => {
    // data = Object.assign(data, {
    //   userId: userInfo.userID
    // })
    // console.log("data", data);
    createQuestion(data)
      .then((res) => {
        setStep(2)
        setIsQuestionSaving(false)
        setSeverity('success')
        handleOpenToaster('Quiz has been saved successfully!')
      })
      .catch((err) => console.log('err', err))
  }

  const call_updateQuestionApi = (data) => {
    setQuestionLoadingGetList(true)
    data.userId = userInfo.userID
    updateQuestionApi(data)
  }

  const updateQuestionApi = (data) => {
    console.log('2')
    data = Object.assign(data, {
      userId: userInfo.userID
    })
    // console.log("data", data);
    updateQuestion(data)
      .then((res) => {
        console.log('3')
        setStep(2)
        setIsQuestionSaving(false)
        setSeverity('success')
        handleOpenToaster('Question has been edited successfully!')
      })
      .catch((err) => console.log('err', err))
  }

  const call_createQuizImportApi = (data) => {
    data = Object.assign(data, {
      userId: userInfo.userID,
      orgId: userInfo.orgID
    })
    createQuiz(data)
      .then((quizRes) => {
        setAddQuizModal(false)
        setDisableAddQuizModalBtn(false)
        getQuizListForImport(data)
          .then((listRes) => {
            let list = listRes.data
            list.unshift({ id: 'addQuiz', label: '+ Add Quiz' })
            setQuizListForImport(list)
            setSelectVal(data.name)
            let valObj = {
              id: quizRes.data.quizId,
              label: data.name
            }
            setSelectValObj(valObj)
          })
          .catch((err) => console.log('err', err))
      })
      .catch((err) => console.log('err', err))
  }

  const call_getQuizListForImportAPI = () => {
    let data = {
      userId: userInfo.userID,
      orgId: userInfo.orgID
    }
    getQuizListForImport(data)
      .then((res) => {
        let list = res.data
        list.unshift({ id: 'addQuiz', label: '+ Add Quiz' })
        setQuizListForImport(res.data)
      })
      .catch((err) => console.log('err', err))
  }

  const call_getImportHistoryAPI = () => {
    setHistoryLoadingGetList(true)
    let data = {
      userId: userInfo.userID,
      orgId: userInfo.orgID
    }
    getImportQuizHistory(data)
      .then((res) => {
        setHistoryLoadingGetList(false)
        setHistoryList(res.data)
      })
      .catch((err) => {
        console.info('err', err)
      })
  }

  const call_importQuestionsAPI = (data) => {
    // console.log('data', data)
    data = Object.assign(data, {
      userId: userInfo.userID,
      orgId: userInfo.orgID
    })

    const uploadPromises = []
    for (const question of data.question) {
      if (question.image) {
        const imageData = {
          folderName: 'quiz',
          imageId: question.image
        }
        let headers = {
          'Content-Type': 'multipart/form-data'
        }
        uploadImage(imageData).then((res) => {
          const s3Url = res.data.s3_signed_url
          const myPromise = axios.put(s3Url, question.imageBlob, headers)
          uploadPromises.push(myPromise)
        })
      }
    }

    Promise.all(uploadPromises)
      .then((res) => {
        return importQuizQuestion(data)
      })
      .then((res) => {
        setSeverity('success')
        handleOpenToaster('Quiz has been imported successfully!')
        call_getImportHistoryAPI()
      })
      .catch((err) => {
        setSeverity('error')
        handleOpenToaster('Fail to import quiz.')
        console.info('err', err)
      })
      .finally(() => {
        setIsUploading(false)
        setUploadedQuizList([])
        setSelectVal(null)
        setSelectValObj(null)
        fileInputRef.current.value = ''
        setFileName('No File Selected')
      })
  }

  const call_getQuizResults = () => {
    let data = {
      orgId: userInfo.orgID,
      userId: userInfo.userID
    }

    let params = {
      offset: (resultsPage - 1) * PER_PAGE,
      limit: PER_PAGE
    }

    setResultLoading(true)
    getQuizResults(data, params)
      .then((res) => {
        console.info('call_getQuizResults res', res)
        setResultLoading(false)
        setQuizResult(res.data.results)
        handleResultsTotalPage(Math.max(Math.ceil(res.data.totalCount / PER_PAGE), 1))
      })
      .catch((err) => console.log('err', err))
  }

  const call_getQuizResultsQuestions = (data) => {
    setResultLoading(true)
    getQuizResultsQuestions(data)
      .then((res) => {
        setResultLoading(false)
        setQuizResultQuestions(res.data)
      })
      .catch((err) => console.log('err', err))
  }

  const call_getQuizResultsParticipants = (data) => {
    setResultLoading(true)
    getQuizResultsParticipants(data)
      .then((res) => {
        setResultLoading(false)
        setQuizResultParticipants(
          res.data.map((item) => {
            item.open = false
            return item
          })
        )
      })
      .catch((err) => console.log('err', err))
  }

  const toSingleResultPage = (item) => {
    setStep(7)
    setSelectedQuiz(item)
  }

  return {
    quizList,
    quizLoadingGetList,
    questionLoadingGetList,
    page,
    quizDetail,
    courseList,
    totalPage,
    handlePage,
    handleTotalPage,
    call_getQuizApi,
    call_createQuizApi,
    call_updateQuizApi,
    call_deleteQuizApi,
    openToaster,
    toasterText,
    handleOpenToaster,
    handleCloseToaster,
    severity,
    setSeverity,
    step,
    setStep,
    call_getCourseApi,
    setCourseList,
    call_getQuizDetail,
    call_createQuestionApi,
    call_updateQuestionApi,
    call_deleteQuestionApi,
    selectedQuiz,
    setSelectedQuiz,
    call_duplicateQuizApi,
    setQuizDetail,
    addQuizModal,
    setAddQuizModal,
    disableAddQuizModalBtn,
    setDisableAddQuizModalBtn,
    disableSaveQuiz,
    setDisableSaveQuiz,
    questionPage,
    questionTotalPage,
    handleQuestionPage,
    handleQuestionTotalPage,
    call_getQuizListForImportAPI,
    call_getImportHistoryAPI,
    call_importQuestionsAPI,
    quizListForImport,
    setQuizListForImport,
    call_createQuizImportApi,
    selectVal,
    setSelectVal,
    selectValObj,
    setSelectValObj,
    isUploading,
    setIsUploading,
    uploadedQuizList,
    setUploadedQuizList,
    fileName,
    setFileName,
    fileInputRef,
    historyList,
    setHistoryList,
    historyLoadingGetList,
    setQuestionLoadingGetList,
    call_duplicateQuestionApi,
    sortBy,
    order,
    handleSortBy,
    handleOrder,
    searchVal,
    setSearchVal,
    resultLoading,
    resultsPage,
    resultsTotalPage,
    handleResultsPage,
    quizResult,
    quizResultQuestions,
    quizResultParticipants,
    call_getQuizResults,
    call_getQuizResultsQuestions,
    call_getQuizResultsParticipants,
    call_deleteQuizResultApi,
    setQuizResultParticipants,
    toSingleResultPage,
    isQuestionSaving,
    setIsQuestionSaving,
    saveImageLoading,
    selectedQuizResult,
    setSelectedQuizResult,
    setSaveImageLoading
  }
}

export default useQuizList
