import styles from './EditPopoverMenu.module.css'

const EditPopoverMenu = ({ closePopover, reformEditInfo }) => {
  
  // edit this event ; edit all events in the series
  const openEditModal = (mode) => {
    closePopover()
    reformEditInfo(mode)
  }
  
  return (
    <div className={styles.menuWrap}>
      <div className={styles.menuOption} onClick={() => openEditModal('Single')}>This class</div>
      <div className={styles.menuOption} onClick={() => openEditModal('Multiple')}>All classes in the series</div>
    </div>
  )
}

export default EditPopoverMenu