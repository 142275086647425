import styles from './AddNewAssignment.module.css'
import ArrowIcon from '../../../assets/icons/breadcrumbArrow.svg'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'
import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import Modal from '../../../parts/Modal'
import AddQuestionModal from '../../../parts/Modals/AddQuestionModal/AddQuestionModal'
import OptionIcon from '../../../assets/icons/options.svg'
import DeleteQuestionModal from '../../../parts/Modals/Activity/DeleteQuestionModal/DeleteQuestionModal'
import TrueFalseIcon from '../../../assets/icons/quiz/trueFalse.svg'
import SingleSelectionIcon from '../../../assets/icons/quiz/singleSelection.svg'
import MultipleSelectionIcon from '../../../assets/icons/quiz/multipleSelection.svg'
import CheckboxChecked from '../../../assets/icons/quiz/checkboxChecked.svg'
import CheckboxUnchecked from '../../../assets/icons/quiz/checkboxUnchecked.svg'
import DisabledCheckboxUnchecked from '../../../assets/icons/quiz/disabledCheckboxUnchecked.svg'
import DisabledCheckboxChecked from '../../../assets/icons/quiz/disabledCheckboxChecked.svg'
import CircularProgress from '@mui/material/CircularProgress'
import CustomPagination from '../../../parts/CustomPagination'
import DiscardChangesModal from '../../../parts/Modals/DiscardChangesModal/DiscardChangesModal'
import ImageIcon from '../../../assets/icons/image.svg'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { AssignmentStatusEnum } from '../../../utils/enum'
import { DateHourMinPicker } from '../../../parts/TimePicker/TimePicker'
import dayjs from 'dayjs'
import { Fragment } from 'react'
import QuestionListMenu from '../../../parts/Activity/QuestionListMenu/QuestionListMenu'

const QuestionList = ({
  loadingGetList,
  setNewQuestionModal,
  setDeleteQuestionModal,
  questions,
  setStep,
  setSelectedQuestionType,
  setSelectedQuestion,
  setQuestionActionType,
  selectedAssignment,
  questionPage,
  questionTotalPage,
  handleQuestionPage,
  handleQuestionTotalPage,
  call_duplicateQuestionApi,
  setIsInit,
  setQuestions,
  setSeverity,
  handleOpenToaster,
  setDuplicateQuestions,
  status
}) => {
  const onClickNewQuestion = () => {
    setNewQuestionModal(true)
    setQuestionActionType('create')
  }

  const filterQuestionList = () => {
    handleQuestionTotalPage(Math.max(Math.ceil(questions.length / 10), 1))
    handleQuestionPage(1)
  }

  useEffect(() => {
    if (questions) {
      filterQuestionList()
    }
  }, [questions])

  return (
    <>
      <div className={styles.titleWrap}>
        <div className={styles.title}>Question List</div>
        {!loadingGetList && status === AssignmentStatusEnum.DRAFT && <button onClick={onClickNewQuestion}>+ New Question</button>}
      </div>
      <div className={styles.tableContainer}>
        <TableHeader status={status} />
        {loadingGetList && (
          <div
            style={{
              height: 'calc(100vh - 550px)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loadingGetList && !questions.length && (
          <div className={styles.noItems}>
            <p>Create your new question!</p>
          </div>
        )}
        {!loadingGetList &&
          questions
            .filter((item, index) => index >= (questionPage - 1) * 10 && index <= (questionPage - 1) * 10 + 9)
            .map((question, index) => {
              return (
                <TableContent
                  status={status}
                  key={index}
                  question={question}
                  setDeleteQuestionModal={setDeleteQuestionModal}
                  questions={questions}
                  setStep={setStep}
                  setSelectedQuestionType={setSelectedQuestionType}
                  setSelectedQuestion={setSelectedQuestion}
                  setQuestionActionType={setQuestionActionType}
                  selectedAssignment={selectedAssignment}
                  call_duplicateQuestionApi={call_duplicateQuestionApi}
                  setIsInit={setIsInit}
                  setQuestions={setQuestions}
                  setSeverity={setSeverity}
                  handleOpenToaster={handleOpenToaster}
                  setDuplicateQuestions={setDuplicateQuestions}
                />
              )
            })}
        {questionTotalPage > 1 && (
          <div className={styles.paginationWrap}>
            <CustomPagination page={questionPage} totalPage={questionTotalPage} handlePage={handleQuestionPage} />
          </div>
        )}
      </div>
    </>
  )
}

const TableHeader = ({ status }) => {
  return (
    <div className={`${styles.tableWrap} ${styles.tableHeader} ${status === AssignmentStatusEnum.DRAFT ? undefined : styles.disabled}`}>
      <div className={`${styles.tableItem} ${styles.name}`}>Question Type</div>
      <div className={`${styles.tableItem} ${styles.email}`}>Question</div>
      <div className={`${styles.tableItem} ${styles.displayName}`}>Options</div>
      {status === AssignmentStatusEnum.DRAFT && <div className={`${styles.tableItem} ${styles.action}`}>Action</div>}
    </div>
  )
}

const TableContent = ({
  status,
  question,
  setDeleteQuestionModal,
  setStep,
  setQuestionActionType,
  setSelectedQuestion,
  selectedAssignment,
  setIsInit,
  setQuestions,
  setSeverity,
  handleOpenToaster,
  questions,
  setDuplicateQuestions,
  call_duplicateQuestionApi
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigateToDetail = (e, question) => {
    if (status === AssignmentStatusEnum.DRAFT) {
      setSelectedQuestion(question)
      setStep(3)
      setQuestionActionType('edit')
    } else {
      setStep(5)
      setSelectedQuestion(question)
    }
  }

  const questionTypeIcon = (type) => {
    switch (type) {
      case 'tf':
        return TrueFalseIcon
      case 'single':
        return SingleSelectionIcon
      case 'multiple':
        return MultipleSelectionIcon
      default:
        return TrueFalseIcon
    }
  }

  const questionType = (type) => {
    switch (type) {
      case 'tf':
        return 'True/False'
      case 'single':
        return 'Single Selection'
      default:
        return 'Multiple Selection'
    }
  }

  const onEditQuestion = (question) => {
    setStep(3)
    setSelectedQuestion(question)
    setQuestionActionType('edit')
  }

  const iconStyles = { color: '#707070', transform: 'scale(1.2)', padding: '0.5rem' }

  return (
    <>
      <div className={`${styles.itemWrap} ${styles.tableContent} ${status === AssignmentStatusEnum.DRAFT ? undefined : styles.disabled}`}>
        <div className={`${styles.tableItem} ${styles.questionType}`}>
          <img alt='questionType' src={questionTypeIcon(question.type)} />
          <span>{questionType(question.type)}</span>
        </div>
        <div className={`${styles.tableItem} ${styles.question}`} onClick={(e) => navigateToDetail(e, question)}>
          <span>Question {question.index}</span>
          <span>:</span>
          {question.content ? <span>{question.content}</span> : <img className={styles.imgIcon} alt='imageQuestion' src={ImageIcon} />}
        </div>
        <div className={`${styles.tableItem} ${styles.options}`}>
          <img alt='options' src={OptionIcon} />
          <p>{question.options.length}</p>
        </div>
        {status === AssignmentStatusEnum.DRAFT && (
          <div className={`${styles.tableItem} ${styles.action} ${open && styles.active}`}>
            <MoreHorizIcon onClick={(e) => handleClick(e, question)} sx={iconStyles} />
            <QuestionListMenu
              typ='Assignment'
              open={open}
              handleClose={handleClose}
              anchorEl={anchorEl}
              setDeleteQuestionModal={setDeleteQuestionModal}
              setStep={setStep}
              setSelectedQuestion={setSelectedQuestion}
              onEditQuestion={() => onEditQuestion(question)}
              question={question}
              selectedParent={selectedAssignment}
              setIsInit={setIsInit}
              setQuestions={setQuestions}
              setSeverity={setSeverity}
              questions={questions}
              handleOpenToaster={handleOpenToaster}
              setDuplicateQuestions={setDuplicateQuestions}
              call_duplicateQuestionApi={call_duplicateQuestionApi}
            />
          </div>
        )}
      </div>
    </>
  )
}





const Information = ({
  timerMin,
  setTimerMin,
  timerSec,
  setTimerSec,
  selectedAssignment,
  assignmentName,
  status,
  onAssignmentNameChange,
  checkedTimer,
  onCheckTimer,
  setIsInit,
  isInit
}) => {
  const inputStyle = {
    '& .MuiOutlinedInput-input': {
      paddingRight: '60px'
    },
    width: '100%',
    '& 	.MuiOutlinedInput-notchedOutline': {
      border: '1px solid #B3B3B3',
      borderRadius: '8px'
    }
  }

  const numInputStyle = {
    width: '5rem',
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#EBEDF0',
      borderRadius: '4px'
    },
    '& 	.MuiOutlinedInput-notchedOutline': {
      border: 'none',
      backgroundColor: 'none'
    }
  }

  const onTimerChange = (e, type) => {
    setIsInit(false)
    if (type === 'min') {
      setTimerMin(e.target.value)
    } else {
      setTimerSec(e.target.value)
    }
  }

  const timerIcon = () => {
    return checkedTimer ? CheckboxChecked : CheckboxUnchecked
  }

  const disabledTimerIcon = () => {
    return checkedTimer ? DisabledCheckboxChecked : DisabledCheckboxUnchecked
  }

  return (
    <div className={styles.information}>
      <div className={styles.title}>Information</div>
      <h1>Assignment Name</h1>
      <div className={styles.inputWrap}>
        <TextField
          required
          sx={inputStyle}
          value={assignmentName}
          onChange={(e) => onAssignmentNameChange(e)}
          id='outlined-basic'
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{ maxLength: 50 }}
          disabled={status !== AssignmentStatusEnum.DRAFT}
        />
        {!assignmentName && !isInit && <p>*Required</p>}
        <div className={styles.lengthHint}>{assignmentName.length}/50</div>
      </div>
      <div className={styles.statusWrap}>
        <span>Assignment Status</span>
        <div className={styles.questionIcon}>
          <Tooltip
            title={`Only ${AssignmentStatusEnum.DRAFT} assignments can be edited.`}
            placement='bottom-start'
            componentsProps={{
              popper: {
                sx: {
                  [`& .${tooltipClasses.arrow}`]: {
                    color: (theme) => theme.palette.common.black
                  },
                  [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: (theme) => theme.palette.common.black,
                    fontWeight: 400
                  }
                }
              }
            }}
            arrow
          >
            <QuestionMarkIcon sx={{ fontSize: 16, color: 'white' }} />
          </Tooltip>
        </div>
      </div>
      <div className={styles.status}>{status.charAt(0).toUpperCase() + status.slice(1)}</div>
      <h1 className={styles.timerTitle}>Timer</h1>
      <div className={styles.timerRow}>
        {status === AssignmentStatusEnum.DRAFT && <img alt='checked' className={styles.pointer} src={timerIcon()} onClick={onCheckTimer} />}
        {status !== AssignmentStatusEnum.DRAFT && <img alt='checked' className={styles.disabledImg} src={disabledTimerIcon()} />}
        <p>Timer</p>
        <TextField
          required
          id='outlined-number'
          type='number'
          value={timerMin}
          onChange={(e) => onTimerChange(e, 'min')}
          sx={numInputStyle}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            inputProps: {
              max: 59,
              min: 0
            }
          }}
          disabled={status !== AssignmentStatusEnum.DRAFT}
        />
        <p className={styles.text}>m</p>
        <TextField
          id='outlined-number'
          type='number'
          value={timerSec}
          onChange={(e) => onTimerChange(e, 'sec')}
          sx={numInputStyle}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            inputProps: {
              max: 59,
              min: 0
            }
          }}
          disabled={status !== AssignmentStatusEnum.DRAFT}
        />
        <p className={styles.text}>s</p>
      </div>
    </div>
  )
}

const Duration = ({ status, durationStim, durationEtim, handleDurationStimChange, handleDurationEtimChange, setInvalidDuration }) => {
  return (
    <div className={styles.duration}>
      <div>
        <div className={styles.subTitle}>Available from</div>
        <DateHourMinPicker label='s' value={durationStim} handleChange={handleDurationStimChange} otherValue={durationEtim} disabled={status !== AssignmentStatusEnum.DRAFT} setInvalidDuration={setInvalidDuration} />
      </div>
      <div>
        <div className={styles.subTitle}>Until</div>
        <DateHourMinPicker label='e' value={durationEtim} handleChange={handleDurationEtimChange} otherValue={durationStim} disabled={status !== AssignmentStatusEnum.DRAFT} setInvalidDuration={setInvalidDuration} />
      </div>
    </div>
  )
}

const SaveButton = ({ txt, isSaving, disabled, fill='blue', action, onSaveAssignment, saveMode, setSaveMode }) => {

  const handleClick = () => {
    if(isSaving || disabled) return
    setSaveMode(txt)
    onSaveAssignment(action)
  }

  return (
    <button className={`${styles.saveBtn} ${styles[fill]} ${disabled && styles.disabled}`} onClick={handleClick}>
      {
        (isSaving && saveMode == txt) ? (
          <Fragment>
            <CircularProgress size={20} color='inherit' />
            <span className={styles.text}>Saving...</span>
          </Fragment>
        ) : (
          <Fragment>
            { txt }
          </Fragment>
        )
      }
    </button>
  )
}

const AddNewAssignment = ({
  timerMin,
  setTimerMin,
  timerSec,
  setTimerSec,
  assignmentName,
  setStep,
  setAssignmentName,
  setSelectedQuestionType,
  setSelectedQuestion,
  assignmentActionType,
  setQuestionActionType,
  selectedAssignment,
  selectedQuestion,
  questions,
  setQuestions,
  call_getAssignmentDetail,
  call_deleteQuestionApi,
  call_updateAssignmentApi,
  call_duplicateQuestionApi,
  assignmentDetail,
  questionLoadingGetList,
  setAssignmentDetail,
  disableSaveAssignment,
  setDisableSaveAssignment,
  questionPage,
  questionTotalPage,
  handleQuestionPage,
  handleQuestionTotalPage,
  setSeverity,
  handleOpenToaster,
  isAssignmentSaving,
  setIsAssignmentSaving,
  durationStim,
  durationEtim,
  handleDurationStimChange,
  handleDurationEtimChange,
  setInvalidDuration,
  invalidDuration
}) => {
  const [newQuestionModal, setNewQuestionModal] = useState(false)
  const [deleteQuestionModal, setDeleteQuestionModal] = useState(false)
  const [checkedTimer, setCheckedTimer] = useState(false)
  const [isInit, setIsInit] = useState(true)
  const [discardChangesModal, setDiscardChangesModal] = useState(false)
  const [goBack, setGoBack] = useState(false)
  const [status, setStatus] = useState('')
  const [saveMode, setSaveMode] = useState('')

  const navigateToAssignment = () => {
    setGoBack(true)
  }

  const onCheckTimer = () => {
    setIsInit(false)
    setCheckedTimer((prev) => !prev)
  }

  const onDurationStimChange = (val) => {
    setIsInit(false)
    handleDurationStimChange(val)
  }

  const onDurationEtimChange = (val) => {
    setIsInit(false)
    handleDurationEtimChange(val)
  }

  const onAssignmentNameChange = (e) => {
    setIsInit(false)
    setAssignmentName(e.target.value)
  }

  const onDeleteQuestion = () => {
    call_deleteQuestionApi({
      questionId: selectedQuestion.questionId,
      assignmentId: selectedAssignment.assignmentId
    })
  }

  const getFinalTimerNum = () => {
    if (checkedTimer) {
      let num = +timerMin * 60 + +timerSec
      if (num === 0) {
        return 10
      } else {
        return num
      }
    }
    return 0
  }

  const onSaveAssignment = (action=null) => {
    if (!assignmentName.trim()) {
      setSeverity('error')
      handleOpenToaster('Assignment name cannot be empty.')
      return
    }
    setIsAssignmentSaving(true)
    setDisableSaveAssignment(true)
    call_updateAssignmentApi({
      assignmentId: selectedAssignment.assignmentId,
      name: assignmentName.trim(),
      timer: getFinalTimerNum(),
      startTime: dayjs(durationStim).unix(),
      endTime: dayjs(durationEtim).unix(),
      action, 
      // null (不做任何動作) / publish / finish
    })
  }

  const checkTimerValue = () => {
    let timer = +timerMin * 60 + +timerSec
    // console.log('timer', timer)
    if (checkedTimer && timer === 0) {
      setTimerSec(10)
    }
  }

  const onDiscard = () => {
    setDiscardChangesModal(false)
    setStep(1)
    setAssignmentName('')
  }

  const onCancel = () => {
    setGoBack(false)
    setDiscardChangesModal(false)
  }

  useEffect(() => {
    window.addEventListener('click', checkTimerValue)

    return () => {
      window.removeEventListener('click', checkTimerValue)
    }
  }, [timerMin, timerSec, checkedTimer])

  useEffect(() => {
    // console.log('selectedAssignment', selectedAssignment)
    call_getAssignmentDetail({
      assignmentId: selectedAssignment.assignmentId
    })

    setIsInit(true)

    return () => {
      setAssignmentDetail(null)
      setTimerMin(0)
      setTimerSec(0)
      handleDurationStimChange(dayjs().add(5, 'minute'))
      handleDurationEtimChange(dayjs().add(1, 'month'))
    }
  }, [])

  useEffect(() => {
    if (assignmentDetail) {
      setAssignmentName(assignmentDetail.name)
      setQuestions(assignmentDetail.questions)
      setCheckedTimer(assignmentDetail.timer !== 0)
      setTimerMin(Math.floor(assignmentDetail.timer / 60))
      setTimerSec(assignmentDetail.timer % 60)
      setStatus(assignmentDetail.status)
      handleDurationStimChange(assignmentDetail.startTime ? dayjs.unix(assignmentDetail.startTime) : dayjs().add(5, 'minute'))
      handleDurationEtimChange(assignmentDetail.endTime ? dayjs.unix(assignmentDetail.endTime) : dayjs().add(1, 'month'))
    }
  }, [assignmentDetail])

  useEffect(() => {
    if (goBack) {
      if (!isInit) {
        setDiscardChangesModal(true)
      } else {
        setStep(1)
        setAssignmentName('')
      }
    }
  }, [goBack, isInit])

  return (
    <>
      <div className={styles.breadCrumbs}>
        <div>
          <div className={`${styles.tab} ${styles.pointer}`} onClick={() => navigateToAssignment()}>
            Assignment
          </div>
          <div>
            <img src={ArrowIcon} alt='' />
          </div>
          <div className={styles.tab}>
            {status !== AssignmentStatusEnum.DRAFT ? selectedAssignment.name : assignmentActionType === 'create' ? 'Add New Assignment' : 'Edit Assignment'}
          </div>
        </div>
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.left}>
          <QuestionList
            loadingGetList={questionLoadingGetList}
            setNewQuestionModal={setNewQuestionModal}
            setDeleteQuestionModal={setDeleteQuestionModal}
            questions={questions}
            setStep={setStep}
            setSelectedQuestionType={setSelectedQuestionType}
            setSelectedQuestion={setSelectedQuestion}
            setQuestionActionType={setQuestionActionType}
            selectedAssignment={selectedAssignment}
            call_duplicateQuestionApi={call_duplicateQuestionApi}
            questionPage={questionPage}
            questionTotalPage={questionTotalPage}
            handleQuestionPage={handleQuestionPage}
            handleQuestionTotalPage={handleQuestionTotalPage}
            setIsInit={setIsInit}
            setQuestions={setQuestions}
            setSeverity={setSeverity}
            handleOpenToaster={handleOpenToaster}
            status={status}
          />
        </div>
        <div className={styles.right}>
          <Information
            setIsInit={setIsInit}
            isInit={isInit}
            timerMin={timerMin}
            setTimerMin={setTimerMin}
            status={status}
            timerSec={timerSec}
            setTimerSec={setTimerSec}
            selectedAssignment={selectedAssignment}
            assignmentName={assignmentName}
            onAssignmentNameChange={onAssignmentNameChange}
            checkedTimer={checkedTimer}
            onCheckTimer={onCheckTimer}
          />
          <Duration 
            status={status} 
            durationStim={durationStim}
            durationEtim={durationEtim}
            handleDurationStimChange={onDurationStimChange}
            handleDurationEtimChange={onDurationEtimChange}
            setInvalidDuration={setInvalidDuration}
          />
        </div>
      </div>
      {(status === AssignmentStatusEnum.DRAFT) && (
        <div className={styles.btnWrap}>
          <SaveButton 
            txt='Save Assignment'
            isSaving={isAssignmentSaving}
            disabled={isInit || !assignmentName || timerMin === '' || timerSec === '' || disableSaveAssignment || invalidDuration || isAssignmentSaving}
            fill='none'
            action={null}
            onSaveAssignment={onSaveAssignment}
            saveMode={saveMode}
            setSaveMode={setSaveMode}
          />
          <SaveButton 
            txt='Save & Publish Assignment'
            isSaving={isAssignmentSaving}
            disabled={isInit || !assignmentName || timerMin === '' || timerSec === '' || disableSaveAssignment || invalidDuration || isAssignmentSaving}
            fill='blue'
            action='publish'
            onSaveAssignment={onSaveAssignment}
            saveMode={saveMode}
            setSaveMode={setSaveMode}
          />
        </div>
      )}
      {newQuestionModal && (
        <Modal>
          <AddQuestionModal setNewQuestionModal={setNewQuestionModal} setStep={setStep} setSelectedQuestionType={setSelectedQuestionType} />
        </Modal>
      )}
      {deleteQuestionModal && (
        <Modal>
          <DeleteQuestionModal onDeleteQuestion={onDeleteQuestion} setDeleteQuestionModal={setDeleteQuestionModal} />
        </Modal>
      )}
      {discardChangesModal && (
        <Modal>
          <DiscardChangesModal onDiscard={onDiscard} onCancel={onCancel} />
        </Modal>
      )}
    </>
  )
}

export default AddNewAssignment
