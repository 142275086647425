import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import styles from './TimePicker.module.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { styled } from '@mui/system'

const inputStyle = {
  font: 'normal normal normal 14px/17px InterRegular',
  // border: '1px solid #B3B3B3',
  borderRadius: '8px',
  backgroundColor: '#fff',
  height: '48px'
}

export function HourMinPicker({ label, value, handleChange, otherValue }) {
  const maxTime = () => {
    if (label === 'Start Time') {
      return dayjs(dayjs(otherValue).subtract(1, 'minute'))
    }
    return null
  }

  const minTime = () => {
    if (label === 'End Time') {
      return dayjs(dayjs(otherValue).add(1, 'minute'))
    }
    return null
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={label}
        value={dayjs(value)}
        onChange={handleChange}
        minTime={minTime()}
        maxTime={maxTime()}
        slotProps={{ textField: { InputProps: { sx: inputStyle } } }}
      />
    </LocalizationProvider>
  )
}

function DateLink(props) {
  const { setOpen, value, typ, InputProps: { ref } = {}, inputProps: { 'aria-label': ariaLabel } = {} } = props

  return (
    <div className={styles[typ]} ref={ref} aria-label={ariaLabel} onClick={() => setOpen?.((prev) => !prev)}>
      {dayjs(value).format('YYYY/MM/DD')}
    </div>
  )
}

export function LinkDatePicker({ typ, value, handleChange }) {
  const [open, setOpen] = useState(false)
  const [view, setView] = useState(['year', 'month', 'day'])

  useEffect(() => {
    if (typ === 'monthInput') {
      setView(['year', 'month'])
    } else {
      setView(['year', 'month', 'day'])
    }
  }, [typ])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        slots={{ field: DateLink }}
        slotProps={{ field: { setOpen, typ } }}
        value={value}
        onChange={handleChange}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        views={view}
      />
    </LocalizationProvider>
  )
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'dayIsSelected'
})(({ theme, dayIsSelected }) => ({
  ...(dayIsSelected && {
    backgroundColor: '#409EFF47',
    color: 'black',
    '&:hover, &:focus': {
      backgroundColor: '#409EFF47'
    }
  })
}))

function CustomDay(props) {
  const { selectedDatesArr = [], day, outsideCurrentMonth, ...other } = props

  return (
    <CustomPickersDay
      dayIsSelected={!!selectedDatesArr.find((item) => dayjs(day).format('YYYY/MM/DD') === dayjs(item).format('YYYY/MM/DD'))}
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
    />
  )
}

export function ReadOnlyCalendar({ selectedDatesArr }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        defaultCalendarMonth={dayjs(selectedDatesArr[0]).date(1)}
        disableHighlightToday
        readOnly
        slots={{ day: CustomDay }}
        slotProps={{
          toolbar: {
            hidden: true
          },
          actionBar: {
            actions: []
          },
          tabs: {
            hidden: true
          },
          day: {
            selectedDatesArr
          }
        }}
      />
    </LocalizationProvider>
  )
}

export function DateHourMinPicker({ label, value, handleChange, otherValue, disabled, setInvalidDuration }) {
  const maxDateTime = () => {
    if (label === 's') {
      return dayjs(dayjs(otherValue).subtract(1, 'minute'))
    }
    return null
  }

  const minDateTime = () => {
    if (label === 'e') {
      return dayjs(dayjs(otherValue).add(1, 'minute'))
    }
    return null
  }

  const handleError = (err) => {
    setInvalidDuration(err && err != 'disablePast' ? true : false)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDateTimePicker
        disabled={disabled}
        slotProps={{ textField: { InputProps: { sx: inputStyle } } }}
        value={value}
        onChange={handleChange}
        maxDateTime={maxDateTime()}
        minDateTime={minDateTime()}
        disablePast
        disableHighlightToday
        onError={handleError}
      />
    </LocalizationProvider>
  )
}

export const CustomDatePicker = ({ value, handleChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker inputFormat='YYYY/MM/DD' value={value} onChange={handleChange} slotProps={{ textField: { InputProps: { sx: inputStyle } } }} />
    </LocalizationProvider>
  )
}
